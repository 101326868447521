export const SET_ACH_DATA = "setAchData"
export const GET_ACH_DATA = "getAchData"

export const SEND_MAIL = "sendmail"
export const SET_MAIL_RESPONSE = "setMailResponse" 
 
export const SET_CURRENT_FORM_STEP = "setCurrentFormState"
export const SET_PREVIOUS_FORM_STEP = "setPreviousFormState"
export const SET_BUSINESS_INFORMATION_FORM_DATA = "setBusinessInformationFormData"
export const SET_CONTROLLER_FORM_DATA = "setControllerFormData"
export const SET_BENEFICIAL_FORM_DATA = "setBeneficiaiFormData"
export const SET_BUSINESS_TYPE = "setBusinessType"
export const SET_PREVIOUS_BTN_CLICK = "setPreviousBtnClick"

export const GET_BUSINESS_CLASSIFICATION_DATA = "getBusinessClassificationData"
export const SET_BUSINESS_CLASSIFICATION_DATA = "setBusinessClassificationData"

export const GET_CONTROLLER_DATA_FROM_API = "getControllerDataFromAPI"
export const SET_CONTROLLER_DATA_FROM_API = "setControllerFormDataFromApi"
export const CREATE_BUSINESS_VERIFIED_CUSTOMER = "createBusinessVerifiedCustomer"
export const SET_BUSINESS_VERIFIED_CUSTOMER_RESPONSE = "setBusinessVerifiedCustomerResponse"

export const CREATE_BENEFICIAL_OWNER = "createBeneficialOwner"
export const SET_BENEFICIAL_OWNER_RESPONSE = "setBeneficialOwnerResponse"

export const SET_SELECTED_BUSINESS_CLASSIFICATION_DATA = "setSelectedBusinessClassificationData"

export const SET_FUNDING_SOURCE_INFO = "setFundingSourceInfo"

export const GET_IAV_TOKEN = "getIavToken"
export const SET_IAV_TOKEN = "setIavToken"

export const GET_IAV_TOKEN_EMPLOYEE = "getIavTokenForEmployee"

export const GET_FUNDING_SOURCE_STATUS = "getFundingSourceStatus"
export const SET_FUNDING_SOURCE_STATUS = "SetFundingSourceStatus"

export const GET_FUNDING_SOURCE_STATUS_EMPLOYEE = "getFundingSourceStatusEmployee"

export const DELETE_FUNDING_SOURCE_COMPANY = "deleteFundingSourceCompany"
export const DELETE_FUNDING_SOURCE_EMPLOYEE = "deleteFundingSourceEmployee"

export const SET_DELETE_FUNDING_SOURCE_STATUS = "SetDeleteFundingSourceStatus"

export const UPLOAD_DOCUMENT = "uploadDocument"
export const SET_UPLOAD_DOCUMENT_STATUS = "setUploadDocumentStatus"

export const SET_DOCUMENT_ARRAY_STATUS = "setDocumentArrayStatus"

export const SEND_ACCOUNT_ALIAS_NAME_EMPLOYEE = "sendAccountAliasNameEmployee"
export const SEND_ACCOUNT_ALIAS_NAME_COMPANY = "sendAccountAliasNameCompany"
export const SET_ACCOUNT_ALIAS_STATUS = "seTAccountAliasStatus"

export const VERIFY_MICRO_DEPOSIT_EMPLOYEE = "verifyMicroDepositEmployee"
export const SET_MICRO_DEPOSIT_VERIFICATION_STATUS_EMPLOYEE = "setMicroDepositVerificationStatusEmployee"
export const VERIFY_MICRO_DEPOSIT_COMPANY = "verifyMicroDepositCompany"
export const SET_MICRO_DEPOSIT_VERIFICATION_STATUS_COMPANY = "setMicroDepositVerificationStatusCompany"

export const SET_MICRO_DEPOSIT_ATTEMPTS_EMPLOYEE = "setMicrodepositAttemptsEmployee";
export const SET_MICRO_DEPOSIT_ATTEMPTS_COMPANY = "setMicrodepositAttemptsCompany";

export const RESET_COMMON_RESPONSE = "resetCommonResponse"

export const FETCH_DWOLLA_API_TOKEN = "fetchDwollaApiToken"
export const SET_DWOLLA_API_TOKEN="setDwollaApiToken"

export const CREATE_CUSTOMER_FOR_BACKEND="createCustomerForBackend"
export const SET_CREATE_CUSTOMER_FOR_BACKEND="setCreateCustomerForBackend"

