export const GET_TEMPLATE_LIST = "getTemplateList";
export const SET_TEMPLATE_LIST = "setTemplateList";

export const DELETE_TEMPLATE = "deleteTemplate";
export const SET_DELETE_TEMPLATE = "setDeleteTemplate";
export const RESET_DELETE_TEMPLATE = "resetDeleteTemplate";

export const GET_FIELDS_LIST = "getFieldsList";
export const SET_FIELDS_LIST = "setFieldsList";

export const CREATE_TEMPLATE = "createTemplate";
export const SET_CREATE_TEMPLATE = "setCreateTemplate";

export const APPEND_TEMPLATE = "appendTemplate";
export const RESET_APPEND_TEMPLATE = "resetAppendTemplate";

export const UPDATE_TEMPLATE = "updateTemplate";
export const RESET_UPDATE_TEMPLATE = "resetUpdateTemplate";

export const GET_TEMPLATE_DETAILS = "getTemplateDetails";
export const SET_TEMPLATE_DETAILS = "setTemplateDetails";

export const EDIT_TEMPLATE = "editTemplate";
export const SET_EDIT_TEMPLATE = "setEditTemplate";

export const GET_PREVIEW_TABLE_DATA = "getPreviewTableData";
export const SET_PREVIEW_TABLE_DATA = "setPreviewTableData";
export const RESET_PREVIEW_TABLE_DATA = "resetPreviewTableData";