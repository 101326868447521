export const GET_MILEAGE_CONFIG = "getMileageConfig";
export const SET_MILEAGE_CONFIG = "setMileageConfig";

export const CREATE_MILEAGE_CONFIG = "createMileageConfig";
export const SET_CREATE_MILEAGE_CONFIG = "setCreateMileageConfig";

export const EDIT_MILEAGE_CONFIG = "editMileageConfig";
export const SET_EDIT_MILEAGE_CONFIG = "setEditMileageConfig";

export const DELETE_MILEAGE_CONFIG = "deleteMileageConfig";
export const SET_DELETE_MILEAGE_CONFIG = "setDeleteMileageConfig";

//Mileage Type actions
export const CREATE_MILEAGE_TYPE = "createMileageType";
export const SET_CREATE_MILEAGE_TYPE = "setCreateMileageType";

export const GET_MILEAGE_TYPE = "getMileageType";
export const SET_MILEAGE_TYPE = "setMileageType";

export const EDIT_MILEAGE_TYPE = "editMileageType";
export const SET_EDIT_MILEAGE_TYPE = "setEditMileageType";

export const DELETE_MILEAGE_TYPE = "deleteMileageType";
export const SET_DELETE_MILEAGE_TYPE = "setDeleteMileageType";

export const GET_MILEAGE_TYPE_LIST = "getMileageTypeList";
export const SET_MILEAGE_TYPE_LIST = "setMileageTypeList";

export const CHANGE_CONFIG_STATUS = "changeConfigStatus";
export const SET_CHANGE_CONFIG_STATUS = "setChangeConfigStatus"

export const EDIT_DISTANCE_UNIT = "editDistanceUnit"
export const SET_DISTANCE_UNIT_RESPONSE = "setDistanceUnitResponse"

export const RESET_CONFIG_STATUS = "resetConfigStatus"
export const RESET_DELETE_CONFIG_STATUS = "resetDeleteConfigStatus"

