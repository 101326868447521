import createReducer from '../../../../components/common/Utility';

import {
	GET_REPORT_DETAILS,
	SET_REPORT_DETAILS,
	SET_APPROVAL_DETAILS,
	SET_REJECT_REPORT,
	SET_APPROVE_REPORT,
	SET_FRAUD_DETAILS,
	RESET_NAVIGATION_STATE
} from '../../../actions/Admin/Reports/Reports.action.type.js';

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
	fetchedReportDataForNavigation: {},
	fetchedReportData: {}, 
	creatorInfo: {},
	isLoadingData: false,
	fetchedApprovalsData: {},
	rejectOrApproveResponse: null,
	displayLoadMore: true,
	fetchedReportPayload: {}
}

export const reducerObj = {
	[SET_REPORT_DETAILS]: (prevState, payload) => {
		if(Object.keys(payload.data).length > 0) {
			//console.log(payload)
			return {
				reportID : payload.data.id,
				displayLoadMore : payload.data?.expense?.length >= 10 && payload.data?.expense?.length >= 10,
				fetchedReportData: Object.keys(prevState.fetchedReportData).length !== 0 ? {...prevState.fetchedReportData , expense: [...prevState.fetchedReportData.expense , ...payload.data.expense]} : payload.data ,
				fetchedReportDataForNavigation: Object.keys(prevState.fetchedReportDataForNavigation).length !== 0 ? {...prevState.fetchedReportDataForNavigation , expense: [...prevState.fetchedReportDataForNavigation.expense , ...payload.data.expense]} : payload.data ,
				creatorInfo: payload.data.creator_info,
				isLoadingData: false,
				fetchedReportPayload: payload
			}
		} else {
			return {
				displayLoadMore : false,
			}
		}
		// // console.log(payload , "INSIDE THE REDUCER")
		// // console.log(Object.keys(payload.data).length)
		// // console.log(payload.data,"CHECKING pyaload")
		// let payloadObject = payload.data;
		// console.log(payload.data)
		// // let a = true;
		// // if(Object.keys(payload.data).length === 0) {
		// // 	a=false;
		// // }
		// // console.log(a,"a")
		// return {
		// 	displayLoadMore : payload.data ,
		// 	fetchedReportData: Object.keys(prevState.fetchedReportData).length !== 0 ? {...prevState.fetchedReportData , expense: [...prevState.fetchedReportData.expense , ...payload.data.expense]} : payload.data ,
		// 	creatorInfo: payload.data.creator_info,
		// 	isLoadingData: false,
		// 	fetchedReportPayload: payload
		// }
	},

	[SET_FRAUD_DETAILS] : (prevState, payload) => {
		let expenseId;
		payload.forEach(fraudObj => {
			if(Number(fraudObj?.expense_id) > 0){
				expenseId = Number(fraudObj.expense_id)
				return;
			}
		})
		let expense = [...prevState.fetchedReportData.expense]
		let tempArr;
		if(expenseId && prevState.fetchedReportData && prevState.fetchedReportData.expense){
			 prevState.fetchedReportData.expense.forEach((expObj,i) => {
				if(expObj.id === expenseId){
					tempArr = {...expense[i]};
					tempArr.checks = payload;
					expense[i] = tempArr;
					return;
				}
			})
			return{
				fetchedReportData : {...prevState.fetchedReportData, expense}
			}
		}
	},

	[SET_APPROVAL_DETAILS]: (prevState, payload) => (
		{
			fetchedApprovalsData: payload.data,
		}
	),

	// ------------ approve and reject Reports -------------
	[SET_REJECT_REPORT]: (prevState, payload) => (
		{
			rejectOrApproveResponse: payload
		}
	),
	[SET_APPROVE_REPORT]: (prevState, payload) => (
		{
			rejectOrApproveResponse: payload
		}
	),

	[RESET_STATE]: () => (
		{
			fetchedReportData: {},
			creatorInfo: {},
			isLoadingData: false,
			fetchedApprovalsData: {},
			rejectOrApproveResponse: null,
			fetchedReportPayload: {}
		}
	),

	[RESET_NAVIGATION_STATE]: () => (
		{
			fetchedReportDataForNavigation: {},
			reportID: null
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => (
		payload === GET_REPORT_DETAILS
			? { isLoadingData: true }
			: { isLoadingData: false }
	),
	[API_END]: (prevState, payload) => (
		payload === SET_REPORT_DETAILS
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
}

let ReportReducer = createReducer(initialState, reducerObj)

export default ReportReducer;