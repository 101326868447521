import actionCreator from "../../../Common/Utils"

import { RESET_STATE } from "../../../Common/Api.types"
import { exportTemplates } from "../../../../../Urls"
import { APPEND_TEMPLATE, CREATE_TEMPLATE, DELETE_TEMPLATE, GET_FIELDS_LIST, GET_TEMPLATE_LIST, SET_CREATE_TEMPLATE, SET_DELETE_TEMPLATE, SET_FIELDS_LIST, SET_TEMPLATE_LIST,RESET_APPEND_TEMPLATE, GET_TEMPLATE_DETAILS, SET_TEMPLATE_DETAILS, RESET_DELETE_TEMPLATE, EDIT_TEMPLATE, SET_EDIT_TEMPLATE, UPDATE_TEMPLATE, RESET_UPDATE_TEMPLATE, SET_PREVIEW_TABLE_DATA, RESET_PREVIEW_TABLE_DATA } from "./ExportTemplates.action.types"

export const getTemplateList = (token, method , data) => {
    return actionCreator({
        method,
        data,
        url: exportTemplates.templateList,
        onSuccess:setTemplateList,
        accessToken: token,
        onFailure: setTemplateList,
        label: GET_TEMPLATE_LIST
    })

    function setTemplateList(resData){
        return {
            type: SET_TEMPLATE_LIST,
            payload: resData
        }
    }
}

export const deleteTemplate = (token, method , data,id) => {
    return actionCreator({
        method,
        data,
        url: `${exportTemplates.templateList}/${id}`,
        onSuccess:setDeleteTemplate,
        accessToken: token,
        onFailure: setDeleteTemplate,
        label: DELETE_TEMPLATE
    })

    function setDeleteTemplate(resData){
        return {
            type: SET_DELETE_TEMPLATE,
            payload: resData
        }
    }
}

export const getFieldsList = (token, method , data) => {
    return actionCreator({
        method,
        data,
        url: exportTemplates.fieldsList,
        onSuccess:setFieldsList,
        accessToken: token,
        onFailure: setFieldsList,
        label: GET_FIELDS_LIST
    })

    function setFieldsList(resData){
        return {
            type: SET_FIELDS_LIST,
            payload: resData
        }
    }
}

export const createTemplate = (token, method , data) => {
    return actionCreator({
        method,
        data,
        url: exportTemplates.templateList,
        onSuccess:setTemplate,
        accessToken: token,
        onFailure: setTemplate,
        label: CREATE_TEMPLATE
    })

    function setTemplate(resData){
        return {
            type: SET_CREATE_TEMPLATE,
            payload: resData
        }
    }
}

export const appendTemplate = (templateData) => {
    return {
        type: APPEND_TEMPLATE,
        payload: templateData
    }
}

export const updateTemplate = (templateData) => {
    return {
        type: UPDATE_TEMPLATE,
        payload: templateData
    }
}



export const editTemplate = (token, method , data, id) => {
    return actionCreator({
        method,
        data,
        url: `${exportTemplates.templateList}/${id}`,
        onSuccess:setTemplate,
        accessToken: token,
        onFailure: setTemplate,
        label: EDIT_TEMPLATE
    })

    function setTemplate(resData){
        return {
            type: SET_EDIT_TEMPLATE,
            payload: resData
        }
    }
}

export const getPreviewTableData = (token, method , data) => {
    return actionCreator({
        method,
        data,
        url: exportTemplates.previewTableData,
        onSuccess: setPreviewTableData,
        accessToken: token,
        onFailure: setPreviewTableData,
        label: GET_FIELDS_LIST
    })

    function setPreviewTableData(resData){
        return {
            type: SET_PREVIEW_TABLE_DATA,
            payload: resData
        }
    }
}

export const resetAppendTemplate = () => {
    return{
        type: RESET_APPEND_TEMPLATE
    }
}

export const resetUpdateTemplate = () => {
    return{
        type: RESET_UPDATE_TEMPLATE
    }
}

export const resetDeleteTemplate = () => {
    return {
        type: RESET_DELETE_TEMPLATE
    }
}

export const resetPreviewTableData = () => {
    return {
        type: RESET_PREVIEW_TABLE_DATA
    }
}

export const getTemplateDetails = (token, method , data,id) => {
    return actionCreator({
        method,
        data,
        url: `${exportTemplates.templateList}/${id}`,
        onSuccess: setTemplateDetails,
        accessToken: token,
        onFailure:  setTemplateDetails,
        label: GET_TEMPLATE_DETAILS
    })

    function  setTemplateDetails(resData){
        return {
            type: SET_TEMPLATE_DETAILS,
            payload: resData
        }
    }
}