import React, { useState, useEffect } from "react";
import { Breadcrumb, Row, Col, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import localStorageService from "../../common/services/Storage_services/LocalStorageService";
import pageHeader from "../../common/components/UI/pageHeader";
import CountUp from 'react-countup';
import Skeleton from 'react-loading-skeleton';

const userAgentString = navigator.userAgent;
let safariAgent = userAgentString.indexOf("Safari") > -1;
let chromeAgent = userAgentString.indexOf("Chrome") > -1;

const LandingPage = () => {
	const [data, setData] = useState({});
	const [showVideo, setShowVideo] = useState(false);
	useEffect(() => {

		// For detecting Safari Browser
		if ((chromeAgent) && (safariAgent)) safariAgent = false;
		
		// Loading the dashboard data.
		axios({
			method: "get",
			url: "/dashboard/details",
			headers: {
				"access-token": JSON.parse(
					localStorageService.getAccessToken()
				),
				'request-from': "web"
			},
		})
			.then((res) => {
				if (res.status === 200) {
					setData(res.data);
				}
			})
			.catch((error) => {
				if (error.response && error.response.status === 401) {
					console.log("Error---->", error);
				}
			});
	}, []);
	const headerComponent = () => {
		return (
			<div className='contents'>
				<div className='left'>
					<Breadcrumb>
						<li
							className='breadcrumb-item'
							style={{ color: "#71717A" }}
						>
							Individual
						</li>
						<Breadcrumb.Item style={{ fontWeight: 500 }} active>Home</Breadcrumb.Item>
					</Breadcrumb>
					<p className='component-name'>Home</p>
				</div>
			</div>
		);
	};

	const amountsDecimalFormat = (amt) => {
		if (amt) {

			let _amount = amt.split('.');
			if (_amount.length > 1) {
				return _amount[0] + '.' + _amount[1].slice(0, 2);
			}

			return amt;
		}
	}

	return (
		<div className='component-container draft-expense-container submitter-ui-container submitter-ui-container-msite'>
			{pageHeader({
				type: "submitterUiPageHeader",
				headerComponent,
				// handleToggleSidebar,
				borderBottom: false,
				isHomeComponent: true,
			})}

			<div className='landing-page-showcase'>
				<div className='user-detail landing-cards'>
					<h6>Hello, {data && data.user_name ? data.user_name : <Skeleton height={20} count={1} />}</h6>
					<hr />
					<div className='create-exp'>
						{/* <Link to="/individual/reports#draft_report" params={{ "testvalue": "hello" }}> */}
						<Link
							to={{
								pathname: "/individual/reports",
								hash: "#draft_report",
								state: { fromDashboard: true },
							}}
						>
							<button>
								<svg
									width='24'
									height='28'
									viewBox='0 0 24 28'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M7.75004 15.4167H16.25M12 11.1667V19.6667M19.0834 26.75H4.91671C4.16526 26.75 3.44459 26.4515 2.91324 25.9201C2.38188 25.3888 2.08337 24.6681 2.08337 23.9167V4.08333C2.08337 3.33189 2.38188 2.61122 2.91324 2.07986C3.44459 1.54851 4.16526 1.25 4.91671 1.25H12.8302C13.2059 1.25008 13.5662 1.39939 13.8318 1.66508L21.5016 9.33492C21.7673 9.60053 21.9166 9.96081 21.9167 10.3365V23.9167C21.9167 24.6681 21.6182 25.3888 21.0868 25.9201C20.5555 26.4515 19.8348 26.75 19.0834 26.75Z'
										stroke='#4A5568'
										strokeWidth='2.5'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
								<span>Create Report</span>
							</button>
						</Link>
						<Link to='/individual/reports/expense/create#expense' onClick={()=>window.localStorage.setItem("previousTab","home")}>
							<button>
								<svg
									width='26'
									height='28'
									viewBox='0 0 26 28'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8.74996 15.4167H13M13 15.4167H17.25M13 15.4167V11.1667M13 15.4167V19.6667M24.3333 26.75V4.08333C24.3333 3.33189 24.0348 2.61122 23.5034 2.07986C22.9721 1.54851 22.2514 1.25 21.5 1.25H4.49996C3.74851 1.25 3.02784 1.54851 2.49649 2.07986C1.96514 2.61122 1.66663 3.33189 1.66663 4.08333V26.75L7.33329 23.9167L13 26.75L18.6666 23.9167L24.3333 26.75Z'
										stroke='#4A5568'
										strokeWidth='2.5'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
								<span>Create Expense</span>
							</button>
						</Link>
					</div>
				</div>
				<div className='user-guide landing-cards'>
					<div className='user-guide-video'>
						<h6 className='landing-card-heading'>User Guide</h6>
						<button onClick={() => data && data.web_guide_video ? setShowVideo(true) : null}>
							<svg
								width='16'
								height='12'
								viewBox='0 0 16 12'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M14.75 2.3625C14.5221 2.2599 14.2691 2.22653 14.0224 2.26653C13.7758 2.30653 13.5463 2.41815 13.3625 2.5875L11.75 4.0875V3C11.75 2.40326 11.5129 1.83097 11.091 1.40901C10.669 0.987053 10.0967 0.75 9.5 0.75H2.75C2.15326 0.75 1.58097 0.987053 1.15901 1.40901C0.737053 1.83097 0.5 2.40326 0.5 3V9C0.5 9.59674 0.737053 10.169 1.15901 10.591C1.58097 11.0129 2.15326 11.25 2.75 11.25H9.5C10.0967 11.25 10.669 11.0129 11.091 10.591C11.5129 10.169 11.75 9.59674 11.75 9V7.9125L13.37 9.4125C13.6085 9.62838 13.9183 9.74858 14.24 9.75C14.4185 9.74958 14.5949 9.71124 14.7575 9.6375C14.9787 9.54802 15.1682 9.39462 15.3018 9.1969C15.4354 8.99918 15.507 8.76612 15.5075 8.5275V3.4725C15.5064 3.23302 15.4337 2.99933 15.2987 2.80152C15.1637 2.60371 14.9726 2.45081 14.75 2.3625ZM10.25 9C10.25 9.19891 10.171 9.38968 10.0303 9.53033C9.88968 9.67098 9.69891 9.75 9.5 9.75H2.75C2.55109 9.75 2.36032 9.67098 2.21967 9.53033C2.07902 9.38968 2 9.19891 2 9V3C2 2.80109 2.07902 2.61032 2.21967 2.46967C2.36032 2.32902 2.55109 2.25 2.75 2.25H9.5C9.69891 2.25 9.88968 2.32902 10.0303 2.46967C10.171 2.61032 10.25 2.80109 10.25 3V9ZM14 7.95L11.8925 6L14 4.05V7.95Z'
									fill='#EB5C24'
								/>
							</svg>
							<span> Watch Video </span>
						</button>
						<div className="ug-faq-desc">Explore Itilite's features, <a href="https://www.itilite.com/in/help-center/" target="_blank" className="ug-faq-link">Check FAQs</a></div>
					</div>
					<div className='user-guide-img'>
						<img
							src='/assets/images/user-guide.svg'
							alt='user-guide-pic'
						/>
					</div>
				</div>
				<div
					className={`draft-expense ${data.approval_report_count === undefined
						? "only landing-cards"
						: "landing-cards"
						}`}
				>
					<Link to='/individual/reports#draft_expense'>
						<div className='d-flex report-wrapper'>
							<div>
								<svg
									width='18'
									height='20'
									viewBox='0 0 18 20'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M5.99999 8.8H12M5.99999 12.8H12M17 19V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V19L5 17L9 19L13 17L17 19Z'
										stroke='#4A5568'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
							<div>
								<h6 className='landing-card-heading'>
									Draft Expenses{" "}
									<span>
										<svg
											width='9'
											height='14'
											viewBox='0 0 9 14'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M1.5 1.16675L7.33333 7.00008L1.5 12.8334'
												stroke='#4A5568'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</span>
								</h6>
								<h5 className='landing-card-numbers'>
									{/* {(data && data.draft_expense_count) || "0"} */}
									{(data.draft_expense_count && <CountUp className="landing-card-numbers" start={0} duration={2.75} separator="," end={data.draft_expense_count} />) || 0}
								</h5>
							</div>
						</div>
					</Link>
				</div>
				<div
					className={`reports-to-be-approved ${data.draft_expense_count === undefined
						? "only landing-cards"
						: "landing-cards"
						}`}
				>
					<Link to='/individual/approvals#all'>
						<div className='d-flex report-wrapper'>
							<div>
								<svg
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M7 10L9 12L13 8M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z'
										stroke='#4A5568'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
							</div>
							<div>
								<h6 className='landing-card-heading'>
									Reports To Be Approved{" "}
									<span>
										<svg
											width='9'
											height='14'
											viewBox='0 0 9 14'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M1.5 1.16675L7.33333 7.00008L1.5 12.8334'
												stroke='#4A5568'
												strokeWidth='2'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</span>
								</h6>
								<h5 className='landing-card-numbers'>
									{/* {(data && data.approval_report_count) || "0"} */}
									{(data && data.approval_report_count && <CountUp start={0} duration={2.75} separator="," end={data.approval_report_count} />) || 0}
								</h5>
							</div>
						</div>
					</Link>
				</div>
				<div className='your-reports'>
					<h6>Your Reports</h6>
					<div>
						<div>
							<h6 className='landing-card-heading'>
								Drafts -{" "}
								{/* {(data && data.unsubmitted_reports_count) || "0"} */}
								{data && data.unsubmitted_reports_count && <CountUp start={0} duration={2.75} separator="," end={data.unsubmitted_reports_count} />}
							</h6>
							<h4 className='landing-card-numbers'>
								{data && data.currency}{" "}
								{/* {(data && amountsDecimalFormat(data.unsubmitted_reports_amount)) || "0"}{" "} */}
								{(data && amountsDecimalFormat(data.unsubmitted_reports_amount) && <CountUp start={0} duration={2.75} separator="," decimals={2} end={parseFloat(amountsDecimalFormat(data.unsubmitted_reports_amount).replace(/,/g, ""))} />) || 0}
							</h4>
						</div>
						<div>
							<h6 className='landing-card-heading'>
								Awaiting Approval -{" "}
								{/* {(data && data.awaiting_approval_reports_count) ||"0"} */}
								{(data && data.awaiting_approval_reports_count && <CountUp start={0} duration={2.75} separator="," end={data.awaiting_approval_reports_count} />) || 0}
							</h6>
							<h4 className='landing-card-numbers'>
								{data && data.currency}{" "}
								{/* {(data && amountsDecimalFormat(data.awaiting_approval_reports_amount)) || "0"}{" "} */}
								{(data && amountsDecimalFormat(data.awaiting_approval_reports_amount) && <CountUp start={0} duration={2.75} separator="," decimals={2} end={parseFloat(amountsDecimalFormat(data.awaiting_approval_reports_amount).replace(/,/g, ""))} />) || 0}
							</h4>
						</div>
						<div>
							<h6 className='landing-card-heading'>
								Awaiting Payments -{" "}
								{/* {(data && data.awaiting_payment_count) || "0"} */}
								{(data && data.awaiting_payment_count && <CountUp start={0} duration={2.75} separator="," end={data.awaiting_payment_count} />) || 0}
							</h6>
							<h4 className='landing-card-numbers'>
								{data && data.currency}{" "}
								{/* {(data && amountsDecimalFormat(data.awaiting_payment_amount)) || "0"}{" "} */}
								{(data && amountsDecimalFormat(data.awaiting_payment_amount) && <CountUp start={0} duration={2.75} separator="," decimals={2} end={parseFloat(amountsDecimalFormat(data.awaiting_payment_amount).replace(/,/g, ""))} />) || 0}
							</h4>
						</div>
					</div>
				</div>
				<div className='step-to-get-reimbursed'>
					<h6>Steps To Get Reimbursed</h6>
					<div>
						<div>
							<svg
								width='24'
								height='28'
								viewBox='0 0 24 28'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.75004 15.4167H16.25M12 11.1667V19.6667M19.0834 26.75H4.91671C4.16526 26.75 3.44459 26.4515 2.91324 25.9201C2.38188 25.3888 2.08337 24.6681 2.08337 23.9167V4.08333C2.08337 3.33189 2.38188 2.61122 2.91324 2.07986C3.44459 1.54851 4.16526 1.25 4.91671 1.25H12.8302C13.2059 1.25008 13.5662 1.39939 13.8318 1.66508L21.5016 9.33492C21.7673 9.60053 21.9166 9.96081 21.9167 10.3365V23.9167C21.9167 24.6681 21.6182 25.3888 21.0868 25.9201C20.5555 26.4515 19.8348 26.75 19.0834 26.75Z'
									stroke='#4A5568'
									strokeWidth='2.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
							<p>Create Report</p>
						</div>
						<div>
							<svg
								width='20'
								height='16'
								viewBox='0 0 20 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M12 1L19 8M19 8L12 15M19 8H1'
									stroke='#CBD2DE'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</div>
						<div>
							<svg
								width='26'
								height='28'
								viewBox='0 0 26 28'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M8.74996 15.4167H13M13 15.4167H17.25M13 15.4167V11.1667M13 15.4167V19.6667M24.3333 26.75V4.08333C24.3333 3.33189 24.0348 2.61122 23.5034 2.07986C22.9721 1.54851 22.2514 1.25 21.5 1.25H4.49996C3.74851 1.25 3.02784 1.54851 2.49649 2.07986C1.96514 2.61122 1.66663 3.33189 1.66663 4.08333V26.75L7.33329 23.9167L13 26.75L18.6666 23.9167L24.3333 26.75Z'
									stroke='#4A5568'
									strokeWidth='2.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>

							<p>Create Expense</p>
						</div>
						<div>
							<svg
								width='20'
								height='16'
								viewBox='0 0 20 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M12 1L19 8M19 8L12 15M19 8H1'
									stroke='#CBD2DE'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</div>
						<div>
							<svg
								width='30'
								height='29'
								viewBox='0 0 30 29'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M11.4499 20.1224L11.5142 20.0458L11.45 20.1224C12.0614 20.6354 12.9975 20.2004 12.9975 19.4008V8.6341C12.9975 8.22505 13.3304 7.8921 13.7395 7.8921C14.1485 7.8921 14.4814 8.22505 14.4814 8.6341V15.3701C14.4814 15.8453 14.8355 16.2461 15.307 16.3048L15.3194 16.2056L15.307 16.3048L20.5671 16.9601C20.9377 17.0063 21.2174 17.323 21.2174 17.6964V22.848H12.3983L8.68097 19.7289C8.39346 19.4876 8.37635 19.1043 8.6421 18.8385C8.95054 18.5301 9.52254 18.5051 9.85667 18.7855L11.4499 20.1224ZM23.83 0.9H6.91014C3.59605 0.9 0.9 3.59605 0.9 6.91014C0.9 10.2242 3.59605 12.9203 6.91014 12.9203H11.1135V17.3807L11.0676 17.3423C9.98929 16.4374 8.30541 16.5107 7.30993 17.5063C6.26378 18.5525 6.33231 20.2176 7.47 21.1721L11.1134 24.2294V27.158C11.1134 27.6782 11.5352 28.1 12.0554 28.1H22.1594C22.6796 28.1 23.1014 27.6782 23.1014 27.158V17.6964C23.1014 16.3742 22.1121 15.2541 20.8 15.0906L16.3654 14.5381V12.9202H23.83C27.1441 12.9202 29.8401 10.2241 29.8401 6.91003C29.8401 3.59593 27.1441 0.9 23.83 0.9ZM12.9974 26.216V24.732H21.2174V26.216H12.9974ZM23.83 11.0362H16.3654V8.6341C16.3654 7.18604 15.1875 6.00811 13.7394 6.00811C12.2913 6.00811 11.1134 7.18604 11.1134 8.6341V11.0362H6.91008C4.63506 11.0362 2.78399 9.18516 2.78399 6.91014C2.78399 4.63511 4.63506 2.78399 6.91014 2.78399H23.83C26.1051 2.78399 27.9561 4.63506 27.9561 6.91014C27.9561 9.18522 26.1051 11.0362 23.83 11.0362Z'
									fill='#4A5568'
									stroke='#4A5568'
									strokeWidth='0.2'
								/>
								<path
									d='M23.1184 4.54388L20.4165 7.24574L19.3986 6.22787C19.0308 5.85998 18.4343 5.85999 18.0664 6.22786C17.6985 6.59574 17.6985 7.19216 18.0664 7.56004L19.7504 9.24402C19.7504 9.24402 19.7504 9.24403 19.7504 9.24403C20.1182 9.6119 20.7147 9.61197 21.0826 9.24403C21.0826 9.24403 21.0826 9.24402 21.0826 9.24402L24.4506 5.87604C24.8184 5.50816 24.8184 4.91175 24.4506 4.54387C24.0827 4.17594 23.4863 4.17594 23.1184 4.54387C23.1184 4.54388 23.1184 4.54388 23.1184 4.54388Z'
									fill='#4A5568'
									stroke='#4A5568'
									strokeWidth='0.2'
								/>
							</svg>
							<p>Submit Report</p>
						</div>
						<div>
							<svg
								width='20'
								height='16'
								viewBox='0 0 20 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M12 1L19 8M19 8L12 15M19 8H1'
									stroke='#CBD2DE'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</div>
						<div>
							<svg
								width='28'
								height='28'
								viewBox='0 0 28 28'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M9.75 14L12.5833 16.8333L18.25 11.1667M26.75 14C26.75 15.6744 26.4202 17.3323 25.7795 18.8792C25.1387 20.4261 24.1996 21.8317 23.0156 23.0156C21.8317 24.1996 20.4261 25.1387 18.8792 25.7795C17.3323 26.4202 15.6744 26.75 14 26.75C12.3256 26.75 10.6677 26.4202 9.12079 25.7795C7.57388 25.1387 6.16834 24.1996 4.98439 23.0156C3.80044 21.8317 2.86128 20.4261 2.22054 18.8792C1.57979 17.3323 1.25 15.6744 1.25 14C1.25 10.6185 2.5933 7.37548 4.98439 4.98439C7.37548 2.5933 10.6185 1.25 14 1.25C17.3815 1.25 20.6245 2.5933 23.0156 4.98439C25.4067 7.37548 26.75 10.6185 26.75 14Z'
									stroke='#4A5568'
									strokeWidth='2.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
							<p>Get Approval</p>
						</div>
						<div>
							<svg
								width='20'
								height='16'
								viewBox='0 0 20 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M12 1L19 8M19 8L12 15M19 8H1'
									stroke='#CBD2DE'
									strokeWidth='2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</div>
						<div>
							<svg
								width='34'
								height='34'
								viewBox='0 0 34 34'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M29.4448 22.1083C29.4108 21.0248 29.1793 20.4018 28.6941 20.0882C27.0908 19.0516 24.365 20.2322 22.6783 21.1434C22.356 20.7043 21.8022 20.4649 21.2215 20.5417L18.9669 20.8417C18.5809 20.8932 18.1805 20.836 17.809 20.6766C17.3749 20.4903 16.5667 20.1436 15.2698 20.2917C12.9404 20.5578 13.0066 20.7484 12.3649 21.0574C12.2174 21.1239 11.7131 21.2058 11.134 21.265C10.879 20.6893 10.3025 20.2864 9.6334 20.2864H6.64063C5.73598 20.2864 5 21.0224 5 21.9271V29.3595C5 30.2641 5.73598 31.0001 6.64063 31.0001H9.6334C10.3867 31.0001 11.0227 30.4895 11.2145 29.7964L12.4378 29.5738C12.7822 29.5233 12.3538 29.5196 19.715 30.0235C21.7645 30.1638 23.735 29.3806 25.0321 28.0364C29.1196 23.854 28.4292 24.5587 28.5091 24.4787C29.1398 23.8243 29.4722 22.9825 29.4448 22.1083V22.1083ZM9.63334 29.3595H6.64063V21.9271H9.6334V29.3595H9.63334ZM27.3298 23.3381C23.2422 27.5204 23.9331 26.8155 23.8531 26.8955C22.8527 27.9334 21.3475 28.4909 19.827 28.3868L13.2132 27.9344C12.501 27.8531 12.0711 27.9732 11.274 28.1181V22.9016C11.9095 22.8392 12.6774 22.728 13.0753 22.5363C13.6849 22.243 13.4687 22.1489 15.4559 21.9219C16.3202 21.8231 16.8268 22.0405 17.1621 22.1844C17.8054 22.4605 18.5042 22.5585 19.1834 22.4681L21.3682 22.1774C21.4474 22.4512 21.4665 22.8223 21.3628 23.0532C21.3103 23.1702 21.2318 23.2411 21.1004 23.2902C20.1912 23.6302 20.1912 23.6302 16.2891 24.1941C15.8408 24.2589 15.5298 24.6749 15.5946 25.1233C15.6596 25.5732 16.0772 25.8827 16.5238 25.8179C20.524 25.2398 20.6005 25.2287 21.675 24.8269C22.4838 24.5244 23.0212 23.8151 23.0698 22.7994C24.5859 21.9179 26.8214 21.0037 27.7117 21.4157C27.7413 21.5099 27.7914 21.7289 27.805 22.1598C27.8184 22.5869 27.6496 23.0053 27.3298 23.3381V23.3381Z'
									fill='#4A5568'
								/>
								<path
									d='M19 18.6406C23.3122 18.6406 26.8203 15.1325 26.8203 10.8203C26.8203 6.50815 23.3122 3 19 3C14.6878 3 11.1797 6.50815 11.1797 10.8203C11.1797 15.1325 14.6878 18.6406 19 18.6406ZM19 4.64062C22.4075 4.64062 25.1797 7.41279 25.1797 10.8203C25.1797 14.2278 22.4075 17 19 17C15.5925 17 12.8203 14.2278 12.8203 10.8203C12.8203 7.41279 15.5925 4.64062 19 4.64062Z'
									fill='#4A5568'
								/>
								<path
									d='M16.8957 14.3033C17.3868 14.6246 17.7613 14.7738 18.1872 14.839V14.9836C18.1872 15.4366 18.5545 15.8039 19.0076 15.8039C19.4606 15.8039 19.8279 15.4366 19.8279 14.9836V14.7509C20.7836 14.4299 21.3945 13.5785 21.5358 12.7379C21.7308 11.5774 21.1023 10.5293 19.9718 10.1297C19.3984 9.927 18.7658 9.68343 18.4215 9.41338C18.3687 9.37198 18.3296 9.23734 18.3613 9.0695C18.377 8.98594 18.4499 8.70665 18.7253 8.62369C19.4256 8.41276 19.7396 8.80367 20.0162 8.94837C20.4177 9.15826 20.9133 9.003 21.1232 8.60149C21.328 8.20976 21.1851 7.72835 20.8051 7.51025C20.5239 7.31929 20.2618 7.14368 19.828 7.02179V6.89502C19.828 6.44199 19.4607 6.07471 19.0077 6.07471C18.5546 6.07471 18.1874 6.44199 18.1874 6.89502V7.07494C16.6003 7.60995 16.213 9.76617 17.4091 10.7044C17.9387 11.1198 18.691 11.4171 19.425 11.6766C19.9131 11.8491 19.9624 12.2011 19.9179 12.466C19.8553 12.8383 19.5421 13.2406 19.0022 13.2442C18.3991 13.2493 18.2418 13.2235 17.7939 12.9304C17.4148 12.6824 16.9064 12.7886 16.6583 13.1678C16.4103 13.5468 16.5166 14.0552 16.8957 14.3033V14.3033Z'
									fill='#4A5568'
								/>
							</svg>
							<p>Get Reimbursed </p>
						</div>
					</div>
				</div>
			</div>
			<Modal
				show={showVideo}
				onHide={() => setShowVideo(false)}
				className='demoVideo'
				centered
			>
				<Modal.Body className="landing-ifram-modal">
					{
						safariAgent ? 
							<iframe
								src={`${data?.web_guide_video}?&autoplay=1`}
								width='800'
								height='95%'
								frameBorder='0'
								allow='autoplay; fullscreen; picture-in-picture'
								title='Demo Video'
								allowFullScreen
							></iframe>
						:
							<embed type="video/webm" width="800" src={`${data?.web_guide_video}?&autoplay=1`}></embed>
						
					}
				</Modal.Body>
			</Modal>
			<footer>
				<Row className='w-100'>
					<Col xs={12} md={6}>
						<div className='need-help-section'>
							<h5>Need help?</h5>
							{data && data.support_mail_text ? (
								<div className='reach-out-by-mail'>
									<div>
										<svg
											width='28'
											height='28'
											viewBox='0 0 28 28'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M1.25 23.9167V11.2658C1.24993 10.7995 1.36497 10.3403 1.58491 9.9291C1.80486 9.51788 2.1229 9.16731 2.51083 8.90849L12.4275 2.29832C12.8931 1.98768 13.4403 1.8219 14 1.8219C14.5597 1.8219 15.1069 1.98768 15.5725 2.29832L25.4892 8.90849C25.8771 9.16731 26.1951 9.51788 26.4151 9.9291C26.635 10.3403 26.7501 10.7995 26.75 11.2658V23.9167M1.25 23.9167C1.25 24.6681 1.54851 25.3888 2.07986 25.9201C2.61122 26.4515 3.33189 26.75 4.08333 26.75H23.9167C24.6681 26.75 25.3888 26.4515 25.9201 25.9201C26.4515 25.3888 26.75 24.6681 26.75 23.9167M1.25 23.9167L10.8125 17.5417M26.75 23.9167L17.1875 17.5417M10.8125 17.5417L1.25 11.1667M10.8125 17.5417L12.4275 18.6183C12.8931 18.929 13.4403 19.0947 14 19.0947C14.5597 19.0947 15.1069 18.929 15.5725 18.6183L17.1875 17.5417M17.1875 17.5417L26.75 11.1667'
												stroke='#718096'
												strokeWidth='2.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</div>
									<div
										dangerouslySetInnerHTML={{
											__html: `${data && data.support_mail_text
												}`,
										}}
									/>
								</div>
							) : null}
							<div className='reach-out-by-phone'>
								<div>
									<svg
										width='28'
										height='28'
										viewBox='0 0 28 28'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M2.07986 2.07986C1.54851 2.61122 1.25 3.33189 1.25 4.08333V5.5C1.25 17.2357 10.7643 26.75 22.5 26.75H23.9167C24.6681 26.75 25.3888 26.4515 25.9201 25.9201C26.4515 25.3888 26.75 24.6681 26.75 23.9167V19.2714C26.7501 18.974 26.6565 18.684 26.4826 18.4427C26.3087 18.2014 26.0632 18.021 25.781 17.927L19.4159 15.8048C19.0924 15.6973 18.741 15.71 18.4262 15.8407C18.1113 15.9713 17.8541 16.2111 17.7017 16.516L16.1009 19.7134C12.6322 18.1461 9.85387 15.3678 8.28658 11.8991L11.484 10.2983C11.7889 10.1459 12.0287 9.88869 12.1593 9.57383C12.29 9.25897 12.3027 8.90756 12.1952 8.58408L10.073 2.219C9.97908 1.93702 9.79886 1.69172 9.55784 1.51782C9.31682 1.34392 9.02721 1.25023 8.73 1.25H4.08333C3.33189 1.25 2.61122 1.54851 2.07986 2.07986Z'
											stroke='#718096'
											strokeWidth='2.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</div>
								<div>
									Also you can directly talk to us 24/7 <br />{" "}
									{data && data.support_contact_no}
								</div>
							</div>
							<div className="queries-faq-link">
								<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="28"
									height="28"
									fill="none"
									viewBox="0 0 28 28"
									>
									<g clipPath="url(#clip0_2_57)">
										<path
										fill="#718096"
										d="M12.046 9.232c-.438.326-.713.778-.713 1.44a1.333 1.333 0 11-2.666 0c0-1.57.726-2.79 1.788-3.58 1.023-.76 2.318-1.1 3.545-1.1 1.226 0 2.521.339 3.545 1.1 1.062.788 1.788 2.009 1.788 3.577 0 1.143-.36 2.014-.979 2.684-.488.527-1.113.886-1.544 1.134l-.139.08c-.498.29-.788.491-.988.752-.167.219-.35.586-.35 1.344a1.333 1.333 0 11-2.666 0c0-1.242.318-2.207.9-2.966.55-.718 1.26-1.142 1.762-1.434l.073-.043c.526-.306.796-.463.994-.678.131-.142.27-.353.27-.873 0-.66-.273-1.112-.711-1.437-.476-.354-1.181-.573-1.955-.573-.773 0-1.478.22-1.954.573zM14 22a1.333 1.333 0 100-2.667A1.333 1.333 0 0014 22zM.667 14C.667 6.636 6.637.667 14 .667c7.364 0 13.333 5.97 13.333 13.333 0 7.364-5.97 13.333-13.333 13.333C6.636 27.333.667 21.363.667 14zM14 3.333C8.11 3.333 3.333 8.11 3.333 14S8.11 24.667 14 24.667 24.667 19.89 24.667 14 19.89 3.333 14 3.333z"
										></path>
									</g>
									<defs>
										<clipPath id="clip0_2_57">
										<path fill="#fff" d="M0 0H28V28H0z"></path>
										</clipPath>
									</defs>
									</svg>									
								</div>									
								<div>Find your queries in<a href="https://www.itilite.com/in/help-center/" target="_blank" className="link-text"> FAQs</a></div>
							</div>
						</div>
					</Col>
					<Col xs={12} md={6}>
						<div className='file-expense-section'>
							<h5>File expenses on the go!</h5>
							<div>
								<img
									src='/assets/images/file-expense-on-go.svg'
									alt='file expense img'
								/>
								<div>
									<p>
										Download the mobile app for <br />{" "}
										Android or iOS
									</p>
									<div>
										<a href="https://play.google.com/store/apps/details?id=com.itilite.starter" target="_blank" rel="noopener noreferrer">
											<img
												src='/assets/images/playstore.svg'
												alt='playstore button'
											/>
										</a>
										<a href="https://apps.apple.com/us/app/itilite-business-travel/id1484326787" target="_blank" rel="noopener noreferrer">
											<img
												src='/assets/images/appstore.svg'
												alt='appstore button'
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} md={6} lg={4}></Col>
				</Row>
			</footer>
		</div>
	);
};

export default LandingPage;
