export const FETCH_ALL_REPORTS = "fetchAllReports";
export const SET_ALL_REPORTS = "setAllReports";

export const FETCH_REPORTS = "fetchReports";
export const SET_REPORTS = "setReports";

export const REJECT_REPORT = "rejectReport";
export const SET_REJECT_REPORT = "setRejectReason";

export const APPROVE_REPORT = "approveReport";
export const SET_APPROVE_REPORT = "setApproveReason";