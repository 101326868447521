//  --------------------------------------- routing variables ---------------------------------------

const NAVBAR = {
    auth: `/api/v1/user-management/navbar-details/company`,
    fetch_travel_company_id: `/api/v1/user-management/user/authorization`
}
// reports
const reports = '/reports/'

// --------------------------------------- api call variables ---------------------------------------

// approvals
const approvals = {
    action: '/approvals/action',
    client_action: '/approvals/client/action',
    list: '/approvals/list',
    client_list: 'approvals/client/list',
    all: '/approvals/list/all',
    payoutList: '/report/payout/list',
    apply_advances: '/approvals/list/apply-advances',
    create_rule: '/approvals/rule/create',
    edit_rule: '/approvals/rule/edit',
    list_rule: '/approvals/v1/rule/list',
    details_rule: '/approvals/rule/details',
    activate_rule: '/approvals/rule/activate',
    save_priority: '/approvals/rule/changeorder'
}

// advances
const advances = {
    action: '/approvals/action',
    list: '/approvals/list',
    all: '/approvals/list/all',
    download: '/expense/download/',
    remind: '/advance/remind',
    outstanding: '/advance/list/available/all',
    edit: '/approvals/edit/advance'
}

// reimbursement
const reimburse = {
    queue: '/reimburse/queue',
    paid: '/reimburse/paid',
    process: '/reimburse/process',
    action: '/reimburse/action',
    downloadPayoutTemplate: '/reimburse/download-payout-template',
    payoutUpload: '/reimburse/payout-upload',
    uploadList: '/reimburse/payout/upload/list',
    payoutUploadDetails: '/reimburse/payout/upload/details',
    bulkProcessingToPaid: 'reimburse/bulk-paid'
}

// report
const report = {
    search: '/report/search',
    details: '/report/details/',
    approvalDetails: '/approvals/approvaldetails',
    download: '/report/download/',
    download_reciept: '/report/download/zip-receipts',
    zip_reports: '/report/download/zip-reports',
    bulk_download: '/report/download/bulk-reports',
    payout_count: '/report/payout-count',
    frauds: '/report/fraudcheck/',
    payment_queue_report_count: "/ach/payout-report-count"
}

//expense
const expense = {
    download: '/expense/download/',
    search: 'expense/search',
    update: '/expense/update',
    uncategorized: '/expense/uncategorized',
    edit_expense: "/approvals/edit/expense",
    policy_expenses: '/expense/policyexpenses'
}

// expense-detail
const expenseDetail = '/expense/details/';

const misc = {
    getSignedUploadUrl: '/misc/get-signed-upload-url',
    userBulkUpload: '/misc/user_upload',
    userBulkUploadList: '/misc/user/upload/list',
    userBulkUploadDetails: '/misc/user/upload/details',
    calc: '/misc/calc'
}

const dashboard = {
    getData: '/analytics/list/1'
}

// advance-detail
const advanceDetail = '/advance/details/';

const rolesAndPermissions = {
    details_by_user_id: '/roles-and-permissions/details/by-user-id',
    details_rp_map_config: '/roles-and-permissions/details/rp-map',
    edit_rp_map_config: '/roles-and-permissions/edit/rp-map',
    details_user_rc_map: '/roles-and-permissions/details/user-rc-map',
    email_suggestions: '/user/get-email-suggestions',
    delete_users_from_role: '/roles-and-permissions/delete/user-rc-map',
    edit_users_for_role: '/roles-and-permissions/create/user-rc-map'
}

//currency-conversion
const currencyConversion = {
    list: '/misc/exrate'
}

//category
const category = {
    all: '/category/v1/all',
    list: '/category/v1/list',
    edit: "/category/edit",
    delete: "/category/delete",
    update: "/category/update",
    create: "/category/create",
    attributes_list: "/attributes/list",
    attributes_types: "/attributes/type"
}

//policy
const policy = {
    create: '/policy/create',
    edit: '/policy/edit',
    list: '/policy/v1/list',
    edit_activate: '/policy/edit/activate',
    get_policy_by_id: '/policy/details/by-id'
}

const users = {
    basics: '/user/basics',
    list: '/user/v1/list',
    getList: '/user/list',
    custom_attributes: '/misc/custom_attribute',
    create: '/user/create',
    edit: '/user/edit',
    status: 'user/enable-disable',
    details: 'user/email',
    search: 'user/search',
    details_by_token: '/user/details',
    travel_nudge_user_navigated_count:'/user/nudge-count',
    travel_nudge_eligibility:'user/nudge-display'
}

const reportList = {
    list: '/analytics/download'
}

const submitter = {
    expense: {
        all: "report/v1/list", // same for draft reports & pending approvals (with filters)
        draft_expense: "expense/v1/list",
        delete_expense: "expense/delete/",
        delete_report: "report/delete",
        new: "expense/create",
        draft: "expense/draft/create",
        edit: "expense/edit",
        draft_edit: "expense/draft/edit"
    },
    advance: {
        all: "advance/list",
        delete: "advance/delete/", // "/${advance_id}"
        submit_advance: "advance/submit",
        create: "advance/create",
        draft: "advance/draft/create",
        edit: "advance/edit",
        draft_edit: "advance/draft/edit"
    },
    approval: {
        all: "approvals/list"
    },
    report: {
        create_new_report: {
            create: "report/create",
            list_expenses: "expense/list",
            submit_for_approval: "approvals/queue/", // "/${reportId}"
            link_unLink_expense: "report/edit",
            delete_report: "report/delete",
            report_title: "report/title_update"
        }
    }
}

const report_download = {
    reports: "report/download/bulk-reports",
    receipts: "report/download/zip-receipts"
}

const trip_ids = "user/trip"; //"GET"

const calc_attr = "attributes/calc"  // POST

const fraud = {
    mark_exception: '/fraud/log'
}

const finance = {
    get_user_accounts: '/finance/user/get-accounts',
    create_link_token: '/finance/create-link-token',
    get_financial_aggregator_id: '/finance/user/financial-aggregator-id',
    generate_access_token: '/finance/generate-access-token',
    deactivate_card: '/finance/user/deactivate-card',
    register_accounts: '/finance/user/register-accounts',
    get_transactions: '/finance/user/transactions',
    refresh_txns: '/finance/user/refresh-transactions'
}

const common = {
    reset_password: '/account/auth/change-password/',
    auth_usmtoken: '/auth/generate-usmtoken'
}

// schedule report download
const schedule_report_download = {
    schedule: "report/schedule",
    list_widgets: "report/schedule/list", //customreport/expensedetails
    list_reports: "report/schedule/list",
    create_report: "report/custom/request",
    cancel_schedule: "report/schedule/cancel",
    download_reports: "report/schedule/download",
    search: "report/schedule/search"
}
const getting_started = "getting-started"

const SUBS_BASE_URL = process.env.REACT_APP_SUBSCRIPTIONS_BILLING_URL;
const PAYMENT_BASE_URL = process.env.REACT_APP_PAYMENT_BASE_URL;
const EXPENSE_BASE_URL = process.env.REACT_APP_BASEURL;

const subscriptionsUrls = {
    get_active_subscription_details_by_app_id: `${SUBS_BASE_URL}/subscription/getActiveSubscriptionDetailsByApplicationClientId`,
    get_payment_transaction_by_app_id: `${SUBS_BASE_URL}/subscription/getPaymentTransactionsByApplicationClientId`,
    get_cards_list: `${SUBS_BASE_URL}/subscription/cardlist`,
	get_vendor_data: `${SUBS_BASE_URL}/subscription/getclientconfiguredvendors`,
	payment_redirect: `${PAYMENT_BASE_URL}/api/v1/auth`,
    get_billing_history_by_clientId: `${SUBS_BASE_URL}/subscription/getBillingHistoryByClientId`,
	get_client_info : `${EXPENSE_BASE_URL}/auth/verifypayment`,
    get_client_address : `${SUBS_BASE_URL}/clientaddress/showClientBillingAddress`,
	GET_ALL_COUNTRY: `${SUBS_BASE_URL}/clientaddress/getClientCountryDetails`,
	UPDATE_ADDRESS_DETAILS : `${SUBS_BASE_URL}/clientaddress/updateClientBillingAddress`,
    PAY_DUE_AMOUNT: `${SUBS_BASE_URL}/autobill/dueAmount`,
	CLIENT_CARD_ADDED: `${SUBS_BASE_URL}/subscription/addcard`,
    YEARLY_PAYMENT_VERIFY: `${SUBS_BASE_URL}/subscription/generatePaymentOrderId`,
	YEARLY_PAYMENT_REDIRECT: `${PAYMENT_BASE_URL}/api/v1/payment/order`,
	IL_ADMIN_VERIFY : `${EXPENSE_BASE_URL}/subscription/verfiy-iladmin`,
    UPDATE_SUBSCRIPTION : `${SUBS_BASE_URL}/subscription/updateSubscription`
};


//mileage feature API URL endpoints

const mileageUser = {
    get_mileage_basics: "/mileage/basics",
    create_mileage: "/mileage/create",
    edit_mileage: "/mileage/edit",
    list_mileage: "/expense/list",
    distance_api: "/attributes/distance",
    travel_info: "/attributes/travel-info",
    mileage_details: "/mileage/details"
}

const mileageConfig = {
    get_mileage : "/mileage/config",
    create_mileage: "/mileage/config/create",
    edit_mileage: "/mileage/config/edit",
    delete_mileage_config: "/mileage/config/delete",
    
    get_mileage_type_list: "/mileage/type",
    get_mileage_type: "/mileage/type/",
    create_mileage_type: "/mileage/type/create",
    edit_mileage_type: "/mileage/type/edit",
    delete_mileage_type: "/mileage/type/delete",
    change_config_status: "/mileage/config/change_active",
    edit_distance_unit: "/mileage/distance-unit/update"
}

const ACH = {
    send_mail: '/ach/request_ach',
    get_business_classification_data: "/ach/business_classifications",
    create_business_verified_customer: "/ach/customer",
    fetch_controller_data: "/ach/fetch_controller",
    create_beneficial_owner: "/ach/beneficial-owners",
    create_document: "/ach/document",
    get_iav_token: "/ach/customer/iav-token",
    get_funding_source_status: "/ach/customer/funding-sources",
    get_ach_details: "/ach/details",
    delete_funding_source_company: "/ach/customer-funding-sources/remove",
    delete_funding_source_employee: "/ach/user-funding-sources/remove",
    upload_document: "/ach/document",
    send_aliasName_employee: "/ach/user-funding-source-alias-name",
    send_aliasName_company: "/ach/customer-funding-source-alias-name",
    make_ach_payment: "/ach/transfers",
    cancel_ach_payment: "ach/transfer/cancel",
    get_iav_token_employee: "/ach/user/iav-token",
    get_funding_source_status_employee: "/ach/user/funding-sources",
    get_ach_payment_error:"/ach/transfers-error-list",
    verify_micro_deposit_company: "/ach/verify-micro-deposits-company",
    verify_micro_deposit_employee: "/ach/verify-micro-deposits-user",
    mass_payments: "/ach/mass-payments",
    process_manual_and_ach_reports: "/ach/process-ach-and-manual-reports",
    create_customer_for_backend: `/ach/user/create-receive-only`
}

const CODAT = {
    sent_integration_request: '/codatservice/intigrationrequest',
    get_authenticate: '/codatservice/company',
    get_codat_data: '/codatservice/intigration/details',
    sent_configuration_request:'/codatservice/update-company',
    get_authorized_response:'/codatservice/authorize'
}
const PerDiem = {

    user_side: {
        
        fetch_attributes: "/per-diem/user/fields",
        create_perDiem: "/per-diem/expense",
        get_perDiem_details: "/per-diem/expense",
        edit_Per_Diem: "/per-diem/expense",
        fetch_summary: "/per-diem/amount/calculate"
    },
    admin_side: {
        get_perDiemAdmin:"/per-diem/config/fields",
        initialPerDiemAdminDetails:'/per-diem/config/basics',
        perDiem_Enable_Disable: '/per-diem/client/enable',
        editCategoryPerdiem:'/per-diem/config/fields',
        getPerDiemRateList:'/per-diem/rates/list',
        deletePerdiemrate:'/per-diem/rate',
        rateStatusPerdiem:'/per-diem/rate',
        perDiemRatePriority:'per-diem/rates/priorities',
        getIndividualPerDiemDetail: '/per-diem/rate/',
        // rates
        createDefaultRate: "/per-diem/rate",
        createNormalRate: "/per-diem/rate",
        getEmployeeGroupDetails: "/per-diem/rate/config/basics",
        getPerDiemTravelDuration:"/per-diem/travelduration",
        searchRates: "/per-diem/rates/search"
    }

}
const ExportHistory = {
    
    get_export_history_data: 'misc/fetch-export-history-list',
   
}


const TandE = {
    featureStatus: '/tne-integration/featureStatus',
    reimbursementStatus: '/tne-integration/reimbursement',
    toggleReimbursement: '/tne-integration/reimbursement',
    categoryMappingList: '/tne-integration/categoryMapping',
    attributeMappingList: '/tne-integration/getAttributeMapping',
    editCategoryMapping: '/tne-integration/editCategoryMapping'
}

export const Integrations = {
    cards_status_sync: "/card-expense-integration/client",
    cards_status_sync_app_itilite: "/api/v1/client",
    toggleSync: ""

}

// exporting api call variables
export { mileageConfig, PerDiem ,mileageUser, trip_ids, reports, approvals, reimburse, report, expense, expenseDetail, advances, advanceDetail, misc, dashboard, rolesAndPermissions, currencyConversion, users, category, policy, reportList, fraud, submitter, report_download, finance, common, calc_attr, getting_started, schedule_report_download, subscriptionsUrls, ACH,CODAT,ExportHistory, TandE, NAVBAR };
// GettingStartedWith urls
export const gettingStartedWith = {
    GET_GETTING_STARTED_WITH: `/company/getting_started`
}

export const help = {
    details: 'dashboard/details' 
}

export const USM = {
	tokenGenerate: `/auth/generate-usmtoken`,
    verifyToken: `/account/auth/token/verify/`
}


//Payout Filters
export const payoutFilters = {
    filterTypes: '/report/payout/filter-types',
    filterData: '/report/payout/filter-values'
}

export const exportTemplates = {
    templateList: '/custom-template',
    fieldsList: '/custom-template/fields',
    previewTableData: '/custom-template/preview'
}
// // exporting api call variables
// export { trip_ids, reports, approvals, reimburse, report, expense, expenseDetail, advances, advanceDetail, misc, dashboard, rolesAndPermissions, currencyConversion, users, category, policy, reportList, fraud, submitter, report_download, finance, common, calc_attr, getting_started, schedule_report_download, subscriptionsUrls, help };


// notifications 
const SERVICE_USM_URL = process.env.REACT_APP_USM_URL;

export const notifications = {
    generateUrlWithCompanyId: (companyId) => {
        return {
            NOTIFICATION_ADMIN_LIST: `${SERVICE_USM_URL}/api/v1/user-management/company/${companyId}/admin-users`,
            NOTIFICATION_FETCH_CONFIGS: `${SERVICE_USM_URL}/api/v1/user-management/company/${companyId}/signup-config`,
        };
    },
};

export const corporateCards = {
    summaryData: '/finance/corporate-accounts/summary',
    accountsList: '/finance/accounts/list',
    assignCardOwner: '/finance/assign-account'
}
