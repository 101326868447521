import createReducer from '../../../../../components/common/Utility';

import {
    SET_ROLES_AND_PERM_CONFIG,
    FETCH_ROLES_AND_PERM_CONFIG,
    EDIT_ROLES_AND_PERM_CONFIG,
    FINISH_ROLES_AND_PERM_CONFIG,
    FETCH_USERS_ASSIGNED_TO_ROLE,
    SET_USERS_ASSIGNED_TO_ROLE
} from '../../../../actions/Admin/Settings/Roles/Roles.action.type';

import { API_START, API_END } from '../../.././../actions/Common/Api.types';

export const initialState = {
    isLoadingData: true,
    rpConfig: {},
    rpConfigErrData: [],
    rpConfigUpdated: true,
    usersAssignedToRoleFetched: false,
    usersInRoleData: [],
    selectedRoleId: '',
    totalUserRoleRowCount: 0,
    rpDetailsNew: null
}

export const reducerObj = {
    [SET_ROLES_AND_PERM_CONFIG]: (prevState, payload) => (
        {
            isLoadingData: false,
            rpConfig: payload['data']
        }
    ),
    [FINISH_ROLES_AND_PERM_CONFIG]: (prevState, payload) => (
        {
            isLoadingData: false,
            rpConfigErrData: payload['error_data'],
            rpDetailsNew: payload['user_rp_details']
        }
    ),
    [SET_USERS_ASSIGNED_TO_ROLE]: (prevState, payload) => (
        {
            isLoadingData: false,
            usersAssignedToRoleFetched: false,
            usersInRoleData: payload['data'],
            selectedRoleId: payload['rc_map_id'],
            totalUserRoleRowCount: payload['total_records']
        }
    ),
    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === FETCH_ROLES_AND_PERM_CONFIG) {
            return { isLoadingData: true }
        }
        if (payload === EDIT_ROLES_AND_PERM_CONFIG) {
            return { isLoadingData: true }
        }
        if (payload === FETCH_USERS_ASSIGNED_TO_ROLE) {
            return { usersAssignedToRoleFetched: true }
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === FINISH_ROLES_AND_PERM_CONFIG) {
            return { isLoadingData: false }
        }
        if (payload === SET_ROLES_AND_PERM_CONFIG) {
            return { isLoadingData: false }
        }
        if (payload === SET_USERS_ASSIGNED_TO_ROLE) {
            return { usersAssignedToRoleFetched: false }
        }
    }
}

let RolesReducer = createReducer(initialState, reducerObj);

export default RolesReducer;