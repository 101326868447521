import axios from 'axios';
import { misc } from '../Urls'
import localStorageService from '../components/common/services/Storage_services/LocalStorageService';

const uploadFile = (file, data, remote_file_path, StateCallback) => {
    let temp = remote_file_path.split("/")
    let f_name = temp[temp.length - 1]
    return new Promise((resolve, reject) => {
        let fd = new FormData()
        for (let key in data.fields) {
            fd.append(key, data.fields[key])
        }
        fd.append('file', file)

        axios({
            method: 'post',
            url: data.url,
            data: fd,
            contentType: false, // NEEDED, DON'T OMIT THIS
            processData: false,
            onUploadProgress : (data) => {StateCallback && StateCallback(Math.round((data.loaded/data.total)*100))}
        }).then((res) => {
            let r = { "status": true, "file": file, "remote_file_data": { path: remote_file_path, type: file['type'].split("/")[1], name: f_name } }
            resolve(r)
        }).catch(error => {
            let r = { "status": false, "file": file, "remote_file_data": { path: remote_file_path, type: file['type'].split("/")[1], name: f_name } }
            reject(r)
        });
    })

}

const uploadFilePromise = (file, StateCallback) => {
    return new Promise((resolve, reject) => {
        if (file) {
            axios({
                method: 'post',
                url: misc.getSignedUploadUrl,
                data: {
                    file_type: file['type'].split("/")[1]
                },
                headers: {
                    'access-token': JSON.parse(localStorageService.getAccessToken()),
                    'request-from': "web"
                }
            }).then((res) => {
                if (res.data.status) {
                    resolve(uploadFile(file, res.data.data, res.data.remote_file_path, StateCallback))
                }
                else {
                    let r = { "status": false, "file": file }
                    reject(r)
                }
            }).catch(error => {
                reject(error);
            });
        } else {
            let r = { "status": false, "file": null }
            reject(r)
        }
    })
}

export { uploadFilePromise }