import LocalStorageService from "../components/common/services/Storage_services/LocalStorageService";
import { services } from '@itilite/utils'
import { USM } from "../Urls";
import Axios from "axios";
import { setUserDetailsAfterUUMTokenVerify } from "../store/actions/Common/Navbar/Navbar.action";
const { IUILocalStorageService } = services;


const fetchAndVerifyUSMToken = async (dispatch) => {

    const fetchUSMToken = async () => {
    const response = await Axios({
        method: "GET",
        url: USM.tokenGenerate,
        headers: {
            "access-token": JSON.parse(
              LocalStorageService.getAccessToken()
            ),
          },
        });
      if (response.status === 200) {
        IUILocalStorageService.setAccessToken(response.data.access);
        IUILocalStorageService.setRefreshToken(response.data.refresh);
      }
      return response
    };

    const verifyUSMToken = async () => {
      const response = await fetch(
        process.env.REACT_APP_USM_URL + USM.verifyToken,
        {
          method: "post",
          headers: {
            "Authorization": `Bearer ${IUILocalStorageService.getAccessToken()}`,
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            "client_id": null,
            "product": "expense"
          })
        }
      )
      const verifyResponse = await response.json();
      dispatch(setUserDetailsAfterUUMTokenVerify(verifyResponse))
      if(verifyResponse.status) localStorage.setItem("uui_il_company",verifyResponse.company)
      return verifyResponse;
    }
    
    try {
      if(!IUILocalStorageService.getAccessToken()) {
        let response = await fetchUSMToken();
        if (response.status === 200) {
          let verifyResponse = await verifyUSMToken()
          if(!verifyResponse.status){
            IUILocalStorageService.removeAccessToken();
            IUILocalStorageService.removeRefreshToken();
            response = await fetchUSMToken();
            if (response.status === 200) {
              verifyResponse = await verifyUSMToken()
            }
          }
          return verifyResponse;
        }
      }
      else {
        let verifyResponse = await verifyUSMToken()
        if(!verifyResponse.status){
          IUILocalStorageService.removeAccessToken();
          IUILocalStorageService.removeRefreshToken();
          const response = await fetchUSMToken();
          if (response.status === 200) {
            verifyResponse = await verifyUSMToken()
          }
        }
        return verifyResponse;
      }
    } catch (error) {}
  };

  export default fetchAndVerifyUSMToken;