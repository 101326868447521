// importing api call variables
import { rolesAndPermissions } from '../../../../Urls';

// importing action types
import {
    FETCH_ROLES_AND_PERMISSIONS,
    SET_USER_ROLES_AND_PERMISSIONS,
	SET_PER_DIEM_ENABLE_DISABLE_BUTTON,
	SET_NEW_USER_ROLES_AND_PERMISSIONS
} from './RolesAndPermissions.action.type';

// importing action creator
import actionCreator from '../../Common/Utils'; 

// -------------------------------------------------------------------------------- action to GET ALL REPORTS --------------------------------------------------------------------------------

export const fetchRolesAndPermissions = (token, method) => {

	return actionCreator({
		method,
		url: rolesAndPermissions.details_by_user_id,
		onSuccess: setUserRolesAndPermissions,
		accessToken: token,
		onFailure: () => console.log("Failed to fetch Reports."),
		label: FETCH_ROLES_AND_PERMISSIONS
	});

	function setUserRolesAndPermissions(resData) {
		return {
			type: SET_USER_ROLES_AND_PERMISSIONS,
			payload: resData
		};
	}

}

export const SetPerDiemEnableButton = (payload) => {

    return {
        type: SET_PER_DIEM_ENABLE_DISABLE_BUTTON,
        payload: payload
    }
}

export const setNewUserRolesAndPermissions = (payload) => {

    return {
        type: SET_NEW_USER_ROLES_AND_PERMISSIONS,
        payload: payload
    }
}