import React from "react";
import { Route, Redirect } from "react-router-dom";
import localStorageService from "../Storage_services/LocalStorageService";

export default class PrivateRoute extends React.Component {
	render() {
		// console.log(this.props);
		if (!localStorageService.getAccessToken()) {
		
			return <Redirect to='/' />;
			
		} 
		// else if (this.props.clientDetails.client_id !== undefined) {
		// 	console.log(this.props.clientDetails);
		// 	const authKey = generateJWTAuthKey();
		// 	const token = JSON.parse(localStorageService.getAccessToken());
		// 	const data = {
		// 		expense_client_id: this.props.clientDetails.client_id,
		// 	};
		// 	const params = new URLSearchParams(this.props.computedMatch.url);
		// 	const pgNo = params.get("pg");
		// 	let urlToReturn = "/home";
		// 	console.log(data);
		// 	try {
		// 		axios({
		// 			method: "POST",
		// 			url: subscriptionUrls.FETCH_CLIENT_SUBSCRIPTION,
		// 			data: data,
		// 			headers: {
		// 				Authorization: authKey,
		// 				"access-token": token,
		// 				"service-type": "client-onboarding",
		// 			},
		// 		}).then((response) => {
		// 			if (response.status === 200) {
		// 				if (response.data?.status) {
		// 					console.log(response, "true  -----------");

		// 					if (pgNo && redirectUrls[pgNo] !== undefined) {
		// 						urlToReturn = redirectUrls[pgNo];
		// 					}
		// 				} else {
		// 					console.log(response, "false  XXXXXXXXXXXXXX");
		// 					urlToReturn = "/subscriptions"
		// 				}
		// 				return <Redirect to={urlToReturn} />;
		// 			}
		// 		});
		// 	} catch (error) {
		// 		console.log("-----------");
		// 		return <Redirect to='/subscriptions' />;
		// 	}
		// }
		
		return <Route {...this.props} />;
	}
}
