import * as React from 'react'

export const Personal = React.memo(
    ({ size = 12, color = '#0A65E7', className, ...rest }) => {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width={size}
                height={size}
                fill='none'
                viewBox={`0 0 ${size} ${size}`}
                className={className}
                {...rest}>
                <path
                    fill={color}
                    d='M6 1.73a2 2 0 100 4 2 2 0 000-4zm2.5 5h-5A1.5 1.5 0 002 8.23c0 1.116.459 2.01 1.212 2.615.741.595 1.735.885 2.788.885 1.053 0 2.047-.29 2.788-.885C9.54 10.24 10 9.346 10 8.23a1.5 1.5 0 00-1.5-1.5z'></path>
            </svg>
        )
    }
)

Personal.displayName = 'Personal'
