export const GET_MILEAGE_BASICS = "getMileageBasics";
export const SET_MILEAGE_BASICS = "setMileageBasics";

export const CREATE_USER_MILEAGE = "createUserMileage";
export const SET_CREATE_USER_MILEAGE = "setCreateUserMileage";

export const EDIT_USER_MILEAGE = "editUserMileage";
export const SET_EDIT_USER_MILEAGE = "setEditUserMileage";

export const GET_DISTANCE = "getDistance";
export const SET_DISTANCE = "setDistance";

export const GET_TRAVEL_INFO = "getTravelInfo";
export const SET_TRAVEL_INFO = "setTravelInfo";

export const GET_USER_MILEAGE = "getUserMileage";
export const SET_USER_MILEAGE = "setUserMileage";

export const FETCH_CAL_ATTRI = "fetchCalulatedAttributes"
export const SET_CAL_ATTRI = "setCalulatedAttributes"