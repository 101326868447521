import createReducer from '../../../../components/common/Utility';


import {
	SET_SIGNED_UPLOAD_URL,
	SET_PAYOUT_UPLOAD_LIST,
	SET_BULK_USER_UPLOAD_LIST
} from '../../../actions/Admin/BulkUpload/BulkUpload.action.type';

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
	uploadButtonStatus : true,
	allPayoutUploads : [],
	allCount : 0,
	allUserUploads: [],
	allUserUploadsCount: 0,
	resp_data: null
}

export const reducerObj = {
	[SET_SIGNED_UPLOAD_URL]: (prevState, payload) => (
		{
			uploadButtonStatus : false
		}
	),

	[SET_PAYOUT_UPLOAD_LIST]: (prevState, payload) => (
		{
			resp_data: payload,
			allPayoutUploads: payload.data,
			allCount: payload.total_num_of_records || 0,
		}
	),

	[SET_BULK_USER_UPLOAD_LIST]: (prevState, payload) => (
		{
			resp_data: payload,
			allUserUploads: payload.data,
			allUserUploadsCount: payload.total_num_of_records || 0,
		}
	),

	[RESET_STATE]: () => (
		{
			rejectOrApproveResponse: null
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => (
		payload === SET_SIGNED_UPLOAD_URL
			? { uploadButtonStatus: true }
			: { uploadButtonStatus: false }
	),
	[API_END]: (prevState, payload) => (
		payload === SET_SIGNED_UPLOAD_URL
			? { uploadButtonStatus: false }
			: { uploadButtonStatus: true }
	),
}

let BulkUploadReducer = createReducer(initialState, reducerObj);

export default BulkUploadReducer;