import createReducer from '../../../components/common/Utility';

import { GET_GETTING_STARTED_WITH, SET_GETTING_STARTED_WITH } from '../../actions/Getting Started/GettingStarted.action.type';

import { API_START, API_END, RESET_STATE } from '../../actions/Common/Api.types';



export const initialState = {
    result: {},
    isLoading: false
}


export const reducerObj = {
    [SET_GETTING_STARTED_WITH]: (prevState, payload) => (
        {
            result: payload,
            isLoading: false
        }
    ),
    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === GET_GETTING_STARTED_WITH) {
            return {
                isLoadingData: true
            }
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === SET_GETTING_STARTED_WITH) {
            return {
                isLoadingData: false
            }
        }
    }
}

const GettingStartedWithReducer = createReducer(initialState, reducerObj);

export default GettingStartedWithReducer;