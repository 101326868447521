export const FETCH_ALL_USERS_BASICS_DATA = "fetchAllUsersBasicData";
export const SET_ALL_USERS_BASICS_DATA = "setAllUsersBasicData";
export const FETCH_CATEGORY_LIST_DATA = "fetchCategoryListData";
export const SET_CATEGORY_LIST_DATA = "setCategoryListData";
export const FETCH_POLICIES = "fetchPolicies";
export const SET_POLICIES = "setPolicies";
export const SAVE_POLICY = "savePolicy";
export const SET_SAVE_POLICY = "setSavePolicy";
export const SET_POLICY_BY_ID = "setPolicyById";
export const FETCH_POLICY_BY_ID = "fetchPolicyById";
export const FETCH_ACTIVE_CATEGORY_LIST_DATA = "fetchActiveCategoryListData";
export const SET_ACTIVE_CATEGORY_LIST_DATA = "setActiveCategoryListData";
export const SET_SAVE_POLICY_FALSE="setSavePolicyFalse";


export const SET_POLICY_LIST_FOR_PAGE_DATA="setPolicyListForPageData";

export const CHANGE_POLICY_STATUS = "changePolicyStatus"
export const SET_POLICY_STATUS = "setPolicyStatus"
export const RESET_POLICY_STATUS_RESPONSE = "resetPolicyStatusResponse"



