import createReducer from '../../../../components/common/Utility';

import {
    FETCH_ALL_CARDS,
    SET_ALL_CARDS,
    ACTIVATE_CARD,
    SET_ACTIVATE_CARD,
    DEACTIVATE_CARD,
    SET_DEACTIVATE_CARD,
    SET_CURRENT_CARD_ACTION
} from '../../../actions/Personal/Cards/Cards.action.type';

// import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
    allCardsCount: 0,
    allCardsData: [],
    allCards: [],
    activeCardResponse: null,
    deActiveCardResponse: null,
    isLoadingData: false,
    currentCardAction: null
}

export const reducerObj = {
    [SET_ALL_CARDS]: (prevState, payload) => (
        {
            allCardsData: payload,
            allCards: payload.status?payload.accounts:[],
            allCardsCount: payload.status && payload.pagination?payload.pagination.total_count:0,
            isLoadingData: false
        }
    ),

    [SET_ACTIVATE_CARD]: (prevState, payload) => (
        {
            activeCardResponse: payload,
            isLoadingData: false
        }
    ),

    [SET_DEACTIVATE_CARD]: (prevState, payload) => (
        {
            deActiveCardResponse: payload,
            isLoadingData: false
        }
    ),
    [SET_CURRENT_CARD_ACTION]: (prevState, payload) => (
        {
            currentCardAction: payload
        }
    ),

    [RESET_STATE]: () => (
        {
            allCardsCount: 0,
            allCardsData: null,
            allCards: [],

            activeCardResponse: null,
            deActiveCardResponse: null,
            isLoadingData: false,
        }
    ),

    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === FETCH_ALL_CARDS) {
            return {
                isLoadingData: true,
                allCardsCount: 0,
                allCardsData: null,
                // allCards: [],
            }
        }
        if (payload === ACTIVATE_CARD) {
            return {
                isLoadingData: true,
                activeCardResponse: null,
            }
        }
        if (payload === DEACTIVATE_CARD) {
            return {
                isLoadingData: true,
                deActiveCardResponse: null,
            }
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === SET_ALL_CARDS || payload === SET_ACTIVATE_CARD || payload === SET_DEACTIVATE_CARD) {
            return {
                isLoadingData: false,
            }
        }
    },
}

let CardsReducer = createReducer(initialState, reducerObj);

export default CardsReducer;