import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Link,
  useHistory,
  NavLink,
} from "react-router-dom";
import { useSpeechRecognition } from "react-speech-kit";
import { Dropdown, DropdownButton, Nav } from "react-bootstrap";
import localStorageService from "../../services/Storage_services/LocalStorageService";
import axios from "axios";
import {
  checkRoleAndPermissionForPage,
  checkRoleAndPermissionForTab,
} from "../../../../utils/checkRoleAndPermission";
import { USMLogout } from "../../../../utils/utils";
import { ExportTemplate, PerDiemIcon } from "../UI/components/SvgIcons";
import {withStyles} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';

const homeSvg = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 8.5L3.16667 6.83333M3.16667 6.83333L9 1L14.8333 6.83333M3.16667 6.83333V15.1667C3.16667 15.3877 3.25446 15.5996 3.41074 15.7559C3.56702 15.9122 3.77899 16 4 16H6.5M14.8333 6.83333L16.5 8.5M14.8333 6.83333V15.1667C14.8333 15.3877 14.7455 15.5996 14.5893 15.7559C14.433 15.9122 14.221 16 14 16H11.5M6.5 16C6.72101 16 6.93298 15.9122 7.08926 15.7559C7.24554 15.5996 7.33333 15.3877 7.33333 15.1667V11.8333C7.33333 11.6123 7.42113 11.4004 7.57741 11.2441C7.73369 11.0878 7.94565 11 8.16667 11H9.83333C10.0543 11 10.2663 11.0878 10.4226 11.2441C10.5789 11.4004 10.6667 11.6123 10.6667 11.8333V15.1667C10.6667 15.3877 10.7545 15.5996 10.9107 15.7559C11.067 15.9122 11.279 16 11.5 16M6.5 16H11.5"
      stroke="#4A5568"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const expenseSvg = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49996 12.1667L12.5 7.16667M7.91663 7.58333H7.92496M12.0833 11.75H12.0916M15.8333 18V4.66667C15.8333 4.22464 15.6577 3.80072 15.3451 3.48816C15.0326 3.17559 14.6087 3 14.1666 3L5.83329 3C5.39127 3 4.96734 3.17559 4.65478 3.48816C4.34222 3.80072 4.16663 4.22464 4.16663 4.66667L4.16663 18L7.08329 16.3333L9.99996 18L12.9166 16.3333L15.8333 18ZM8.33329 7.58333C8.33329 7.69384 8.28939 7.79982 8.21125 7.87796C8.13311 7.9561 8.02713 8 7.91663 8C7.80612 8 7.70014 7.9561 7.622 7.87796C7.54386 7.79982 7.49996 7.69384 7.49996 7.58333C7.49996 7.47283 7.54386 7.36685 7.622 7.28871C7.70014 7.21056 7.80612 7.16667 7.91663 7.16667C8.02713 7.16667 8.13311 7.21056 8.21125 7.28871C8.28939 7.36685 8.33329 7.47283 8.33329 7.58333ZM12.5 11.75C12.5 11.8605 12.4561 11.9665 12.3779 12.0446C12.2998 12.1228 12.1938 12.1667 12.0833 12.1667C11.9728 12.1667 11.8668 12.1228 11.7887 12.0446C11.7105 11.9665 11.6666 11.8605 11.6666 11.75C11.6666 11.6395 11.7105 11.5335 11.7887 11.4554C11.8668 11.3772 11.9728 11.3333 12.0833 11.3333C12.1938 11.3333 12.2998 11.3772 12.3779 11.4554C12.4561 11.5335 12.5 11.6395 12.5 11.75Z"
      stroke="#4A5568"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const advanceSvg = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3334 13V12.1667C13.3334 11.2826 12.9822 10.4348 12.3571 9.80964C11.7319 9.18452 10.8841 8.83333 10 8.83333H6.66671M6.66671 8.83333L9.16671 11.3333M6.66671 8.83333L9.16671 6.33333M16.6667 18V4.66667C16.6667 4.22464 16.4911 3.80072 16.1786 3.48816C15.866 3.17559 15.4421 3 15 3L5.00004 3C4.55801 3 4.13409 3.17559 3.82153 3.48816C3.50897 3.80072 3.33337 4.22464 3.33337 4.66667L3.33337 18L6.66671 16.3333L10 18L13.3334 16.3333L16.6667 18Z"
      stroke="#4A5568"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const approvalSvg = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 10.5L9.16667 12.1667L12.5 8.83333M17.5 10.5C17.5 11.4849 17.306 12.4602 16.9291 13.3701C16.5522 14.2801 15.9997 15.1069 15.3033 15.8033C14.6069 16.4997 13.7801 17.0522 12.8701 17.4291C11.9602 17.806 10.9849 18 10 18C9.01509 18 8.03982 17.806 7.12987 17.4291C6.21993 17.0522 5.39314 16.4997 4.6967 15.8033C4.00026 15.1069 3.44781 14.2801 3.0709 13.3701C2.69399 12.4602 2.5 11.4849 2.5 10.5C2.5 8.51088 3.29018 6.60322 4.6967 5.1967C6.10322 3.79018 8.01088 3 10 3C11.9891 3 13.8968 3.79018 15.3033 5.1967C16.7098 6.60322 17.5 8.51088 17.5 10.5Z"
      stroke="#4A5568"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const insightsSvg = () => (
  <svg
    width="20"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
    />
  </svg>
);

const payoutSvg = () => (
  <svg
    width="20"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
    />
  </svg>
);

const analyticsSvg = () => (
  <svg
    width="20"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
    />
  </svg>
);

const billingSvg = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8337 4.66667V18L12.917 16.3333L10.0003 18L7.08366 16.3333L4.16699 18L4.16699 4.66667C4.16699 4.22464 4.34259 3.80072 4.65515 3.48816C4.96771 3.17559 5.39163 3 5.83366 3L14.167 3C14.609 3 15.0329 3.17559 15.3455 3.48816C15.6581 3.80072 15.8337 4.22464 15.8337 4.66667Z"
      stroke="#4A5568"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4 6.8C9.0744 6.8 8 7.516 8 8.4C8 9.284 9.0744 10 10.4 10M10.4 6.8C11.288 6.8 12.064 7.1216 12.4792 7.6M10.4 6.8V6M10.4 6.8V10M10.4 10C11.7256 10 12.8 10.716 12.8 11.6C12.8 12.484 11.7256 13.2 10.4 13.2M10.4 10V13.2M10.4 13.2V14M10.4 13.2C9.512 13.2 8.736 12.8784 8.3208 12.4"
      stroke="#4A5568"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const usersSvg = () => (
  <svg
    width="20"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
);

const rolesSvg = () => (
  <svg
    width="20"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
    />
  </svg>
);

const expenseCategoriesSvg = () => (
  <svg
    width="20"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
    />
  </svg>
);

const expensePoliciesSvg = () => (
  <svg
    width="20"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
    />
  </svg>
);

const expenseApprovalSvg = () => (
  <svg
    width="20"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
    />
  </svg>
);

const currencySvg = () => (
  <svg
    width="20"
    height="21"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const profileSvg = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.2675 13.3367C5.01618 12.3483 6.99132 11.8303 9 11.8334C11.0833 11.8334 13.0392 12.3792 14.7325 13.3367M11.5 6.83336C11.5 7.4964 11.2366 8.13228 10.7678 8.60112C10.2989 9.06996 9.66304 9.33336 9 9.33336C8.33696 9.33336 7.70107 9.06996 7.23223 8.60112C6.76339 8.13228 6.5 7.4964 6.5 6.83336C6.5 6.17031 6.76339 5.53443 7.23223 5.06559C7.70107 4.59675 8.33696 4.33336 9 4.33336C9.66304 4.33336 10.2989 4.59675 10.7678 5.06559C11.2366 5.53443 11.5 6.17031 11.5 6.83336ZM16.5 8.50002C16.5 9.48494 16.306 10.4602 15.9291 11.3701C15.5522 12.2801 14.9997 13.1069 14.3033 13.8033C13.6069 14.4998 12.7801 15.0522 11.8701 15.4291C10.9602 15.806 9.98491 16 9 16C8.01509 16 7.03982 15.806 6.12987 15.4291C5.21993 15.0522 4.39314 14.4998 3.6967 13.8033C3.00026 13.1069 2.44781 12.2801 2.0709 11.3701C1.69399 10.4602 1.5 9.48494 1.5 8.50002C1.5 6.5109 2.29018 4.60324 3.6967 3.19672C5.10322 1.7902 7.01088 1.00002 9 1.00002C10.9891 1.00002 12.8968 1.7902 14.3033 3.19672C15.7098 4.60324 16.5 6.5109 16.5 8.50002Z"
      stroke="#3F3F46"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const changePasswordSvg = () => (
  <svg
    width="18"
    height="17"
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
    />
  </svg>
);

const helpSvg = () => (
  <svg
    width="18"
    height="17"
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

// const mileageSvg = () => <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M18.3802 4.57L17.1502 6.42C17.7433 7.60307 18.0337 8.91483 17.9954 10.2377C17.957 11.5606 17.591 12.8533 16.9302 14H3.07017C2.21134 12.5101 1.85547 10.7831 2.05531 9.07507C2.25516 7.36701 3.00008 5.76882 4.17965 4.51743C5.35922 3.26603 6.91064 2.42804 8.60391 2.1277C10.2972 1.82735 12.0422 2.08063 13.5802 2.85L15.4302 1.62C13.5467 0.412215 11.3125 -0.128996 9.08506 0.0829355C6.85762 0.294867 4.76562 1.2477 3.14366 2.78901C1.52171 4.33033 0.463534 6.37107 0.13839 8.58481C-0.186755 10.7986 0.239923 13.0574 1.35017 15C1.52468 15.3023 1.77526 15.5536 2.077 15.7291C2.37875 15.9045 2.72114 15.9979 3.07017 16H16.9202C17.2726 16.0014 17.6191 15.9096 17.9247 15.734C18.2302 15.5584 18.484 15.3052 18.6602 15C19.5815 13.4039 20.0439 11.5843 19.9963 9.74195C19.9486 7.89964 19.3928 6.10632 18.3902 4.56L18.3802 4.57ZM8.59017 11.41C8.77592 11.596 8.9965 11.7435 9.23929 11.8441C9.48209 11.9448 9.74234 11.9966 10.0052 11.9966C10.268 11.9966 10.5283 11.9448 10.7711 11.8441C11.0138 11.7435 11.2344 11.596 11.4202 11.41L17.0802 2.92L8.59017 8.58C8.40422 8.76575 8.2567 8.98632 8.15605 9.22912C8.0554 9.47191 8.0036 9.73217 8.0036 9.995C8.0036 10.2578 8.0554 10.5181 8.15605 10.7609C8.2567 11.0037 8.40422 11.2243 8.59017 11.41Z" fill="#EB5C24"/>
// </svg>
const mileageSvg = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9833 4.64193L14.9583 6.1836C15.4526 7.16949 15.6946 8.26262 15.6626 9.36503C15.6306 10.4674 15.3256 11.5447 14.775 12.5003H3.22498C2.50929 11.2587 2.21273 9.81953 2.37926 8.39615C2.5458 6.97278 3.16657 5.64095 4.14955 4.59812C5.13252 3.55529 6.42537 2.85697 7.83643 2.60668C9.24749 2.35639 10.7016 2.56746 11.9833 3.2086L13.525 2.1836C11.9554 1.17711 10.0936 0.726101 8.23739 0.902711C6.38119 1.07932 4.63785 1.87334 3.28622 3.15777C1.9346 4.4422 1.05278 6.14282 0.781829 7.98761C0.510875 9.8324 0.86644 11.7148 1.79165 13.3336C1.93707 13.5855 2.14589 13.7949 2.39734 13.9411C2.64879 14.0873 2.93412 14.1652 3.22498 14.1669H14.7666C15.0603 14.1681 15.3491 14.0916 15.6037 13.9453C15.8584 13.799 16.0698 13.5879 16.2166 13.3336C16.9845 12.0035 17.3698 10.4872 17.3301 8.95189C17.2904 7.41663 16.8272 5.9222 15.9916 4.6336L15.9833 4.64193ZM7.82498 10.3419C7.97977 10.4969 8.16358 10.6198 8.36591 10.7037C8.56824 10.7876 8.78512 10.8307 9.00415 10.8307C9.22317 10.8307 9.44005 10.7876 9.64238 10.7037C9.84471 10.6198 10.0285 10.4969 10.1833 10.3419L14.9 3.26693L7.82498 7.9836C7.67002 8.13839 7.54709 8.3222 7.46321 8.52453C7.37934 8.72686 7.33617 8.94374 7.33617 9.16276C7.33617 9.38179 7.37934 9.59867 7.46321 9.801C7.54709 10.0033 7.67002 10.1871 7.82498 10.3419Z"
      fill="#4A5568"
    />
  </svg>
);

const manageCardSvg = () => (
  <svg
    width="18"
    height="15"
    // viewBox="0 0 18 15"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
    <path
      fillRule="evenodd"
      d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
      clipRule="evenodd"
    />
  </svg>
);

const AchSvg = () => <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 7.16667C8.61917 7.16667 7.5 7.9125 7.5 8.83333C7.5 9.75417 8.61917 10.5 10 10.5C11.3808 10.5 12.5 11.2458 12.5 12.1667C12.5 13.0875 11.3808 13.8333 10 13.8333M10 7.16667V13.8333M10 7.16667C10.925 7.16667 11.7333 7.50167 12.1658 8M10 7.16667V6.33333M10 13.8333V14.6667M10 13.8333C9.075 13.8333 8.26667 13.4983 7.83417 13M17.5 10.5C17.5 11.4849 17.306 12.4602 16.9291 13.3701C16.5522 14.2801 15.9997 15.1069 15.3033 15.8033C14.6069 16.4997 13.7801 17.0522 12.8701 17.4291C11.9602 17.806 10.9849 18 10 18C9.01509 18 8.03982 17.806 7.12987 17.4291C6.21993 17.0522 5.39314 16.4997 4.6967 15.8033C4.00026 15.1069 3.44781 14.2801 3.0709 13.3701C2.69399 12.4602 2.5 11.4849 2.5 10.5C2.5 8.51088 3.29018 6.60322 4.6967 5.1967C6.10322 3.79018 8.01088 3 10 3C11.9891 3 13.8968 3.79018 15.3033 5.1967C16.7098 6.60322 17.5 8.51088 17.5 10.5Z" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

const CodatSvg=()=> <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.818 0.00293299C11.8825 0.00386682 11.0153 0.492822 10.5304 1.29278C10.0456 2.09288 10.0135 3.08781 10.4457 3.91746L4.41599 9.94712C3.65343 9.5493 2.74628 9.54202 1.97735 9.92751C1.20843 10.313 0.671634 11.0446 0.534364 11.8936C0.397089 12.7427 0.675933 13.6061 1.28426 14.2142C1.89239 14.8225 2.75579 15.1014 3.60485 14.9641C4.45391 14.8268 5.18549 14.29 5.57096 13.5211C5.95645 12.7522 5.94917 11.845 5.55135 11.0825L11.581 5.05281C12.3102 5.43438 13.1743 5.4594 13.9246 5.1208C14.6747 4.782 15.2273 4.11692 15.423 3.31754C15.619 2.51816 15.4361 1.67302 14.9276 1.02587C14.419 0.37892 13.6411 0.00164205 12.818 0.00293299ZM3.17895 13.3905C2.89488 13.3905 2.62256 13.2777 2.4216 13.0769C2.22082 12.8759 2.10801 12.6036 2.10801 12.3196C2.10801 12.0355 2.22082 11.763 2.4216 11.5622C2.62256 11.3614 2.89487 11.2484 3.17895 11.2484C3.46303 11.2484 3.73554 11.3614 3.93631 11.5622C4.13708 11.763 4.25008 12.0355 4.25008 12.3196C4.25008 12.6036 4.13708 12.876 3.93631 13.0769C3.73553 13.2777 3.46304 13.3905 3.17895 13.3905ZM12.818 3.75145C12.5339 3.75145 12.2614 3.63846 12.0606 3.43768C11.8599 3.23691 11.7469 2.96441 11.7469 2.68033C11.7469 2.39625 11.8599 2.12393 12.0606 1.92297C12.2614 1.7222 12.5339 1.60939 12.818 1.60939C13.1021 1.60939 13.3744 1.7222 13.5754 1.92297C13.7761 2.12393 13.8889 2.39624 13.8889 2.68033C13.8889 2.9644 13.7761 3.23691 13.5754 3.43768C13.3744 3.63846 13.1021 3.75145 12.818 3.75145Z" fill="#4A5568"/>
<path d="M13.0858 11.2485C13.0858 11.6035 12.9447 11.9442 12.6937 12.1952C12.4427 12.4462 12.102 12.5872 11.747 12.5872H7.73072V14.1939H11.747C12.5282 14.1939 13.2774 13.8836 13.8297 13.3312C14.3821 12.7789 14.6923 12.0298 14.6923 11.2485V7.23222H13.0857L13.0858 11.2485Z" fill="#4A5568"/>
<path d="M2.9112 3.75145C2.9112 3.3964 3.05221 3.05572 3.30323 2.80471C3.55424 2.55369 3.89491 2.41268 4.24996 2.41268H8.26623V0.806078H4.24996C3.4687 0.806078 2.71958 1.1163 2.16729 1.66876C1.61481 2.22105 1.30461 2.97018 1.30461 3.75144V7.76771H2.91121L2.9112 3.75145Z" fill="#4A5568"/>
</svg>

const ExportHistorySvg=()=><svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 6.5V10.5L12 12.5" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.25469 14.5C5.51964 16.3135 7.62126 17.5 10 17.5C13.866 17.5 17 14.366 17 10.5C17 6.63401 13.866 3.5 10 3.5C7.2076 3.5 4.7971 5.13505 3.67363 7.5" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 4.5V7.5H6" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

const UniversalNavigation = (props) => {
  const history = useHistory();
  const {
    toggled,
    handleToggleSidebar,
    handleCloseToggleSidebar,
    ilAdminMenuVisible,
  } = props;
  // accessing state from the reducer.
  const rolesAndPermissionsState = useSelector(
    (state) => state.RolesAndPermissionsReducer
  );
  // destructuring state variables.
  const { userRolesAndPermissions, card_recon_enabled, expense_only, ach_flags, show_per_diem } =
    rolesAndPermissionsState;
  const [search, setSearch] = useState("");
  const [suggestionsArray, setSuggestionsArray] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setObtainedTranscript(result);
    },
  });

  const [obtainedTranscript, setObtainedTranscript] = useState(null);
  const [rolesAndPermData, setRolesAndPermData] = useState({
    showReportsTab: false,
    showPeronalExpenseReportTab: false,
    showPayoutTab: false,
    showAdvancesTab: false,
    showSettingsTab: false,
    showDashboardTab: false,
    showReportDownloadTab: false,
    showPendingApprovalTab: false,
    showOrgTab: false,
    showILTab: false,
    showAchUser: false,
    showAchAdmin: false,
    showPerDiemUser: false
  });

  const CustomTooltip = withStyles({
    tooltip: {
      color: "#FFFFFF",
      backgroundColor: "#1F2937",
      fontSize: "14px",
      textAlign: "left",
      boxShadow: "0px 1px 2px 0px #1118271A, 0px 4px 8px 0px #11182726",      
      borderRadius: "6px",
      padding: "12px 16px"
    },
    arrow: {
      color: "#1F2937"
    }
  })(Tooltip);

  
  const routes = {
    home_take_: {
      name: "Home ",
      info: "Navigate to home page",
      url: "/home",
      icon: homeSvg(),
    },
    expense_reports_draft_expense_: {
      name: "Draft Expense",
      info: "View and edit your draft expenses here",
      url: "/individual/reports#draft_expense",
      icon: expenseSvg(),
    },
    expense_reports_draft_reports_: {
      name: "Draft Reports ( User )",
      info: "View and edit you draft expense reports here",
      url: "/individual/reports#draft_report",
      icon: expenseSvg(),
    },
    expense_reports_all_reports_: {
      name: "All Reports ( User )",
      info: "View all your submitted expense reports here",
      url: "/individual/reports#all",
      icon: expenseSvg(),
    },
    manage_cards_: {
      name: "Manage Cards",
      info: "Add or manage your cards & card transactions here.",
      url: "/individual/cards",
      icon: manageCardSvg(),
    },
    advances_: {
      displayForAdminOnly: rolesAndPermData.showPeronalAdvanceTab,
      name: "Advances",
      info: "View your draft advances here",
      url: "/individual/advances#draft_advance",
      icon: advanceSvg(),
    },
    all_advances_ : {
      displayForAdminOnly: rolesAndPermData.showPeronalAdvanceTab,
      name: "All Advances",
      info: "View all your submitted advances here",
      url: "/individual/advances#all",
      icon: advanceSvg(),
    },
    approval_pending_: {
      displayForAdminOnly: rolesAndPermData.showPendingApprovalTab,
      name: "Approval Pending",
      info: "View all expense reports/advances awaiting your approval",
      url: "/individual/approvals#all",
      icon: approvalSvg(),
    },
    approvals_history_: {
      displayForAdminOnly: rolesAndPermData.showPendingApprovalTab,
      name: "Approvals History",
      info: "View all your previously approved expense reports/advances here",
      url: "/individual/approvals#history",
      icon: approvalSvg(),
    },
    create_mileage_user_: {
      name: "Create Mileage Expense",
      info: "Create mileage expense here",
      url: "/individual/reports/expense/create#mileage",
      icon: mileageSvg(),
    },
    create_perdiem_per_diem_user_: {
      displayForAdminOnly: rolesAndPermData.showPerDiemUser,
      name: "Create Per Diem",
      info: "User can create Per diem expenses on this page",
      url: "/individual/reports/expense/create#per_diem",
      icon: PerDiemIcon(),
    },
    create_expense_: {
      name: "Create New Expense",
      info: "Create a new expense here",
      url: "/individual/reports/expense/create#expense",
      icon: expenseSvg(),
    },

    // ach user side

    ach_user_bank_funding_source_banks: {
      displayForAchApplicableCustomer: rolesAndPermData.showAchUser,
      name: "Bank",
      info: "Add bank account here for ACH",
      url: "/individual/banks",
      icon: AchSvg(),
    },

    // organisation
    organisation_admin_insights_: {
      displayForAdminOnly: rolesAndPermData.showDashboardTab,
      name: "Insights",
      info: "View organisation's insights dashboard here",
      url: "/insights",
      icon: insightsSvg(),
    },
    organisation_admin_expense_reports_pending_approval_approvals_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Organisation: Approvals",
      info: "All expense reports in the organisation that are awaiting approval can be viewed here",
      url: "/approvals#pending",
      icon: expenseSvg(),
    },
    organisation_admin_expense_reports_all_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "All Admin Expense Reports",
      info: "All submitted expense reports in the organisation can be viewed here",
      url: "/approvals#all",
      icon: expenseSvg(),
    },
    organisation_admin_advances_pending_approval_: {
      displayForAdminOnly: rolesAndPermData.showAdvancesTab,
      name: "Admin: Advances Pending Approval",
      info: "All advances in the organisation that are awaiting approval can be viewed here",
      url: "/advances#pending",
      icon: advanceSvg(),
    },
    organisation_admin_outstanding_advances_: {
      displayForAdminOnly: rolesAndPermData.showAdvancesTab,
      name: "Admin: Outstanding Advances",
      info: "View all the advances that are outstanding settlements",
      url: "/advances#outstanding",
      icon: advanceSvg(),
    },
    organisation_admin_advances_all_: {
      displayForAdminOnly: rolesAndPermData.showAdvancesTab,
      name: "All Admin Advances",
      info: "All submitted advances in the organisation can be viewed here",
      url: "/advances#all",
      icon: advanceSvg(),
    },

    //payouts
    organisation_admin_payout_apply_advances_payouts_: {
      displayForAdminOnly: rolesAndPermData.showAdvancesTab,
      name: "Admin: Apply Advances",
      info: "Outstanding advances can be linked to expense reports here",
      url: "/payout#reports",
      icon: payoutSvg(),
    },
    organisation_admin_payout_payments_queue_: {
      displayForAdminOnly: rolesAndPermData.showPayoutTab,
      name: "Admin: Payments Queue",
      info: "Verify and process all the approved expense reports/ advances that need to be paid here",
      url: "/payout#payment",
      icon: payoutSvg(),
    },
    organisation_admin_payout_processing_: {
      displayForAdminOnly: rolesAndPermData.showPayoutTab,
      name: "Admin: Processing Payouts",
      info: "View all expense reports/advances that in payments processing state",
      url: "/payout#processing",
      icon: payoutSvg(),
    },
    organisation_admin_payout_paid_: {
      displayForAdminOnly: rolesAndPermData.showPayoutTab,
      name: "Admin: Paid reports",
      info: "View expense reports/advances that have been paid out",
      url: "/payout#paid",
      icon: payoutSvg(),
    },
    organisation_admin_mileage_configure: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Configure Mileage",
      info: "Configure mileage rates and custom fields required for submitting mileage expenses.",
      url: "/settings/expense/mileage",
      icon: mileageSvg(),
    },
    organisation_admin_mileage_rates_configure: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Configure Mileage Rates",
      info: "Configure mileage rates as per your company policy",
      url: "/settings/expense/mileage/rates",
      icon: mileageSvg(),
    },
    organisation_admin_mileage_fields_configure_mileagefields_mileagefield: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Configure Mileage Fields",
      info: "Configure mileage fields that your submitters need to fill in while creating mileage expenses.",
      url: "/settings/expense/mileage/fields",
      icon: mileageSvg(),
    },

    // per diem
    configure_perdiem_per_diem_admin_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Configure Per Diem",
      info: "Admin can configure Per diem on this page",
      url: "/settings/expense/per_diem",
      icon: PerDiemIcon(),
    },
    organisation_admin_per_diem_fields_configure_perdiemfield_perdiem: {
      displayForAdminOnly: rolesAndPermData.showPerDiemUser,
      name: "Configure Per Diem Fields",
      info: "Configure Per diem fields that your submitters need to fill in while creating Per diem expenses.",
      url: "/settings/expense/per_diem/fields",
      icon: PerDiemIcon(),
    },

    configure_admin_per_diem_perdiem_rates_: {
      displayForAdminOnly: rolesAndPermData.showPerDiemUser,
      name: "Configure Per Diem Rates",
      info: "Configure per diem  rates as per your company policy",
      url: "/settings/expense/per_diem/rates",
      icon:PerDiemIcon(),
    },
    configure_admin_per_diem_perdiem_travel_duration_: {
      displayForAdminOnly: rolesAndPermData.showPerDiemUser,
      name: "Configure Per Diem Travel Duration",
      info: "Configure per diem  travel duration as per your company policy",
      url: "/settings/expense/per_diem/travelDuration",
      icon:PerDiemIcon(),
    },
    //Analytics
    organisation_admin_analytics_: {
      displayForAdminOnly: rolesAndPermData.showReportDownloadTab,
      name: "Admin: Analytics",
      info: "View, download and schedule data exports here",
      url: "/analytics",
      icon: analyticsSvg(),
    },

    //Billing & Subscription
    organisation_admin_billing_subscription_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Billing and Subscription",
      info: "View and manage your billings and subscriptions",
      url: "/billingsubscriptions",
      icon: billingSvg(),
    },
    organisation_admin_export_history_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Export History",
      info: "View export history",
      url: "/export-history",
      icon: ExportHistorySvg(),
    },

    //Settings
    organisation_admin_users_settings_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: User Settings",
      info: "Admin can configure the User Settings here",
      url: "/settings/general/users",
      icon: usersSvg(),
    },
	organisation_admin_roles_settings_: {
	  displayForAdminOnly: rolesAndPermData.showSettingsTab,
	  name: "Admin: Manage Roles",
	  info: "Manage/assign/access who gets to see what in the organisation",
	  url: "/settings/general/roles",
	  icon: rolesSvg(),
	},
  
  export_custom_templates_: {
	  displayForAdminOnly: rolesAndPermData.showSettingsTab,
	  name: "Admin: Export Custom Templates",
	  info: "Admin can configure custom export templates here",
	  url: "/settings/export-templates",
	  icon: ExportTemplate(),
	},

	//new USM pages
    organisation_admin_department_settings_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Department",
      info: "Configure organisation's departments here",
      url: "/settings/general/departments",
      icon: usersSvg(),
    },
    organisation_admin_entity_settings_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Entity",
      info: "Configure organisation's entities here",
      url: "/settings/general/entity",
      icon: usersSvg(),
    },
    organisation_admin_business_unit_settings_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Business Unit",
      info: "Configure organisation's business units here",
      url: "/settings/general/business-unit",
      icon: usersSvg(),
    },
    organisation_admin_employee_level_settings_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Employee Level",
      info: "Configure organisation's employee level here",
      url: "/settings/general/employee-level",
      icon: usersSvg(),
    },
    organisation_admin_custom_fields_settings_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Custom Fields Settings",
      info: "Admin can configure the Custom Fields Settings here",
      url: "/settings/general/custom-fields",
      icon: usersSvg(),
    },


    // ACH
    organisation_admin_ach_bank_funding: {
      displayForAdminOnly: rolesAndPermData.showAchAdmin && rolesAndPermData.showOrgTab && rolesAndPermData.showSettingsTab,
      name: "ACH",
      info: "Automate the expense reimbursement process with the help of ACH",
      url: "/settings/ach",
      icon: AchSvg(),
    },
    organisation_admin_integration_settings_integrations_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Integrations",
      info: "Configure integration with your HRMS, Accounting, SSO, or Payroll systems",
      url: "/settings/integrations",
      icon: CodatSvg(),
    },
    //Expense
    organisation_admin_expense_categories_settings_category_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Expense Categories",
      info: "Configure expense categories and fields here",
      url: "/settings/expense/categories",
      icon: expenseCategoriesSvg(),
    },
    organisation_admin_expense_policies_settings_policy_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Expense Policies",
      info: "View and configure expense policies setup in the organisation",
      url: "/settings/expense/policies",
      icon: expensePoliciesSvg(),
    },
    organisation_admin_expense_approval_flow_settings_: {
      displayForAdminOnly: rolesAndPermData.showSettingsTab,
      name: "Admin: Expense Approval Flow",
      info: "View and configure expense approvals setup in the organisation",
      url: "/settings/expense/approval",
      icon: expenseApprovalSvg(),
    },

    //Advances
    organisation_admin_advances_categories_settings_category_: {
      displayForAdminOnly: rolesAndPermData.showAdvancesTab,
      name: "Admin: Advances Categories",
      info: "Configure advances categories and fields here",
      url: "/settings/advance/categories",
      icon: expenseCategoriesSvg(),
    },
    organisation_admin_advances_policies_settings_policy_: {
      displayForAdminOnly: rolesAndPermData.showAdvancesTab,
      name: "Admin: Advance Policies",
      info: "View and configure advance policies setup in the organisation",
      url: "/settings/advance/policies",
      icon: expensePoliciesSvg(),
    },
    organisation_admin_advance_approval_flow_settings_: {
      displayForAdminOnly: rolesAndPermData.showAdvancesTab,
      name: "Admin: Advance Approval Flow",
      info: "View and configure advance approvals setup in the organisation",
      url: "/settings/advance/approval",
      icon: expenseApprovalSvg(),
    },
    //Other settings
    organisation_admin_currency_conversion_settings_: {
      displayForAdminOnly: rolesAndPermData.showOrgTab,
      name: "Admin: Currency Conversion",
      info: "View Conversion Rates For A Specific Date.",
      url: "/settings/currency",
      icon: currencySvg(),
    },

    //My accounts
    profile_: {
      name: "Profile Page",
      info: "View and edit your profile details",
      url: "/profile",
      icon: profileSvg(),
    },
    change_password_: {
      name: "Change Password",
      info: "Change your account password!",
      url: "/changepassword",
      icon: changePasswordSvg(),
    },
    help: {
      name: "Help Page",
      info: "Stuck? Need some help? Click here",
      url: "/help",
      icon: helpSvg(),
    },
  };

  const handleSearch = (e) => {
    setSearch(e);
    filterRoutes(e);
  };

  const filterRoutes = (searchValue) => {
    let localRoute = [];
    localRoute = Object.keys(routes);
    // const newArr = localRoute.map((route) => route.split("_").join(" "));
    let newArr1 = localRoute.filter((route) =>
      route
        .split("_")
        .join(" ")
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );

    let filteredArray = [];
    newArr1.forEach((route) => {
      if (
        (routes[route].hasOwnProperty("displayForAdminOnly") &&
        routes[route].displayForAdminOnly) || (routes[route].hasOwnProperty("displayForAchApplicableCustomer") &&
        routes[route].displayForAchApplicableCustomer)
      ) {
        filteredArray.push(route);
      }
      if (!routes[route].hasOwnProperty("displayForAdminOnly") && !routes[route].hasOwnProperty("displayForAchApplicableCustomer"))
        filteredArray.push(route);
    });
    if(window.location.pathname.includes("/individual/reports/expense/edit/")) {
      filteredArray = filteredArray.filter(suggestion => suggestion !== "create_expense_" )
      filteredArray = filteredArray.filter(suggestion => suggestion !== "create_mileage_user_" )
      filteredArray = filteredArray.filter(suggestion => suggestion !== "create_perdiem_per_diem_user_" )
    }
    if(window.location.pathname.includes("/individual/reports/expense/create")) {
      filteredArray = filteredArray.filter(suggestion => suggestion !== "create_expense_" )
      filteredArray = filteredArray.filter(suggestion => suggestion !== "create_mileage_user_" )
      filteredArray = filteredArray.filter(suggestion => suggestion !== "create_perdiem_per_diem_user_" )
    }
    setSuggestionsArray(filteredArray);
    if (searchValue === "") {
      setSuggestionsArray([]);
    }
  }

  const handleLinkClicked = (e) => {
    // e.preventDefault()
    setSearch("");
    setSuggestionsArray([]);
  };

  const checkAchPermissionUser = () => {

    if(ach_flags)
    {
      if(ach_flags.is_ach_applicable && ach_flags.details?.customer?.status === "verified")
      return true
      else return false
    }

  }

  const checkAchPermissionAdmin = () => {

    if(ach_flags)
    {
      if(ach_flags.is_ach_applicable)
      return true
      else return false
    }

  }

  const checkPerDiemPermission = () => {
    if(show_per_diem)
    {
      return true
    }
    else{
      return false
    }
  }


  useEffect(() => {
    if (Object.keys(userRolesAndPermissions).length) {
      let showReportsTab = checkRoleAndPermissionForPage(
        userRolesAndPermissions,
        "/approvals"
      );
      let showPeronalExpenseReportTab = checkRoleAndPermissionForPage(
        userRolesAndPermissions,
        "/individual/reports"
      );
      let showPeronalAdvanceTab = checkRoleAndPermissionForPage(
        userRolesAndPermissions,
        "/individual/advances"
      );
      let showPayoutTab = checkRoleAndPermissionForPage(
        userRolesAndPermissions,
        "/payout"
      );
      let showAdvancesTab = checkRoleAndPermissionForPage(
        userRolesAndPermissions,
        "/advances"
      );
      let showSettingsTab = checkRoleAndPermissionForPage(
        userRolesAndPermissions,
        "/settings"
      );
      let showDashboardTab = checkRoleAndPermissionForPage(
        userRolesAndPermissions,
        "/insights"
      );
      let showReportDownloadTab = checkRoleAndPermissionForPage(
        userRolesAndPermissions,
        "/analytics"
      );
      let showPendingApprovalTab = checkRoleAndPermissionForTab(
        userRolesAndPermissions,
        "/individual/approvals",
        "#pending-approvals",
        "#approvals-histroy"
      );
      let showAchUser = checkAchPermissionUser()
      let showAchAdmin = checkAchPermissionAdmin()
      let showPerDiemUser = checkPerDiemPermission()

      setRolesAndPermData({
        ...rolesAndPermData,
        showReportsTab: showReportsTab,
        showPeronalExpenseReportTab: showPeronalExpenseReportTab,
        showPeronalAdvanceTab: showPeronalAdvanceTab,
        showPayoutTab: showPayoutTab,
        showAdvancesTab: showAdvancesTab,
        showSettingsTab: showSettingsTab,
        showDashboardTab: showDashboardTab,
        showReportDownloadTab: showReportDownloadTab,
        showPendingApprovalTab: showPendingApprovalTab,
        showOrgTab:
          showReportsTab ||
          showPayoutTab ||
          showAdvancesTab ||
          showSettingsTab ||
          showDashboardTab ||
          showReportDownloadTab,
        showILTab: ilAdminMenuVisible,
        showAchUser: showAchUser,
        showAchAdmin: showAchAdmin,
        showPerDiemUser: showPerDiemUser
      });
    }
  }, [userRolesAndPermissions, ilAdminMenuVisible, show_per_diem]);

  return (
    <div>
      <div className="universal-searchbar-container">
      <div className="sidebar-brand sidebar-brand-hidden">
                <img
                alt=""
                src={window.location.origin + "/assets/images/itilite-logo.png"}
                width="30"
                height="30"
                className="d-inline-block align-top logo"
              />
              <span className='accent-orange'>iti</span>
              <span className='accent-grey'>lite</span>
              </div>
        <div className="universal-search-bar">
          <div className="universal-search-input-container">
          
          <div className="input-wrapper-uni">
              <input
                  value={search}
                  placeholder={
                    !listening
                      ? `Navigate to -> e.g., expense, policy...`
                      : `Just say the word now...`
                  }
                  onChange={(e) => handleSearch(e.target.value)}
                  className={`universal-search-input`}
                />
                {search.length !== 0 && (
                  <button onClick={() => setSearch("")} className="cross-btn">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.15152 1.15162C1.37656 0.926659 1.68173 0.80028 1.99992 0.80028C2.31812 0.80028 2.62329 0.926659 2.84832 1.15162L7.99992 6.30322L13.1515 1.15162C13.2622 1.03701 13.3946 0.945593 13.541 0.882703C13.6874 0.819812 13.8449 0.786708 14.0042 0.785324C14.1636 0.783939 14.3216 0.814301 14.4691 0.874638C14.6165 0.934975 14.7505 1.02408 14.8632 1.13675C14.9759 1.24942 15.065 1.3834 15.1253 1.53088C15.1856 1.67835 15.216 1.83637 15.2146 1.99571C15.2132 2.15504 15.1801 2.3125 15.1172 2.45891C15.0544 2.60531 14.9629 2.73773 14.8483 2.84842L9.69672 8.00002L14.8483 13.1516C15.0669 13.3779 15.1879 13.6811 15.1851 13.9957C15.1824 14.3103 15.0562 14.6113 14.8337 14.8338C14.6112 15.0563 14.3102 15.1825 13.9956 15.1852C13.681 15.188 13.3778 15.067 13.1515 14.8484L7.99992 9.69682L2.84832 14.8484C2.622 15.067 2.31888 15.188 2.00424 15.1852C1.6896 15.1825 1.38863 15.0563 1.16614 14.8338C0.94365 14.6113 0.817447 14.3103 0.814713 13.9957C0.811979 13.6811 0.932933 13.3779 1.15152 13.1516L6.30312 8.00002L1.15152 2.84842C0.926557 2.62339 0.800179 2.31822 0.800179 2.00002C0.800179 1.68183 0.926557 1.37666 1.15152 1.15162Z"
                        fill="#718096"
                      />
                    </svg>
                  </button>
                )}
                 {search.length !== 0 && (
            <div className="suggestions">
              {suggestionsArray?.map((option) => {
                if(routes[option].url.includes('#')){
                  return (
                    <div style={{cursor:"pointer"}}
                      onClick={() =>{
                        history.push(`${routes[option].url}`)
                        handleLinkClicked() 
                      }}
                      className="link-container-block"
                      // to={routes[option].url}
                    >
                      <div key={routes[option].url} className="option">
                        <div>{routes[option].icon}</div>
                        <div className="info-container">
                          <span className="option-name">
                            {routes[option].name}
                          </span>
                          <div className="info">{routes[option].info}</div>
                        </div>
                      </div>
                    </div>
                  );
                }
                else 
                return (
                  <Link
                    onClick={handleLinkClicked}
                    className="link-container-block"
                    to={routes[option].url}
                  >
                    <div key={routes[option].url} className="option">
                      <div>{routes[option].icon}</div>
                      <div className="info-container">
                        <span className="option-name">
                          {routes[option].name}
                        </span>
                        <div className="info">{routes[option].info}</div>
                      </div>
                    </div>
                  </Link>
                );
              })}
              {suggestionsArray.length === 0 && (
                <div>
                  Could not find anything that matches <strong>{search}</strong>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-emoji-frown"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                  </svg>
                </div>
              )}
            </div>
          )}
          </div>
            {/* <button className="mic-btn" onClick={listening ? stop : listen}> {!listening ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-mic-mute" viewBox="0 0 16 16">
												    <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3z"/>
												    <path d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z"/>
												  </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" clasNames="bi bi-mic" viewBox="0 0 16 16">
												    <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
												    <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
												  </svg>}</button> */}
            {/* <div className="siri">
			<div>
			{
			    listening && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-record-circle-fill" viewBox="0 0 16 16">
			    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
			  </svg>
			  }
			  </div>
		    </div> */}
            <div className="profile-container">
              <div className="help">
                <NavLink to="/help" activeClassName="active">
                  {" "}
                  <CustomTooltip title = "Support" placement="bottom" arrow>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="20"
                      cy="20"
                      r="9"
                      stroke="#6B7280"
                      strokeWidth="2"
                    />
                    <path
                      d="M18.8281 22.2301H20.4432V22.0852C20.4517 21.2543 20.75 20.8665 21.4233 20.4616C22.2202 19.9886 22.7401 19.3622 22.7401 18.3608C22.7401 16.8693 21.5384 16 19.8466 16C18.2997 16 17.0384 16.8097 17 18.5142H18.7386C18.7642 17.8196 19.2798 17.4489 19.8381 17.4489C20.4134 17.4489 20.8778 17.8324 20.8778 18.4247C20.8778 18.983 20.473 19.3537 19.9489 19.6861C19.233 20.1378 18.8324 20.5938 18.8281 22.0852V22.2301ZM19.6676 24.9574C20.2131 24.9574 20.6861 24.5014 20.6903 23.9347C20.6861 23.3764 20.2131 22.9205 19.6676 22.9205C19.1051 22.9205 18.6406 23.3764 18.6449 23.9347C18.6406 24.5014 19.1051 24.9574 19.6676 24.9574Z"
                      fill="#6B7280"
                    />
                  </svg>
                  </CustomTooltip>
                </NavLink>
              </div>
              <div className="profile">
                <div className="profile-action-container">
                  <DropdownButton
                    title={
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M23.6365 17.2726C23.6365 18.237 23.2534 19.1619 22.5714 19.8439C21.8895 20.5258 20.9646 20.909 20.0001 20.909C19.0357 20.909 18.1108 20.5258 17.4288 19.8439C16.7469 19.1619 16.3638 18.237 16.3638 17.2726C16.3638 16.3082 16.7469 15.3832 17.4288 14.7013C18.1108 14.0193 19.0357 13.6362 20.0001 13.6362C20.9646 13.6362 21.8895 14.0193 22.5714 14.7013C23.2534 15.3832 23.6365 16.3082 23.6365 17.2726ZM21.8183 17.2726C21.8183 17.7548 21.6268 18.2173 21.2858 18.5582C20.9448 18.8992 20.4823 19.0908 20.0001 19.0908C19.5179 19.0908 19.0555 18.8992 18.7145 18.5582C18.3735 18.2173 18.182 17.7548 18.182 17.2726C18.182 16.7904 18.3735 16.3279 18.7145 15.9869C19.0555 15.646 19.5179 15.4544 20.0001 15.4544C20.4823 15.4544 20.9448 15.646 21.2858 15.9869C21.6268 16.3279 21.8183 16.7904 21.8183 17.2726Z"
                          fill="#6B7280"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20 10C14.4773 10 10 14.4773 10 20C10 25.5227 14.4773 30 20 30C25.5227 30 30 25.5227 30 20C30 14.4773 25.5227 10 20 10ZM11.8182 20C11.8182 21.9 12.4664 23.6491 13.5527 25.0382C14.3157 24.0363 15.2999 23.2243 16.4286 22.6657C17.5572 22.1072 18.7998 21.8171 20.0591 21.8182C21.3021 21.817 22.529 22.0996 23.6463 22.6443C24.7636 23.1891 25.7418 23.9817 26.5064 24.9618C27.294 23.9287 27.8244 22.7229 28.0535 21.4442C28.2827 20.1654 28.204 18.8505 27.8241 17.6082C27.4441 16.3658 26.7738 15.2319 25.8686 14.3C24.9634 13.3682 23.8493 12.6653 22.6185 12.2496C21.3877 11.8338 20.0756 11.7171 18.7908 11.9091C17.5059 12.1011 16.2853 12.5963 15.2298 13.3537C14.1743 14.1111 13.3144 15.109 12.7211 16.2647C12.1279 17.4205 11.8183 18.7009 11.8182 20ZM20 28.1818C18.1218 28.1846 16.3002 27.5385 14.8436 26.3527C15.4299 25.5134 16.2103 24.8281 17.1184 24.3552C18.0264 23.8822 19.0353 23.6356 20.0591 23.6364C21.0701 23.6356 22.0668 23.876 22.9663 24.3377C23.8658 24.7993 24.6422 25.469 25.2309 26.2909C23.763 27.5152 21.9114 28.1845 20 28.1818Z"
                          fill="#6B7280"
                        />
                      </svg>
                    }
                    id="dropdown-menu-align-end"
                    className="profile-main-button"
                  >
                    <Dropdown.Item eventKey="profile">
                      <NavLink className="dropdown-item" to="/profile" as="li"  activeClassName="active">
                      
                        <span>Profile</span>
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="changePassword">
                      <NavLink className="dropdown-item" to="/changepassword" as="li"  activeClassName="active">
                     
                        <span>Change password</span>
                      </NavLink>
                    </Dropdown.Item>
                    <div className="hr"></div>
                    <Nav.Link  className="dropdown-item m-left" onClick={e => {
						USMLogout(`${process.env.REACT_APP_ITILITEURL}/logout`)
                        // localStorageService.clearToken();
                        // axios({
                        //   method: 'get',
                        //   url: `${process.env.REACT_APP_ITILITEURL}/logout`,
                        //   headers: {
                        //     'request-from': "web"
                        //   }
                        // }).then((res) => {
						// 	USMLogout(`${process.env.REACT_APP_ITILITEURL}/logout`)
                        // }).catch(error => {
                        //   console.log(error);
                        // });
                      }}>Logout</Nav.Link>
                  </DropdownButton>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default UniversalNavigation;
