export const FETCH_ALL_REPORTS = "fetchAllAdvanceReports";
export const SET_ALL_REPORTS = "setAllAdvanceReports";

export const FETCH_REPORTS = "fetchAdvanceReports";
export const SET_REPORTS = "setAdvanceReports";

export const FETCH_OUTSTANDING_ADVANCES = "fetchOutstandingAdvances";
export const SET_OUTSTANDING_ADVANCES = "setOutstandingAdvances";

export const REJECT_REPORT = "rejectAdvanceReport";
export const SET_REJECT_REPORT = "setAdvanceRejectReason";

export const APPROVE_REPORT = "approveAdvanceReport";
export const SET_APPROVE_REPORT = "setAdvanceApproveReason";