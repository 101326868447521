import { API } from "./Api.types";

// action creator function
export default function actionCreator({
    url = "",
    method = "GET",
    data = null,
    accessToken = null,
    onSuccess = () => { },
    onFailure = () => { },
    label = "",
    headersOverride = null,
    baseURL = null,
    customHeaders = null,
    sendAccessToken = true
}) {
    return {
        type: API,
        payload: {
            url,
            method,
            data,
            accessToken,
            onSuccess,
            onFailure,
            label,
            headersOverride,
            baseURL,
            customHeaders
        }
    };
}