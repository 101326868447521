import createReducer from '../../../../../components/common/Utility';

import {
    FETCH_ALL_UNFILED_TRANSACTIONS,
    SET_ALL_UNFILED_TRANSACTIONS,
    FETCH_ALL_FILED_TRANSACTIONS,
    SET_ALL_FILED_TRANSACTIONS,
    // CREATE_UNFILED_TRANSACTION_EXPENSE,
    // SET_CREATE_UNFILED_TRANSACTION_EXPENSE
} from '../../../../actions/Personal/Cards/Transactions/Transactions.action.type';

// import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE } from '../../../../actions/Common/Api.types';

export const initialState = {
    filedTransactionsCount: 0,
    filedTransactionsData: {},
    filedTransactions: [],
    lastSyncTime: "",

    unFiledTransactionsCount: 0,
    unFiledTransactionsData: null,
    unFiledTransactions: [],

    isLoadingData: false,
}

export const reducerObj = {
    [SET_ALL_FILED_TRANSACTIONS]: (prevState, payload) => (
        {
            filedTransactions: payload.status ? payload.transactions : [],
            filedTransactionsData: payload,
            filedTransactionsCount: payload.status && payload.pagination ? payload.pagination.total_count : 0,
            isLoadingData: false,
            lastSyncTime: payload.status ? payload.last_sync_time : ""
        }
    ),

    [SET_ALL_UNFILED_TRANSACTIONS]: (prevState, payload) => (
        {
            // unFiledTransactions: payload.data,
            unFiledTransactionsData: payload,
            unFiledTransactionsCount: payload.total_records || 0,
            isLoadingData: false
        }
    ),

    [RESET_STATE]: () => (
        {
            filedTransactionsCount: 0,
            filedTransactionsData: null,
            filedTransactions: [],

            unFiledTransactionsCount: 0,
            unFiledTransactionsData: null,
            unFiledTransactions: [],

            isLoadingData: false,
        }
    ),

    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === FETCH_ALL_UNFILED_TRANSACTIONS) {
            return {
                filedTransactions: [],
                filedTransactionsData: {},
                filedTransactionsCount: 0,
                isLoadingData: true,
            }
        }
        if (payload === FETCH_ALL_FILED_TRANSACTIONS) {
            return {
                filedTransactions: [],
                filedTransactionsData: {},
                filedTransactionsCount: 0,
                isLoadingData: true,
            }
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === SET_ALL_UNFILED_TRANSACTIONS || payload === SET_ALL_FILED_TRANSACTIONS) {
            return {
                isLoadingData: false,
            }
        }
    },
}

let TransactionReducer = createReducer(initialState, reducerObj);

export default TransactionReducer;