import React, { Component } from 'react';


class Home extends Component {
  state = {}

  componentWillMount() {
    //   alert("s");

  }

  render() {
      return (
        <div className="header-not-login">
        <div className="logo-menu-wrapper">
          <div className="logo-wrapper">
            <img alt="" src="../assets/images/itilite-logo-1.png" className="site-logo" />
          </div>
          <div className="login-btn-wrpper">
            <a href={`${process.env.REACT_APP_ITILITEURL}/sign-in`} className="login-btn">Login</a>
          </div>
        </div>
      </div>
      )
  }
}

export default Home;
 