export const FETCH_ALL_CATEGORIES = "fetchAllCategories";
export const SET_ALL_CATEGORIES = "setAllCategories";

// delete
export const DELETE_CATEGORY = "deleteCategory";
export const SET_DELETED_CATEGORY = "setDeleteCategory";

// update
export const UPDATE_CATEGORY = "updateCategory";
export const SET_UPDATE_CATEGORY = "setUpdateCategory";
//handling switch

export const SET_SWITCH_HANDLER="setSwitchHandler";
//Reseting category list
export const RESET_CATEGORY_LIST ="resetCategoryList";

