import createReducer from '../../../../../components/common/Utility';

import {
	SAVE_RULES,
	SET_RULES,
	SET_SAVE_RULES,
	SET_RULES_BY_ID,
	FETCH_RULES,
	FETCH_RULES_BY_ID,
	GET_SAVE_APPROVAL_RULE_PRIORITY_ORDER,
	SET_SAVE_APPROVAL_RULE_PRIORITY_ORDER,
	SET_SAVE_POLICY_FALSE,
	SET_APPROVAL_LIST_FOR_PAGE_DATA,
	SET_APPROVAL_FLOW_STATUS,
    RESET_APPROVAL_FLOW_STATUS_RESPONSE,
	SET_USERS

} from '../../../../actions/Admin/Settings/ApprovalFlow/ApprovalFlow.action.type';

import { API_START, API_END,RESET_STATE } from '../../../../actions/Common/Api.types';

export const initialState = {
	userBasics: {},
	isLoadingData: true,
	categoryList: {},
	rulesList: [],
	totalRulesRecords: 0,
	policySaved: false,
	policyEditResponse: {},
	rulesObj: {},
	approvalOrderResponse: null,
	approvalListPageData: [],
	approvalFlowStatusResponse: null,
	usersData: []
}

export const reducerObj = {
	[SET_RULES_BY_ID]: (prevState, payload) => (
		{
			isLoadingData: false,
			rulesObj: payload
		}
	),
	[SET_RULES]: (prevState, payload) => (
		{
			isLoadingData: false,
			rulesList: payload['data'],
			totalRulesRecords: payload['total_records']
		}
	),
	[SET_APPROVAL_LIST_FOR_PAGE_DATA]: (prevState, payload) => (
		{
			approvalListPageData: payload
		}
	),

	[SET_SAVE_RULES]: (prevState, payload) => (
		{
			isLoadingData: false,
			policySaved: true,
			policyEditResponse: payload
		}
	),
	[SET_SAVE_APPROVAL_RULE_PRIORITY_ORDER]: (prevState , payload) => {
		return {
			isLoadingData: false,
			approvalOrderResponse: payload
		}
	},
	[SET_APPROVAL_FLOW_STATUS]: (prevState, payload) => (
		{
		    approvalFlowStatusResponse: payload
		}
	),
	[RESET_APPROVAL_FLOW_STATUS_RESPONSE]:(prevState, payload)=>(
		{
			approvalFlowStatusResponse: payload
		}
	),
	[SET_SAVE_POLICY_FALSE]:(prevState)=>(
		{
		    policySaved: false,
		    policyEditResponse: {}      
        }
	),
	[SET_USERS]: (prevState, payload) => {
		return {
			usersData: [...prevState.usersData, ...payload]
		}
	},
    [RESET_STATE] : (prevState , payload) => (
		{
			...initialState
		}
	),
	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === SAVE_RULES || payload === FETCH_RULES || payload === FETCH_RULES_BY_ID || payload === GET_SAVE_APPROVAL_RULE_PRIORITY_ORDER) {
			return { policySaved: false, isLoadingData: true }
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === SAVE_RULES || payload === FETCH_RULES || payload === FETCH_RULES_BY_ID || payload === SET_SAVE_APPROVAL_RULE_PRIORITY_ORDER) {
			return { policySaved: false, isLoadingData: false }
		}
	}
}

let ApprovalFlowReducer = createReducer(initialState, reducerObj);

export default ApprovalFlowReducer;