import createReducer from '../../../../components/common/Utility';

import {
	FETCH_ALL_DATA,
	SET_DASHBOARD_DATA,
} from '../../../actions/Admin/OrgDashboard/Dashboard.action.type';

import { API_START, API_END } from '../../../actions/Common/Api.types';

export const initialState = {
	dashboardData: {},
}

export const reducerObj = {
	[SET_DASHBOARD_DATA]: (prevState, payload) => (
		{
			dashboardData: payload,
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => (
		payload === FETCH_ALL_DATA
			? { isLoadingData: true }
			: { isLoadingData: false }
	),
	[API_END]: (prevState, payload) => (
		payload === FETCH_ALL_DATA
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
}

let DashboardReducer = createReducer(initialState, reducerObj);

export default DashboardReducer;