import createReducer from "../../../../../components/common/Utility";
import {SET_CODAT_DATA,SET_CODAT_CONFIG_FORM_DATA, SET_IS_FORM_OTHER_OPTION_SELECTED,GET_CODAT_DATA,SET_CODAT_INTEGRATION_RESPONSE,CREATE_CODAT_INTEGRATION_REQUEST,Get_CODAT_AUTHENTICATE_REQUEST,SET_CODAT_AUTHENTICATE_RESPONSE,SET_CODAT_REQUEST_MODAL,SET_CODAT_CONFIGURATION_RESPONSE,CREATE_CODAT_CONFIGURATION_REQUEST,GET_CODAT_Authorize_Request,SET_CODAT_Authorize_Request, SET_CODAT_Authorize_Response} from "../../../../actions/Admin/Settings/Codat/CodatActionType"
import { API_END, API_ERROR, API_START, RESET_STATE } from "../../../../actions/Common/Api.types"

export const initialState = {
    CodatInitialData:null,
    CodatConfigFormData:null,
    CodatFormOtherOptionSelected:null,
    CodatIntegrationRequestResponse:null,
    isLoadingData:false,
    commonResponse:null,
    codatAuthenticationresponse:null,
    showrequestmodel:false,
    CodatFormConfigurationResponse:null,
    CodatAuthorizedResponse:null,
}

 
export const reducerObj = {
    
    [SET_CODAT_DATA]: (prevState, payload) => (
       {
        CodatInitialData: payload
       }
    ),
   
    [SET_CODAT_CONFIG_FORM_DATA]: (prevState, payload) => (
        {
            CodatConfigFormData: payload
        }
     ),
     [SET_IS_FORM_OTHER_OPTION_SELECTED]:(prevState, payload) => (

        {
            CodatFormOtherOptionSelected:payload
        }
     ),
     [SET_CODAT_INTEGRATION_RESPONSE]:(prevState, payload) => (

        {
            CodatIntegrationRequestResponse:payload  
        }
     ),  
     [SET_CODAT_AUTHENTICATE_RESPONSE]: (prevState, payload) => (
        {
            codatAuthenticationresponse: payload
        }
    ),
    [SET_CODAT_CONFIGURATION_RESPONSE]:(prevState, payload) => (

        {
            CodatFormConfigurationResponse:payload  
        }
     ),
    [SET_CODAT_REQUEST_MODAL]: (prevState, payload) => (
        {
            showrequestmodel: payload
        }
    ),
    [SET_CODAT_Authorize_Response]: (prevState, payload) => (
        {
            CodatAuthorizedResponse: payload
        }
    ),
     [RESET_STATE]: (prevState , payload) => (
        {
            CodatIntegrationRequestResponse:null,
            CodatFormConfigurationResponse:null,
            CodatInitialData:null
            
        } 
    ),
     [API_START]: (prevState, payload) => {
		if ( payload === CREATE_CODAT_INTEGRATION_REQUEST || payload=== CREATE_CODAT_CONFIGURATION_REQUEST || payload===GET_CODAT_DATA)
        return { isLoadingData: true }
	},
    
    [API_END]: (prevState, payload) => (
	   ( payload === SET_CODAT_INTEGRATION_RESPONSE || payload=== SET_CODAT_CONFIGURATION_RESPONSE || payload===SET_CODAT_DATA)
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
    [API_ERROR]: (prevState, payload) => {
		
			return {
				commonResponse: payload
			}
		
	}
}



let CodatReducer = createReducer(initialState , reducerObj);

export default CodatReducer;