// USER SIDE

export const GET_PERDIEM_ATTRIBUTES = "getPerDIemAttributes"
export const SET_PERDIEM_ATTRIBUTES = "setPerDIemAttributes"

export const CREATE_PERDIEM = "createPerDiem"
export const SET_CREATE_PERDIEM_RESPONSE = "setcreatePerDiem"

export const FETCH_CAL_ATTRI_PERDIEM = "fetchCalcAttributePerdiem"
export const SET_CAL_ATTRI_PERDIEM = "setCalcAttributePerdiem"

export const GET_PER_DIEM_DETAILS = "getPerDiemDetails"
export const SET_PER_DIEM_DETAILS = "setPerDiemDetails"

export const EDIT_PER_DIEM = "editPerDiemUser"
export const SET_EDIT_PER_DIEM_RESPONSE = "setEditPerDiemResponse"

export const RESET_COMMON_ERROR_RESPONSE = "resetCommonResponse"

export const FETCH_PERDIEM_SUMMARY = "fetchPerDiemSummary"
export const SET_PERDIEM_SUMMARY = "setPerDiemSummary"

export const FETCH_EXCHANGE_RATE = "exchangerateFetch"
export const SET_EXCHANGE_RATE_DATA = "setExchangerateFetch"

// ADMIN SIDE


