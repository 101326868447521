import createReducer from '../../../../../components/common/Utility';

import {
	SAVE_POLICY,
	SET_ALL_USERS_BASICS_DATA,
	SET_CATEGORY_LIST_DATA,
	SET_POLICIES,
	SET_SAVE_POLICY,
	SET_POLICY_BY_ID,
	FETCH_ALL_USERS_BASICS_DATA,
	FETCH_CATEGORY_LIST_DATA,
	FETCH_POLICIES,
	FETCH_POLICY_BY_ID,
	FETCH_ACTIVE_CATEGORY_LIST_DATA,
	SET_ACTIVE_CATEGORY_LIST_DATA,
	SET_SAVE_POLICY_FALSE,
	SET_POLICY_LIST_FOR_PAGE_DATA,
	SET_POLICY_STATUS,
	RESET_POLICY_STATUS_RESPONSE

} from '../../../../actions/Admin/Settings/Policies/Policies.action.type';

import { API_START, API_END, RESET_STATE, API_ERROR } from '../../../../actions/Common/Api.types';

export const initialState = {
	userBasics: {},
	isLoadingData: false,
	categoryList: {},
	categoryListForExpense: null,
	categoryListForAdvance: null,
	policyList: [],
	totalPolicyRecords: 0,
	policySaved: false,
	policyEditResponse: {},
	policyObj: {},
	isCategoryListLoading: false,
	policyListPageData: [],
	policyStatusResponse: null
}

export const reducerObj = {
	[SET_ALL_USERS_BASICS_DATA]: (prevState, payload) => (
		{
			userBasics: payload
		}
	),
	[SET_POLICY_LIST_FOR_PAGE_DATA]: (prevState, payload) => (
		{
			policyListPageData: payload
		}
	),
	[SET_POLICY_LIST_FOR_PAGE_DATA]: (prevState, payload) => (
		{
			policyListPageData: payload
		}
	),
	[SET_POLICY_STATUS]: (prevState, payload) => (
		{
			policyStatusResponse: payload
		}
	),
	// [SET_POLICY_STATUS]: (prevState, payload) => {

	// 	// console.log("hellooo")
	// 	// let data = [...prevState]


	// 	// let requiredData = data.map((data) => {

	// 	// 	if(data.uid === payload.data.uid)
	// 	// 	{
	// 	// 		return {
	// 	// 			...data,
	// 	// 			active: payload.data.is_enabled === true ? 1 : 0
	// 	// 		}
	// 	// 	}
	// 	// 	else{
	// 	// 		return data
	// 	// 	}
	// 	// })

	// 	// console.log(data)
	// 	return {
	// 		policyListPageData: requiredData
	// 	}
	// },
	[SET_CATEGORY_LIST_DATA]: (prevState, payload) => (
		{
			categoryList: payload
		}
	),
	[SET_ACTIVE_CATEGORY_LIST_DATA]: (prevState, payload) => (
		{
			categoryList: payload,
			categoryListForExpense: payload.data.expense,
			categoryListForAdvance: payload.data.advance,
			isCategoryListLoading: false
		}
	),
	[SET_POLICY_BY_ID]: (prevState, payload) => (
		{
			policyObj: payload,
			isLoadingData: false
		}
	),
	[SET_POLICIES]: (prevState, payload) => (
		{
			policyList: payload['data'],
			isLoadingData: false,
			totalPolicyRecords: payload['total_records']
		}
	),
	[SET_SAVE_POLICY]: (prevState, payload) => (
		{
			policySaved: true,
			policyEditResponse: payload,
			isLoadingData: false
		}
	),
	[SET_SAVE_POLICY_FALSE]:(prevState)=>(
		{
			policySaved: false,
			policyEditResponse: {}
		}
	),
	[RESET_POLICY_STATUS_RESPONSE]:(prevState, payload)=>(
		{
			policyStatusResponse: payload
		}
	),
	[RESET_STATE]: (prevState, payload) => (
		{
			userBasics: {},
			isLoadingData: false,
			categoryList: {},
			categoryListForExpense: null,
			categoryListForAdvance: null,
			policyList: [],
			totalPolicyRecords: 0,
			policySaved: false,
			policyEditResponse: {},
			policyObj: {},
			isCategoryListLoading: false,
			policyStatusResponse: null
		}
	),
	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === FETCH_CATEGORY_LIST_DATA || payload === FETCH_ACTIVE_CATEGORY_LIST_DATA) {
			return { policySaved: false, categoryList: {}, isCategoryListLoading: true };
		} else if (payload === SAVE_POLICY || payload === FETCH_POLICIES || payload === FETCH_POLICY_BY_ID) {
			return { policySaved: false, isLoadingData: true, isCategoryListLoading: true };
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === SAVE_POLICY || payload === SET_POLICIES || payload === SET_POLICY_BY_ID){
			return { policySaved: false, isLoadingData: false, isCategoryListLoading: false }
		} else if (payload === SET_ALL_USERS_BASICS_DATA || payload === SET_CATEGORY_LIST_DATA || payload === SET_ACTIVE_CATEGORY_LIST_DATA) {
			return { isCategoryListLoading: false }
		}
	},
	[API_ERROR]: (prevState, payload) => {
		if (payload.label === SAVE_POLICY) {
			return { 
				policySaved: true,
				policyEditResponse: payload.data,
				isLoadingData: false, 
				isCategoryListLoading: false 
			}
		}
	}
}

let PoliciesReducer = createReducer(initialState, reducerObj);

export default PoliciesReducer;