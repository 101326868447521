export const SCHEDULE_REPORT = "scheduleReport";
export const SET_SCHEDULE_REPORT = "setScheduleReport";

export const CANCEL_SCHEDULED_REPORT = "cancelScheduledReport";
export const SET_CANCEL_SCHEDULED_REPORT = "setCancelScheduleDReport";

export const CREATE_CUSTOM_REPORT = "createCustomReport";
export const SET_CREATE_CUSTOM_REPORT = "setCreateCustomReport";

export const LIST_WIDGETS = "listWidgets";
export const SET_LIST_WIDGETS = "setListWidgets";

export const LIST_REPORTS = "listReports";
export const SET_LIST_REPORTS = "setListReports";

export const SEARCH_LIST_REPORTS = "searchListReports";
export const SET_SEARCH_LIST_REPORTS = "setSearchListReports";

export const DOWNLOAD_REPORTS = "downloadReports";
export const SET_DOWNLOAD_REPORTS = "setDownloadReports";