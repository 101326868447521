import jwt from "jwt-simple";

// ------------------------------------- variable declaration for respective util function ---------------------------------
import React from "react";
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { services } from "@itilite/universal-ui";
import LocalStorageService from "../components/common/services/Storage_services/LocalStorageService";


let result = null; // for idGenerator()
let paginatedRows = {},
    paginatedRowsIds = {},
    _component = ""; // for persistSelectedTableRows()
var data = [];
var columns = [];
// ---------------------------------------------------------------------------------------------

export const convertTimeTo12hrs = (time) => {
                
    // Check correct time format and split into components
time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

if (time.length > 1) { // If time format correct
time = time.slice (1);  // Remove full string match value
time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
time[0] = +time[0] % 12 || 12; // Adjust hours
}
return time.join (''); // return adjusted time or original string


}

const idGenerator = (list) => {
    let id = Math.floor(Math.random() * 100);

    const checkId = (_list, _id) => {
        result = _list.filter((li) => li.id === _id);

        if (result && result.length) {
            id += 1;

            return checkId(list, id);
        }

        return result;
    };

    if (list.length) {
        checkId(list, id);
    }

    return id;
};

const dateFormatter = (string) => {
    if (!string) return;

    let data = null;
    let month = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
    };

    data =
        string.split("-")[0].length > 2
            ? string.split("-").reverse()
            : string.split("-");
    if (string.split("-")[1].length > 1) {
        data[1] = " " + month[string.split("-")[1]] + " ";
    } else {
        data[1] = " " + month["0" + string.split("-")[1]] + " ";
    }
    data.join();

    return data;
};

const shimmer = (props) => {
    return (
        <div className='shimmer-wrapper' style={props && props.padding ? { padding: "1rem 2rem" } : { padding: "0" }}>
            <Skeleton height={props?.height || 30} count={props?.count || 10} />
        </div>
    )
}

const showLoader = ({ res_data, res_list, loading_state, count }) => {
    // console.log(res_data, res_list, loading_state, count);
    // res_data = payload obj
    // res_list = payload.data
    // count = number of
    let showLoader = true;
    // if (!loading_state && !res_data) return showLoader = false;

    if (!loading_state && count > 0 && (res_list && res_list.length > 0)) {
        // console.log("90")
        showLoader = false;
    } else {
        // console.log("73")
        if (res_data && Object.keys(res_data).length) {
            // console.log("74")
            if (res_data.data) {
                // console.log("77")
                if (Array.isArray(res_data.data)) {
                    // console.log("79")
                    if (res_data.data.length === 0) {
                        // console.log("81")
                        showLoader = false;
                    }
                } else {
                    // console.log("85")
                    if (res_data.data.expense?.length === 0 || res_data.data.advance?.length === 0) {
                        // console.log("87")
                        showLoader = false;
                    }
                }
            } else {
                // console.log("92")
                if (res_data.status === true || res_data.status === false) {
                    // console.log("94")
                    showLoader = false;
                }
            }
        } else {
            // console.log("99")
            if (!loading_state) {
                // console.log("101")
                if (count >= 0) {
                    if (count > 0) {
                        // console.log("103")
                        showLoader = false;
                    } else {
                        // console.log("106")
                        showLoader = true;
                    }
                } else {
                    // console.log("133");
                    showLoader = false;
                }
            } else {
                // console.log("110")
                showLoader = true;
            }
        }
    }
    return showLoader;
}

const persistSelectedTableRows = (selectedRows, { multipleTabs, activeTab, paginatingNum }, target, component, reset = false) => {
    if (component !== _component || reset) {
        paginatedRows = {};
        paginatedRowsIds = {};
    }
    _component = component;

    if (multipleTabs) {

        paginatedRows[activeTab] = {
            ...paginatedRows[activeTab],
            [paginatingNum]: []
        }
        paginatedRowsIds[activeTab] = {
            ...paginatedRowsIds[activeTab],
            [paginatingNum]: []
        }

        const filter = (id) => {
            const index = paginatedRows[activeTab][paginatingNum].findIndex(x => x[target] === id);
            return index;
        }

        const compare = (b1, b2) => {
            if (!b1[activeTab][paginatingNum] || !b2) return [];

            var res = b1[activeTab][paginatingNum].filter(item1 => !b2.some(item2 => (item2[target] === item1[target] && ((item2["category"] && item1["category"]) && (item2.category["name"] && item1.category["name"]) ? item2.category.name === item1.category.name : item2[target] === item1[target]))));
            return res;
        }


        if (selectedRows.length) {
            for (let i = 0; i < selectedRows.length; i++) {
                if (paginatedRows[activeTab][paginatingNum] && paginatedRows[activeTab][paginatingNum].length) {
                    if (filter(selectedRows[i][target]) === -1) {
                        paginatedRows[activeTab][paginatingNum].push(selectedRows[i]);
                        paginatedRowsIds[activeTab][paginatingNum].push(selectedRows[i][target]);
                    } else {
                        if (compare(paginatedRows, selectedRows).length) {
                            for (let i = 0; i < compare(paginatedRows, selectedRows).length; i++) {
                                for (let j = 0; j < paginatedRows[activeTab][paginatingNum].length; j++) {
                                    if (compare(paginatedRows, selectedRows)[i][target] === paginatedRows[activeTab][paginatingNum][j][target]) {
                                        paginatedRows[activeTab][paginatingNum].splice(j, 1);
                                        paginatedRowsIds[activeTab][paginatingNum].splice(j, 1);

                                        break;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (!paginatedRows[activeTab][paginatingNum] || !paginatedRowsIds[activeTab][paginatingNum]) {
                        paginatedRows[activeTab][paginatingNum] = [];
                        paginatedRowsIds[activeTab][paginatingNum] = [];
                        paginatedRows[activeTab][paginatingNum].push(selectedRows[i]);
                        paginatedRowsIds[activeTab][paginatingNum].push(selectedRows[i][target]);
                    } else {
                        paginatedRows[activeTab][paginatingNum].push(selectedRows[i]);
                        paginatedRowsIds[activeTab][paginatingNum].push(selectedRows[i][target]);
                    }
                }
            }
        } else {
            if (compare(paginatedRows, selectedRows).length) {
                for (let i = 0; i < compare(paginatedRows, selectedRows).length; i++) {
                    for (let j = 0; j < paginatedRows[activeTab][paginatingNum].length; j++) {
                        if (compare(paginatedRows, selectedRows)[i] && paginatedRows[activeTab][paginatingNum][j]) {
                            if ((compare(paginatedRows, selectedRows)[i][target] === paginatedRows[activeTab][paginatingNum][j][target])) {
                                paginatedRows[activeTab][paginatingNum].splice(j, 1);
                                paginatedRowsIds[activeTab][paginatingNum].splice(j, 1);

                                break;
                            }
                        }
                    }
                }
            } else {
                if (reset) {
                    paginatedRows = {};
                    paginatedRowsIds = {};
                }
            }
        }
    } else {
        const filter = (id) => {
            const index = paginatedRows[paginatingNum].findIndex(x => x[target] === id);
            return index;
        }

        const compare = (b1, b2) => {
            if (!b1[paginatingNum] || !b2) return [];

            var res = b1[paginatingNum].filter(item1 => !b2.some(item2 => (item2[target] === item1[target] && ((item2["category"] && item1["category"]) && (item2.category["name"] && item1.category["name"]) ? item2.category.name === item1.category.name : item2[target] === item1[target]))));
            return res;
        }


        if (selectedRows.length) {
            for (let i = 0; i < selectedRows.length; i++) {
                if (paginatedRows[paginatingNum] && paginatedRows[paginatingNum].length) {
                    if (filter(selectedRows[i][target]) === -1) {
                        paginatedRows[paginatingNum].push(selectedRows[i]);
                        paginatedRowsIds[paginatingNum].push(selectedRows[i][target]);
                    } else {
                        if (compare(paginatedRows, selectedRows).length) {
                            for (let i = 0; i < compare(paginatedRows, selectedRows).length; i++) {
                                for (let j = 0; j < paginatedRows[paginatingNum].length; j++) {
                                    if (compare(paginatedRows, selectedRows)[i][target] === paginatedRows[paginatingNum][j][target]) {
                                        paginatedRows[paginatingNum].splice(j, 1);
                                        paginatedRowsIds[paginatingNum].splice(j, 1);

                                        break;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (!paginatedRows[paginatingNum] || !paginatedRowsIds[paginatingNum]) {
                        paginatedRows[paginatingNum] = [];
                        paginatedRowsIds[paginatingNum] = [];
                        paginatedRows[paginatingNum].push(selectedRows[i]);
                        paginatedRowsIds[paginatingNum].push(selectedRows[i][target]);
                    } else {
                        paginatedRows[paginatingNum].push(selectedRows[i]);
                        paginatedRowsIds[paginatingNum].push(selectedRows[i][target]);
                    }
                }
            }
        } else {
            if (compare(paginatedRows, selectedRows).length) {
                for (let i = 0; i < compare(paginatedRows, selectedRows).length; i++) {
                    for (let j = 0; j < paginatedRows[paginatingNum].length; j++) {
                        if (compare(paginatedRows, selectedRows)[i] && paginatedRows[paginatingNum][j]) {
                            if ((compare(paginatedRows, selectedRows)[i][target] === paginatedRows[paginatingNum][j][target])) {
                                paginatedRows[paginatingNum].splice(j, 1);
                                paginatedRowsIds[paginatingNum].splice(j, 1);

                                break;
                            }
                        }
                    }
                }
            } else {
                if (reset) {
                    paginatedRows = {};
                    paginatedRowsIds = {};
                }
            }
        }
    }

    // console.log(paginatedRowsIds);

    return { paginatedRows, paginatedRowsIds };
}

const resetPersistedData = (empty_data, tabsObj, target, component) => {
    persistSelectedTableRows(empty_data, tabsObj, target, component, true);
}

const amountValDecimalChecker = (amount) => {
    if (!amount && amount === "") return;

    let arr = [];
    let split = amount.toString().split(".")[1].split("");

    arr.push(split.shift());
    arr.push(split.shift());

    return parseInt(split.join("")) > 0;
};

const generateJWTAuthKey = () => {
    const dt = new Date();

    const timeStamp = `${dt.getFullYear().toString().padStart(4, "0")}-${(
        dt.getMonth() + 1
    )
        .toString()
        .padStart(2, "0")}-${dt.getDate().toString().padStart(2, "0")} ${dt
            .getHours()
            .toString()
            .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt
                .getSeconds()
                .toString()
                .padStart(2, "0")}.${dt.getMilliseconds().toString().padStart(5, "0")}`;
    const payload = { iat_: timeStamp };
    return jwt.encode(
        payload,
        process.env.REACT_APP_CLIENT_ONBOARDING_KEY,
        "HS256"
    );
};



const select_unSelect_All = {

    selectAllcheckbox: ({ checked, handleChange, r_ids, type = null }) => {

        
        const GreenCheckbox = withStyles({
            root: {
                color: "#71717A",
                '&$checked': {
                    color: "#EB5C24",
                },
            },
            checked: {},
        })((props) => <Checkbox color="default" {...props} inputProps={{ 'data-type': type }} />);

        return (
            <FormControlLabel
                style={window.location.hash === "#processing" || window.location.hash === "#paid" ? {left: "-3px"} : null}
                id="custom-selectall-checkbox"
                control={<GreenCheckbox checked={checked} onChange={handleChange} data-type={type} name="checkedG" />}
            />
        )
    },

    selectAllMessage: ({ shouldRender,currentSelectedRowsCount, row_type, total_count, tab_name, selectAllHandler, respState, un_selectHandler, type = null, reportCountApi,actionButtons, payoutFiltersCount, status }) => {
        
        // console.log(currentSelectedRowsCount, row_type, total_count, tab_name, selectAllHandler, respState, un_selectHandler, type);
        
        return (
            <div className="select-all-message-wrapper">
                <span className="currentSelectedRowsCount payout-filter-count">{status && payoutFiltersCount ? `Showing ${total_count} result(s)` : `Showing all items`}</span>
                <p className="action-buttons-wrapper">
                    {/* All <span className="currentSelectedRowsCount">{respState.selected ? total_count : currentSelectedRowsCount}</span> {row_type} {respState.selected ? "" : `on this page`} are selected. */}
                    <p>{respState.selected ? "All" : ""} <span className="currentSelectedRowsCount">{(respState.selected || currentSelectedRowsCount === total_count) ? total_count : currentSelectedRowsCount}</span> {row_type} <span className="payout-filter-count"> are selected.</span></p>
                    {shouldRender ? <button onClick={(respState.selected || currentSelectedRowsCount === total_count) ? un_selectHandler : selectAllHandler} disabled={respState.isSelecting || reportCountApi} data-type={type} >
                        {respState.isSelecting
                            ? (
                                "Selecting..."
                            ) : (
                                (respState.selected || currentSelectedRowsCount === total_count)
                                    ? (
                                        "Clear selection"
                                    ) : (
                                        `Select all ${total_count} ${row_type} ${reportCountApi ? "..." : ""}`
                                    )
                            )
                        }
                    </button>: null}
                    {actionButtons}
                    <Link className='export-history-btn' to='/export-history'>View export history</Link>
                </p>
            </div>
        )
    }
}

// For handeling the initials or the profile pic of the user.
// @param {name} name of the user.
// @param {image} profile picture of the user.
// @returns {first[0] + last[0] name || profile picture} if profile pic is not there then it will return first[0] + last[0] name else profile pic.
const initialsHandler = (name, image) => {
    name = name.trim()
    if (image) {
        return (
            <div className='creator-initials-image'>
                <img alt='profile-pic' src={image} />
            </div>
        )
    } else if (name && name !== "") {
        let f_n = name.split(" ")[0][0]?.toUpperCase();
        let s_n = name.split(" ")[name.split(" ").length - 1][0]?.toUpperCase();
        return (
            <div className='creator-initials'>
                {name.split(" ")?.length >= 2 && f_n && s_n ? `${f_n + s_n}` : f_n ? f_n : s_n}
            </div>
        );
    }
}

// For handeling Comments/Activity-log for partial approval flow in "report-details page and expense-details".
// @param {approverComments} an array of all the comments.
// @param {myDetails} your details as a logged in user.
// @param {newComment} state variable to store the input value for the new comment.
// @param {setNewComment} fn to update the @newComment state variable.
// @returns {ui} all the previous comments and an input box for new comment.
const partialApprovalCommentSection = ({
    report_id,
    coming_from,
    approverComments,
    parentComponent,
    approvalInfo
    // myDetails,
    // newComment,
    // setNewComment
}) => {

    // const onChangeHandler = ({ target: { value } }) => {
    //     if (!/^\s/.test(value)) { // not only spaces
    //         setNewComment(value)
    //     } else {
    //         setNewComment("")
    //     }
    // }

    // const onKeyDownHandler = ({ keyCode }) => {
    //     if (keyCode === 13) {
    //         if (newComment && newComment !== "") {
    //             // do something
    //             console.log(newComment)
    //         }
    //     }
    // }

    let ui = (
        <div className='partial-approvers-comments-container'>
            <div className='approvers-comments-container-header partial-approvers-comments-container-header'>Comments</div>
            <div className="comments-scrollable-container">
                {
                    approverComments && approverComments.length
                        ? (
                            approverComments.filter((comment, i) => (comment.comments.trim() !== "" || (comment.behalf_user && Object.keys(comment.behalf_user).length))).map((comment, i) => (
                                <div className='author-container' key={i}>
                                    {initialsHandler(comment.behalf_user && Object.keys(comment.behalf_user).length ? `${comment.behalf_user.first_name} ${comment.behalf_user.last_name}` : comment?.name,comment.behalf_user && Object.keys(comment.behalf_user).length ? comment.behalf_user.img_path : comment?.img_path)}
                                    <div className='approver-details'>
                                        <div className="creator-details">
                                            <p className="creator-name">{comment.behalf_user && Object.keys(comment.behalf_user).length ? `${comment.behalf_user.first_name} ${comment.behalf_user.last_name}` : comment.name} <span className='creator-mail'>{comment.behalf_user && Object.keys(comment.behalf_user).length ? comment.behalf_user.email : comment.email}</span></p>
                                            <p className='creator-time'>{comment.timestamp}</p> 
                                        </div>
                                        { 
                                            // comment?.edited_data && "comments" in comment?.edited_data && comment.behalf_user && Object.keys(comment.behalf_user).length
                                            //     ? (
                                            //         <div className='action-container'>
                                            //             <ul className='action'>
                                            //                 <li>
                                            //                     Edited Amount on Expense <Link to={`/individual/reports/expense/edit/${comment?.edited_data?.expense_id}?from=${coming_from}&r_id=${report_id}`}>#{comment?.edited_data?.expense_id}</Link> {comment?.edited_data?.edited_msg.split("##expense_uid##")[1]}
                                            //                 </li>
                                            //                 <li>
                                            //                     Approved by {comment.behalf_user.first_name} { comment.behalf_user.last_name} on behalf of Titu George.
                                            //                 </li>
                                            //             </ul> 
                                            //         </div>
                                            //     ) : 
                                                comment.status && comment.behalf_user && Object.keys(comment.behalf_user).length ?
                                                    <div className='action-container'>
                                                        <p className='action'>{comment.status} by {comment.behalf_user.first_name} { comment.behalf_user.last_name} on behalf of {comment.name}.</p>
                                                    </div>
                                                     : comment?.edited_data && "comments" in comment?.edited_data ? 
                                                        <div className='action-container'>
                                                            {
                                                                parentComponent === "createNewReport"
                                                                    ? (
                                                                        <p className='action'>
                                                                            Edited Amount on Expense <Link to={`/individual/reports/expense/edit/${comment?.edited_data?.expense_id}?from=${coming_from}&r_id=${report_id}`}>#{comment?.edited_data?.expense_id}</Link> {comment?.edited_data?.edited_msg.split("##expense_uid##")[1]}
                                                                            {/* Edited Amount on Expense <Link to={`/individual/reports/expense/edit/${comment?.edited_data?.expense_id}?from=${coming_from}&r_id=${report_id}`}>#{comment?.edited_data?.expense_id}</Link> from {comment?.currency} {comment?.amount} to {comment?.currency} {comment?.edited_data?.edited_amount || 0} */}
                                                                        </p>
                                                                    ) : (
                                                                        <p className='action'>
                                                                            {comment?.edited_data?.edited_msg.replace("on Expense ##expense_uid##", "")}
                                                                            {/* Edited Amount from {comment?.currency} {comment?.amount} to {comment?.currency} {comment?.edited_data?.edited_amount || 0} */}
                                                                        </p>
                                                                    )
                                                            }
                                                        </div>
                                                    : null
                                                    
                                                
                                        }
                                        {
                                            comment.comments.trim() === "" ? null :
                                            <div className='reason-container'>
                                                <p className='comment'>{comment.comments}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="no-activity mb-0">No activity recorded yet</p>
                        )
                }
            </div>
            {/* <div className="new-comment-container">
                {myDetails && initialsHandler(myDetails?.name, myDetails?.img_path)}
                <input placeholder="Write a comment" className="new-comment-input" onChange={(e) => onChangeHandler(e)} onKeyDown={(e) => onKeyDownHandler(e)} value={newComment} />
            </div> */}
        </div>
    )

    return ui;
}

// For handeling "STRIKE-OUT" for amounts at different places based on the response coming".
// @param {dataObj} api resp for report-details page or expense-details page.
// @param {className} current className.
// @param {parentComponent} for identifying the parent-component and make changes conditionally accroding to the parent.
// @returns {newClassNames} partiallyAprovedAmount + the old className based on the response.
const isPartiallyApproved = {
    amount: (dataObj, { parentComponent, inputName, label }) => {
        let renderAmount = false;

        if (dataObj && Object.keys.length) {
            const { edited_amount, amount, edited_converted_amount, converted_amount, edited_ex_rate, ex_rate, edited_total, total, report_status } = dataObj;
            if (!parentComponent) {
                if (!report_status || report_status === "Sent back") return false;
            }

            if (parentComponent) {
                if (parentComponent === "createNewReport") {
                    if (edited_total !== null && edited_total !== total) {
                        renderAmount = true;
                    }
                } else if (parentComponent === "draftExpenseTable") {
                    if (label === "amount-input") {

                        if (edited_converted_amount !== null && edited_converted_amount !== converted_amount) {
                            renderAmount = true;
                        }
                    } else if (label === "final-amount") {

                        if (edited_amount !== null && edited_amount !== amount) {
                            renderAmount = true;
                        }
                    }
                } else if (parentComponent === "pendingApprovalsTable") {
                    if (edited_amount !== null && edited_amount !== amount) {
                        renderAmount = true;
                    }
                }
            } else {
                if (inputName) {
                    if (inputName === "amount-input") {
                        if (!report_status || report_status === "Sent back") return false;

                        if (edited_converted_amount !== null && edited_converted_amount !== converted_amount) {
                            renderAmount = true;
                        }
                    } else if (inputName === "conversion-rate") {
                        if (!report_status || report_status === "Sent back") return false;

                        if (edited_ex_rate !== null && edited_ex_rate !== ex_rate) {
                            renderAmount = true;
                        }
                    } else if (inputName === "final-amount") {
                        if (!report_status || report_status === "Sent back") return false;

                        if (edited_amount !== null && edited_amount !== amount) {
                            renderAmount = true;
                        }
                    }
                } else {
                    if ((edited_amount !== null && edited_amount !== amount) || (edited_converted_amount !== null && edited_converted_amount !== converted_amount) || (edited_ex_rate !== null && edited_ex_rate !== ex_rate)) {
                        renderAmount = true
                    }
                }
            }

            return renderAmount
        } else return false;
    },

    strikeThrough: (dataObj, className, { parentComponent, inputName, label }) => {
        let newClassNames = className && className !== "" ? className : "";

        if (dataObj && Object.keys.length) {
            const { edited_amount, amount, edited_converted_amount, converted_amount, edited_ex_rate, ex_rate, edited_total, total, report_status } = dataObj;

            if (!parentComponent) {
                if (!report_status || report_status === "Sent back") return className;
            }

            if (parentComponent) {
                if (parentComponent === "createNewReport") {
                    if (edited_total !== null && edited_total !== total) {
                        newClassNames += " partiallyAprovedAmount";
                    }
                } else if (parentComponent === "draftExpenseTable") {
                    if (label === "amount-input") {

                        if (edited_converted_amount !== null && edited_converted_amount !== converted_amount) {
                            newClassNames += " partiallyAprovedAmount";
                        }
                    } else if (label === "final-amount") {

                        if (edited_amount !== null && edited_amount !== amount) {
                            newClassNames += " partiallyAprovedAmount";
                        }
                    }
                } else if (parentComponent === "pendingApprovalsTable") {
                    if (edited_amount !== null && edited_amount !== amount) {
                        newClassNames += " partiallyAprovedAmount";
                    }
                }
            } else {
                if (inputName) {
                    if (inputName === "amount-input") {
                        if (!report_status || report_status === "Sent back") return className;

                        if (edited_converted_amount !== null && edited_converted_amount !== converted_amount) {
                            newClassNames += " partiallyAprovedAmount";
                        }
                    } else if (inputName === "conversion-rate") {
                        if (!report_status || report_status === "Sent back") return className;

                        if (edited_ex_rate !== null && edited_ex_rate !== ex_rate) {
                            newClassNames += " partiallyAprovedAmount";
                        }
                    } else if (inputName === "final-amount") {
                        if (!report_status || report_status === "Sent back") return className;

                        if (edited_amount !== null && edited_amount !== amount) {
                            newClassNames += " partiallyAprovedAmount";
                        }
                    }
                } else {
                    if ((edited_amount !== null && edited_amount !== amount) || (edited_converted_amount !== null && edited_converted_amount !== converted_amount) || (edited_ex_rate !== null && edited_ex_rate !== ex_rate)) {
                        newClassNames += " partiallyAprovedAmount";
                    }
                }
            }

            return newClassNames
        } else return className;
    }
}

function USMLogout(_redirectUrl) {
    // const { UUILogout } = services;
    // UUILogout(_redirectUrl, _logoutCallback);
    _logoutCallback()
}
function _logoutCallback() {
    LocalStorageService.clearAllToken();
    window.location.href = `${process.env.REACT_APP_ITILITEURL}/logout`;
}

function validAlphabetLetter(letter) {
    return /^[a-zA-Z]$/.test(letter);
}  


function areBracketsBalanced(expr)
{

    let operators = {
        "+": "+",
        "-": "-",
        "/": "/",
        "*": "*",
    };

    let zz = /\([^)]*\)|\[[^\]]*\]/g
      
    // Using ArrayDeque is faster
    // than using Stack class

    let stack = [];

    let tempStr = ""
    for(let i = 0; i < expr?.length; i++)
    {
        if(!validAlphabetLetter(expr[i]) && expr[i] !== "_" && !operators[expr[i]] )
        {
            tempStr+= expr[i]
        }
    }
 
    // Traversing the Expression
    for(let i = 0; i < tempStr.length; i++)
    {
        let x = tempStr[i];
  
        if (x === '(' || x === '[' || x === '{')
        {
              
            // Push the element in the stack
            stack.push(x);
            continue;
        }
  
        // If current character is not opening
        // bracket, then it must be closing. 
        // So stack cannot be empty at this point.
        if (stack.length === 0)
            return false;
              
        let check;
        switch (x){
        case ')':
            check = stack.pop();
            if (check === '{' || check === '[')
                return false;
            break;
  
        case '}':
            check = stack.pop();
            if (check === '(' || check === '[')
                return false;
            break;
  
        case ']':
            check = stack.pop();
            if (check === '(' || check === '{')
                return false;
            break;
        default:

        }
    }
  
    // Check Empty Stack
    return (stack.length === 0);
}

// removes unwanted space from a string.
const spaceRemover = (string) => {

    let arr = string.split(" ")
  
    let filteredStringWithoutEmptySpaces = arr.filter(a => a !== "").join(" ")
  
    return filteredStringWithoutEmptySpaces?.trim()
  }

  const convertToDots = (mask) => {
    // Replace each character in the first part of the mask with a dot
    const dottedMask = mask.replace(/[x]/g, '•');
    return dottedMask;
  };

const formatStrings = (text, replaceChars, replaceByChar=' ') => { // replaceChars => regex of character need to be replaced
    if (!text) return '';
    let formattedText = text;
    if (replaceChars) {
        formattedText = formattedText.replace(new RegExp(`[${replaceChars}]`, "g"), replaceByChar)
    }
    formattedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1);    
    return spaceRemover(formattedText);
};

export { idGenerator, dateFormatter, showLoader, amountValDecimalChecker, persistSelectedTableRows, paginatedRows, paginatedRowsIds, resetPersistedData, select_unSelect_All, generateJWTAuthKey, shimmer, partialApprovalCommentSection, isPartiallyApproved, initialsHandler, USMLogout, areBracketsBalanced, spaceRemover, convertToDots, formatStrings };
