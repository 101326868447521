import createReducer from '../../../../components/common/Utility';

import {
	FETCH_ALL_ADVANCES,
	SET_ALL_ADVANCES,
	FETCH_DRAFT_ADVANCES,
	SET_DRAFT_ADVANCES,
	CREATE_DRAFT_ADVANCES,
	SET_CREATE_DRAFT_ADVANCES,
	EDIT_DRAFT_ADVANCES,
	SET_EDIT_DRAFT_ADVANCES,
	DELETE_DRAFT_ADVANCES,
	SET_DELETE_DRAFT_ADVANCES,
	SUBMIT_DRAFT_ADVANCES,
	SET_SUBMIT_DRAFT_ADVANCES,
} from '../../../actions/Personal/Advances/Advances.action.type';

import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE, API_ERROR } from '../../../actions/Common/Api.types';

export const initialState = {
	// all advances
	allAdvancesCount: 0,
	allAdvancesData: null,
	allAdvances: [],

	// draft advances
	draftAdvancesCount: 0,
	draftAdvancesData: null,
	draftAdvances: [],

	isLoadingData: false,
	draftAdvanceActionResponse: null,

	errorObj: {
		status: null,
		statusText: null,
		data: null,
	},

	total_records: {
		allAdvances: 0,
		draftAdvances: 0
	},
}

export const reducerObj = {
	[SET_ALL_ADVANCES]: (prevState, payload) => (
		{
			allAdvancesData: payload,
			allAdvances: payload.data,
			allAdvancesCount: payload.total_records || 0,
			total_records: {
				...initialState.total_records,
				allAdvances: payload.no_of_reports || 0
			},
			isLoadingData: false
		}
	),

	[SET_DRAFT_ADVANCES]: (prevState, payload) => (
		{
			draftAdvancesData: payload,
			draftAdvances: payload.data,
			draftAdvancesCount: payload.total_records || 0,
			total_records: {
				...initialState.total_records,
				draftAdvances: payload.no_of_reports || 0
			},
			isLoadingData: false
		}
	),

	[SET_CREATE_DRAFT_ADVANCES]: (prevState, payload) => (
		{
			draftAdvanceActionResponse: payload
		}
	),
	[SET_EDIT_DRAFT_ADVANCES]: (prevState, payload) => (
		{
			draftAdvanceActionResponse: payload
		}
	),
	[SET_DELETE_DRAFT_ADVANCES]: (prevState, payload) => (
		{
			draftAdvanceActionResponse: payload
		}
	),
	[SET_SUBMIT_DRAFT_ADVANCES]: (prevState, payload) => (
		{
			draftAdvanceActionResponse: payload
		}
	),

	[RESET_STATE]: () => (
		{ ...initialState }
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === FETCH_ALL_ADVANCES) {
			return {
				isLoadingData: true,
				allAdvancesCount: 0,
				allAdvancesData: null,
				allAdvances: [],
			}
		}
		if (payload === FETCH_DRAFT_ADVANCES) {
			return {
				isLoadingData: true,
				draftAdvancesCount: 0,
				draftAdvancesData: null,
				draftAdvances: [],
			}
		}
		if (payload === CREATE_DRAFT_ADVANCES || payload === EDIT_DRAFT_ADVANCES || payload === DELETE_DRAFT_ADVANCES || payload === SUBMIT_DRAFT_ADVANCES) {
			return {
				isLoadingData: true
			}
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === SET_ALL_ADVANCES || payload === SET_DRAFT_ADVANCES || payload === SET_CREATE_DRAFT_ADVANCES || payload === SET_EDIT_DRAFT_ADVANCES || payload === SET_DELETE_DRAFT_ADVANCES || payload === SET_SUBMIT_DRAFT_ADVANCES) {
			return {
				isLoadingData: false
			}
		}
	},
	// [API_ERROR]: (prevState, payload) => (
	// 	{
	// 		isLoading: false,
	// 		allAdvancesData: payload.data,
	// 		errorObj: {
	// 			...initialState.errorObj,
	// 			status: payload.status,
	// 			statusText: payload.statusText,
	// 			data: payload.data
	// 		}
	// 	}
	// ),
}

let PersonalAdvancesReducer = createReducer(initialState, reducerObj);

export default PersonalAdvancesReducer;