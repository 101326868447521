import { NAVBAR } from "../../../../Urls"
import actionCreator from "../Utils"
import { GET_CLIENT_DETAILS, GET_NAVBAR_DETAILS, SET_CLIENT_DETAILS, SET_NAVBAR_DETAILS, SET_USER_DETAILS_AFTER_UUM_TOKEN_VERIFY } from "./Navbar.action.type"

export const getNavbarDetails = (token, method, data, company_id, headers) => {
   return actionCreator({
        method,
        data,
        url: NAVBAR.auth + `/${company_id}`,
        onSuccess:setNavbarDetails,
        accessToken: token,
        onFailure: setNavbarDetails,
        label:GET_NAVBAR_DETAILS,
        baseURL: process.env.REACT_APP_USM_URL,
        customHeaders: headers
    })

    function setNavbarDetails(resData){

        return {
            type: SET_NAVBAR_DETAILS,
            payload: resData
        }
    }
}
export const getClientDetails = (token, method, data, headers ) => {
   return actionCreator({
        method,
        data,
        url: NAVBAR.fetch_travel_company_id,
        onSuccess:setClientDetails,
        accessToken: token,
        onFailure: setClientDetails,
        label:GET_CLIENT_DETAILS,
        baseURL: process.env.REACT_APP_USM_URL,
        customHeaders: headers
    })
    function setClientDetails(resData){
        return {
            type: SET_CLIENT_DETAILS,
            payload: resData
        }
    }
}


export const setUserDetailsAfterUUMTokenVerify = (resData) => {

    return {
        type: SET_USER_DETAILS_AFTER_UUM_TOKEN_VERIFY,
        payload: resData
    }
}
