import createReducer from '../../../../../../components/common/Utility';

import {
	// categories
	FETCH_ALL_DATA,
	SET_ALL_DATA,
	// attributes
	FETCH_ALL_ATTRIBUTES,
	SET_ALL_ATTRIBUTES,
	// create
	CREATE_CATEGORY,
	SET_CREATED_CATEGORY,
	// edit
	EDIT_CATEGORY,
	SET_EDITED_CATEGORY,
	// attributes types
	FETCH_ALL_ATTRIBUTES_TYPES,
	SET_ALL_ATTRIBUTES_TYPES,
	// misc/calc
	FETCH_CALC,
	SET_CALC,
	RESET_CATEGORY_LIST
} from '../../../../../actions/Admin/Settings/Categories/CreateNewCategory/CreateNewCategory.action.type';

import { API_START, API_END, RESET_STATE, API_ERROR } from '../../../../../actions/Common/Api.types';

export const initialState = {
	categoryList: {},
	isLoadingData: false,
	attributesListResponse: {},
	createCategoryResponse: null,
	editCategoryResponse: null,
	attributesTypesResponse: {},
	calc: {},
	fetchingCalc: false,
}

export const reducerObj = {
	[SET_ALL_DATA]: (prevState, payload) => (
		{
			categoryList: payload
		}
	),

	[SET_ALL_ATTRIBUTES]: (prevState, payload) => (
		{
			attributesListResponse: payload
		}
	),

	[SET_ALL_ATTRIBUTES_TYPES]: (prevState, payload) => (
		{
			attributesTypesResponse: payload
		}
	),

	[SET_CREATED_CATEGORY]: (prevState, payload) => (
		{
			createCategoryResponse: payload
		}
	),

	[SET_EDITED_CATEGORY]: (prevState, payload) => (
		{
			editCategoryResponse: payload
		}
	),

	[SET_CALC]: (prevState, payload) => (
		{
			calc: payload
		}
	),
   
	[RESET_STATE]: () => (
		{
			createCategoryResponse: null,
			editCategoryResponse: null,
			calc: {},
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === FETCH_CALC) {
			return (
				{
					fetchingCalc: true,
					isLoadingData: false
				}
			)
		}
		if (payload === FETCH_ALL_DATA || payload === FETCH_ALL_ATTRIBUTES || payload === CREATE_CATEGORY || payload === EDIT_CATEGORY || payload === FETCH_ALL_ATTRIBUTES_TYPES) {
			return (
				{ isLoadingData: true }
			)
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === SET_ALL_DATA || payload === SET_ALL_ATTRIBUTES || payload === SET_CREATED_CATEGORY || payload === SET_EDITED_CATEGORY || payload === SET_ALL_ATTRIBUTES_TYPES || payload === SET_CALC) {
			return (
				{
					isLoadingData: false,
					fetchingCalc: false,
				}
			)
		}
	},
	[API_ERROR]: (prevState, payload) => {
		return (
			{
				createCategoryResponse: payload.data,
				isLoadingData: false,
				fetchingCalc: false,
				editCategoryResponse: payload.data
			}
		)
	}
}

let CreateNewCategoryReducer = createReducer(initialState, reducerObj);

export default CreateNewCategoryReducer;