export const FETCH_ALL_DATA = "fetchAllUsers";
export const SET_ALL_DATA = "setAllUsers";

export const FETCH_ATTRIBUTES = "fetchAttributes";
export const SET_ATTRIBUTES = "setAttributes";

export const FETCH_USER = "fetchUser";
export const SET_USER = "setUser";

export const FETCH_USER_BASICS = "fetchUserBasics";
export const SET_USER_BASICS = "setUserBasics";

export const FETCH_USER_STATUS = "fetchUserStatus";
export const SET_USER_STATUS = "setUserStatus";

export const FETCH_USER_DETAIL = "fetchUserDetail";
export const SET_USER_DETAIL = "setUserDetail";