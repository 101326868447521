import createReducer from '../../../../components/common/Utility';


import { SET_EXPORT_HISTORY_DATA,FETCH_EXPORT_HISTORY_DATA} from '../../../actions/Admin/ExportHistory/Export.action.type';

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
    export_History_Data:null,
	isLoadingData:false
}

export const reducerObj = {
	[SET_EXPORT_HISTORY_DATA]: (prevState, payload) => (
		{
			export_History_Data : payload
		
		}
	),

	

	
	[RESET_STATE]: () => (
		{
			export_History_Data: null,
			isLoadingData:false
		}
	),

	[API_START]: (prevState, payload) => {
		if (payload === FETCH_EXPORT_HISTORY_DATA ) {
			return {  isLoadingData: true};
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === SET_EXPORT_HISTORY_DATA ) {
			return {  isLoadingData: false }
		}
	}

}

let ExportHistoryReducer = createReducer(initialState, reducerObj);

export default ExportHistoryReducer;