import createReducer from '../../../../components/common/Utility';

import {
	FETCH_ALL_REPORTS,
	SET_ALL_REPORTS,
	SET_REJECT_REPORT,
	SET_APPROVE_REPORT,
	REJECT_REPORT,
	APPROVE_REPORT,
	FETCH_REPORTS,
	SET_REPORTS,
} from '../../../actions/Admin/Approvals/Approvals.action.type';

import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
	allCount: 0,
	pendingCount: 0,
	allData: {},
	pendingData: {},
	allReports: [],
	pendingReports: [],
	isLoadingData: false,
	rejectOrApproveResponse: null,
	total_records: {
		pending: 0,
		all: 0
	},
}

export const reducerObj = {
	[SET_ALL_REPORTS]: (prevState, payload) => (
		{
			allData: payload,
			allReports: payload.data,
			allCount: payload.total_records || 0,
			total_records: {
				...initialState.no_of_reports,
				all: payload.no_of_reports || 0
			},
		}
	),

	[SET_REPORTS]: (prevState, payload) => (
		{
			pendingData: payload,
			pendingReports: payload.data,
			pendingCount: payload.total_records || 0,
			total_records: {
				...initialState.no_of_reports,
				pending: payload.no_of_reports || 0
			},
		}
	),

	// ------------ approve and reject Reports -------------
	[SET_REJECT_REPORT]: (prevState, payload) => (
		{
			rejectOrApproveResponse: payload
		}
	),
	[SET_APPROVE_REPORT]: (prevState, payload) => (
		{
			rejectOrApproveResponse: payload
		}
	),

	[RESET_STATE]: () => (
		{
			rejectOrApproveResponse: null
		}
	),
	[SEARCH_ACTION_LABEL]: () => (
		{
			total_records: {
				...initialState.total_records,
				all: 0,
				pending: 0
			},
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => (
		payload === FETCH_ALL_REPORTS || REJECT_REPORT || APPROVE_REPORT || FETCH_REPORTS || SEARCH_ACTION_LABEL
			? { isLoadingData: true }
			: { isLoadingData: false }
	),
	[API_END]: (prevState, payload) => (
		payload === FETCH_ALL_REPORTS || REJECT_REPORT || APPROVE_REPORT || FETCH_REPORTS
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
}

let ApprovalsReducer = createReducer(initialState, reducerObj);

export default ApprovalsReducer;