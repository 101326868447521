import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
const ToastService = (function(){
    function _notify(msg) {
      return toast.info(msg, {autoClose: true , progressStyle:{backgroundColor:"#F97316"}});
    }

    function _successnotify(msg) {
      return toast.success(msg, {autoClose: true});
    }
    
   return {
      notify : _notify,
      sNotify : _successnotify
    }
})();

export default ToastService;