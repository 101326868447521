import { faGlassWhiskey, faSmileWink } from '@fortawesome/free-solid-svg-icons';
import createReducer from '../../../../components/common/Utility';

import {
    SET_CORPORATE_ACCOUNTS_LIST,
    FETCH_CORPORATE_ACCOUNTS_LIST,
    SET_CORPORATE_SUMMARY_DATA,
    FETCH_CORPORATE_SUMMARY_DATA,
    SET_CARD_OWNER_STATUS,
    SET_LOADING_STATES,
    ASSIGN_CARD_OWNER_STATUS_API,
    RESET_ERROR_MESSAGES
} from '../../../actions/Admin/CorporateCards/CorporateCards.action.type';

import {
    API_START,
    API_END,
    RESET_STATE,
    API_ERROR
} from '../../../actions/Common/Api.types';

export const initialState = {
    isAccountsListDataLoading: false,
    corporateAccountList: {},
    isSummaryDataLoading: false,
    corporateSummaryData: {},
    assignOwner: null,
    isLoadingModule: {
        assignOwner: false
    }
};

export const reducerObj = {
    [SET_CORPORATE_ACCOUNTS_LIST]: (prevState, payload) => ({
        ...prevState,
        corporateAccountList: payload
    }),
    [SET_CORPORATE_SUMMARY_DATA]: (prevState, payload) => ({
        ...prevState,
        corporateSummaryData: payload
    }),
    [SET_CARD_OWNER_STATUS]: (prevState, payload) => ({
        ...prevState,
        assignOwner: payload
    }),
    [SET_LOADING_STATES]: (prevState, payload) => ({
        ...prevState,
        isLoadingModule: {
            ...prevState.isLoadingModule,
            [payload.key]: payload.value
        }
    }),
    [RESET_ERROR_MESSAGES]: (prevState, payload) => ({
        ...prevState,
        errorModule: null
    }),

    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === FETCH_CORPORATE_SUMMARY_DATA ) {
            return {
                isSummaryDataLoading: true
            };
        }
        if (payload === FETCH_CORPORATE_ACCOUNTS_LIST ) {
            return {
                isAccountsListDataLoading: true
            };
        }
        if ( payload === SET_CARD_OWNER_STATUS) {
            return {
                isLoadingModule: {
                    assignOwner: false
                }
            };
        }
        if(payload === ASSIGN_CARD_OWNER_STATUS_API){
            return {
                isLoadingModule: {
                    assignOwner: true
                }
            }
        }
        return prevState;
    },
    [API_END]: (prevState, payload) => {
        if (payload === SET_CARD_OWNER_STATUS) {
            return {
                isLoadingModule: {
                    assignOwner: false
                }
            };
        }
        if (payload === SET_CORPORATE_SUMMARY_DATA ) {
            return {
                isSummaryDataLoading: false
            };
        }
        if (payload === SET_CORPORATE_ACCOUNTS_LIST ) {
            return {
                isAccountsListDataLoading: false
            };
        }
        return prevState;
    },
    [API_ERROR]: (prev, payload) => {
        return {

            errorModule: payload,
            isAccountsListDataLoading: false,
            isSummaryDataLoading: false,
            isLoadingModule: {
                assignOwner: false
            }
        }
    }
};

const CorporateCardsAccountsListReducer = createReducer(initialState, reducerObj);

export default CorporateCardsAccountsListReducer;

