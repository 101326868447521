// attributes types
export const FETCH_ALL_ATTRIBUTES_TYPES = "fetchAllAttributesTypes";
export const SET_ALL_ATTRIBUTES_TYPES = "setAllAttributesTypes";

// create
export const CREATE_CATEGORY = "createCategory";
export const SET_CREATED_CATEGORY = "setCreatedCategory";
 
// edit
export const EDIT_CATEGORY_PERDIEM = "editCategoryPerdiem";
export const SET_EDITED_CATEGORY_PERDIEM = "setEditedCategoryPerDiem";

// misc/calc
export const FETCH_CALC = "fetchCalc";
export const SET_CALC = "setCalc";

//Admin Side Actions Types
export const GET_PERDIEM_CONFIG = "getPerDiemConfig";
export const SET_PERDIEM_CONFIG = "setPerDiemConfig";
export const GET_PERDIEM_INITIAL_DETAILS="getperDiemInitialDetails";
export const SET_PERDIEM_INITIAL_DETAILS="serperDiemInitialDetails";
export const GET_PERDIEM_ENABLE_DISABLE_DETAILS="getperDiemEnableDisableDetails"
export const SET_PERDIEM_ENABLE_DISABLE_DETAILS="setperDiemEnableDisableDetails"
export const SET_PERDIEM_RATE_DATA="setPerDiemRateData"
export const GET_PERDIEM_RATE_LIST="getPerDiemrateList"

export const DELETE_PER_DIEM = "deletePerDiemSingle"
export const SET_DELETE_PER_DIEM_RESPONSE = "deletePerDiemSingleResponse"

// Perdiem rates

export const FETCH_EMPLOYEE_GROUP_DETAILS = "fetchEmployeeGroupDetails"
export const SET_EMPLOYEE_GROUP_DETAILS = "setEmployeeGroupDetails"

export const CREATE_PERDIEM_DEFAULT_RATE = "createDefaultRate"
export const SET_PERDIEM_DEFAULT_RATE_RESPONSE = "setCreateDefaultRateResponse"
export const CREATE_PERDIEM_NORMAL_RATE = "createNormalRate"
export const SET_PERDIEM_NORMAL_RATE_RESPONSE = "createNormalRateResponse"


// RATES
export const EDIT_PERDIEM_DEFAULT_RATE = "editDefaultRate"
export const SET_EDIT_PERDIEM_DEFAULT_RATE_RESPONSE = "setEditDefaultRateResponse"
export const EDIT_PERDIEM_NORMAL_RATE = "editNormalRate"
export const SET_EDIT_PERDIEM_NORMAL_RATE_RESPONSE = "editNormalRateResponse"
export const GET_RATE_RULE_PRIORITY_ORDER="getRateRulePriorityOrder"
export const SET_RATE_RULE_PRIORITY_ORDER="setRateRulePriorityOrder"
export const CHANGE_RATE_CONFIG_STATUS= "chanheRateConfigStatus"
export const SET_CHANGE_RATE_CONFIG_STATUS="setChangeRateConfigStatus"
export const DELETE_PER_DIEM_RATE = "deletePerDiemRate"
export const SET_DELETE_PER_DIEM_RATE="setdeletePerDiemRate"
export const FETCH_PERDIEM_RATE_DETAILS = "fetchIndividualPerDiemRateDetails"
export const SET_PERDIEM_RATE_DETAILS = "setIndividualPerDiemRateDetails"

export const CLEAR_FETCHED_INDIVIDUAL_RATE_DETAIL = "clearFetchedIndividualRateDetail"

export const SEARCH_RATES = "searchPerDiemRates"
export const SET_SEARCHED_RATES_DATA = "setSearchedPerDiemRatesData"




// TRAVLE DURATION
export const GET_PERDIEM_TRAVEL_DURATION="getPerDiemTravelDuration"
export const SET_PERDIEM_TRAVEL_DURATION="setPerDiemTravelDuration"
export const DELETE_PER_DIEM_TRAVEL_DURATION="deletePerDiemTravelDuration"
export const SET_DELETE_PER_DIEM_TRAVEL_DURATION="setdeletePerDiemTravelDuration"
export const SET_SAVE_PER_DIEM_TRAVEL_DURATION="setSavePerDiemTravelDuration"
export const SAVE_PER_DIEM_TRAVEL_DURATION ="SavePerDiemTravelDuration"
