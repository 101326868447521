/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import localStorageService from '../../services/Storage_services/LocalStorageService';
import { checkRoleAndPermissionForPage, checkRoleAndPermissionForTab } from '../../../../utils/checkRoleAndPermission'
import { NavLink } from 'react-router-dom';

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { PerDiemIcon } from '../UI/components/SvgIcons';
import { CorporateCard } from '../../Icons';


const Header = (props) => {

  const [isCustomerVerified, setIsCustomerVerified] = useState(false)
  const token = localStorageService.getAccessToken();
  const { toggled, handleToggleSidebar, handleCloseToggleSidebar, ilAdminMenuVisible } = props;

  // accessing state from the reducer.
  const rolesAndPermissionsState = useSelector(state => state.RolesAndPermissionsReducer);
  const achState = useSelector(state => state.AchReducer);
  const navbarState = useSelector(state => state.NavbarReducer);
  // destructuring state variables.
  const { userRolesAndPermissions, card_recon_enabled, expense_only, ach_flags } = rolesAndPermissionsState;
  const { achData } = achState
  const { navbar } = navbarState


  const [rolesAndPermData, setRolesAndPermData] = useState({
    showReportsTab: false,
    showPeronalExpenseReportTab: false,
    showPayoutTab: false,
    showAdvancesTab: false,
    showSettingsTab: false,
    showDashboardTab: false,
    showReportDownloadTab: false,
    showPendingApprovalTab: false,
    showOrgTab: false,
    showILTab: false,
    showGeneralTab: false
  })

  const [openOrgTab, setOpenOrgTab] = useState(false);

  useEffect(() => {
    if (Object.keys(userRolesAndPermissions).length) {
      let showReportsTab = checkRoleAndPermissionForPage(userRolesAndPermissions, '/approvals')
      let showPeronalExpenseReportTab = checkRoleAndPermissionForPage(userRolesAndPermissions, '/individual/reports');
      let showPeronalAdvanceTab = checkRoleAndPermissionForPage(userRolesAndPermissions, '/individual/advances');
      let showPayoutTab = checkRoleAndPermissionForPage(userRolesAndPermissions, '/payout')
      let showAdvancesTab = checkRoleAndPermissionForPage(userRolesAndPermissions, '/advances')
      let showSettingsTab = checkRoleAndPermissionForPage(userRolesAndPermissions, '/settings')
      let showDashboardTab = checkRoleAndPermissionForPage(userRolesAndPermissions, '/insights')
      let showReportDownloadTab = checkRoleAndPermissionForPage(userRolesAndPermissions, '/analytics')
      let showPendingApprovalTab = checkRoleAndPermissionForTab(userRolesAndPermissions, '/individual/approvals', "#pending-approvals")
      let showGeneralTab = checkRoleAndPermissionForPage(userRolesAndPermissions, '/settings/general')


      setRolesAndPermData({
        ...rolesAndPermData,
        showReportsTab: showReportsTab,
        showPeronalExpenseReportTab: showPeronalExpenseReportTab,
        showPeronalAdvanceTab: showPeronalAdvanceTab,
        showPayoutTab: showPayoutTab,
        showAdvancesTab: showAdvancesTab,
        showSettingsTab: showSettingsTab,
        showDashboardTab: showDashboardTab,
        showReportDownloadTab: showReportDownloadTab,
        showPendingApprovalTab: showPendingApprovalTab,
        showOrgTab: (showReportsTab || showPayoutTab || showAdvancesTab || showSettingsTab || showDashboardTab || showReportDownloadTab),
        showILTab: ilAdminMenuVisible,
        showGeneralTab: showGeneralTab
      })
    }
  }, [userRolesAndPermissions, ilAdminMenuVisible])
  useEffect(() => {
    let prevTab = window.localStorage.getItem('previousTab');
    let tabs = ['expenses', 'insights', 'advances', 'payout', 'analytics', 'billingsubscriptions', 'settings'];
    if (prevTab && tabs.includes(prevTab)) {
      setOpenOrgTab(true);
    }
  }, []);

  useEffect(() => {
    if (ach_flags?.details?.customer?.status === "verified" || achData?.details?.customer?.status === "verified")
      setIsCustomerVerified(true)
    else
      setIsCustomerVerified(false)
  }, [achData, ach_flags])

  if (token) {
    return (
      <ProSidebar
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
        onClick={handleCloseToggleSidebar}
      >
        <div className="sidebar-brand">
          <Navbar.Brand>
            <img
              alt=""
              src={window.location.origin + "/assets/images/itilite-logo.png"}
              width="30"
              height="30"
              className="d-inline-block align-top logo"
            />
            <span className='accent-orange'>iti</span>
            <span className='accent-grey'>lite</span>
          </Navbar.Brand>
        </div>
        <Menu iconShape="square" className="main-menu-items">
          <MenuItem>
            <h2 className='expense-faded-icon'>
              Expense
            </h2>
          </MenuItem>
          {!expense_only ?
            <div>
              <MenuItem style={{ display: expense_only === 0 ? 'block' : 'none' }}>
                <Nav.Link target="_blank" href={process.env.REACT_APP_ITILITEURL} className="inactive no-padding">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                  </svg> Travel
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="travel-external-link">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </Nav.Link>
              </MenuItem>
            </div>
            : <div />}
          <MenuItem style={{ display: navbar?.card ? 'block' : 'none' }}>
            <Nav.Link target="_blank" href={navbar?.card} className="inactive no-padding">
              <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.5 10C10.2239 10 10 10.2239 10 10.5C10 10.7761 10.2239 11 10.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H10.5ZM1 5.5C1 4.11929 2.11929 3 3.5 3H12.5C13.8807 3 15 4.11929 15 5.5V10.5C15 11.8807 13.8807 13 12.5 13H3.5C2.11929 13 1 11.8807 1 10.5V5.5ZM14 6V5.5C14 4.67157 13.3284 4 12.5 4H3.5C2.67157 4 2 4.67157 2 5.5V6H14ZM2 7V10.5C2 11.3284 2.67157 12 3.5 12H12.5C13.3284 12 14 11.3284 14 10.5V7H2Z" fill="#212529"></path>
              </svg>{" "}Card
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="travel-external-link">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </Nav.Link>
          </MenuItem>
          <hr style={{ display: expense_only === 0 ? 'block' : 'none' }} />
          <SubMenu title="Individual" defaultOpen={true}>

            <MenuItem icon={<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 8.5L3.16667 6.83333M3.16667 6.83333L9 1L14.8333 6.83333M3.16667 6.83333V15.1667C3.16667 15.3877 3.25446 15.5996 3.41074 15.7559C3.56702 15.9122 3.77899 16 4 16H6.5M14.8333 6.83333L16.5 8.5M14.8333 6.83333V15.1667C14.8333 15.3877 14.7455 15.5996 14.5893 15.7559C14.433 15.9122 14.221 16 14 16H11.5M6.5 16C6.72101 16 6.93298 15.9122 7.08926 15.7559C7.24554 15.5996 7.33333 15.3877 7.33333 15.1667V11.8333C7.33333 11.6123 7.42113 11.4004 7.57741 11.2441C7.73369 11.0878 7.94565 11 8.16667 11H9.83333C10.0543 11 10.2663 11.0878 10.4226 11.2441C10.5789 11.4004 10.6667 11.6123 10.6667 11.8333V15.1667C10.6667 15.3877 10.7545 15.5996 10.9107 15.7559C11.067 15.9122 11.279 16 11.5 16M6.5 16H11.5" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            }
            // style={{ display: rolesAndPermData.showDashboardTab ? 'block' : 'none' }}
            >
              <NavLink to="/home" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "home")}>
                Home
              </NavLink>
            </MenuItem>
            <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />

            </svg>} style={{ display: rolesAndPermData.showPeronalExpenseReportTab ? 'block' : 'none' }}>
              <NavLink to="/individual/reports#draft_expense" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "individualExpense")}
                isActive={(match, location) => {
                  // Searchbar condition to hightlight the sidebar
                  if (location.pathname && location.pathname.toLowerCase() === '/individual/reports'.toLowerCase() && location.hash &&
                    (location.hash.toLowerCase() === "#draft_expense".toLowerCase() || location.hash.toLowerCase() === "#draft_report".toLowerCase() || location.hash.toLowerCase() === "#all".toLowerCase())
                  ) return true;

                  if (location.pathname && location.pathname.toLowerCase() === "/individual/reports/expense/create".toLowerCase() && (location.hash && (location.hash === "#mileage" || location.hash === "#expense"))) {
                    return true;
                  }

                  // if(window.location.pathname.includes("/individual/reports/expense/edit/") || window.location.pathname.includes("/individual/reports/expense/mileage/edit/")) return true;

                  let prevTab = window.localStorage.getItem("previousTab");

                  if (location.search !== "") {
                    if (location.search.split("?from=")[location.search.split("?from=").length - 1].includes("approval_pending")) {
                      return false;
                    }
                  } else if (prevTab !== "individualExpense") {
                    return false;
                  }

                  if (match && prevTab === "individualExpense") return true;
                }}
              >
                Expense Reports
              </NavLink>
            </MenuItem>
            {
              (!ach_flags?.is_ach_applicable && !isCustomerVerified) ? <MenuItem
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                    <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" />
                  </svg>
                }
                style={{ display: rolesAndPermData.showPeronalExpenseReportTab && card_recon_enabled ? 'block' : 'none' }}
                className="new-tag-link"
              >
                <NavLink to="/individual/cards" activeClassName="active">
                  Manage Cards
                </NavLink>
              </MenuItem> : null
            }
            {
              (ach_flags?.is_ach_applicable && !isCustomerVerified) ? <MenuItem
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                    <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" />
                  </svg>
                }
                style={{ display: rolesAndPermData.showPeronalExpenseReportTab && card_recon_enabled ? 'block' : 'none' }}
                className="new-tag-link"
              >
                <NavLink to="/individual/cards" activeClassName="active">
                  Manage Cards
                </NavLink>
              </MenuItem> : null
            }

            {
              ach_flags?.is_ach_applicable && (ach_flags?.details?.customer?.status === "verified" || achData?.details?.customer?.status === "verified") ? <SubMenu title="Cards and Bank"
                icon={<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.0166 9.5V7.5C17.0166 6.96957 16.8059 6.46086 16.4308 6.08579C16.0557 5.71071 15.547 5.5 15.0166 5.5H5.0166C4.48617 5.5 3.97746 5.71071 3.60239 6.08579C3.22732 6.46086 3.0166 6.96957 3.0166 7.5V13.5C3.0166 14.0304 3.22732 14.5391 3.60239 14.9142C3.97746 15.2893 4.48617 15.5 5.0166 15.5H7.0166M9.0166 19.5H19.0166C19.547 19.5 20.0557 19.2893 20.4308 18.9142C20.8059 18.5391 21.0166 18.0304 21.0166 17.5V11.5C21.0166 10.9696 20.8059 10.4609 20.4308 10.0858C20.0557 9.71071 19.547 9.5 19.0166 9.5H9.0166C8.48617 9.5 7.97746 9.71071 7.60239 10.0858C7.22732 10.4609 7.0166 10.9696 7.0166 11.5V17.5C7.0166 18.0304 7.22732 18.5391 7.60239 18.9142C7.97746 19.2893 8.48617 19.5 9.0166 19.5ZM16.0166 14.5C16.0166 15.0304 15.8059 15.5391 15.4308 15.9142C15.0557 16.2893 14.547 16.5 14.0166 16.5C13.4862 16.5 12.9775 16.2893 12.6024 15.9142C12.2273 15.5391 12.0166 15.0304 12.0166 14.5C12.0166 13.9696 12.2273 13.4609 12.6024 13.0858C12.9775 12.7107 13.4862 12.5 14.0166 12.5C14.547 12.5 15.0557 12.7107 15.4308 13.0858C15.8059 13.4609 16.0166 13.9696 16.0166 14.5Z" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                }
                style={{ display: rolesAndPermData.showPeronalExpenseReportTab ? 'block' : 'none' }}
              >
                <MenuItem
                  icon={
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                      <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" />
                    </svg>
                  }
                  style={{ display: rolesAndPermData.showPeronalExpenseReportTab && card_recon_enabled ? 'block' : 'none' }}
                  className="new-tag-link"
                >
                  <NavLink to="/individual/cards" activeClassName="active">
                    Cards
                  </NavLink>
                </MenuItem>
                <MenuItem
                  icon={
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.0166 6.66667C8.63577 6.66667 7.5166 7.4125 7.5166 8.33333C7.5166 9.25417 8.63577 10 10.0166 10C11.3974 10 12.5166 10.7458 12.5166 11.6667C12.5166 12.5875 11.3974 13.3333 10.0166 13.3333M10.0166 6.66667V13.3333M10.0166 6.66667C10.9416 6.66667 11.7499 7.00167 12.1824 7.5M10.0166 6.66667V5.83333M10.0166 13.3333V14.1667M10.0166 13.3333C9.0916 13.3333 8.28327 12.9983 7.85077 12.5M17.5166 10C17.5166 10.9849 17.3226 11.9602 16.9457 12.8701C16.5688 13.7801 16.0163 14.6069 15.3199 15.3033C14.6235 15.9997 13.7967 16.5522 12.8867 16.9291C11.9768 17.306 11.0015 17.5 10.0166 17.5C9.03169 17.5 8.05642 17.306 7.14648 16.9291C6.23653 16.5522 5.40974 15.9997 4.7133 15.3033C4.01686 14.6069 3.46442 13.7801 3.0875 12.8701C2.71059 11.9602 2.5166 10.9849 2.5166 10C2.5166 8.01088 3.30678 6.10322 4.7133 4.6967C6.11982 3.29018 8.02748 2.5 10.0166 2.5C12.0057 2.5 13.9134 3.29018 15.3199 4.6967C16.7264 6.10322 17.5166 8.01088 17.5166 10Z" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  }
                  style={{ display: rolesAndPermData.showPeronalExpenseReportTab ? 'block' : 'none' }}
                  className="new-tag-link"
                >
                  <NavLink to="/individual/banks" activeClassName="active">
                    Bank
                  </NavLink>
                </MenuItem>

              </SubMenu> : null}
            <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z" />
            </svg>} style={{ display: rolesAndPermData.showPeronalAdvanceTab ? 'block' : 'none' }}>
              <NavLink to="/individual/advances#draft_advance" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "individualAdvances")} isActive={(match, location) => {
                // Searchbar condition to hightlight the sidebar 
                if (location.pathname && location.pathname.toLowerCase() === "/individual/advances".toLowerCase()) return true;
                if (location.pathname.split("?from=").length > 1) {
                  if (location.pathname.split("?from=")[location.pathname.split("?from=").length - 1].includes("approval_pending")) {
                    return false;
                  }
                } else if (location.search !== "") {
                  if (location.search.split("?from=")[location.search.split("?from=").length - 1].includes("approval_pending")) {
                    return false;
                  }
                } else if (window.localStorage.getItem("previousTab") !== "individualAdvances") {
                  return false;
                }
                if (match) return true;

              }}>
                Advances
              </NavLink>
            </MenuItem>
            <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>} style={{ display: rolesAndPermData.showPendingApprovalTab ? 'block' : 'none' }}>
              <NavLink to="/individual/approvals#all" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "pendingApproval")} isActive={(match, location) => {
                if (match) return true;

                if (location.pathname.split("?from=").length > 1) {
                  if (location.pathname.split("?from=")[location.pathname.split("?from=").length - 1].includes("approval_pending")) {
                    return true;
                  } else return false;
                } else if (location.search !== "") {
                  if (location.search.split("?from=")[location.search.split("?from=").length - 1].includes("approval_pending")) {
                    return true;
                  }
                  //  else if (location.search.split("from=")[location.search.split("from=").length - 1].split("&r_id=")[location.search.split("from=")[location.search.split("from=").length - 1].split("&r_id=").length - 2] === "approval_pending") {
                  //   return true;
                  // } else return false;
                } else return false;
              }}
              >
                Approvals
              </NavLink>
            </MenuItem>
          </SubMenu>
          <hr style={{ display: rolesAndPermData.showOrgTab ? 'block' : 'none' }} />
          <SubMenu title="Organization" style={{ display: rolesAndPermData.showOrgTab ? 'block' : 'none' }} open={openOrgTab} onOpenChange={() => setOpenOrgTab(!openOrgTab)}>
            <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
            </svg>} style={{ display: rolesAndPermData.showDashboardTab ? 'block' : 'none' }}>
              <NavLink to="/insights" activeClassName="active">
                {/* Dashboard */}
                Insights
              </NavLink>
            </MenuItem>
            <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
            </svg>} style={{ display: rolesAndPermData.showReportsTab ? 'block' : 'none' }}>
              <NavLink to="/approvals#pending" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "expenses")}
                isActive={(match, location) => {
                  // Searchbar condition to hightlight the sidebar
                  if (location.pathname && location.pathname.toLowerCase() === '/approvals'.toLowerCase() && location.hash &&
                    (location.hash.toLowerCase() === "#pending".toLowerCase() || location.hash.toLowerCase() === "#all".toLowerCase())
                  ) return true;

                  if (match) return true;

                  if (location?.pathname) {
                    let path = location.pathname.split("/")[location.pathname.split("/").length - 3];
                    let prevTab = window.localStorage.getItem("previousTab");
                    if ((prevTab !== "payout" && prevTab !== "settings" && prevTab === "expenses") && (path === "report" || path === "expense") && (location.pathname !== "/settings/expense/mileage/fields" && location.pathname !== "/settings/expense/mileage/rates")) {
                      return true;
                    } else return false;
                  }
                }}
              >
                Expense Reports
              </NavLink>
            </MenuItem>
            <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z" />
            </svg>} style={{ display: rolesAndPermData.showAdvancesTab ? 'block' : 'none' }}>
              <NavLink to="/advances#pending" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "advances")}
                isActive={(match, location) => {
                  if (match) return true;

                  if (location?.pathname) {
                    let path = location.pathname.split("/")[location.pathname.split("/").length - 3];
                    let prevTab = window.localStorage.getItem("previousTab");
                    if ((prevTab !== "payout" && prevTab !== "settings" && prevTab === "advances") && (path === "advance")) {
                      return true;
                    } else return false;
                  }
                }}
              >
                Advances
              </NavLink>
            </MenuItem>
            <MenuItem
              icon={
                <CorporateCard />
              }
              style={{ display: rolesAndPermData.showReportDownloadTab && card_recon_enabled ? 'block' : 'none' }}
            >
              <NavLink to="/corporate-cards" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "corporate-cards")}>
                Corporate cards
              </NavLink>
            </MenuItem>
            <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
            </svg>} style={{ display: rolesAndPermData.showPayoutTab ? 'block' : 'none' }}>
              <NavLink to="/payout#reports" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "payout")}
                isActive={(match, location) => {
                  if (match) return true;

                  if (location?.pathname) {
                    let path = location.pathname.split("/")[location.pathname.split("/").length - 3];
                    let prevTab = window.localStorage.getItem("previousTab");
                    if ((prevTab === "payout") && (path === "advance" || path === "report" || path === "expense")) {
                      return true;
                    } else return false;
                  }
                }}
              >
                Payout
              </NavLink>
            </MenuItem>

            <MenuItem icon={<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 6.5V10.5L12 12.5" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.25469 14.5C5.51964 16.3135 7.62126 17.5 10 17.5C13.866 17.5 17 14.366 17 10.5C17 6.63401 13.866 3.5 10 3.5C7.2076 3.5 4.7971 5.13505 3.67363 7.5" stroke="#4A5568" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 4.5V7.5H6" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            } style={{ display: rolesAndPermData.showPayoutTab ? 'block' : 'none' }}>
              <NavLink to="/export-history" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "exporthistory")}
                isActive={(match, location) => {
                  if (match) return true;

                  if (location?.pathname) {
                    let path = location.pathname.split("/")[location.pathname.split("/").length - 3];
                    let prevTab = window.localStorage.getItem("previousTab");
                    // if ((prevTab === "payout") && (path === "advance" || path === "report" || path === "expense")) {
                    //   return true;
                    // } else return false;
                  }
                }}
              >
                Export History
              </NavLink>
            </MenuItem>
            <MenuItem
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                </svg>
              }
              style={{ display: rolesAndPermData.showReportDownloadTab ? 'block' : 'none' }}
            >
              <NavLink to="/analytics" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "analytics")}>
                Analytics
              </NavLink>
            </MenuItem>
            <MenuItem icon={<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.8337 4.66667V18L12.917 16.3333L10.0003 18L7.08366 16.3333L4.16699 18L4.16699 4.66667C4.16699 4.22464 4.34259 3.80072 4.65515 3.48816C4.96771 3.17559 5.39163 3 5.83366 3L14.167 3C14.609 3 15.0329 3.17559 15.3455 3.48816C15.6581 3.80072 15.8337 4.22464 15.8337 4.66667Z" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10.4 6.8C9.0744 6.8 8 7.516 8 8.4C8 9.284 9.0744 10 10.4 10M10.4 6.8C11.288 6.8 12.064 7.1216 12.4792 7.6M10.4 6.8V6M10.4 6.8V10M10.4 10C11.7256 10 12.8 10.716 12.8 11.6C12.8 12.484 11.7256 13.2 10.4 13.2M10.4 10V13.2M10.4 13.2V14M10.4 13.2C9.512 13.2 8.736 12.8784 8.3208 12.4" stroke="#4A5568" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            } style={{ display: rolesAndPermData.showSettingsTab ? 'block' : 'none' }}>
              <NavLink to="/billingsubscriptions" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "billingsubscriptions")}>
                Billing & Subscription
              </NavLink>
            </MenuItem>
            <SubMenu title="Settings" icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>} style={{ display: rolesAndPermData.showSettingsTab ? 'block' : 'none' }}>
              <SubMenu style={{ display: rolesAndPermData.showGeneralTab ? 'block' : 'none' }} title="General" icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
              </svg>}>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>}>
                  <NavLink to="/settings/general/users" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Users</NavLink>
                </MenuItem>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>}>
                  <NavLink to="/settings/general/roles" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Roles</NavLink>
                </MenuItem>

                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>}>
                  <NavLink to="/settings/general/departments" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Departments</NavLink>
                </MenuItem>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>}>
                  <NavLink to="/settings/general/entity" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Entity</NavLink>
                </MenuItem>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>}>
                  <NavLink to="/settings/general/business-unit" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Business Unit</NavLink>
                </MenuItem>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>}>
                  <NavLink to="/settings/general/employee-level" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Employee Level</NavLink>
                </MenuItem>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                </svg>}>
                  <NavLink to="/settings/general/custom-fields" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Custom Fields</NavLink>
                </MenuItem>
                {/* <NavLink to="/insights" style={{ display: showDashboardTab ? 'block' : 'none' }} activeClassName="active">Dashboard</NavLink>
                  <NavLink to="/insights" style={{ display: showDashboardTab ? 'block' : 'none' }} activeClassName="active">Dashboard</NavLink> */}
              </SubMenu>
              <SubMenu title="Expense" icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
              </svg>}>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                </svg>}>
                  <NavLink to="/settings/expense/categories" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Categories</NavLink>
                </MenuItem>
                <MenuItem icon={<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.9833 4.64193L14.9583 6.1836C15.4526 7.16949 15.6946 8.26262 15.6626 9.36503C15.6306 10.4674 15.3256 11.5447 14.775 12.5003H3.22498C2.50929 11.2587 2.21273 9.81953 2.37926 8.39615C2.5458 6.97278 3.16657 5.64095 4.14955 4.59812C5.13252 3.55529 6.42537 2.85697 7.83643 2.60668C9.24749 2.35639 10.7016 2.56746 11.9833 3.2086L13.525 2.1836C11.9554 1.17711 10.0936 0.726101 8.23739 0.902711C6.38119 1.07932 4.63785 1.87334 3.28622 3.15777C1.9346 4.4422 1.05278 6.14282 0.781829 7.98761C0.510875 9.8324 0.86644 11.7148 1.79165 13.3336C1.93707 13.5855 2.14589 13.7949 2.39734 13.9411C2.64879 14.0873 2.93412 14.1652 3.22498 14.1669H14.7666C15.0603 14.1681 15.3491 14.0916 15.6037 13.9453C15.8584 13.799 16.0698 13.5879 16.2166 13.3336C16.9845 12.0035 17.3698 10.4872 17.3301 8.95189C17.2904 7.41663 16.8272 5.9222 15.9916 4.6336L15.9833 4.64193ZM7.82498 10.3419C7.97977 10.4969 8.16358 10.6198 8.36591 10.7037C8.56824 10.7876 8.78512 10.8307 9.00415 10.8307C9.22317 10.8307 9.44005 10.7876 9.64238 10.7037C9.84471 10.6198 10.0285 10.4969 10.1833 10.3419L14.9 3.26693L7.82498 7.9836C7.67002 8.13839 7.54709 8.3222 7.46321 8.52453C7.37934 8.72686 7.33617 8.94374 7.33617 9.16276C7.33617 9.38179 7.37934 9.59867 7.46321 9.801C7.54709 10.0033 7.67002 10.1871 7.82498 10.3419Z" fill="#4A5568" />
                </svg>}>
                  <NavLink to="/settings/expense/mileage" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Mileage</NavLink>
                </MenuItem>
                <MenuItem icon={PerDiemIcon()}>
                  <NavLink to="/settings/expense/per_diem" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Per diem</NavLink>
                </MenuItem>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                </svg>}>
                  <NavLink to="/settings/expense/policies" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Policies</NavLink>
                </MenuItem>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                </svg>}>
                  <NavLink to="/settings/expense/approval" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Approval Flow</NavLink>
                </MenuItem>
              </SubMenu>
              <SubMenu style={{ display: rolesAndPermData.showAdvancesTab ? 'block' : 'none' }} title="Advance" icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z" />
              </svg>}>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                </svg>}>
                  <NavLink to="/settings/advance/categories" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Categories</NavLink>
                </MenuItem>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                </svg>}>
                  <NavLink to="/settings/advance/policies" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Policies</NavLink>
                </MenuItem>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                </svg>}>
                  <NavLink to="/settings/advance/approval" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Approval Flow</NavLink>
                </MenuItem>
              </SubMenu>

              {ach_flags?.is_ach_applicable ? <MenuItem icon={<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5.16667C7.61917 5.16667 6.5 5.9125 6.5 6.83333C6.5 7.75417 7.61917 8.5 9 8.5C10.3808 8.5 11.5 9.24583 11.5 10.1667C11.5 11.0875 10.3808 11.8333 9 11.8333M9 5.16667V11.8333M9 5.16667C9.925 5.16667 10.7333 5.50167 11.1658 6M9 5.16667V4.33333M9 11.8333V12.6667M9 11.8333C8.075 11.8333 7.26667 11.4983 6.83417 11M16.5 8.5C16.5 9.48491 16.306 10.4602 15.9291 11.3701C15.5522 12.2801 14.9997 13.1069 14.3033 13.8033C13.6069 14.4997 12.7801 15.0522 11.8701 15.4291C10.9602 15.806 9.98491 16 9 16C8.01509 16 7.03982 15.806 6.12987 15.4291C5.21993 15.0522 4.39314 14.4997 3.6967 13.8033C3.00026 13.1069 2.44781 12.2801 2.0709 11.3701C1.69399 10.4602 1.5 9.48491 1.5 8.5C1.5 6.51088 2.29018 4.60322 3.6967 3.1967C5.10322 1.79018 7.01088 1 9 1C10.9891 1 12.8968 1.79018 14.3033 3.1967C15.7098 4.60322 16.5 6.51088 16.5 8.5Z" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>}>
                <NavLink to="/settings/ach" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>ACH</NavLink>
              </MenuItem> : null}

              <MenuItem icon={<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.818 0.00293299C11.8825 0.00386682 11.0153 0.492822 10.5304 1.29278C10.0456 2.09288 10.0135 3.08781 10.4457 3.91746L4.41599 9.94712C3.65343 9.5493 2.74628 9.54202 1.97735 9.92751C1.20843 10.313 0.671634 11.0446 0.534364 11.8936C0.397089 12.7427 0.675933 13.6061 1.28426 14.2142C1.89239 14.8225 2.75579 15.1014 3.60485 14.9641C4.45391 14.8268 5.18549 14.29 5.57096 13.5211C5.95645 12.7522 5.94917 11.845 5.55135 11.0825L11.581 5.05281C12.3102 5.43438 13.1743 5.4594 13.9246 5.1208C14.6747 4.782 15.2273 4.11692 15.423 3.31754C15.619 2.51816 15.4361 1.67302 14.9276 1.02587C14.419 0.37892 13.6411 0.00164205 12.818 0.00293299ZM3.17895 13.3905C2.89488 13.3905 2.62256 13.2777 2.4216 13.0769C2.22082 12.8759 2.10801 12.6036 2.10801 12.3196C2.10801 12.0355 2.22082 11.763 2.4216 11.5622C2.62256 11.3614 2.89487 11.2484 3.17895 11.2484C3.46303 11.2484 3.73554 11.3614 3.93631 11.5622C4.13708 11.763 4.25008 12.0355 4.25008 12.3196C4.25008 12.6036 4.13708 12.876 3.93631 13.0769C3.73553 13.2777 3.46304 13.3905 3.17895 13.3905ZM12.818 3.75145C12.5339 3.75145 12.2614 3.63846 12.0606 3.43768C11.8599 3.23691 11.7469 2.96441 11.7469 2.68033C11.7469 2.39625 11.8599 2.12393 12.0606 1.92297C12.2614 1.7222 12.5339 1.60939 12.818 1.60939C13.1021 1.60939 13.3744 1.7222 13.5754 1.92297C13.7761 2.12393 13.8889 2.39624 13.8889 2.68033C13.8889 2.9644 13.7761 3.23691 13.5754 3.43768C13.3744 3.63846 13.1021 3.75145 12.818 3.75145Z" fill="#4A5568" />
                <path d="M13.0858 11.2485C13.0858 11.6035 12.9447 11.9442 12.6937 12.1952C12.4427 12.4462 12.102 12.5872 11.747 12.5872H7.73072V14.1939H11.747C12.5282 14.1939 13.2774 13.8836 13.8297 13.3312C14.3821 12.7789 14.6923 12.0298 14.6923 11.2485V7.23222H13.0857L13.0858 11.2485Z" fill="#4A5568" />
                <path d="M2.9112 3.75145C2.9112 3.3964 3.05221 3.05572 3.30323 2.80471C3.55424 2.55369 3.89491 2.41268 4.24996 2.41268H8.26623V0.806078H4.24996C3.4687 0.806078 2.71958 1.1163 2.16729 1.66876C1.61481 2.22105 1.30461 2.97018 1.30461 3.75144V7.76771H2.91121L2.9112 3.75145Z" fill="#4A5568" />
              </svg>

              }>
                <NavLink to="/settings/integrations" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Integrations</NavLink>
              </MenuItem>
              <MenuItem icon={<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.16667 11.0625C6 10.8958 5.92 10.6944 5.92667 10.4583C5.93389 10.2222 6.02083 10.0208 6.1875 9.85417L7.70833 8.33333H1.33333C1.09722 8.33333 0.899445 8.25333 0.74 8.09333C0.58 7.93389 0.5 7.73611 0.5 7.5C0.5 7.26389 0.58 7.06583 0.74 6.90583C0.899445 6.74639 1.09722 6.66667 1.33333 6.66667H7.70833L6.16667 5.125C6 4.95833 5.91667 4.76056 5.91667 4.53167C5.91667 4.30222 6 4.10417 6.16667 3.9375C6.33333 3.77083 6.53139 3.6875 6.76083 3.6875C6.98972 3.6875 7.1875 3.77083 7.35417 3.9375L10.3333 6.91667C10.4167 7 10.4758 7.09028 10.5108 7.1875C10.5453 7.28472 10.5625 7.38889 10.5625 7.5C10.5625 7.61111 10.5453 7.71528 10.5108 7.8125C10.4758 7.90972 10.4167 8 10.3333 8.08333L7.33333 11.0833C7.18056 11.2361 6.98972 11.3125 6.76083 11.3125C6.53139 11.3125 6.33333 11.2292 6.16667 11.0625ZM2.16667 15C1.70833 15 1.31583 14.8369 0.989167 14.5108C0.663055 14.1842 0.5 13.7917 0.5 13.3333V10.8333C0.5 10.5972 0.58 10.3992 0.74 10.2392C0.899445 10.0797 1.09722 10 1.33333 10C1.56944 10 1.7675 10.0797 1.9275 10.2392C2.08694 10.3992 2.16667 10.5972 2.16667 10.8333V13.3333H13.8333V1.66667H2.16667V4.16667C2.16667 4.40278 2.08694 4.60056 1.9275 4.76C1.7675 4.92 1.56944 5 1.33333 5C1.09722 5 0.899445 4.92 0.74 4.76C0.58 4.60056 0.5 4.40278 0.5 4.16667V1.66667C0.5 1.20833 0.663055 0.815833 0.989167 0.489167C1.31583 0.163055 1.70833 0 2.16667 0H13.8333C14.2917 0 14.6842 0.163055 15.0108 0.489167C15.3369 0.815833 15.5 1.20833 15.5 1.66667V13.3333C15.5 13.7917 15.3369 14.1842 15.0108 14.5108C14.6842 14.8369 14.2917 15 13.8333 15H2.16667Z" fill="#4A5568" />
              </svg>}>
                <NavLink to="/settings/export-templates" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Export templates</NavLink>
              </MenuItem>
              <SubMenu title="Others" icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
              </svg>}>
                <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>}>
                  <NavLink to="/settings/currency" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "settings")}>Currency Conversion</NavLink>
                </MenuItem>
              </SubMenu>
            </SubMenu>
          </SubMenu>

          <hr style={{ display: rolesAndPermData.showILTab ? 'block' : 'none' }} />

          <SubMenu title="IL Admin" style={{ display: rolesAndPermData.showILTab ? 'block' : 'none' }} defaultOpen={false}>
            <MenuItem
              icon={
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.8337 4.66667V18L12.917 16.3333L10.0003 18L7.08366 16.3333L4.16699 18L4.16699 4.66667C4.16699 4.22464 4.34259 3.80072 4.65515 3.48816C4.96771 3.17559 5.39163 3 5.83366 3L14.167 3C14.609 3 15.0329 3.17559 15.3455 3.48816C15.6581 3.80072 15.8337 4.22464 15.8337 4.66667Z" stroke="#4A5568" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.4 6.8C9.0744 6.8 8 7.516 8 8.4C8 9.284 9.0744 10 10.4 10M10.4 6.8C11.288 6.8 12.064 7.1216 12.4792 7.6M10.4 6.8V6M10.4 6.8V10M10.4 10C11.7256 10 12.8 10.716 12.8 11.6C12.8 12.484 11.7256 13.2 10.4 13.2M10.4 10V13.2M10.4 13.2V14M10.4 13.2C9.512 13.2 8.736 12.8784 8.3208 12.4" stroke="#4A5568" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              }>
              <NavLink to="/adminsubscriptions" activeClassName="active" onClick={() => window.localStorage.setItem("previousTab", "home")}>
                Client Subscription
              </NavLink>
            </MenuItem>
          </SubMenu>
          <hr />
          {/* <SubMenu title="My Account" defaultOpen={false}> */}
          {/* <MenuItem icon={<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.2675 13.3367C5.01618 12.3483 6.99132 11.8303 9 11.8334C11.0833 11.8334 13.0392 12.3792 14.7325 13.3367M11.5 6.83336C11.5 7.4964 11.2366 8.13228 10.7678 8.60112C10.2989 9.06996 9.66304 9.33336 9 9.33336C8.33696 9.33336 7.70107 9.06996 7.23223 8.60112C6.76339 8.13228 6.5 7.4964 6.5 6.83336C6.5 6.17031 6.76339 5.53443 7.23223 5.06559C7.70107 4.59675 8.33696 4.33336 9 4.33336C9.66304 4.33336 10.2989 4.59675 10.7678 5.06559C11.2366 5.53443 11.5 6.17031 11.5 6.83336ZM16.5 8.50002C16.5 9.48494 16.306 10.4602 15.9291 11.3701C15.5522 12.2801 14.9997 13.1069 14.3033 13.8033C13.6069 14.4998 12.7801 15.0522 11.8701 15.4291C10.9602 15.806 9.98491 16 9 16C8.01509 16 7.03982 15.806 6.12987 15.4291C5.21993 15.0522 4.39314 14.4998 3.6967 13.8033C3.00026 13.1069 2.44781 12.2801 2.0709 11.3701C1.69399 10.4602 1.5 9.48494 1.5 8.50002C1.5 6.5109 2.29018 4.60324 3.6967 3.19672C5.10322 1.7902 7.01088 1.00002 9 1.00002C10.9891 1.00002 12.8968 1.7902 14.3033 3.19672C15.7098 4.60324 16.5 6.5109 16.5 8.50002Z" stroke="#3F3F46" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>}>
            <NavLink to="/profile" activeClassName="active">Profile</NavLink>
          </MenuItem>
          <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
          </svg>}>
            <NavLink to="/changepassword" activeClassName="active">Change Password</NavLink>
          </MenuItem>
          <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>}>
            <NavLink to="/help" activeClassName="active">Help</NavLink>
          </MenuItem> */}
          {/* <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
          </svg>}>
            <Nav.Link href={`${process.env.REACT_APP_ITILITEURL}/logout`} onClick={e => {
              localStorageService.clearToken();
              axios({
                method: 'get',
                url: `${process.env.REACT_APP_ITILITEURL}/logout`,
                headers: {
                  'request-from': "web"
                }
              }).then((res) => {
              }).catch(error => {
                console.log(error);
              });
            }} className="no-padding">Logout</Nav.Link>
          </MenuItem> */}
          {/* </SubMenu> */}
        </Menu >
        <Menu iconShape="square" className="bottom-menu">
          {/* <MenuItem icon={<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.2675 13.3367C5.01618 12.3483 6.99132 11.8303 9 11.8334C11.0833 11.8334 13.0392 12.3792 14.7325 13.3367M11.5 6.83336C11.5 7.4964 11.2366 8.13228 10.7678 8.60112C10.2989 9.06996 9.66304 9.33336 9 9.33336C8.33696 9.33336 7.70107 9.06996 7.23223 8.60112C6.76339 8.13228 6.5 7.4964 6.5 6.83336C6.5 6.17031 6.76339 5.53443 7.23223 5.06559C7.70107 4.59675 8.33696 4.33336 9 4.33336C9.66304 4.33336 10.2989 4.59675 10.7678 5.06559C11.2366 5.53443 11.5 6.17031 11.5 6.83336ZM16.5 8.50002C16.5 9.48494 16.306 10.4602 15.9291 11.3701C15.5522 12.2801 14.9997 13.1069 14.3033 13.8033C13.6069 14.4998 12.7801 15.0522 11.8701 15.4291C10.9602 15.806 9.98491 16 9 16C8.01509 16 7.03982 15.806 6.12987 15.4291C5.21993 15.0522 4.39314 14.4998 3.6967 13.8033C3.00026 13.1069 2.44781 12.2801 2.0709 11.3701C1.69399 10.4602 1.5 9.48494 1.5 8.50002C1.5 6.5109 2.29018 4.60324 3.6967 3.19672C5.10322 1.7902 7.01088 1.00002 9 1.00002C10.9891 1.00002 12.8968 1.7902 14.3033 3.19672C15.7098 4.60324 16.5 6.5109 16.5 8.50002Z" stroke="#3F3F46" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>}>
            <NavLink to="/profile" activeClassName="active">Profile</NavLink>
          </MenuItem>
          <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
          </svg>}>
            <NavLink to="/changepassword" activeClassName="active">Change Password</NavLink>
          </MenuItem>
          <MenuItem icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
          </svg>}>
            <Nav.Link href={`${process.env.REACT_APP_ITILITEURL}/logout`} onClick={e => {
              localStorageService.clearToken();
              axios({
                method: 'get',
                url: `${process.env.REACT_APP_ITILITEURL}/logout`,
                headers: {
                  'request-from': "web"
                }
              }).then((res) => {
              }).catch(error => {
                console.log(error);
              });
            }} className="no-padding">Logout</Nav.Link>
          </MenuItem> */}
          <p className='copyright-text'>&copy; ITILITE.</p>
        </Menu>
      </ProSidebar >
    )
  } else {
    return ""
  }
}

export default Header;