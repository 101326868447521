export const FETCH_ALL_EXPENSES = "fetchAllExpenses"; // for listing all the expenses
export const SET_ALL_EXPENSES = "setAllExpenses";

export const SUBMIT_REPORT_FOR_APPROVAL = "submitReportForApproval";
export const SET_SUBMIT_REPORT_FOR_APPROVAL = "setSubmitReportForApproval";

export const LINK_UNLINK_EXPENSE = "linkUnLinkExpense";
export const SET_LINK_UNLINK_EXPENSE = "setLinkUnLinkExpense";

export const DELETE_REPORT = "reportDelete";
export const SET_REPORT_DELETE = "SET_REPORT_DELETE";

export const REPORT_TITLE_UPDATE = "reportTitleUpdate";
export const SET_REPORT_TITLE_UPDATE = "setReportTitleUpdate";


export const REJECT_REPORT = 'rejectReport';
export const SET_REJECT_REPORT = 'setRejectReport';

export const APPROVE_REPORT = 'approveReport';
export const SET_APPROVE_REPORT = 'setApproveReport';