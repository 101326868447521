// import createReducer from "../../../../components/common/Utility";
import createReducer from "../../../../../components/common/Utility";
import { API_START,API_END , RESET_STATE , API_ERROR } from "../../../../actions/Common/Api.types";
import { GET_CATEGORIES_MAPPING, 
    GET_REIMBURSEMENT_STATUS, 
    GET_TANDE_FEATURE_STATUS, 
    RESET_COMMON_ERROR_RESPONSE,
    SET_CATEGORIES_MAPPING, 
    SET_REIMBURSEMENT_STATUS,
    SET_TANDE_FEATURE_STATUS,
    SET_TOGGLE_REIMBURSEMENT_OPTION,
    SET_TOGGLE_TANDE_FEATURE_STATUS,
    SET_ATTRIBUTE_MAPPING,
    RESET_ATTRIBUTE_MAPPING,
    GET_ATTRIBUTE_MAPPING,
    SET_EDIT_CATEGORY_MAPPING,
    EDIT_CATEGORY_MAPPING,
    RESET_EDIT_CATEGORY_MAPPING,
    RESET_TANDE_FEATURE_STATUS
} from "../../../../actions/Admin/Settings/TandE/TandE.action.type";

const initialState = {
    isLoadingData: false,
    tandeFeatureStatus: null,
    toggledTandEFeatureStatus: null,
    reimbursementStatus: null,
    toggledReimbursementStatus: null,
    categoriesMappingList: [],
    commonErrorResponse: null,
    attributeMapping: null,
    isLoading: {
        attributeMapping: false,
        categoriesMappingList: false,
        editCategoryMappingResponse: false
    },
    editCategoryMappingResponse: null
}

export const reducerObj = {
    [SET_TANDE_FEATURE_STATUS]: (prevState, payload) => ({
        tandeFeatureStatus: payload
    }),

    [SET_TOGGLE_TANDE_FEATURE_STATUS]: (prevState, payload) => ({
        toggledTandEFeatureStatus: payload
    }),

    [SET_REIMBURSEMENT_STATUS]: (prevState, payload) => ({
        reimbursementStatus: payload
    }),

    [SET_TOGGLE_REIMBURSEMENT_OPTION]: (prevState, payload) => ({
        toggledReimbursementStatus: payload
    }),

    [SET_CATEGORIES_MAPPING]: (prevState, payload) => ({
        categoriesMappingList: payload,
        isLoading: {
            ...prevState.isLoading,
            categoriesMappingList: false
        }
    }),
    
    [SET_ATTRIBUTE_MAPPING]: (prevState, payload) => {
        return {
            attributeMapping: payload,
            isLoading: {
                ...prevState.isLoading,
                attributeMapping: false
            }
        }
    },

    [SET_EDIT_CATEGORY_MAPPING]: (prevState, payload) => {
        console.log("payload",payload)
        return {
            editCategoryMappingResponse: payload,
            isLoading: {
                ...prevState.isLoading,
                editCategoryMappingResponse: false
            }
        }
    },

    [RESET_STATE]: (prevState , payload) => ({
        isLoadingData: false,
        tandeFeatureStatus: null,
        toggledTandEFeatureStatus: null,
        reimbursementStatus: null,
        categoriesMappingList: [],
        commonErrorResponse: null,
    }),

    [RESET_ATTRIBUTE_MAPPING]: (prevState, payload) => ({
        attributeMapping: null
    }),

    [RESET_EDIT_CATEGORY_MAPPING]: (prevState, payload) => ({
        editCategoryMappingResponse: null
    }),

    [RESET_TANDE_FEATURE_STATUS]: (prevState, payload) => ({
        tandeFeatureStatus: null,
    }),

    // ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === GET_TANDE_FEATURE_STATUS || payload === GET_REIMBURSEMENT_STATUS) {
			return { isLoadingData: true }
		}
        if(payload === GET_ATTRIBUTE_MAPPING){
            return {
                isLoading: {
                    ...prevState.isLoading,
                    attributeMapping: true
                }
            }
        }
        if(payload === GET_CATEGORIES_MAPPING){
            return {
                isLoading: {
                    ...prevState.isLoading,
                    categoriesMappingList: true
                }
            }
        }
        if(payload === EDIT_CATEGORY_MAPPING){
            return {
                isLoading: {
                    ...prevState.isLoading,
                    editCategoryMappingResponse: true
                }
            }
        }
	},
	[API_END]: (prevState, payload) => (
        { isLoadingData: false }
    ),
    [API_ERROR]: (prevState, payload) => (
            { commonErrorResponse: payload}
    ),
    [RESET_COMMON_ERROR_RESPONSE]: (prevState, payload) => (
            { commonErrorResponse: null}
    ),
}

const TandEReducer = createReducer(initialState, reducerObj)

export default TandEReducer;