export const FETCH_CORPORATE_ACCOUNTS_LIST = "fetchCorporateAccountsList";
export const SET_CORPORATE_ACCOUNTS_LIST = "setCorporateAccountsList";
export const FETCH_CORPORATE_SUMMARY_DATA = "fetchCorporateSummaryData";
export const SET_CORPORATE_SUMMARY_DATA = "setCorporateSummaryData";


export const ASSIGN_CARD_OWNER_STATUS_API = "setCardOwnerApi";
export const SET_CARD_OWNER_STATUS = "setCardOwnerStatus";

export const SET_LOADING_STATES = "setLoadingStatesForCorporateCards"

export const RESET_ERROR_MESSAGES = "resetErrorStates"