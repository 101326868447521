import createReducer from '../../../../components/common/Utility';

import {
    GET_EXPENSE_DETAILS,
    SET_EXPENSE_DETAILS,
    EDIT_EXPENSE_AMOUNT,
    SET_EDITTED_EXPENSE_AMOUNT,
} from '../../../actions/Admin/Reports/ExpenseDetails.action.type';

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
    fetchedExpenseData: {},
    fetchedExpenseCategory: {},
    exceededBy: '',
    allowedAmount: '',
    claimedAmount: '',
    isLoadingData: null,
    fetchedExpensePayload: {},
    editAmountResp: null
}

export const reducerObj = {
    [SET_EXPENSE_DETAILS]: (prevState, payload) => (
        {
            allowedAmount: payload.data.policy_violation ? (
                // (payload.data.amount - payload.data.policy_violation.amount) < 0
                //     ? (
                //         0
                //     ) : (
                        // payload.data.amount - payload.data.policy_violation.amount
                        payload.data.policy_violation.allowed_amount
                    // )
            ) : "",
            attributes_violation: payload.data.policy_violation ? payload.data.policy_violation?.attributes_violation : [],
            policy_violation: payload.data.policy_violation ? payload.data.policy_violation : [],
            exceededBy: payload.data.policy_violation ? payload.data.policy_violation.amount : "",
            fetchedExpenseData: payload.data,
            fetchedExpenseCategory: payload.data.category,
            claimedAmount: payload.data.amount,
            isLoadingData: false,
            fetchedExpensePayload: payload,
            staticMapUrl: payload.data?.map_url
        }
    ),
    [RESET_STATE]: (prevState, payload) => (
        {
            fetchedExpenseData: {},
            fetchedExpenseCategory: {},
            exceededBy: '',
            allowedAmount: '',
            claimedAmount: '',
            isLoadingData: false,
            editAmountResp: null,
            fetchedExpensePayload: {},
            attributes_violation:[],
            policy_violation:[]
        }
    ),

    [SET_EDITTED_EXPENSE_AMOUNT]: (prevState, payload) => {
        return {
            editAmountResp: payload,
            fetchedExpenseData: {},
            fetchedExpenseCategory: {},
            exceededBy: '',
            allowedAmount: '',
            claimedAmount: '',
            isLoadingData: false,
            fetchedExpensePayload: {},
            attributes_violation: [],
            policy_violation:[]
        }
    },

    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === GET_EXPENSE_DETAILS || payload === EDIT_EXPENSE_AMOUNT) {
            return { isLoadingData: true };
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === SET_EXPENSE_DETAILS || payload === SET_EDITTED_EXPENSE_AMOUNT) {
            return { isLoadingData: false };
        }
    },
}

let ExpenseDetailsReducer = createReducer(initialState, reducerObj)

export default ExpenseDetailsReducer;