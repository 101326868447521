const redirectUrls = {
	1: "/getting-started",
	2: "/subscriptions",
	3: "/settings/general/users",
	4: "/settings/expense/categories",
	5: "/settings/expense/policies",
	6: "/settings/expense/approval",
	7: "/individual/reports/expense/create"
};

export { redirectUrls };
