import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import App from './App';

import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css';
import "@itilite/iuistyles/dist/index.css";
import "@itilite/iuistyles/dist/classes.css";
import './index.css';

// redux imports
import { createStore, applyMiddleware, compose } from 'redux'; // to create a redux store.
import { Provider } from 'react-redux'; // to provide state to the root component.
import Reducer from './store/reducers'; //importing REDUCER.
// import { createLogger } from 'redux-logger'; // importing redux logger.
import { composeWithDevTools } from "redux-devtools-extension"; // importing redux chrome extension
// import thunkMiddleWare from 'redux-thunk'; // importing redux middle-ware for asynchronous tasks.
import apiMiddleware from './store/Middleware';

axios.defaults.baseURL = process.env.REACT_APP_BASEURL;

// initializing the redux logger.
// const logger = createLogger();

// initializing the redux enhancer by combining the redux chrome extension.
const composeEnhancers = composeWithDevTools() || compose;

// creating the store by combining REDUCER, MIDDLE-WARES and ENHANCERS.
const store = createStore(Reducer, compose(applyMiddleware(apiMiddleware), composeEnhancers));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
