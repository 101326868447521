import _ from 'lodash';
import createReducer from '../../../../components/common/Utility';

import {
	FETCH_ALL_REPORTS,
	SET_ALL_MY_REPORTS,
	FETCH_DRAFT_REPORTS,
	SET_DRAFT_REPORTS,
	FETCH_DRAFT_EXPENSE,
	SET_DRAFT_EXPENSE,
	FETCH_PENDING_APPROVAL,
	SET_PENDING_APPROVAL,
	DELETE_DRAFT,
	SET_DELETE_DRAFT,
	SET_TRIP_IDS,
	FETCH_TRIP_IDS,
	CREATE_NEW_EXPENSE,
	SET_NEW_EXPENSE,
	CREATE_NEW_DRAFT_EXPENSE,
	SET_NEW_DRAFT_EXPENSE,
	CREATE_NEW_REPORT,
	SET_CREATE_NEW_REPORT,
	EDIT_EXPENSE,
	SET_EDIT_EXPENSE,
	DELETE_EXPENSE,
	SET_DELETE_EXPENSE,
	FETCH_CALC_ATTR,
	SET_CALC_ATTR,
	SET_TIME_BASED_POLICIES_DATA,
	RESET_TRIPID_STATE,
	SET_DELETE_LOCAL_DRAFT_EXPENSE,
	RESET_DRAFT_EXPENSE_STATE,
	SET_DELETE_LOCAL_DRAFT_REPORT,
	RESET_ALL_REPORT_STATE,
	RESET_DRAFT_DELETE_RESPONSE,
	SET_TRAVEL_NUDGE_USER_NAVIGATED_COUNT,
	SET_TRAVEL_NUDGE_ELIGIBILITY,
	RESET_TRAVEL_NUDGE_ELIGIBILITY_RESPONSE
} from '../../../actions/Personal/DraftExpense/DraftExpense.action.type';

// import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE, API_ERROR } from '../../../actions/Common/Api.types';

export const initialState = {
	allReportsCount: 0,
	draftReportsCount: 0,
	draftExpensesCount: 0,
	pendingApprovalsCount: 0,
	ilTripsCount: 0,
	trip_data_count:null,
	displayLoadMore: true,
	allReportsData: null,
	draftReportsData: null,
	draftExpensesData: null,
	pendingApprovalsData: null,
	ilTripsData: null,
	// total_Trips:null,
	allReports: [],
	draftReports: [],
	draftExpenses: [],
	pendingApprovals: [],
	ilTrips: [],

	isLoadingData: false,
	draftDeleteResponse: null,
    userNavigatedResponse:null,
	travelNudgeEligibilityResponse:null,
	total_records: {
		all_my_reports: 0,
		draft_expense: 0,
		draft_report: 0
	},

	createNewReportResponse: null,
	calculatedAttributes: null,
	timeBasedPolicyData: null,
	isLoading: {
		draftExpenseList: false,
		deleteDraft: false,
		draftReportList: false,
		allReportList: false,
	}
}

export const reducerObj = {
	[SET_ALL_MY_REPORTS]: (prevState, payload) => (
		{
			allReportsData: payload,
			allReports: payload.data,
			allReportsCount: payload.total_records || 0,
			total_records: {
				...initialState.total_records,
				all_my_reports: payload.total_records || 0
			},
			isLoadingData: false,
			isLoading: {
				...prevState.isLoading,
				allReportList: false
			}
		}
	),

	[SET_DRAFT_REPORTS]: (prevState, payload) => (
		{
			draftReportsData: payload,
			draftReports: payload.data,
			draftReportsCount: payload.total_records || 0,
			total_records: {
				...initialState.total_records,
				draft_report: payload.total_records || 0
			},
			isLoadingData: false,
			isLoading: {
				...prevState.isLoading,
				draftReportList: false
			}
		}
	),

	[SET_DRAFT_EXPENSE]: (prevState, payload) => (
		{
			draftExpensesData: payload,
			draftExpenses: payload.data,
			draftExpensesCount: payload.total_records || 0,
			total_records: {
				...initialState.total_records,
				draft_expense: payload.total_records || 0
			}, 
			isLoadingData: false,
			isLoading: {
				...prevState.isLoading,
				draftExpenseList: false
			}
		}
	),

	[SET_PENDING_APPROVAL]: (prevState, payload) => (
		{
			pendingApprovalsData: payload,
			pendingApprovals: payload.data,
			pendingApprovalsCount: payload.total_records || 0,
			total_records: {
				...initialState.total_records,
				pendingApprovals: payload.total_records || 0
			},
			isLoadingData: false
		}
	),

	[SET_TRIP_IDS]: (prevState, payload) => {
		if(payload.data.length===0){
			return{
				displayLoadMore:false,
			}
		}
			return{
			
				ilTripsData: _.merge(prevState.ilTripsData, payload),
				ilTrips: prevState.ilTrips.length ? [...prevState.ilTrips,...payload.data_obj]:payload.data_obj ,
				ilTripsCount: payload.data_obj.length || 0,
				isLoadingData: false,
				trip_data_count:payload.data_obj.length,
				displayLoadMore:payload.has_more_results?true:false,
			}
		
	
},

	[SET_DELETE_DRAFT || SET_NEW_EXPENSE || SET_NEW_DRAFT_EXPENSE]: (prevState, payload) => (
		{
			draftDeleteResponse: payload
		}
	),

	[SET_NEW_DRAFT_EXPENSE]: (prevState, payload) => (
		{
			draftDeleteResponse: payload
		}
	),

	[SET_NEW_EXPENSE]: (prevState, payload) => (
		{
			draftDeleteResponse: payload
		}
	),

	[SET_EDIT_EXPENSE]: (prevState, payload) => (
		{
			draftDeleteResponse: payload
		}
	),

	[SET_DELETE_EXPENSE]: (prevState, payload) => (
		{
			draftDeleteResponse: payload
		}
	),
	[SET_DELETE_LOCAL_DRAFT_EXPENSE]: (prevState,payload) => {
		return {
			draftExpenses: prevState.draftExpenses.filter(expId => !payload?.includes(expId.expense_id)),
			draftExpensesCount: prevState.draftExpensesCount - payload?.length,
		}
	},

	[SET_DELETE_LOCAL_DRAFT_REPORT]: (prevState,payload) => {
		return {
			draftReports: prevState.draftReports.filter(report => !payload?.includes(report.report_id)),
			draftReportsCount: prevState.draftReportsCount - payload?.length,
		}
	},

	[SET_TIME_BASED_POLICIES_DATA] : (prevState, payload) => (
		{
			timeBasedPolicyData: payload
		}
	),
	[SET_TRAVEL_NUDGE_USER_NAVIGATED_COUNT] : (prevState,payload) =>(
	   {
			userNavigatedResponse:payload
		}
	),
	[SET_TRAVEL_NUDGE_ELIGIBILITY]: (prevState,payload) =>(
		{
			travelNudgeEligibilityResponse:payload
		}
	),
	[RESET_TRAVEL_NUDGE_ELIGIBILITY_RESPONSE]:(prevState,payload)=>(
		{
			travelNudgeEligibilityResponse:payload
		}
	),

	[RESET_DRAFT_EXPENSE_STATE]: (prevState, payload) => {
		return {
			draftExpensesCount: 0,
			draftExpensesData: null,
			draftExpenses: [],
			total_records: {
				...prevState.total_records,
				draftExpenses: 0,
			},
		}
	},

	[RESET_ALL_REPORT_STATE]: (prevState, payload) => {
		return {
			allReportsCount: 0,
			allReportsData: null,
			allReports: [],
			total_records: {
				...prevState.total_records,
				allReports: 0,
			},
		}
	},

	[RESET_STATE]: () => (
		{
			// allReportsCount: 0,
			// draftReportsCount: 0,
			// draftExpensesCount: 0,
			pendingApprovalsCount: 0,
			// ilTripsCount: 0,

			// allReportsData: null,
			// draftReportsData: null,
			// draftExpensesData: null,
			pendingApprovalsData: null,
			// ilTripsData: null,

			// allReports: [],
			// draftReports: [],
			// draftExpenses: [],
			pendingApprovals: [],
			// ilTrips: [],

			isLoadingData: false,
			draftDeleteResponse: null,

			total_records: {
				// allReports: 0,
				// draftReports: 0,
				// draftExpenses: 0,
				pendingApprovals: 0,
			},

			createNewReportResponse: null,
			timeBasedPolicyData: null
		}
	),
	[RESET_TRIPID_STATE]: () => (
		{
		
			ilTripsCount: 0,

			
			ilTripsData: null,

			
			ilTrips: [],

			
		}
	),
	[SET_CREATE_NEW_REPORT]: (prevState, payload) => (
		{
			createNewReportResponse: payload,
		}
	),

	// [SEARCH_ACTION_LABEL]: () => (
	// 	{
	// 		total_records: {
	// 			...initialState.total_records,
	// 			all: 0,
	// 			pending: 0,
	// 			outstanding: 0
	// 		},
	// 	}
	// ),

	[SET_CALC_ATTR]: (prevState, payload) => {
		return {
			calculatedAttributes: payload
		}
	},
    [RESET_DRAFT_DELETE_RESPONSE]:(prevState)=>{
		return{
			draftDeleteResponse:null
		}
	},
	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === FETCH_ALL_REPORTS) {
			return {
				isLoading: {
					...prevState.isLoading,
					allReportList: true
				}
				// isLoadingData: true,
				// allReportsData: null,
				// allReports: [],
				// allReportsCount: 0,
			}
		}
		if (payload === FETCH_DRAFT_REPORTS) {
			return {
				isLoading: {
					...prevState.isLoading,
					draftReportList: true
				},
				// isLoadingData: true,
				// draftReportsData: null,
				// draftReports: [],
				// draftReportsCount: 0
			}
		}
		if (payload === FETCH_DRAFT_EXPENSE) {
			return {
				isLoadingData: true,
				// draftExpensesData: null,
				isLoading: {
					...prevState.isLoading,
					draftExpenseList: true
				}
				// draftExpenses: [],
				// draftExpensesCount: 0
			}
		}
		if(payload === DELETE_DRAFT){
			return {
				// isLoadingData: true,
				isLoading: {
					...prevState.isLoading,
					deleteDraft: true
				}
			}
		}
		if (payload === FETCH_TRIP_IDS) {
			return {
				// ilTripsData: null,
				// ilTrips: [],
				// ilTripsCount: 0,
				isLoadingData: true
			}
		}
		if (payload === FETCH_PENDING_APPROVAL || payload === DELETE_DRAFT || payload === FETCH_TRIP_IDS || payload === CREATE_NEW_EXPENSE || payload === CREATE_NEW_DRAFT_EXPENSE || payload === CREATE_NEW_REPORT || payload === EDIT_EXPENSE || payload === DELETE_EXPENSE || payload === FETCH_CALC_ATTR) {
			return { isLoadingData: true }
		}
	},
	[API_END]: (prevState, payload) => {
		// payload === SET_ALL_MY_REPORTS || SET_DRAFT_REPORTS || SET_DRAFT_EXPENSE || SET_PENDING_APPROVAL || SET_DELETE_DRAFT || SET_TRIP_IDS || SET_NEW_EXPENSE || SET_NEW_DRAFT_EXPENSE || SET_CREATE_NEW_REPORT || SET_EDIT_EXPENSE || SET_DELETE_EXPENSE || FETCH_CALC_ATTR
		// 	? { isLoadingData: false,
		// 		isLoading: {
		// 			draftExpenseList: false,
		// 			deleteDraft: false,
		// 			draftReportList: false,
		// 			allReportList: false
		// 		}
		// 	}
		// 	: { isLoadingData: false }
		if(payload === SET_DRAFT_EXPENSE){
			return {
				isLoading: {
							...prevState.isLoading,
							draftExpenseList: false,
				}
			}
		}
		if(payload === SET_DRAFT_REPORTS){
			return {
				isLoading: {
							...prevState.isLoading,
							draftReportList: false,
				}
			}
		}
		if(payload === SET_ALL_MY_REPORTS){
			return {
				isLoading: {
							...prevState.isLoading,
							allReportList: false,
				}
			}
		}
		if(payload === SET_DELETE_DRAFT || payload === SET_DELETE_EXPENSE){
			return {
				isLoading: {
							...prevState.isLoading,
							deleteDraft: false,
				}
			}
		}
		else{
			return {isLoadingData: false}
		}
	},
	[API_ERROR]: (prevState, payload) => {
		if (payload.label === FETCH_TRIP_IDS) {
			return {
				ilTripsData: payload.data,
				ilTrips: payload.data.data,
				ilTripsCount: payload.total,
				isLoadingData: false
			}
		}
		if (payload.label === FETCH_DRAFT_EXPENSE) {
			return {
				isLoadingData: false,
				draftExpensesData: payload.data,
				draftExpenses: payload.data.data || [],
				draftExpensesCount: payload.total
			}
		}
	}
}

let DraftExpenseReducer = createReducer(initialState, reducerObj);

export default DraftExpenseReducer;