// inspired by https://leanpub.com/redux-book
import axios from "axios";
import toastService from '../../components/common/services/Storage_services/ToastService';
import { API } from "../actions/Common/Api.types";
import { accessDenied, apiError, apiStart, apiEnd } from "./Middleware.actions";

const apiMiddleware = ({ dispatch }) => next => action => {
	if (action) {
		next(action);

		if (action && action.type !== API) return;

		const {
			url,
			method,
			data,
			accessToken,
			onSuccess,
			// onFailure,
			label,
			headersOverride,
			baseURL = null,
			customHeaders = null
		} = action.payload;

		// const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

		// axios default configs
		axios.defaults.baseURL = baseURL || process.env.REACT_APP_BASEURL || "";

		axios.defaults.headers.common["Content-Type"] = "application/json";
		axios.defaults.headers.common["access-token"] = accessToken;
		axios.defaults.headers.common["request-from"] = "web";

		 // Clear previously set custom headers
		

		if (headersOverride) {
			// adding new header for search api. 
			axios.defaults.headers.common["condition"] = headersOverride;
		}
		// if(customHeaders)
		// {
		// 	customHeaders.forEach((_customHeader) => {
		// 		axios.defaults.headers.common[_customHeader.key] = _customHeader.value;
		// 	})
		// 		}

		if (label) {
			dispatch(apiStart(label));
		}

		axios({
			url,
			method,
			headersOverride,
			data
		})
			.then(({ data }) => {
				if (data && data.message === "Something went wrong!!!") {
					toastService.notify(data.message);
					dispatch(apiError(data));
				} else {
					dispatch(onSuccess(data));
					// dispatch(apiEnd(onSuccess(data).type));
				}
			})
			.catch(error => {
				// internal server error
				if (error && error.response && error.response.status === 500) {
					toastService.notify(error.response.statusText + " - " + error.response.data.message);
					dispatch(apiError(error.response));
					// dispatch(onFailure(error.response));
				}

				// for all failed codes.
				if (error && error.response && (error.response.status === 409 || error.response.status === 400 || error.response.status === 404 || error.response.status === 405 || error.response.status === 406)) {
					error.response["label"] = label
					dispatch(apiError(error.response));
				}

				// unauthorised || forbidden
				if (error.response && (error.response.status === 403 || error.response.status === 401 || error.response.status === 407)) {
					toastService.notify(error.response.statusText + " - " + error.response.data.message)
					dispatch(accessDenied(window.location.pathname));
				}
			})
			.finally(() => {
				// if (label) {
				// 	dispatch(apiEnd(label));
				// }
				// axios.defaults.headers.common = {}	
				// axios.defaults.baseURL = process.env.REACT_APP_BASEURL
				dispatch(apiEnd(onSuccess(data).type));
			});
	}
};

export default apiMiddleware;
