import createReducer from '../../../../components/common/Utility';

import {
	FETCH_REPORT_LIST, SET_REPORT_LIST
} from '../../../actions/Admin/ReportDownload/ReportDownload.action.type';

import { API_START, API_END } from '../../../actions/Common/Api.types';

export const initialState = {
	reportList: {},
}

export const reducerObj = {
	[SET_REPORT_LIST]: (prevState, payload) => (
		{
			reportList: payload,
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => (
		payload === FETCH_REPORT_LIST
			? { isLoadingData: true }
			: { isLoadingData: false }
	),
	[API_END]: (prevState, payload) => (
		payload === FETCH_REPORT_LIST
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
}

let ReportDownloadReducer = createReducer(initialState, reducerObj);

export default ReportDownloadReducer;