import createReducer from '../../../../components/common/Utility';

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';
import { GET_NAVBAR_DETAILS, SET_CLIENT_DETAILS, SET_NAVBAR_DETAILS, SET_USER_DETAILS_AFTER_UUM_TOKEN_VERIFY } from '../../../actions/Common/Navbar/Navbar.action.type';

export const initialState = {
    isLoading: true,
    navbar: null,
    uumDetails: null,
    clientDetails: null
}

export const reducerObj = {
    // ------------- Api end and start loading state ----------
    [GET_NAVBAR_DETAILS]: (prevState, payload) => (
        {
         isLoading: true
        }
     ),
    [SET_NAVBAR_DETAILS]: (prevState, payload) => (
        {
         navbar: payload,
         isLoading: false
        }
     ),
    [SET_USER_DETAILS_AFTER_UUM_TOKEN_VERIFY]: (prevState, payload) => (
        {
         uumDetails: payload
        }
     ),
    [SET_CLIENT_DETAILS]: (prevState, payload) => (
        {
            clientDetails: payload
        }
    ),
    [API_START]: (prevState, payload) => {
        if (payload === GET_NAVBAR_DETAILS) {
            return {
                isLoading: true
            }
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === SET_NAVBAR_DETAILS) {
            return {
                isLoading: false
            }
        }
    },
}

let NavbarReducer = createReducer(initialState, reducerObj);

export default NavbarReducer;