export const GET_REPORT_DETAILS = 'getReportDetails';
export const SET_REPORT_DETAILS = 'setReportDetails';

export const GET_APPROVAL_DETAILS = 'getApprovalDetails';
export const SET_APPROVAL_DETAILS = 'setApprovalDetails';

export const REJECT_REPORT = 'rejectReport';
export const SET_REJECT_REPORT = 'setRejectReport';

export const APPROVE_REPORT = 'approveReport';
export const SET_APPROVE_REPORT = 'setApproveReport';

export const GET_FRAUD_DETAILS = 'getFraudDetails';
export const SET_FRAUD_DETAILS = 'setFraudDetails';

export const RESET_NAVIGATION_STATE = "resetNavigationState";

export const APPROVE_CLIENT_REPORT = 'approveClientReport';
export const SET_APPROVE_CLIENT_REPORT = 'setApproveClientReport';
