import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import pageHeader from "../../../common/components/UI/pageHeader";
import localStorageService from "../../../common/services/Storage_services/LocalStorageService";
import { checkRoleAndPermissionForPage } from "../../../../utils/checkRoleAndPermission";
import { uploadFilePromise } from "../../../../utils/upload";
import { users } from "../../../../Urls";
import ToastService from "../../services/Storage_services/ToastService";

// bootstrap import
import Row from "react-bootstrap/Row";

// action import
// import * as myDetailsActions from "../../../../store/actions/Common/MyDetails/MyDetails.action";
import axios from "axios";

function ProfileSettings(props) {
	const { handleToggleSidebar } = props;
	const fileInput = useRef(null);
	const [isCallUserDetails, setIsCallUserDetails] = useState(false);
	const [showProfileSetting, setShowProfilePage] = useState(false);
	const [showUnauthorizedAlert, setShowUnauthorizedAlert] = useState(false);
	const [uploadProfileImage, setUploadProfileImage] = useState({
		buttonStatus: true,
		buttonText: "",
	});
	const pageHeaderName = "Profile";

	// const dispatch = useDispatch(); // initiated dispatch

	// accessing state from the reducer.
	const {
		myDetails,
		isLoadingData,
	} = useSelector((state) => state.MyDetailsReducer);
	const { userRolesAndPermissions } = useSelector(
		(state) => state.RolesAndPermissionsReducer
	);

	useEffect(() => {
		if (Object.keys(userRolesAndPermissions).length) {
			let __showProfilePage = checkRoleAndPermissionForPage(
				userRolesAndPermissions,
				"/settings"
			);

			setShowProfilePage(__showProfilePage);

			if (__showProfilePage === false) {
				setShowUnauthorizedAlert(true);
			}
		}
	}, [userRolesAndPermissions]);

	// useEffect(() => {
	// 	dispatch( ----------------- don't have to make api call now getting the data from mydetails.reducer.
	// 		myDetailsActions.getMyDetails(
	// 			JSON.parse(localStorageService.getAccessToken()),
	// 			"GET"
	// 		)
	// 	);
	// }, [dispatch, isCallUserDetails]);

	const handleImageUpload = () => {
		fileInput.current.click();
	};

	const saveProfileImage = async (res) => {
		let __tempUserDetails = { ...myDetails };

		__tempUserDetails["img_name"] =
			res === "remove" ? null : res.remote_file_data.name;
		__tempUserDetails["img_path"] =
			res === "remove" ? null : res.remote_file_data.path;
		__tempUserDetails["img_type"] =
			res === "remove" ? null : res.remote_file_data.type;

		try {
			const response = await axios({
				method: "PUT",
				url: users.edit,
				headers: {
					"access-token": JSON.parse(
						localStorageService.getAccessToken()
					),
					'request-from': "web"
				},
				data: {
					users: [__tempUserDetails],
				},
			});

			if (response.status === 200) {
				ToastService.sNotify(response.message);
				setTimeout(() => {
					window.location.reload();
				}, 0);
			}
		} catch (error) {
			ToastService.notify("Something went wrong");
		}
		// await dispatch(
		// 	actions_user.saveUser(
		// 		JSON.parse(localStorageService.getAccessToken()),
		// 		"PUT",
		// 		{
		// 			users: [__tempUserDetails],
		// 		},
		// 		"EDIT"
		// 	)
		// );

		await setIsCallUserDetails(!isCallUserDetails);
	};

	const handleProfileImgChange = async (event) => {
		if (event.target.files[0]?.type.includes("jpg") || event.target.files[0]?.type.includes("jpeg") || event.target.files[0]?.type.includes("png")) {
			setUploadProfileImage({
				buttonStatus: false,
				buttonText: "Uploading...",
			});
			const uploadedFile = event.target.files[0];

			// This is the default behaviour of the <input type="file"> tag. 
			event.target.value = ""; //we have to set the value to "" in order to trigger it again.

			// Have to change it because "await uploadFilePromise()" was ignoring the promise "reject()".
			uploadFilePromise(uploadedFile)
				.then((result) => {
					setUploadProfileImage({
						buttonStatus: true,
						buttonText: "",
					});

					saveProfileImage(result);
				})
				.catch((error) => {
					if (error?.response?.data?.status === false) {
						ToastService.notify(error.response.data.message);
					}
					setUploadProfileImage({
						buttonStatus: true,
						buttonText: "",
					});
				});
		} else {
			ToastService.notify("Please upload JPG, JPEG, PNG file.");
			return;
		}
	};

	return (
		<div className='payout-container page-container users-container'>
			<div className='profile-pageheader profile-pageheader-msite'>
				{pageHeader({
					type: "pageHeader",
					name: pageHeaderName,
					background: false,
					handleToggleSidebar
				})}
			</div>

			<div className='users-body-container'>
				<div className='profile-img-section profile-img-section-msite'>
					<div className={isLoadingData ? "" : 'img-circle'}>
						{!isLoadingData ? (
							myDetails && myDetails.img_path && (
								<img alt='profile-pic' src={myDetails.img_path} />
							)
						) : (
							<Skeleton circle={true} width={90} height={90} />
						)}
					</div>
					<div className='upload-img'>
						<div>
							{!isLoadingData ? (
								<button
									className='upload-img-btn'
									onClick={handleImageUpload}
									disabled={uploadProfileImage.buttonText !== ""}
								>
									{uploadProfileImage.buttonText !== ""
										? uploadProfileImage.buttonText
										: "Upload new photo"}
								</button>
							) : <Skeleton width={100} height={30} count={2} />}
							<input
								type='file'
								style={{ display: "none" }}
								ref={fileInput}
								onChange={handleProfileImgChange}
								name='Upload'
								accept=".jpg,.jpeg,.png"
							/>
							{myDetails && myDetails.img_path && uploadProfileImage.buttonText === "" && !isLoadingData && (
								<React.Fragment>
									<div className='dot-circle'></div>
									<button
										onClick={() =>
											saveProfileImage("remove")
										}
									>
										Remove photo
									</button>
								</React.Fragment>
							)}
						</div>
						<div className='img-helptext'>
							Photos help your teammates recognize you in Itilite
						</div>
					</div>
				</div>
				<div className='user-details-container profile-details-container profile-details-container-msite'>
					<Row className='user-details-container-row user-details-container-row-msite p-0'>
						<div className='user-details-container-col user-details-container-col-msite'>
							<label className='profile-lable-text'>
								First Name
							</label>
							{!isLoadingData ? (
								<input
									type='text'
									className='form-controller-input'
									name='first_name'
									data-type='userDetails'
									disabled
									value={
										myDetails && myDetails.first_name
											? myDetails.first_name
											: ""
									}
								/>
							) : <Skeleton height={35} />}
						</div>
						<div className='user-details-container-col user-details-container-col-msite'>
							<label className='profile-lable-text'>
								Middle Name
							</label>
							{!isLoadingData ? (
								<input
									type='text'
									className='form-controller-input'
									name='last_name'
									data-type='userDetails'
									disabled
									value={
										myDetails && myDetails.middle_name
											? myDetails.middle_name
											: ""
									}
								/>
							) : <Skeleton height={35} />}
						</div>
						<div className='user-details-container-col user-details-container-col-msite'>
							<label className='profile-lable-text'>
								Last Name
							</label>
							{!isLoadingData ? (
								<input
									type='text'
									className='form-controller-input'
									name='last_name'
									data-type='userDetails'
									disabled
									value={
										myDetails && myDetails.last_name ? myDetails.last_name : ""
									}
								/>
							) : <Skeleton height={35} />}
						</div>
					</Row>
					<Row className='user-details-container-row p-0 user-details-container-row-email'>
						<div className='user-details-container-col user-details-container-col-msite user-details-container-col-email'>
							<label className='profile-lable-text'>Email</label>
							{!isLoadingData ? (
								<input
									type='text'
									className='form-controller-input'
									name='email'
									data-type='userDetails'
									disabled
									value={myDetails && myDetails.email ? myDetails.email : ""}
								/>
							) : <Skeleton height={35} />}
						</div>
					</Row>
					<Row className='user-details-container-row user-details-container-row-msite p-0 user-details-container-row-2'>
						<div className='user-details-container-col user-details-container-col-msite user-details-container-col-email'>
							<label className='profile-lable-text'>
								Business Unit
							</label>
							{!isLoadingData ? (
								<input
									type='text'
									className='form-controller-input'
									name='email'
									data-type='userDetails'
									disabled
									value={
										myDetails && myDetails.business_unit
											? myDetails.business_unit
											: ""
									}
								/>
							) : <Skeleton height={35} />}
						</div>
						<div className='user-details-container-col user-details-container-col-msite user-details-container-col-email'>
							<label className='profile-lable-text'>
								Entity
							</label>
							{!isLoadingData ? (
								<input
									type='text'
									className='form-controller-input'
									name='email'
									data-type='userDetails'
									disabled
									value={
										myDetails && myDetails.legal_unit
											? myDetails.legal_unit
											: ""
									}
								/>
							) : <Skeleton height={35} />}
						</div>
						<div className='user-details-container-col user-details-container-col-msite user-details-container-col-email'>
							<label className='profile-lable-text'>
								Department
							</label>
							{!isLoadingData ? (
								<input
									type='text'
									className='form-controller-input'
									name='email'
									data-type='userDetails'
									disabled
									value={
										myDetails && myDetails.department
											? myDetails.department
											: ""
									}
								/>
							) : <Skeleton height={35} />}
						</div>
						<div className='user-details-container-col user-details-container-col-msite user-details-container-col-email'>
							<label className='profile-lable-text'>
								User Level
							</label>
							{!isLoadingData ? (
								<input
									type='text'
									className='form-controller-input'
									name='email'
									data-type='userDetails'
									disabled
									value={myDetails && myDetails.level ? myDetails.level : ""}
								/>
							) : <Skeleton height={35} />}
						</div>
					</Row>
				</div>
				{myDetails && myDetails.approvers.length > 0 ? (
					<React.Fragment>
						<hr />
						<div className='approvers-details-container profile-setting-approvers profile-setting-approvers-msite profile-details-container-msite'>
							<h5 className='container-heading'>Approvers</h5>
							<Row className='approvers-details-container-row'>
								{myDetails &&
									myDetails.approvers?.map((approver, index) => {
										return (
											!isLoadingData
												? (
													<div
														key={index}
														className='approvers-details-container-col approvers-details-container-col-msite'
													>
														<label className='profile-lable-text'>
															Approver #{approver.seq}
														</label>
														<input
															type='email'
															disabled
															className='form-controller-input'
															value={approver.approver_email}
														/>
													</div>
												) : (
													<div
														key={index}
														className='approvers-details-container-col approvers-details-container-col-msite'
														style={{ width: "16rem" }}
													>
														<Skeleton height={35} />
													</div>
												)
										);
									})}
							</Row>
						</div>
					</React.Fragment>
				) : null}

				{myDetails && myDetails.custom_attribute.length > 0 ? (
					<React.Fragment>
						<hr />
						<div className='attributes-container profile-attributes-container attributes-container-msite'>
							<h5 className='container-heading'>
								Custom Attributes
							</h5>
							<Row className='attributes-container-row attributes-container-row-msite'>
								{myDetails.custom_attribute?.map((attr, key) => {
									return (
										!isLoadingData
											? (
												<div
													key={key}
													className='approvers-details-container-col approvers-details-container-col-msite'
												>
													<label className='profile-lable-text'>
														{attr.name}
													</label>
													<input
														type='text'
														className='form-controller-input'
														value={attr.value}
														disabled
													/>
												</div>
											) : (
												<div
													key={key}
													className='approvers-details-container-col approvers-details-container-col-msite'
												>
													<Skeleton height={35} />
												</div>
											)
									);
								})}
							</Row>
						</div>
					</React.Fragment>
				) : null}
			</div>
		</div>
	);
}

export default ProfileSettings;
