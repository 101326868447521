const LocalStorageService = (function () {
    var _service
    function _getService() {
        if (!_service) {
            _service = this
            return _service
        }
        return _service
    }
    function _setToken(token) {
        window.localStorage.removeItem('ilexp_access_token')
        localStorage.setItem('ilexp_access_token', token)
    }
    function _getAccessToken() {
        if (localStorage.getItem('ilexp_access_token')) {
            return localStorage.getItem('ilexp_access_token')
        } else {
            return false
        }
    }
    function _getUUMAccessTOken() {
        if (localStorage.getItem('uui_il_access_token')) {
            return localStorage.getItem('uui_il_access_token')
        } else {
            return false
        }
    }
    function _clearToken() {
        localStorage.removeItem('ilexp_access_token')
    }
    function _clearClientToken() {
        localStorage.removeItem('ilexp_client_token')
    }
    function _setFlowToken(token) {
        window.localStorage.removeItem('ilexp_flow_token')
        localStorage.setItem('ilexp_flow_token', token)
    }
    function _getFlowToken() {
        if (localStorage.getItem('ilexp_flow_token')) {
            return localStorage.getItem('ilexp_flow_token')
        } else {
            return false
        }
    }
    function _clearFlowToken() {
        localStorage.removeItem('ilexp_flow_token')
    }

    function _setSubscriptionToken(token) {
        window.localStorage.removeItem('ilexp_subscription_token')
        localStorage.setItem('ilexp_subscription_token', token)
    }
    function _getSubscriptionToken() {
        if (localStorage.getItem('ilexp_subscription_token')) {
            return localStorage.getItem('ilexp_subscription_token')
        } else {
            return false
        }
    }
    function _clearSubscriptionToken() {
        localStorage.removeItem('ilexp_subscription_token')
    }
    function _clearAllToken() {
        localStorage.clear()
    }
    function _showTrailHeader(val) {
        window.localStorage.removeItem('trailHeader')
        localStorage.setItem('trailHeader', val)
    }
    function _getShowHeaderTrail() {
        if (localStorage.getItem('trailHeader')) {
            return localStorage.getItem('trailHeader')
        } else {
            return false
        }
    }
    function _closeTrailHeader() {
        localStorage.removeItem('trailHeader')
    }
    return {
        getService: _getService,
        setToken: _setToken,
        getAccessToken: _getAccessToken,
        clearToken: _clearToken,
        clearClientToken: _clearClientToken,
        setFlowToken: _setFlowToken,
        getFlowToken: _getFlowToken,
        clearFlowToken: _clearFlowToken,
        setSubscriptionToken: _setSubscriptionToken,
        getSubscriptionToken: _getSubscriptionToken,
        clearSubscriptionToken: _clearSubscriptionToken,
        clearAllToken: _clearAllToken,
        showTrailHeader: _showTrailHeader,
        getShowHeaderTrail: _getShowHeaderTrail,
        closeTrailHeader: _closeTrailHeader,
        getUUMToken: _getUUMAccessTOken
    }
})()

export default LocalStorageService
