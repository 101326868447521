import createReducer from '../../../../../components/common/Utility';

import {
	FETCH_ALL_CATEGORIES,
	SET_ALL_CATEGORIES,
	DELETE_CATEGORY,
	SET_DELETED_CATEGORY,
	UPDATE_CATEGORY,
	SET_UPDATE_CATEGORY,
	SET_SWITCH_HANDLER,
	RESET_CATEGORY_LIST
} from '../../../../actions/Admin/Settings/Categories/Categories.action.type';

import {
	// categories
	FETCH_ALL_DATA,
} from '../../../../actions/Admin/Settings/Categories/CreateNewCategory/CreateNewCategory.action.type';

import { API_START, API_END, RESET_STATE } from '../../.././../actions/Common/Api.types';

export const initialState = {
	categoryList: {},
	isLoadingData: false,
	deleteCategoryResponse: null,
	updateCategoryResponse: null,
	fetchingAllCategories: false,
	setSwitchHandler:null
}

export const reducerObj = {
	[SET_ALL_CATEGORIES]: (prevState, payload) => (
		{
			categoryList: payload,
			fetchingAllCategories: false,
		}
	),

	[SET_DELETED_CATEGORY]: (prevState, payload) => (
		{
			deleteCategoryResponse: payload
		}
	),

	[SET_UPDATE_CATEGORY]: (prevState, payload) => (
		{
			updateCategoryResponse: payload
		}
	),
   [SET_SWITCH_HANDLER]:(prevState,payload)=>(
	{
		categoryList:payload

	}
   ),
   [RESET_CATEGORY_LIST]:(prevState,payload)=>(
	 {
	    categoryList:payload 
     }
	),
	[RESET_STATE]: () => (
		{
			deleteCategoryResponse: null,
			updateCategoryResponse: null
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === FETCH_ALL_DATA) {
			return (
				{
					isLoadingData: true,
					fetchingAllCategories: true,
				}
			)
		}
		if (payload === DELETE_CATEGORY || payload === UPDATE_CATEGORY || payload === FETCH_ALL_CATEGORIES) {
			return (
				{
					isLoadingData: true
				}
			)
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === SET_ALL_CATEGORIES) {
			return (
				{
					isLoadingData: false,
					fetchingAllCategories: false,
				}
			)
		}
		if (payload === SET_DELETED_CATEGORY || payload === SET_UPDATE_CATEGORY) {
			return (
				{
					isLoadingData: false
				}
			)
		}
	},
}

let CategoriesReducer = createReducer(initialState, reducerObj);

export default CategoriesReducer;