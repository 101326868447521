const itemInList = (list, item) => {
    return list.some(i => i === item);
}
const handleCheck = (user_rp_details, access_type, reqdPerm, allow_staff=false) => {
    //scan for all other available roles
    if (reqdPerm === 'approver') {
        for (let role in user_rp_details) {
                if (itemInList(user_rp_details[role][access_type], 'approver')) {
                    return true
                }
                if (itemInList(user_rp_details[role][access_type], 'report_advance_approval')) {
                    return true
                }
        }
    }

    // we don't have dynamic permission for the general tab, so if user has the admin role then only show the general tab
    if(reqdPerm === 'General_setting'){
        return Object.hasOwnProperty.call(user_rp_details, 'super_admin')
    }
    for (let role in user_rp_details) {
        if (role !== 'employee' || allow_staff) {
            if (itemInList(user_rp_details[role][access_type], reqdPerm)) {
                return true
            }
        }

    }

    return false
}

const checkRoleAndPermissionForPage = (user_rp_details, current_page) => {
    let reqdPerm = '';
    let allow_staff = false;
    switch (current_page) {
        case '/approvals':
            reqdPerm = 'Reports'
            break;

        case '/individual/reports':
            reqdPerm = 'Reports'
            allow_staff = true;
            break;
        
        case '/individual/advances':
            reqdPerm = 'Advances'
            allow_staff = true;
            break;

        case '/advances':
            reqdPerm = 'Advances'
            break;

        case '/payout':
            reqdPerm = 'Payout'
            break;

        case '/settings':
            reqdPerm = 'Settings'
            break;

        case '/insights':
            reqdPerm = 'Analytics'
            break;

        case '/analytics':
            reqdPerm = 'Analytics'
            break;
        
        case '/settings/general':
            reqdPerm = 'General_setting'
            break;

        default:
            break;
    }

    return handleCheck(user_rp_details, 'read', reqdPerm, allow_staff);
}

const checkRoleAndPermissionForTab = (user_rp_details, current_page, current_tab) => {
    let reqdPerm = '';
    let reqdWriteFlag = 0;
    let reqdApprovalAccess = 0;
    switch (current_page) {
        case '/approvals':
            reqdPerm = 'Reports'
            break;

        case '/individual/approvals':
            return handleCheck(user_rp_details, 'write', 'approver')

        case '/advances':
            reqdPerm = 'Advances'
            break;

        case '/settings':
            reqdPerm = 'Settings'
            break;

        case '/payout':
            reqdPerm = 'Payout'
            break;

        case '/insights':
            reqdPerm = 'Analytics'
            break;

        case '/analytics':
            reqdPerm = 'Analytics'
            break;

        default:
            break;
    }

    switch (current_tab) {
        case '#pending':
        case '#pending-approvals':
            reqdWriteFlag = 1
            reqdApprovalAccess = 1
            break;
        case '#approvals-histroy':
            reqdWriteFlag = 1
            reqdApprovalAccess = 1
            break;

        case '#reports':
        case '#payment':
        case '#processing':
            reqdWriteFlag = 1
            break;

        default:
            break;
    }

    if (reqdWriteFlag && reqdApprovalAccess) {
        return (handleCheck(user_rp_details, 'write', reqdPerm) && handleCheck(user_rp_details, 'write', 'approver'));
    }
    else if (reqdWriteFlag) {
        return handleCheck(user_rp_details, 'write', reqdPerm);
    }
    return true
}

export { checkRoleAndPermissionForPage, checkRoleAndPermissionForTab };