import createReducer from "../../../../../components/common/Utility";
import { APPEND_TEMPLATE, CREATE_TEMPLATE, DELETE_TEMPLATE, GET_FIELDS_LIST, GET_TEMPLATE_LIST, SET_CREATE_TEMPLATE, SET_DELETE_TEMPLATE, SET_FIELDS_LIST, SET_TEMPLATE_LIST,RESET_APPEND_TEMPLATE, SET_TEMPLATE_DETAILS, GET_TEMPLATE_DETAILS, RESET_DELETE_TEMPLATE, SET_EDIT_TEMPLATE, EDIT_TEMPLATE, UPDATE_TEMPLATE, RESET_UPDATE_TEMPLATE, SET_PREVIEW_TABLE_DATA, GET_PREVIEW_TABLE_DATA, RESET_PREVIEW_TABLE_DATA } from "../../../../actions/Admin/Settings/ExportTemplates/ExportTemplates.action.types";
import { deleteTemplate } from "../../../../actions/Admin/Settings/ExportTemplates/ExportTemplates.action";
import { API_END, API_ERROR, API_START, RESET_STATE } from "../../../../actions/Common/Api.types";
// import uuid from 'uuid';
import { nanoid } from 'nanoid';

const initialState = {
    templateList: null,
    deleteTemplate: null,
    fieldsList: null,
    deleteTemplateResponse: null,
    createTemplateResponse: null,
    editTemplateResponse: null,
    templateDetails: null,
    previewTableData: null,
    isLoading: {
        templateList: null,
        deleteTemplate: null,
        fieldsList: null,
        createTemplate: null,
        templateDetails: null,
        editTemplate: null,
        previewTableData: null,
    },
    makeAPICall: {
        templateList: true
    }
}

export const reducerObj = {
    [SET_TEMPLATE_LIST]: (prevState, payload) => {
        return {
            templateList: payload,
            isLoading: {
                templateList: false
            },
            makeAPICall: {
                ...prevState.makeAPICall,
                templateList: false
            }
        }
    },
    [SET_DELETE_TEMPLATE]: (prevState,payload) => {
        let filteredTemplateList = prevState.templateList.data.filter(template => template.id != payload.template_id)
        return {
            deleteTemplateResponse:payload,
            templateList:{...prevState.templateList,data:filteredTemplateList}
        }
    },
    [SET_FIELDS_LIST]: (prevState, payload) => {
        let {expense_fields, report_fields, user_fields} = payload;
        let finalFieldsObj = {};
        let expenseFieldsList = {static_fields: [], custom_fields: []};
        let reportFieldsList =  {static_fields: [], custom_fields: []};
        let userFieldsList = {static_fields: [], custom_fields: []};
        
        expense_fields.static_fields && expense_fields.static_fields.forEach(field => {
            expenseFieldsList.static_fields.push(
                {
                    ...field,
                    id: 'expense_fields_static'
                }
            )
        })

        expense_fields.custom_fields && expense_fields.custom_fields.forEach(field => {
            expenseFieldsList.custom_fields.push(
                {
                    ...field,
                    id: 'expense_fields_custom'
                }
            )
        })
        
        report_fields.static_fields && report_fields.static_fields.forEach(field => {
            reportFieldsList.static_fields.push(
                {
                    ...field,
                    id: 'report_fields_static'
                }
            )
        })

        report_fields.custom_fields && report_fields.custom_fields.forEach(field => {
            reportFieldsList.custom_fields.push(
                {
                    ...field,
                    id: 'report_fields_custom'
                }
            )
        })

        user_fields.static_fields && user_fields.static_fields.forEach(field => {
            userFieldsList.static_fields.push(
                {
                    ...field,
                    id: 'user_fields_static'
                }
            )
        })

        user_fields.custom_fields && user_fields.custom_fields.forEach(field => {
            userFieldsList.custom_fields.push(
                {
                    ...field,
                    id: 'user_fields_custom'
                }
            )
        })

       finalFieldsObj = {expense_fields: {...expenseFieldsList}, report_fields: {...reportFieldsList}, user_fields: {...userFieldsList}}
        
       return {
            fieldsList: finalFieldsObj,
            isLoading: {
                fieldsList: false
            }
        }
    },
    [SET_PREVIEW_TABLE_DATA]: (prevState, payload) => {
        return {
            previewTableData: payload,
            isLoading:{
                previewTableData: false
            }
        }
    },
    [SET_CREATE_TEMPLATE]: (prevState, payload) => {
        return {
            createTemplateResponse: payload,
            isLoading:{
                createTemplate: false
            }
        }
    },
    [APPEND_TEMPLATE]: (prevState, payload) => {
        return {
            templateList:{
                ...prevState.templateList,
                data: [...prevState.templateList.data, payload]    
            },
            makeAPICall: {
                ...prevState.makeAPICall,
                templateList: false
            } 
        }
    },
    [UPDATE_TEMPLATE]: (prevState, payload) => {
        let templateList = [];
        prevState.templateList.data.forEach(template => {
            if(template.id === Number(payload.id)){
                templateList.push(payload)
            }
            else templateList.push(template)
        })
        return {
            templateList:{
                ...prevState.templateList,
                data: templateList  
            },
            makeAPICall: {
                ...prevState.makeAPICall,
                templateList: false
            } 
        }
    },
    [SET_TEMPLATE_DETAILS]: (prevState, payload) => {
        let finalFieldsObj = {};
        let expenseFieldsList = {static_fields: [], custom_fields: []};
        let reportFieldsList =  {static_fields: [], custom_fields: []};
        let userFieldsList = {static_fields: [], custom_fields: []};
        let dummyFieldsList = [];
        let {report_fields, expense_fields, user_fields,dummy_fields}= payload.data.fields

        if(expense_fields){
            expense_fields.static_fields && expense_fields.static_fields.forEach(field => {
                expenseFieldsList.static_fields.push(
                    {
                        ...field,
                        id: 'expense_fields_static',
                        checked:true
                    }
                )
            })
    
            expense_fields.custom_fields && expense_fields.custom_fields.forEach(field => {
                expenseFieldsList.custom_fields.push(
                    {
                        ...field,
                        id: 'expense_fields_custom',
                        checked:true
                    }
                )
            })
        }
        
        if(report_fields){
            report_fields.static_fields && report_fields.static_fields.forEach(field => {
                reportFieldsList.static_fields.push(
                    {
                        ...field,
                        id: 'report_fields_static',
                        checked:true
                    }
                )
            })
    
            report_fields.custom_fields && report_fields.custom_fields.forEach(field => {
                reportFieldsList.custom_fields.push(
                    {
                        ...field,
                        id: 'report_fields_custom',
                        checked:true
                    }
                )
            })
        }

        if(dummy_fields){
            dummy_fields.forEach(field => {
                dummyFieldsList.push(
                    {
                        ...field,
                        checked:true,
                        // id: uuid(),
                        id: nanoid(),
                        fieldType: "uuid"
                    }
                )
            })
        }

        user_fields.static_fields && user_fields.static_fields.forEach(field => {
            userFieldsList.static_fields.push(
                {
                    ...field,
                    id: 'user_fields_static',
                    checked:true,
                    isUserField: true
                }
            )
        })

        user_fields.custom_fields && user_fields.custom_fields.forEach(field => {
            userFieldsList.custom_fields.push(
                {
                    ...field,
                    id: 'user_fields_custom',
                    checked:true,
                    isUserField: true
                }
            )
        })

        if(expense_fields){
            finalFieldsObj = {expense_fields: {...expenseFieldsList}, user_fields: {...userFieldsList}, dummy_fields: dummyFieldsList}
        }
        if(report_fields){
            finalFieldsObj = { report_fields: {...reportFieldsList},user_fields: {...userFieldsList}, dummy_fields: dummyFieldsList}
        }
        return {
            templateDetails: {...payload.data,fields: finalFieldsObj },
            isLoading: {
                templateDetails: false
            }
        }
    },
    [SET_EDIT_TEMPLATE]: (prevState, payload) => {
        return {
            editTemplateResponse: payload,
            isLoading:{
                editTemplate: false
            }
        }
    },
    [RESET_APPEND_TEMPLATE]: () => {
        return {
            createTemplateResponse: null,
            editTemplateResponse: null
        }
    },
    [RESET_UPDATE_TEMPLATE]: () => {
        return {
            editTemplateResponse: null
        }
    },
    [RESET_DELETE_TEMPLATE]: () => {
        return {
            deleteTemplateResponse: null
        }
    },
    [RESET_PREVIEW_TABLE_DATA]: () => {
        return {
            previewTableData: null
        }
    },
    [RESET_STATE]:()=>(        
        {
            deleteTemplateResponse:null 
        }  
    ),
    [API_START]: (prevState, payload) => {
        if(payload === GET_TEMPLATE_LIST){
            return {
                isLoading:{
                ...prevState.isLoading,
                templateList: true
                }
            }
        }
        if(payload === DELETE_TEMPLATE){
            return {
                isLoading:{
                ...prevState.isLoading,
                deleteTemplate: true
                }
            }
        }
        if(payload === GET_FIELDS_LIST){
            return {
                isLoading:{
                ...prevState.isLoading,
                fieldsList: true
                }
            }
        }
        if(payload === CREATE_TEMPLATE){
            return {
                isLoading:{
                ...prevState.isLoading,
                createTemplate: true
                }
            }
        }
        if(payload === EDIT_TEMPLATE){
            return {
                isLoading:{
                ...prevState.isLoading,
                editTemplate: true
                }
            }
        }
        if(payload === GET_TEMPLATE_DETAILS){
            return {
                isLoading:{
                    ...prevState.isLoading,
                    templateDetails: true
                }
            }
        }
        if(payload === GET_PREVIEW_TABLE_DATA){
            return {
                isLoading:{
                    ...prevState.isLoading,
                    previewTableData: true
                }
            }
        }
    },
    [API_END]: (prevState, payload) => {
        if(payload === SET_TEMPLATE_LIST){
            return {
                isLoading:{
                    ...prevState.isLoading,
                    templateList: false
                }
            }
        }
        if(payload === SET_DELETE_TEMPLATE){
            return {
                isLoading:{
                    ...prevState.isLoading,
                    deleteTemplate: false
                }
            }
        }
        if(payload === SET_FIELDS_LIST){
            return {
                isLoading:{
                    ...prevState.isLoading,
                    fieldsList: false
                }
            }
        }
        if(payload === SET_CREATE_TEMPLATE){
            return {
                isLoading:{
                    ...prevState.isLoading,
                    createTemplate: false
                }
            }
        }
        if(payload === SET_EDIT_TEMPLATE){
            return {
                isLoading:{
                    ...prevState.isLoading,
                    editTemplate: false
                }
            }
        }
        if(payload === SET_TEMPLATE_DETAILS){
            return {
                isLoading:{
                    ...prevState.isLoading,
                    templateDetails: false
                }
            }
        }
        if(payload === SET_PREVIEW_TABLE_DATA){
            return {
                isLoading:{
                    ...prevState.isLoading,
                    previewTableData: false
                }
            }
        }
    },
    [API_ERROR]: (prevState, payload) => {
		if (payload.label === CREATE_TEMPLATE) {
			return {
                createTemplateResponse: payload.data,
                isLoading:{
                    ...prevState.isLoading,
                    createTemplate: false
                }
			}
		}
        if (payload.label === EDIT_TEMPLATE) {
			return {
                editTemplateResponse: payload.data,
                isLoading:{
                    ...prevState.isLoading,
                    editTemplate: false
                }
			}
		}
        if (payload.label === GET_FIELDS_LIST) {
			return {
                previewTableData: payload.data,
			}
		}
	}
}

let ExportTemplatesReducer = createReducer(initialState , reducerObj);

export default ExportTemplatesReducer;