import createReducer from '../../../../components/common/Utility';

import {
    SET_MY_DETAILS,
    GET_MY_DETAILS
} from "../../../actions/Common/MyDetails/MyDetails.action.type";

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
    isLoading: false,
    myDetails: null
}

export const reducerObj = {
    [SET_MY_DETAILS]: (prevState, payload) => (
        {
            isLoading: false,
            myDetails: payload.data
        }
    ),

    // [RESET_STATE]: () => (
    //     {
    //         isLoading: false,
    //         myDetails: null
    //     }
    // ),

    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === GET_MY_DETAILS) {
            return {
                isLoading: true
            }
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === SET_MY_DETAILS) {
            return {
                isLoading: false
            }
        }
    },
}

let MyDetailsReducer = createReducer(initialState, reducerObj);

export default MyDetailsReducer;