import createReducer from "../../../../components/common/Utility"

import { EDIT_CATEGORY_PERDIEM,SET_EDITED_CATEGORY_PERDIEM,FETCH_CALC_ATTR, SET_EMPLOYEE_GROUP_DETAILS, FETCH_EMPLOYEE_GROUP_DETAILS, SET_PERDIEM_NORMAL_RATE_RESPONSE, CREATE_PERDIEM_NORMAL_RATE,SET_DELETE_PER_DIEM_RATE, SET_PERDIEM_TRAVEL_DURATION, SET_DELETE_PER_DIEM_TRAVEL_DURATION,SET_SAVE_PER_DIEM_TRAVEL_DURATION,SAVE_PER_DIEM_TRAVEL_DURATION,GET_PERDIEM_TRAVEL_DURATION, SET_PERDIEM_DEFAULT_RATE_RESPONSE, CREATE_PERDIEM_DEFAULT_RATE, SET_PERDIEM_RATE_DETAILS, FETCH_PERDIEM_RATE_DETAILS, CLEAR_FETCHED_INDIVIDUAL_RATE_DETAIL, DELETE_PER_DIEM, SET_DELETE_PER_DIEM_RESPONSE, SET_SEARCHED_RATES_DATA, SEARCH_RATES} from "../../../actions/Admin/Settings/PerDiemAdmin/PerDiem.action.type"

 import { SET_PERDIEM_CONFIG ,SET_PERDIEM_ENABLE_DISABLE_DETAILS, SET_PERDIEM_INITIAL_DETAILS,GET_PERDIEM_ENABLE_DISABLE_DETAILS,SET_PERDIEM_RATE_DATA,SET_CHANGE_RATE_CONFIG_STATUS,SET_RATE_RULE_PRIORITY_ORDER} from "../../../actions/Admin/Settings/PerDiemAdmin/PerDiem.action.type"

// import { FETCH_CAL_ATTRI_PERDIEM, GET_PERDIEM_ATTRIBUTES,SET_PERDIEM_ATTRIBUTES } from "../../../actions/Personal/PerDiem/PerDiem.action.type"
import { API_END, API_ERROR, API_START, RESET_STATE } from "../../../actions/Common/Api.types"
import { CREATE_PERDIEM, EDIT_PER_DIEM, FETCH_CAL_ATTRI_PERDIEM, FETCH_EXCHANGE_RATE, FETCH_PERDIEM_SUMMARY, GET_PERDIEM_ATTRIBUTES, GET_PERDIEM_CONFIG,GET_PER_DIEM_DETAILS,RESET_COMMON_ERROR_RESPONSE,SET_CAL_ATTRI_PERDIEM,SET_CREATE_PERDIEM_RESPONSE,SET_EDIT_PER_DIEM_RESPONSE,SET_EXCHANGE_RATE_DATA,SET_PERDIEM_ATTRIBUTES, SET_PERDIEM_SUMMARY, SET_PER_DIEM_DETAILS } from "../../../actions/Personal/PerDiem/PerDiem.action.type"

const initialState = {
       isLoadingData: false,
       commonErrorResponse: null,
       isLoadingState: {
            defaultRate: false,
            normalRate: false,
            employeeGroup: false,
            summary: false,
            individualPerDiemRateFetching: false,
            employeeGroupDetails: false,
            exRate: false,
            rateSearch: false
        },

    // user side
  
        perDiemAttributes: null,
        createPerDiemResponse: null,
        calculatedAttributesForPerDiem: null,
        individualPerDiemDetails: null,
        editPerDiemResponse: null,
        deletePerDiemResponse: null,
        summaryResponse: null,
        exRateData: null,


    // admin side
        perDiemConfigResponse:null,
        perDiemAdminInitialData:null,
        perDiemClientEnableDisableData: null,
        EditCategoryPerDiemAdminResponse:null,
        PerDiemRateListData:null,
        deletePerDiemRateResponse:null,
        PerDiemratelistStatus:null,
        perDiemRatepriorityOrderres:null,

        // perdiem rates
        employeeGroupDetails: null,
        editDefaultRateResponse: null,
        editNormalRateResponse: null,
        defaultRateSaveResponse: null,
        normalRateSaveResponse: null,
        individualPerDiemRateDetail: null,
        searchedRatesData: null,


        // Travel duration
        perDiemTravelDurationData:null,
        SavePerDiemTravelDuration:null
  
}

const reducerObject = {

    // USER SIDE

    [SET_PERDIEM_ATTRIBUTES]: (prevState, payload) => (
        {
            perDiemAttributes: payload
        }
    ),
    [SET_CREATE_PERDIEM_RESPONSE]: (prevState, payload) => (
        {
            createPerDiemResponse: payload
        }
    ),
    [SET_PERDIEM_INITIAL_DETAILS]: (prevState , payload) => (
        {
            perDiemAdminInitialData: payload
        }
    ),
    
    [SET_PERDIEM_ENABLE_DISABLE_DETAILS]: (prevState , payload) => (
        {
            perDiemClientEnableDisableData: payload
        }
    ),

    [SET_EDITED_CATEGORY_PERDIEM]: (prevState , payload) => (
        {
            EditCategoryPerDiemAdminResponse: payload
        }
    ),
       
    [SET_CAL_ATTRI_PERDIEM]: (prevState , payload) => (
        {
            calculatedAttributesForPerDiem: payload
        }
    ),
    [SET_PER_DIEM_DETAILS]: (prevState , payload) => (
        {
            individualPerDiemDetails: payload
        }
    ),
    [SET_EDIT_PER_DIEM_RESPONSE]: (prevState , payload) => (
        {
            editPerDiemResponse: payload
        }
    ),
    [SET_DELETE_PER_DIEM_RESPONSE]: (prevState , payload) => (
        {
            deletePerDiemResponse: payload
        }
    ),
    // ADMIN SIDE
    [SET_PERDIEM_CONFIG]: (prevState , payload) => (
        {
           perDiemConfigResponse: payload
        }
    ),
    [SET_PERDIEM_RATE_DATA]: (prevState , payload) => (
        {
            PerDiemRateListData: payload
        }
    ),
    [SET_EMPLOYEE_GROUP_DETAILS]: (prevState , payload) => (
        {
            employeeGroupDetails: payload
        }
    ),
    [SET_PERDIEM_NORMAL_RATE_RESPONSE]: (prevState , payload) => (
        {
            normalRateSaveResponse: payload
        }
    ),
    [SET_DELETE_PER_DIEM_RATE]: (prevState , payload) => (
        {
            deletePerDiemRateResponse: payload
        }
    ),
    [SET_CHANGE_RATE_CONFIG_STATUS]: (prevState , payload) => (
        {
            PerDiemratelistStatus: payload
        }
    ),
    [SET_RATE_RULE_PRIORITY_ORDER]: (prevState , payload) => (
        {
            perDiemRatepriorityOrderres: payload
        }
    ),
    [SET_PERDIEM_SUMMARY]: (prevState , payload) => (
        {
            summaryResponse: payload
        }
    ),
    [SET_PERDIEM_DEFAULT_RATE_RESPONSE]: (prevState, payload) => (
        {
            defaultRateSaveResponse: payload
        }
    ),
    [SET_PERDIEM_RATE_DETAILS]: (prevState, payload) => (
        {
            individualPerDiemRateDetail: payload
        }
    ),
    [SET_EXCHANGE_RATE_DATA]: (prevState, payload) => (
        {
            exRateData: payload
        }
    ),
    [SET_SEARCHED_RATES_DATA]: (prevState, payload) => (
        {
            searchedRatesData: payload
        }
    ),

    // TRAVEL
    [SET_PERDIEM_TRAVEL_DURATION]: (prevState , payload) => (
        {
            perDiemTravelDurationData: payload
        }
    ),
    [SET_DELETE_PER_DIEM_TRAVEL_DURATION]: (prevState , payload) => (
        {
            deletePerDiemTravelDuration: payload
        }
    ),
    [SET_SAVE_PER_DIEM_TRAVEL_DURATION]: (prevState , payload) => (
        {
            SavePerDiemTravelDuration: payload
        }
    ),
    [CLEAR_FETCHED_INDIVIDUAL_RATE_DETAIL]: (prevState , payload) => (
        {
            individualPerDiemRateDetail: null
        }
    ),

    // [FETCH_EMPLOYEE_GROUP_DETAILS]: (prevState, payload) => (
    //     { 
    //         isLoadingData: true,
    //         isLoadingState: {
    //             employeeGroupDetails: true
    //         } 
    //     } 
    // ) ,
    [RESET_STATE]: (prevState , payload) => (
        {
            isLoadingData: false,
            calculatedAttributesForMileage: null,
            EditCategoryPerDiemAdminResponse:null,
            perDiemClientEnableDisableData:null,
            calculatedAttributesForPerDiem: null,
            perDiemAttributes: null,
            createPerDiemResponse: null,
            individualPerDiemDetails: null,
            editPerDiemResponse: null,
            commonErrorResponse: null,
            deletePerDiemResponse: null,
            
           
            editDefaultRateResponse: null,
            editNormalRateResponse: null,
            defaultRateSaveResponse: null,
            normalRateSaveResponse: null,
            employeeGroupDetails: null,
            PerDiemRateListData:null,
            perDiemRatepriorityOrderres:null,
            deletePerDiemRateResponse:null,
            perDiemTravelDurationData:null,
            deletePerDiemTravelDuration:null,
            SavePerDiemTravelDuration:null,

            isLoadingState: {
                employeeGroupDetails: false,
                normalRate: false,
                employeeGroup: false,
                summary: false,
                individualPerDiemRateFetching: false,
                defaultRate: false,
                exRate: false,
                rateSearch: false
             },
            summaryResponse: null,
            individualPerDiemRateDetail: null,
            individualPerDiemRateDetailResponse: null,
            exRateData: null,
            searchedRatesData:null,
            PerDiemratelistStatus: null
            

        }
    ),

      // ------------- Api end and start loading state ----------

      [API_START]: (prevState, payload) => {
        //console.log(payload)
        if(payload === GET_PERDIEM_ATTRIBUTES || payload === CREATE_PERDIEM || GET_PER_DIEM_DETAILS || EDIT_PER_DIEM ||GET_PERDIEM_ENABLE_DISABLE_DETAILS
            || FETCH_EMPLOYEE_GROUP_DETAILS || DELETE_PER_DIEM)
        {
            return { 
                     isLoadingData: true 
                   }
        }
        if(payload === FETCH_PERDIEM_RATE_DETAILS)
        {
            return {
                isLoadingState: {
                    individualPerDiemRateFetching: true
                }
            }
        }
        if(payload === FETCH_EMPLOYEE_GROUP_DETAILS)
        {
            return {
                isLoadingState: {
                    employeeGroupDetails: true
                }
            }
        }
        if(payload === FETCH_PERDIEM_SUMMARY)
        {
            return {
                isLoadingState: {
                    ...prevState.isLoadingState,
                    summary: true
                }
            }
        }
        if(payload === FETCH_EXCHANGE_RATE)
        {
            return {
                isLoadingState: {
                    ...prevState.isLoadingState,
                    exRate: true
                }
            }
        }
        
	},
    [API_START]: (prevState, payload) => {

        
        
        if(payload === FETCH_EMPLOYEE_GROUP_DETAILS)
        {
            
          return  { 
                isLoadingData: true,
                isLoadingState: {
                    ...prevState.isLoadingState,
                    employeeGroupDetails: true

                } 
            }
        }
        if(payload === FETCH_PERDIEM_RATE_DETAILS)
        {
            return {
                isLoadingData: true,
                isLoadingState: {
                    ...prevState.isLoadingState,
                    individualPerDiemRateFetching: true
                }
            }
        }
        else if(payload === CREATE_PERDIEM_NORMAL_RATE)
        {
            return  { 
                isLoadingData: true,
                isLoadingState: {
                    ...prevState.isLoadingState,
                    normalRate: true

                } 
            }
        }
        else if(payload === CREATE_PERDIEM_DEFAULT_RATE)
        {
            return  { 
                isLoadingData: true,
                isLoadingState: {
                    ...prevState.isLoadingState,
                    default: true

                } 
            }
        }
        else if(payload === FETCH_EXCHANGE_RATE)
        {
            return {
                isLoadingData: true,
                isLoadingState: {
                    ...prevState.isLoadingState,
                    exRate: true
                }
            }
        }
        else if(payload === SEARCH_RATES)
        {
            return {
                isLoadingData: true,
                isLoadingState: {
                    ...prevState.isLoadingState,
                    rateSearch: true
                }
            }
        }
        else if(payload === FETCH_PERDIEM_SUMMARY)
        {
            return {
                isLoadingState: {
                    ...prevState.isLoadingState,
                    summary: true
                }
            }
        }
        else if(payload === GET_PERDIEM_ATTRIBUTES || payload === CREATE_PERDIEM || GET_PER_DIEM_DETAILS || EDIT_PER_DIEM ||GET_PERDIEM_ENABLE_DISABLE_DETAILS
            || FETCH_EMPLOYEE_GROUP_DETAILS ||SAVE_PER_DIEM_TRAVEL_DURATION||GET_PERDIEM_TRAVEL_DURATION)
        {
            return { 
                     isLoadingData: true 
                   }
        }
        
    },
    [API_END]: (prevState, payload) => (
      
			{ 
                isLoadingData: false,
                isLoadingState: {
                    defaultRate: false,
                    normalRate: false,
                    employeeGroup: false,
                    summary: false,
                    individualPerDiemRateFetching: false,
                    employeeGroupDetails: false,
                    exRate: false,
                    rateSearch: false    
                } 
            }
	),
    [API_ERROR]: (prevState, payload) => {
        if(payload.data.message==='No rate found. Ask admin to set up per diem rates.'){
            payload.data.message=null;
            return { commonErrorResponse: payload}
        }
			else{
                return { commonErrorResponse: payload}
            }
        },
    [RESET_COMMON_ERROR_RESPONSE]: (prevState, payload) => (
			{ commonErrorResponse: null}
	),

  
}

const PerDiemReducer = createReducer(initialState, reducerObject)

export default PerDiemReducer;