export const FETCH_ALL_ADVANCES = "fetchAllAdvances";
export const SET_ALL_ADVANCES = "setAllAdvances";

export const FETCH_DRAFT_ADVANCES = "fetchDraftAdvances";
export const SET_DRAFT_ADVANCES = "setDraftAdvances";

export const CREATE_DRAFT_ADVANCES = "createDraftAdvances";
export const SET_CREATE_DRAFT_ADVANCES = "setCreateDraftAdvances";

export const EDIT_DRAFT_ADVANCES = "editDraftAdvances";
export const SET_EDIT_DRAFT_ADVANCES = "setEditDraftAdvances";

export const DELETE_DRAFT_ADVANCES = "deleteDraftAdvances";
export const SET_DELETE_DRAFT_ADVANCES = "setDeleteDraftAdvances";

export const SUBMIT_DRAFT_ADVANCES = "submitDraftAdvances";
export const SET_SUBMIT_DRAFT_ADVANCES = "setSubmitDraftAdvances";