import { combineReducers } from 'redux'; // importing COMBINE REDUCERS for combining reducers.

// importing reducers
import ApprovalsReducer from '../reducers/Admin/Approvals/Approvals.reducer';
import ExpenseDetailsReducer from '../reducers/Admin/Reports/ExpenseDetails.reducer';
import ReportReducer from '../reducers/Admin/Reports/Reports.reducer';
import PayoutReducer from '../reducers/Admin/Payout/Payout.reducer';
import BulkUploadReducer from '../reducers/Admin/BulkUpload/BulkUpload.reducer';
import CorporateCardsAccountsListReducer from '../reducers/Admin/CorporateCards/CorporateCards.reducer'
import AdvancesReducer from '../reducers/Admin/Advances/Advances.reducer';
import DashboardReducer from '../reducers/Admin/OrgDashboard/Dashboard.reducer';
import RolesAndPermissionsReducer from '../reducers/Admin/RolesAndPermissions/RolesAndPermissions.reducer';
import currencyConversionReducer from '../reducers/Admin/CurrencyConversion/CurrencyConversion.reducer';
import CreateNewCategoryReducer from '../reducers/Admin/Settings/Categories/CreateNewCategory/CreateNewCategory.reducers';
import CategoriesReducer from '../reducers/Admin/Settings/Categories/Categories.reducer';
import PoliciesReducer from '../reducers/Admin/Settings/Policies/Policies.reducer';
import ApprovalFlowReducer from '../reducers/Admin/Settings/ApprovalFlow/ApprovalFlow.reducer';
import UsersReducer from '../reducers/Admin/Settings/Users/Users.reducer';
import RolesReducer from '../reducers/Admin/Settings/Roles/Roles.reducer';
import ReportDownloadReducer from '../reducers/Admin/ReportDownload/ReportDownload.reducer';
import DraftExpenseReducer from '../reducers/Personal/DraftExpense/DraftExpense.reducer';
import PersonalAdvancesReducer from '../reducers/Personal/Advances/Advances.reducer';
import PendingApprovalReducer from '../reducers/Personal/PendingApproval/PendingApproval.reducer'
import CreateNewReportReducer from '../reducers/Personal/DraftExpense/create/CreateNewReport.reducer';
import CardsReducer from "../reducers/Personal/Cards/Cards.reducer";
import TransactionReducer from "../reducers/Personal/Cards/Transactions/Transactions.reducer";
import AnalyticsReducer from "../reducers/Admin/Analytics/Analytics.reducer";
import MyDetailsReducer from "../reducers/Common/MyDetails/MyDetails.reducer";
import MileageReducer from './Personal/Mileage/Mileage.reducer';
import GettingStartedWithReducer from "../reducers/Getting Started/GettingStarted.reducer";
import AchReducer from '../reducers/Admin/Settings/ACH/Ach.Reducer';
import CodatReducer from  '../reducers/Admin/Settings/Codat/CodatReducer';
import ExportHistoryReducer from './Admin/ExportHistory/ExportHistory.reducer';
import ExportTemplatesReducer from './Admin/Settings/ExportTemplates/ExportTemplates.reducer';

import PerDiemReducer from './Personal/PerDiem/PerDiem.reducer';
import TandEReducer from './Admin/Settings/TandE/TandE.reducer';
import IntegrationsReducer from './Admin/Settings/Integrations/Integrations.reducer';
import NavbarReducer from '../reducers/Common/MyDetails/Navbar.reducer';
import NotificationsReducer from "./Admin/Settings/Notifications/Notifications.reducer"

// creating a combined reducer.
const Reducer = combineReducers({
	
	ApprovalsReducer,
	ExpenseDetailsReducer,
	ReportReducer,
	PayoutReducer,
	BulkUploadReducer,
	AdvancesReducer,
	DashboardReducer,
	RolesAndPermissionsReducer,
	currencyConversionReducer,
	CreateNewCategoryReducer,
	PoliciesReducer,
	ApprovalFlowReducer,
	UsersReducer,
	CategoriesReducer,
	RolesReducer,
	ReportDownloadReducer,
	DraftExpenseReducer,
	PersonalAdvancesReducer,
	PendingApprovalReducer,
	CreateNewReportReducer,
	CardsReducer,
	TransactionReducer,
	AnalyticsReducer,
	MyDetailsReducer,
	GettingStartedWithReducer,
	AchReducer,
    CodatReducer,
	MileageReducer,
	PerDiemReducer,
	ExportHistoryReducer,
	ExportTemplatesReducer,
	TandEReducer,
	NotificationsReducer,
	IntegrationsReducer,
	NavbarReducer,
	CorporateCardsAccountsListReducer,
});

export default Reducer; //exporting the REDUCER.