import React from 'react'

export const ItiliteBadgeCurve = React.memo((props) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='28'
            height='24'
            fill='none'
            viewBox='0 0 28 24'>
            <path
                fill='#FFE9CF'
                d='M28 0v24h-1.068c-3.3 0-6.465-1.839-8.8-5.112L8.313 5.112C5.978 1.84 3.3 0 0 0h28z'></path>
        </svg>
    )
})

ItiliteBadgeCurve.displayName = 'ItiliteBadgeCurve'
