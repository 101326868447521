// import createReducer from "../../../../components/common/Utility";
import createReducer from "../../../../components/common/Utility";

import { 
    GET_MILEAGE_BASICS,
    SET_MILEAGE_BASICS,
    CREATE_USER_MILEAGE,
    SET_CREATE_USER_MILEAGE,
    EDIT_USER_MILEAGE,
    SET_EDIT_USER_MILEAGE,
    GET_DISTANCE,
    SET_DISTANCE,
    SET_USER_MILEAGE,
    GET_USER_MILEAGE,
    SET_CAL_ATTRI,
    SET_TRAVEL_INFO
    } from "../../../actions/Personal/Mileage/Mileage.action.types";

import { API_START,API_END , RESET_STATE , API_ERROR } from "../../../actions/Common/Api.types";
import {
         SET_CREATE_MILEAGE_TYPE,
        SET_MILEAGE_TYPE_LIST,
        SET_CREATE_MILEAGE_CONFIG, 
        SET_MILEAGE_CONFIG, 
        SET_DELETE_MILEAGE_CONFIG, 
        SET_DELETE_MILEAGE_TYPE, 
        SET_EDIT_MILEAGE_TYPE, 
        SET_EDIT_MILEAGE_CONFIG,
        GET_MILEAGE_CONFIG,
        CREATE_MILEAGE_CONFIG,
        EDIT_MILEAGE_CONFIG,
        DELETE_MILEAGE_CONFIG,
        CREATE_MILEAGE_TYPE,
        GET_MILEAGE_TYPE,
        EDIT_MILEAGE_TYPE,
        DELETE_MILEAGE_TYPE,
        GET_MILEAGE_TYPE_LIST,
        SET_MILEAGE_TYPE,
        CHANGE_CONFIG_STATUS,
        SET_CHANGE_CONFIG_STATUS,
        SET_DISTANCE_UNIT_RESPONSE,
        RESET_CONFIG_STATUS,
        RESET_DELETE_CONFIG_STATUS
    } from "../../../actions/Admin/Mileage/Mileage.action.types";


export const initialState = {
    isLoadingData: false,
    createNewMileageResponse: null,
    mileageTypesResponse: [],
    createMileageTypeResponse: null,
    createMileageConfigResponse: null,
    mileageConfigResponse: null,
    editUserMileageResponse:null,
    createUserMileageResponse: null,
    mileagebasics: null,
    deleteMileageConfigResponse: null ,
    deleteMileageTypeResponse: null,
    editMileageTypeResponse: null,
    editMileageConfigResponse: null,
    changeConfigStatusResponse: null,
    userMileageDetails: null,
    distanceApiData: null,
    calculatedAttributesForMileage: null,
    editDistanceUnitResponse: null,
    travelInfo:null
}

export const reducerObj = {
    
    //USER REDUCERS
    [SET_MILEAGE_BASICS]: (prevState, payload) => (
        {
            mileagebasics: payload
        }
    ),
    [SET_CREATE_USER_MILEAGE]: (prevState, payload) => (
        {
            createUserMileageResponse: payload,
        }
    ),
    [SET_EDIT_USER_MILEAGE]: (prevState , payload) => (
        {
            editUserMileageResponse: payload,
        }
    ),
    [SET_TRAVEL_INFO]: (prevState , payload) => (
        {
            travelInfo: payload,
        }
    ),
    //ADMIN REDUCERS
    [SET_MILEAGE_TYPE_LIST]: (prevState , payload) => (
        {
            mileageTypesResponse : payload
        }
    ),
    [SET_CREATE_MILEAGE_TYPE]: (prevState , payload) => (
        {
            createMileageTypeResponse : payload
        }
    ),
    [SET_MILEAGE_CONFIG]: (prevState , payload) => (
        {
            mileageConfigResponse: payload
        }
    ),
    [SET_CREATE_MILEAGE_CONFIG]: (prevState , payload) => (
        {
            createMileageConfigResponse : payload
        }
    ),
    [SET_DELETE_MILEAGE_CONFIG]: (prevState, payload) => (
        {
            deleteMileageConfigResponse: payload
        }
    ),
    [SET_DELETE_MILEAGE_TYPE]: (prevState , payload) => (
        {
            deleteMileageTypeResponse: payload
        }
    ),
    [SET_EDIT_MILEAGE_TYPE]: (prevState , payload) => (
        {
            editMileageTypeResponse: payload
        }
    ),
    [SET_EDIT_MILEAGE_CONFIG]: (prevState, payload) => (
        {
            editMileageConfigResponse: payload
        }
    ),
    [SET_CHANGE_CONFIG_STATUS]: (prevState , payload) => (
        {
            changeConfigStatusResponse: payload
        }
    ),
    [RESET_CONFIG_STATUS]: (prevState , payload) => (
        {
            changeConfigStatusResponse: null
        }
    ),
    [SET_DISTANCE]: (prevState , payload) => (
        {
            distanceApiData: payload
        }
    ),
    [SET_USER_MILEAGE]: (prevState , payload) => (
        {
            userMileageDetails: payload
        }
    ),
    [SET_CAL_ATTRI]: (prevState , payload) => (
        {
            calculatedAttributesForMileage: payload
        }
    ),
    [SET_DISTANCE_UNIT_RESPONSE]: (prevState , payload) => (
        {
            editDistanceUnitResponse: payload
        }
    ),
    [RESET_DELETE_CONFIG_STATUS]: (prevState , payload) => (
        {
            deleteMileageConfigResponse: null
        }
    ),
    
    
    [RESET_STATE]: (prevState , payload) => (
        {
            isLoadingData: false,
            createNewMileageResponse: null,
            mileageTypesResponse: [],
            createMileageTypeResponse: null,
            createMileageConfigResponse: null,
            createUserMileageResponse: null,
            mileageConfigResponse: null,
            deleteMileageConfigResponse: null,
            mileagebasics: null,
            deleteMileageTypeResponse: null,
            editMileageTypeResponse: null,
            editMileageConfigResponse: null,
            changeConfigStatusResponse: null,
            editUserMileageResponse:null,
            distanceApiData: null,
            userMileageDetails: null,
            calculatedAttributesForMileage: null,
            editDistanceUnitResponse: null
        }
        ),

    // ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === GET_MILEAGE_CONFIG || payload === CREATE_MILEAGE_CONFIG || payload === EDIT_MILEAGE_CONFIG || payload === DELETE_MILEAGE_CONFIG || payload === CREATE_MILEAGE_TYPE || 
            payload === GET_MILEAGE_TYPE || payload === EDIT_MILEAGE_TYPE || payload === DELETE_MILEAGE_TYPE || GET_MILEAGE_TYPE_LIST || CHANGE_CONFIG_STATUS || GET_USER_MILEAGE) {
			return { isLoadingData: true }
		}
	},
	[API_END]: (prevState, payload) => (
		payload === SET_MILEAGE_CONFIG || SET_CREATE_MILEAGE_CONFIG || SET_EDIT_MILEAGE_CONFIG || SET_DELETE_MILEAGE_CONFIG || SET_CREATE_MILEAGE_TYPE || SET_MILEAGE_TYPE || SET_EDIT_MILEAGE_TYPE || SET_DELETE_MILEAGE_TYPE || SET_MILEAGE_TYPE_LIST || SET_CHANGE_CONFIG_STATUS || SET_USER_MILEAGE || SET_DELETE_MILEAGE_CONFIG
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
	[API_ERROR]: (prevState, payload) => {
		if (payload.message === "Something went wrong!!!") {
			return {
				errorRsp: {
					status: false,
					message: payload.message
				}
			}
		}
	}
}


let MileageReducer = createReducer(initialState , reducerObj);

export default MileageReducer;