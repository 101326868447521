import {
	FETCH_NOTIFICATION_CONFIGS,
	SET_ADMIN_LIST,
	SET_NOTIFICATION_CONFIGS,
	UPDATE_EDIT_LIST,
	UPDATE_EDIT_MODE,
	UPDATE_NOTIFICATION_CONFIG,
} from "../../../../actions/Admin/Settings/Notifications/Notifications.action.type";

const initialState = {
	data: [],
	editedList: [],
	editMode: false,
	adminList: [],
	isLoading: false,
};
const NotificationsReducer = function reducerObj(state = initialState, action) {
	// console.log("action", action)
	const { type, payload } = action;
	switch (type) {
		case SET_NOTIFICATION_CONFIGS: {
			var { data, clientId } = payload;
			data.sort(function (x, y) {
				return x.id === clientId ? -1 : y.id === clientId ? 1 : 0;
			});
			return {
				...state,
				data: data,
				editedList: data,
				isLoading: false,
			};
		}
		case UPDATE_NOTIFICATION_CONFIG: {
			return {
				...state,
				data: state.editedList,
				editMode: false,
				isLoading: true,
			};
		}
		case UPDATE_EDIT_LIST: {
			return {
				...state,
				editedList: payload,
			};
		}
		case UPDATE_EDIT_MODE: {
			var adminListData = state.adminList;
			var newEditedData = [];
			adminListData.forEach((item) => {
				if (state.data.filter((e) => e.id === item.id).length > 0)
					newEditedData.push(item);
			});
			console.log(newEditedData);
			return {
				...state,
				editMode: payload,
				editedList: newEditedData,
			};
		}
		case SET_ADMIN_LIST: {
			return {
				...state,
				adminList: payload,
			};
		}
		default:
			return state;
	}
};

export default NotificationsReducer;

