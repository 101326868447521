import createReducer from '../../../../components/common/Utility';

import {
	FETCH_ALL_PENDING_APPROVALS,
	SET_ALL_PENDING_APPROVALS,
	SET_REJECT_REPORT,
	SET_APPROVE_REPORT,
	FETCH_APPROVALS_HISTORY,
	SET_APPROVALS_HISTORY
} from '../../../actions/Personal/PendingApproval/PendingApproval.action.type';

// import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
	allPendingApprovalsCount: 0,

	allPendingApprovalsData: null,

	allPendingApprovals: [],

	approvalsHistoryCount: 0,

	approvalsHistoryData: null,

	approvalsHistory: [],

	rejectOrApproveResponse: null,

	isLoadingData: false,

	total_records: {
		allPendingApprovals: 0,
	},
}

export const reducerObj = {
	[SET_ALL_PENDING_APPROVALS]: (prevState, payload) => (
		{
			allPendingApprovalsData: payload,
			allPendingApprovals: payload.data,
			allPendingApprovalsCount: payload.total_records || 0,
			total_records: {
				...initialState.total_records,
				allPendingApprovals: payload.no_of_reports || 0
			},
			isLoadingData: false
		}
	),

	[SET_APPROVALS_HISTORY]: (prevState, payload) => {
		return {
			approvalsHistoryData: payload,
			approvalsHistory: payload.data,
			approvalsHistoryCount: payload.total_records || 0,
			total_records: {
				...initialState.total_records,
				approvalsHistory: payload.no_of_reports || 0
			},
			isLoadingData: false
		}
	},

	// [RESET_STATE]: () => (
	// 	{
	// 		rejectOrApproveResponse: null
	// 	}
	// ),

	// [SEARCH_ACTION_LABEL]: () => (
	// 	{
	// 		total_records: {
	// 			...initialState.total_records,
	// 			all: 0,
	// 			pending: 0,
	// 			outstanding: 0
	// 		},
	// 	}
	// ),

	[SET_REJECT_REPORT]: (prevState, payload) => (
		{
			rejectOrApproveResponse: payload
		}
	),
	[SET_APPROVE_REPORT]: (prevState, payload) => (
		{
			rejectOrApproveResponse: payload
		}
	),

	[RESET_STATE]: () => (
		{
			rejectOrApproveResponse: null
		}
	),


	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === FETCH_ALL_PENDING_APPROVALS || payload === FETCH_APPROVALS_HISTORY) {
			return {
				isLoadingData: true,
				// allPendingApprovalsData: null,
				// allPendingApprovals: [],
				// allPendingApprovalsCount: 0
			}
		}
	},
	[API_END]: (prevState, payload) => (
		payload === FETCH_ALL_PENDING_APPROVALS
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
}

let PendingApprovalReducer = createReducer(initialState, reducerObj);

export default PendingApprovalReducer;