import createReducer from '../../../../../components/common/Utility';

import {
	// FETCH_ALL_EXPENSES,
	// SET_ALL_EXPENSES,
	SUBMIT_REPORT_FOR_APPROVAL,
	SET_SUBMIT_REPORT_FOR_APPROVAL,
	LINK_UNLINK_EXPENSE,
	SET_LINK_UNLINK_EXPENSE,
	DELETE_REPORT,
	SET_REPORT_DELETE,
	REPORT_TITLE_UPDATE,
	SET_REPORT_TITLE_UPDATE,
	SET_APPROVE_REPORT,
	APPROVE_REPORT
} from '../../../../actions/Personal/DraftExpense/create/report/CreateNewReport.action.type.js';

// import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE, API_ERROR } from '../../../../actions/Common/Api.types';

export const initialState = {
	isLoadingData: false,
	createNewResponse: null,
	reportDelResponse: null,
	submitForApprovalResponse: null,
	expensesLinkUnLinkResponse: null,
	rejectOrApproveResponse: null,
	errorRsp: null
}

export const reducerObj = {
	[SET_REPORT_TITLE_UPDATE]: (prevState, payload) => (
		{
			createNewResponse: payload,
			errorRsp: null
		}
	),
	[SET_LINK_UNLINK_EXPENSE]: (prevState, payload) => (
		{
			expensesLinkUnLinkResponse: payload,
			errorRsp: null
		}
	),
	[SET_REPORT_DELETE]: (prevState, payload) => (
		{
			reportDelResponse: payload,
			errorRsp: null
		}
	),
	[SET_SUBMIT_REPORT_FOR_APPROVAL]: (prevState, payload) => (
		{
			submitForApprovalResponse: payload,
			errorRsp: null
		}
	),
	[RESET_STATE]: () => (
		{
			createNewResponse: null,
			reportDelResponse: null,
			submitForApprovalResponse: null,
			expensesLinkUnLinkResponse: null,
			rejectOrApproveResponse: null,
			errorRsp: null
		}
	),

	[SET_APPROVE_REPORT]: (prevState, payload) => (
		{
			rejectOrApproveResponse: payload,
			errorRsp: null
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === SUBMIT_REPORT_FOR_APPROVAL || payload === LINK_UNLINK_EXPENSE || payload === DELETE_REPORT || payload === REPORT_TITLE_UPDATE || payload === APPROVE_REPORT) {
			return { isLoadingData: true }
		}
	},
	[API_END]: (prevState, payload) => (
		payload === SET_SUBMIT_REPORT_FOR_APPROVAL || SET_LINK_UNLINK_EXPENSE || SET_REPORT_DELETE || SET_REPORT_TITLE_UPDATE || APPROVE_REPORT 
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
	[API_ERROR]: (prevState, payload) => {
		if (payload.message === "Something went wrong!!!") {
			return {
				errorRsp: {
					status: false,
					message: payload.message
				}
			}
		}
	}
}

let CreateNewReportReducer = createReducer(initialState, reducerObj);

export default CreateNewReportReducer;