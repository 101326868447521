import createReducer from '../../../../../components/common/Utility';

import {
	FETCH_ALL_DATA,
	SET_ALL_DATA,
	SET_ATTRIBUTES,
	FETCH_ATTRIBUTES,
	FETCH_USER,
	SET_USER,
	FETCH_USER_BASICS,
	SET_USER_BASICS,
	FETCH_USER_STATUS,
	SET_USER_STATUS,
	FETCH_USER_DETAIL,
	SET_USER_DETAIL,
	// SET_USER_DETAIL_BY_TOKEN // moved to myDetails.action.type.js
} from '../../../../actions/Admin/Settings/Users/Users.action.type';

import { API_START, API_END, RESET_STATE } from '../../../../actions/Common/Api.types';

export const initialState = {
	usersList: {},
	isLoadingData: false,
	customAttributes: {},
	userRes: {},
	userBasics: {},
	userStatusRes: null,
	userDetail: {},
	// userDetailsByToken : {} // moved to myDetails.reducer.js
}

export const reducerObj = {
	[SET_ALL_DATA]: (prevState, payload) => (
		{
			usersList: payload
		}
	),

	[SET_ATTRIBUTES]: (prevState, payload) => (
		{
			customAttributes: payload
		}
	),

	[SET_USER]: (prevState, payload) => (
		{
			userRes: payload
		}
	),

	[SET_USER_BASICS]: (prevState, payload) => (
		{
			userBasics: payload
		}
	),

	// [SET_USER_DETAIL_BY_TOKEN]: (prevState, payload) => ( // moved to myDetails.reducer.js
	// 	{
	// 		userDetailsByToken: payload
	// 	}
	// ),

	[RESET_STATE]: () => (
		{
			userStatusRes: null
		}
	),

	[SET_USER_STATUS]: (prevState, payload) => (
		{
			userStatusRes: payload
		}
	),

	[SET_USER_DETAIL]: (prevState, payload) => (
		{
			userDetail: payload
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => (
		payload === FETCH_ALL_DATA || FETCH_ATTRIBUTES || FETCH_USER || FETCH_USER_BASICS || FETCH_USER_STATUS || FETCH_USER_DETAIL
			? { isLoadingData: true }
			: { isLoadingData: false }
	),
	[API_END]: (prevState, payload) => (
		payload === FETCH_ALL_DATA || FETCH_ATTRIBUTES || FETCH_USER || FETCH_USER_BASICS || FETCH_USER_STATUS || FETCH_USER_DETAIL
			? payload === FETCH_USER_DETAIL ? { isLoadingData: false, userRes: {} } : payload === SET_USER ? { isLoadingData: false, userDetail: {} } : { isLoadingData: false, userDetail: {}, userRes: {} }
			: { isLoadingData: false }
	),
}

let UsersReducer = createReducer(initialState, reducerObj);

export default UsersReducer;