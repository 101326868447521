import createReducer from '../../../../components/common/Utility';


import {
	SET_USER_ROLES_AND_PERMISSIONS,
	SET_PER_DIEM_ENABLE_DISABLE_BUTTON,
	SET_NEW_USER_ROLES_AND_PERMISSIONS,
	SET_IL_CARDS

} from '../../../actions/Admin/RolesAndPermissions/RolesAndPermissions.action.type';

import { API_START, API_END } from '../../../actions/Common/Api.types';

export const initialState = {
	userRolesAndPermissions : {},
	isInvalidToken: false,
	rolesAndPermissionsFetched : false,
	show_payment_details_popup: 0,
	expense_only: 1,
	hide_add_card: 0,
	hide_refresh_txn: 0,
	hide_remove_card: 0,
	is_ach_enabled: null,
    is_ach_requested: 0,
    is_ach_applicable: 0,
	ach_flags: null,
	show_per_diem:null,
}

export const reducerObj = {
	[SET_USER_ROLES_AND_PERMISSIONS]: (prevState, payload) => (
		{
			userRolesAndPermissions : payload['user_rp_details'],
			isInvalidToken: (payload.message && (payload.message === "Invalid token")) ? true : false,
			show_payment_details_popup: payload['show_payment_details_popup'],
			download_rp_button_txt: payload['download_rp_button_txt'],
			card_recon_enabled: payload['card_recon_enabled'],
			expense_only: payload['expense_only'],
			rolesAndPermissionsFetched : true,
			hide_add_card: payload['hide_add_card'], 
			hide_refresh_txn: payload['hide_refresh_txn'],
			hide_remove_card: payload['hide_remove_card'],
			is_ach_enabled: payload['is_ach_enabled'],
			is_ach_requested: payload['is_ach_requested'],
			is_ach_applicable: payload['is_ach_applicable'],
			ach_flags: payload['ach_data'],
			show_per_diem: payload['show_per_diem'],
		}
	),
	[SET_PER_DIEM_ENABLE_DISABLE_BUTTON]: (prevState, payload) => (
		{
			
			show_per_diem: payload['show_per_diem'],
		}
	),
	[SET_NEW_USER_ROLES_AND_PERMISSIONS]: (prevState, payload) => (
		{
			
			userRolesAndPermissions: payload,
		}
	),
	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => (
		{rolesAndPermissionsFetched : false}
	),
	[API_END]: (prevState, payload) => (
		{rolesAndPermissionsFetched : true}
	),
}

let RolesAndPermissionsReducer = createReducer(initialState, reducerObj);

export default RolesAndPermissionsReducer;