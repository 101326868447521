import createReducer from '../../../../components/common/Utility';

import {
	FETCH_ALL_REPORTS,
	SET_ALL_REPORTS,
	SET_REJECT_REPORT,
	SET_APPROVE_REPORT,
	REJECT_REPORT,
	APPROVE_REPORT,
	FETCH_REPORTS,
	SET_REPORTS,
	FETCH_OUTSTANDING_ADVANCES,
	SET_OUTSTANDING_ADVANCES
} from '../../../actions/Admin/Advances/Advances.action.type';

import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';
import { SET_EDITED_ADVANCE_AMOUNT } from '../../../actions/Admin/Reports/AdvanceDetails.action.type';

export const initialState = {
	allCount: 0,
	pendingCount: 0,
	outStandingAdvancesCount: 0,

	allData: {},
	pendingData: {},
	outStandingAdvancesData: {},

	allReports: [],
	pendingReports: [],
	outStandingAdvances: [],

	isLoadingData: false,
	rejectOrApproveResponse: null,

	total_records: {
		pending: 0,
		all: 0,
		outstanding: 0,
	},
}

export const reducerObj = {
	[SET_ALL_REPORTS]: (prevState, payload) => (
		{
			allData: payload,
			allReports: payload.data,
			allCount: payload.total_records || 0,
			total_records: {
				...initialState.no_of_reports,
				all: payload.no_of_reports || 0
			},
		}
	),

	[SET_REPORTS]: (prevState, payload) => (
		{
			pendingData: payload,
			pendingReports: payload.data,
			pendingCount: payload.total_records || 0,
			total_records: {
				...initialState.no_of_reports,
				pending: payload.no_of_reports || 0
			},
		}
	),

	[SET_OUTSTANDING_ADVANCES]: (prevState, payload) => (
		{
			outStandingAdvancesData: payload,
			outStandingAdvances: payload.data,
			outStandingAdvancesCount: payload.total_records || 0,
			total_records: {
				...initialState.no_of_reports,
				outstanding: payload.no_of_reports || 0
			},
		}
	),

	// ------------ approve and reject Reports -------------
	[SET_REJECT_REPORT]: (prevState, payload) => (
		{
			rejectOrApproveResponse: payload
		}
	),
	[SET_APPROVE_REPORT]: (prevState, payload) => (
		{
			rejectOrApproveResponse: payload
		}
	),

	[RESET_STATE]: () => (
		{
			rejectOrApproveResponse: null
		}
	),

	[SEARCH_ACTION_LABEL]: () => (
		{
			total_records: {
				...initialState.total_records,
				all: 0,
				pending: 0,
				outstanding: 0
			},
		}
	),

	[SET_EDITED_ADVANCE_AMOUNT]: (prevState, payload) => {
        return {
            editAmountResp: payload,
            fetchedExpenseData: {},
            fetchedExpenseCategory: {},
            exceededBy: '',
            allowedAmount: '',
            claimedAmount: '',
            isLoadingData: false,
            fetchedExpensePayload: {},
            attributes_violation: [],
            policy_violation:[]
        }
    },

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if (payload === FETCH_ALL_REPORTS || payload === REJECT_REPORT || payload === APPROVE_REPORT || payload === FETCH_REPORTS || payload === FETCH_OUTSTANDING_ADVANCES || payload === SEARCH_ACTION_LABEL || payload=== SET_EDITED_ADVANCE_AMOUNT) {
			return (
				{ isLoadingData: true }
			)
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === SET_ALL_REPORTS || payload === SET_REJECT_REPORT || payload === SET_APPROVE_REPORT || payload === SET_REPORTS || payload === SET_OUTSTANDING_ADVANCES || payload=== SET_EDITED_ADVANCE_AMOUNT) {
			return (
				{ isLoadingData: false }
			)
		}
	},
}

let AdvancesReducer = createReducer(initialState, reducerObj);

export default AdvancesReducer;