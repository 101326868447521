import createReducer from "../../../../../components/common/Utility"
import { GET_CARDS_INTEGRATION_STATUS, SET_CARDS_INTEGRATION_STATUS, TOGGLE_CARDS_INTEGRATION_STATUS } from "../../../../actions/Admin/Settings/Integrations/Integrations.action.type"
import { API_END, API_START } from "../../../../actions/Common/Api.types"

const initialState = {
    isLoading: {
        cards: true,
        toggle: true
    },
    cards: {
        status: null
    }
}
export const reducerObj = {

    [GET_CARDS_INTEGRATION_STATUS]: (prevState, payload) => (
        {
            isLoading: {
                cards: true
            }
        }
    ),
    [TOGGLE_CARDS_INTEGRATION_STATUS]: (prevState, payload) => (
        {
            isLoading: {
                toggle: true
            }
        }
    ),
    [SET_CARDS_INTEGRATION_STATUS]: (prevState, payload) => (
        {
            cards: {
                status: payload
            }
        }
    ),
    [API_END]: (prevState, payload) => (
        {
            isLoading: {
                cards: false,
                toggle: false
            }
        }
    ),
    [API_START]: (prevState, payload) => {
        if(payload === GET_CARDS_INTEGRATION_STATUS || payload === TOGGLE_CARDS_INTEGRATION_STATUS)
        return  {
            isLoading: {
                cards: true,
                toggle: true
            }
        }
    },
}

const IntegrationsReducer = createReducer(initialState, reducerObj)

export default IntegrationsReducer;