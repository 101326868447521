import * as React from 'react'

export const DeleteIcon = React.memo(
    ({ size = 18, color = '#6B7280', className, ...rest }) => {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width={size}
                height={size}
                fill='none'
                viewBox={`0 0 ${size} ${size}`}
                className={className}
                {...rest}>
                <path
                    fill={color}
                    fillRule='evenodd'
                    d='M8.25 3.355a.375.375 0 00-.375.375v.75h2.25v-.75a.375.375 0 00-.375-.375h-1.5zm3 1.125v-.75a1.5 1.5 0 00-1.5-1.5h-1.5a1.5 1.5 0 00-1.5 1.5v.75H2.812a.563.563 0 000 1.125h.598l.614 9.52A2.25 2.25 0 006.27 17.23h5.46a2.25 2.25 0 002.246-2.105l.614-9.52h.598a.562.562 0 100-1.125H11.25zm2.213 1.125H4.537l.61 9.447c.038.592.53 1.053 1.123 1.053h5.46c.594 0 1.085-.46 1.123-1.053l.61-9.447zM7.313 7.48c.31 0 .562.252.562.562v5.626a.563.563 0 01-1.125 0V8.041c0-.31.252-.562.563-.562zm3.375 0c.31 0 .562.252.562.562v5.626a.563.563 0 01-1.125 0V8.041c0-.31.252-.562.563-.562z'
                    clipRule='evenodd'></path>
            </svg>
        )
    }
)

DeleteIcon.displayName = 'DeleteIcon'
