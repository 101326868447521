import createReducer from '../../../../components/common/Utility';

import {
  GET_APPLY_ADVANCES,
  SET_APPLY_ADVANCES,
  GET_PAYMENTS_QUEUE,
  SET_PAYMENTS_QUEUE,
  GET_PAYMENT_PROCESSING,
  SET_PAYMENT_PROCESSING,
  GET_PAID,
  SET_PAID,
  SET_MOVE_TO_PAID_MODAL,
  SUBMIT_REJECTION_REASON,
  SET_PAYOUT_COUNT,
  SET_ACH_PAYMENT_RESPONSE,
  SET_CANCEL_ACH_PAYMENT_RESPONSE,
  MAKE_PAYMENT_USING_ACH,
  CANCEL_ACH_PAYMENT,
  SET_ACTION_ROW_ID,
  RESET_COMMON_RESPONSE_PAYOUT,
  SET_ACH_PAYMENT_ERROR_RESPONSE,
  GET_ACH_ERROR_PAYMENT,
  SET_BULK_PROCESSING_TO_PAID,
  RESET_BULK_PROCESSING_TO_PAID,
  SET_MASS_ACH_PAYMENTS_RESPONSE,
  SET_TYPE_OF_ACTION,
  START_MASS_ACH_PAYMENTS,
  SET_DOWNLOADED_REPORT_URL,
  SET_MANUAL_AND_ACH_PAYMENT_RESPONSE,
  START_MANUAL_AND_ACH_PAYMENT,
  SET_REPORT_COUNT_API,
  START_REPORT_COUNT_API,
  SET_PAYOUT_FILTER_OPTIONS,
  SELECTED_FILTER_DATA,
  SET_SELECTED_FILTER_DATA,
  RESET_SELECTED_FILTER_DATA
} from '../../../actions/Admin/Payout/Payout.action.type';

import { SELECT_ALL_ACTION_LABEL, SET_SELECT_ALL_ACTION_LABEL, UN_SELECT_ALL_ACTION_LABEL, SET_CUSTOM_SELECT_ACTION_LABEL } from "../../../actions/Common/SelectAllRows/SelectAllRows.action.type";

import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE, API_ERROR } from '../../../actions/Common/Api.types';

const initialState = {
  fetchedapprovedReports: {},
  approvedCount: 0,

  fetchedQueueReports: {},
  queueCount: 0,

  fetchedProcessingReports: {},
  processingCount: 0,

  fetchedPaidReports: {},
  paidCount: 0,

  allSelected: {
    ids: [],
    isSelecting: false,
    selected: false,
    // total_selected_advances: 0,
    // total_selected_expenses: 0
  },

  modalData: '',

  isLoadingData: {
    reports: false,
    payment: false,
    processing: false,
    paid: false,
    achPayment: false,           
    cancelAchPayment:false,
    achPaymentError: false,
    massAchPayment: false,
    achAndManualReportPaymentResponse: false,
    reportCountApi: false,
    selectedFilterData: false,
  },

  total_records: {
    reports: 0,
    payment: 0,
    processing: 0,
    paid: 0
  },

  countObj: {},
  export_history_stats:null,
  achPaymentResponse: null,
  cancelAchPaymentResponse: null,
  actionRowId: null,
  commonErrorResponse: null,
  achPaymentErrorResponse: null,
  bulkProcessingToPaidResponse: null ,
  massAchPaymentResponse: null,
  typeOfAction: {
    modalType: "",
    downloadReport: true,
    actionType: ""
  },
  downloadedDataUrl: null,
  achAndManualReportPaymentResponse: null,
  payoutReportCount: null,
  payoutFilterOptions: null,
  selectedFilterData: null,

  currency: null
}

const reducerObj = {
  [SET_APPLY_ADVANCES]: (prevState, payload) => (
    {
      ...prevState,
      fetchedapprovedReports: payload,
      approvedCount: payload.total_records || 0,
      total_records: {
        ...prevState.total_records,
        reports: payload.no_of_reports || 0
      },
      countObj: {
        ...prevState.countObj,
        apply_advances_count: payload.total_records
      },
      isLoadingData: {
        ...prevState.isLoadingData,
        reports: false,
      },
      currency: payload.currency
    }
  ),


  [SET_PAYMENTS_QUEUE]: (prevState, payload) => (
    {
      ...prevState,
      fetchedQueueReports: payload,
      queueCount: payload.total_records || 0,
      total_records: {
        ...prevState.total_records,
        payment: payload.no_of_reports || 0
      },
      countObj: {
        ...prevState.countObj,
        payment_queue_count: payload.total_records
      },
      isLoadingData: {
        ...prevState.isLoadingData,
        payment: false,
      },
      currency: payload.currency
    }
  ),
  [SET_PAYMENT_PROCESSING]: (prevState, payload) => (
    {
      ...prevState,
      fetchedProcessingReports: payload,
      processingCount: payload.total_records || 0,
      total_records: {
        ...prevState.total_records,
        processing: payload.no_of_reports || 0
      },
      countObj: {
        ...prevState.countObj,
        payment_processing_count: payload.total_records
      },
      isLoadingData: {
        ...prevState.isLoadingData,
        processing: false,
      },
      currency: payload.currency
    }
  ),

  [SET_PAID]: (prevState, payload) => (
    {
      ...prevState,
      fetchedPaidReports: payload,
      paidCount: payload.total_records || 0,
      total_records: {
        ...prevState.total_records,
        paid: payload.no_of_reports || 0
      },
      countObj: {
        ...prevState.countObj,
        paid_count: payload.total_records
      },
      isLoadingData: {
        ...prevState.isLoadingData,
        paid: false,
      },
      currency: payload.currency
    }
  ),

  [SET_MOVE_TO_PAID_MODAL]: (prevState, payload) => (
    {
      modalData: payload,
    }
  ),
  [SET_BULK_PROCESSING_TO_PAID] : (prevState , payload) => {
    return {
      ...prevState,
      bulkProcessingToPaidResponse: payload
    }
  },

  [SET_SELECT_ALL_ACTION_LABEL]: (prevState, payload) => {

    return {
      ...prevState,
      allSelected: {
        ...prevState.allSelected,
        isSelecting: false,
        ids: [],
        selected: true,
        // total_selected_advances: payload.no_of_advance,
        // total_selected_expenses: payload.no_of_expense
      },
      isLoadingData: {
        ...prevState.isLoadingData,
      },
    }
  },

  [UN_SELECT_ALL_ACTION_LABEL]: (prevState) => {

    return {
      ...prevState,
      allSelected: {
        ...prevState.allSelected,
        isSelecting: false,
        ids: [],
        selected: false,
        // total_selected_advances: 0,
        // total_selected_expenses: 0
      },
      isLoadingData: {
        ...prevState.isLoadingData,
      },
    }
  },
  // [SET_CUSTOM_SELECT_ACTION_LABEL]: (prevState, payload) => { // action for this type is not getting dispatched hence commenting it.
  //   let l_ids = [];
  //   if (payload && payload.length) {
  //     l_ids = payload;
  //   }
  //   return {
  //     allSelected: {
  //       ...prevState.allSelected,
  //       isSelecting: false,
  //       ids: l_ids,
  //       selected: false,
  //       total_advances: payload.adv_no,
  //       total_expenses: payload.exp_no
  //     },
  //     isLoadingData: false,
  //   }
  // },

  [SEARCH_ACTION_LABEL]: (prevState) => (
    {
      ...prevState,
      total_records: {
        ...prevState.total_records,
        reports: 0,
        payment: 0,
        processing: 0,
        paid: 0
      },
    }
  ),

  [SET_PAYOUT_COUNT]: (prevState, payload) => (
    {
      ...prevState,
      countObj: {...payload.data,expo_history_state:payload.expo_history_info_status},
      isLoadingData: {
        ...prevState.isLoadingData
      }
    }
  ),
  [SET_ACH_PAYMENT_RESPONSE]: (prevState, payload) => (
    {
      ...prevState,
      isLoadingData: {
        ...prevState.isLoadingData,
        achPayment: false
      },
      achPaymentResponse: payload
    }
  ),
  [SET_CANCEL_ACH_PAYMENT_RESPONSE]: (prevState, payload) => (
    {
      ...prevState,
      isLoadingData: {
        ...prevState.isLoadingData,
        cancelAchPayment: false
      },
      cancelAchPaymentResponse: payload
    }
  ),
  [SET_ACTION_ROW_ID]: (prevState, payload) => (
    {
      ...prevState,
      actionRowId: payload
     }
  ),
  [SET_DOWNLOADED_REPORT_URL]: (prevState, payload) => (
    {
      ...prevState,
      downloadedDataUrl: payload
     }
  ),
  [SET_ACH_PAYMENT_ERROR_RESPONSE]: (prevState, payload) => (
    {
      ...prevState,
      achPaymentErrorResponse: payload
     }
  ),
  [SET_MASS_ACH_PAYMENTS_RESPONSE]: (prevState, payload) => (
    {
      ...prevState,
      isLoadingData: {
        ...prevState.isLoadingData,
        massAchPayment: false
      },
      massAchPaymentResponse: payload,

     }
  ),
  [SET_MANUAL_AND_ACH_PAYMENT_RESPONSE]: (prevState, payload) => (
    {
      ...prevState,
      isLoadingData: {
        ...prevState.isLoadingData,
        achAndManualReportPaymentResponse: false
      },
      achAndManualReportPaymentResponse: payload,

     }
  ),
  [SET_REPORT_COUNT_API]: (prevState, payload) => (
    {
      ...prevState,
      isLoadingData: {
        ...prevState.isLoadingData,
        reportCountApi: false
      },
      payoutReportCount : payload,

     }
  ),
  [SET_TYPE_OF_ACTION]: (prevState, payload) => (
    {
      ...prevState,
      typeOfAction: payload
     }
  ),
  [RESET_STATE]: () => (
    {
      achPaymentResponse: null,
      cancelAchPaymentResponse: null,
      achPaymentErrorResponse: null,
      massAchPaymentResponse: null,
      downloadedDataUrl: null,
      achAndManualReportPaymentResponse: null
    }
  ),

  [API_ERROR]: (prevState, payload) => (
    {
      commonErrorResponse: payload
    }

  ),
  [RESET_COMMON_RESPONSE_PAYOUT]: (prevState, payload) => (
    {
      commonErrorResponse: null
    }

  ),
  [RESET_BULK_PROCESSING_TO_PAID]: (prevState , payload) => (
    {
      bulkProcessingToPaidResponse: null
    }
  ),

  //Payout Filters reducers
  [SET_PAYOUT_FILTER_OPTIONS]: (prevState , payload) => {

    //Removing the submitted date and submitter name filter as these are not displayed in the dropdown
    let payoutFilterOptions = payload.data.filter(filter => filter.name.toLowerCase() !== "Submitter name".toLowerCase() &&  filter.name.toLowerCase() !== "Submitted date".toLowerCase())
    return{
      payoutFilterOptions: payoutFilterOptions
    }
  },
  [SET_SELECTED_FILTER_DATA]: (prevState , payload) => (
    {
      selectedFilterData: payload,
      isLoadingData: {
        ...prevState.isLoadingData,
        selectedFilterData: false
      }
    }
  ),
  [RESET_SELECTED_FILTER_DATA]: (prevState , payload) => {
    return {
      selectedFilterData: null
    }
  },

  // ------------- Api end and start loading state ----------
  [API_START]: (prevState, payload) => {

    if (payload === SELECT_ALL_ACTION_LABEL) {
      return {
        ...prevState,
        allSelected: {
          ...prevState.allSelected,
          isSelecting: false,
        },
        isLoadingData: {
          reports: false,
          payment: false,
          processing: false,
          paid: false
        }
      }
    }

    if (payload === GET_APPLY_ADVANCES) {
      return {
        ...prevState,
        allSelected: {
          ...prevState.allSelected,
          isSelecting: false,
        },
        isLoadingData: {
          ...prevState.isLoadingData,
          reports: true,
        },
      };
    }

    if (payload === GET_PAYMENTS_QUEUE || payload === SUBMIT_REJECTION_REASON) {
      return {
        ...prevState,
        allSelected: {
          ...prevState.allSelected,
          isSelecting: false,
        },
        isLoadingData: {
          ...prevState.isLoadingData,
          payment: true,
        }
      };
    }

    if (payload === GET_PAYMENT_PROCESSING) {
      return {
        ...prevState,
        allSelected: {
          ...prevState.allSelected,
          isSelecting: false,
        },
        isLoadingData: {
          ...prevState.isLoadingData,
          processing: true,
        }
      };
    }

    if (payload === GET_PAID) {
      return {
        ...prevState,
        allSelected: {
          ...prevState.allSelected,
          isSelecting: false,
        },
        isLoadingData: {
          ...prevState.isLoadingData,
          paid: true
        }
      };
    }

    if (payload === SEARCH_ACTION_LABEL) {
      return {
        ...prevState,
        allSelected: {
          ...prevState.allSelected,
          isSelecting: false,
        },
        isLoadingData: {
          reports: true,
          payment: true,
          processing: true,
          paid: true
        }
      };
    }
    if (payload === MAKE_PAYMENT_USING_ACH) {
      return {
        ...prevState,
        
        isLoadingData: {
          ...prevState.isLoadingData,
          achPayment: true
        }
      };
    }
    if (payload === CANCEL_ACH_PAYMENT) {
      return {
        ...prevState,
        isLoadingData: {
          ...prevState.isLoadingData,
          cancelAchPayment: true
        }
      };
    }
    if (payload === GET_ACH_ERROR_PAYMENT) {
      return {
        ...prevState,
        isLoadingData: {
          ...prevState.isLoadingData,
          achPaymentError: true
        }
      };
    }
    if (payload === START_MASS_ACH_PAYMENTS) {
      return {
        ...prevState,
        isLoadingData: {
          ...prevState.isLoadingData,
          massAchPayment: true
        }
      };
    }
    if (payload === START_MANUAL_AND_ACH_PAYMENT) {
      return {
        ...prevState,
        isLoadingData: {
          ...prevState.isLoadingData,
          achAndManualReportPaymentResponse: true
        }
      };
    }
    if (payload === START_REPORT_COUNT_API) {
      return {
        ...prevState,
        isLoadingData: {
          ...prevState.isLoadingData,
          reportCountApi: true
        }
      };
    }
    if(payload === SELECTED_FILTER_DATA){
      return {
        ...prevState,
        isLoadingData: {
          ...prevState.isLoadingData,
          selectedFilterData: true
        }
      }
    }
  },
  [API_END]: (prevState, payload) => {
    if (payload === SET_APPLY_ADVANCES || payload === SET_PAYMENTS_QUEUE || payload === SET_PAYMENT_PROCESSING || payload === SET_PAID || payload === SET_ACH_PAYMENT_RESPONSE || payload === SET_CANCEL_ACH_PAYMENT_RESPONSE || payload === SET_ACH_PAYMENT_ERROR_RESPONSE || payload === SET_MASS_ACH_PAYMENTS_RESPONSE || SET_MANUAL_AND_ACH_PAYMENT_RESPONSE || SET_REPORT_COUNT_API ) {
      return {
        ...prevState,
        allSelected: {
          ...prevState.allSelected,
          isSelecting: false,
        },
        isLoadingData: {
          ...prevState.isLoadingData,
          achPayment: false,           
          cancelAchPayment:false,
          achPaymentError: false,
          massAchPayment: false,
          achAndManualReportPaymentResponse: false,
          reportCountApi: false

        },
        actionRowId: "",
        typeOfAction: {
          modalType: "",
          downloadReport: true,
          actionType: ""
        }
      }
    }
  },
}

let PayoutReducer = createReducer(initialState, reducerObj);

export default PayoutReducer;