import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import localStorageService from '../Storage_services/LocalStorageService';


export default class PublicRoute extends React.Component {
  render() {
    if (localStorageService.getAccessToken()) {
      // return <Redirect to="/approvals#pending" />
      // return <Redirect to="/individual/reports#draft_expense" />
      return <Redirect to="/home" />
    }
    return <Route {...this.props} />
  }
}