import createReducer from '../../../../components/common/Utility';

import {
	FETCH_ALL_DATA,
	SET_ALL_DATA
} from '../../../actions/Admin/CurrencyConversion/CurrencyConversion.action.type';

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
	currencyList: {},
	isLoadingData: false,
}

export const reducerObj = {
	[SET_ALL_DATA]: (prevState, payload) => (
		{
			currencyList: payload
		}
	),

	[RESET_STATE]: () => (
		{
			currencyList: {},
			isLoadingData: false,
			rejectOrApproveResponse: null
		}
	),

	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => (
		payload === FETCH_ALL_DATA
			? { isLoadingData: true }
			: { isLoadingData: false }
	),
	[API_END]: (prevState, payload) => (
		payload === FETCH_ALL_DATA
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
}

let CurrencyConversionReducer = createReducer(initialState, reducerObj);

export default CurrencyConversionReducer;