import React from 'react';
import Row from 'react-bootstrap/Row';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import localStorageService from '../../services/Storage_services/LocalStorageService'

function pageHeader(props, ...rest) {

  const { handleToggleSidebar, name, headerComponent, backButton, background, desc } = props;

  const renderHeaderComponent = (component) => {
    if (component) {
      return (
        <div className="header-component-col">
          {component()}
        </div>
      );
    }
  }

  return (
    <div className={background === false ? 'component-header no-background' : 'component-header'}>
      {(<div className="btn-toggle" onClick={handleToggleSidebar}><FaBars /></div>)}
      <div className="header-name-col">
        <div className='name-col'>
          {backButton
            ? (
              backButton()
            )
            : null
          }
          <h1 className="page-heading page-heading-msite">
            {name}
          </h1>
        </div>
        {desc ? desc : null}
      </div>

      {renderHeaderComponent(headerComponent)}
    </div>
  );
}

function pageHeaderWithComponents(props, ...rest) {

  const { name, headerComponent, backButton, headerRowComponents, desc } = props;

  return (
    <div className="component-header no-background">
      <Row>
        <div className="header-components-name-col">
          <div className='name-col'>
            {backButton
              ? (
                backButton()
              )
              : null
            }
            <h1 className="page-heading">
              {name}
            </h1>
          </div>
          {desc ? desc : null}
        </div>

        {headerComponent
          ? (
            <div className="header-component-col">
              {headerComponent()}
            </div>
          ) : null
        }
      </Row>
      {headerRowComponents
        ? (
          headerRowComponents()
        ) : null
      }
    </div>
  );
}

function SubmitterUiPageHeader(props) {
  const {
      handleToggleSidebar,
      headerComponent,
      borderBottom = false,
      trialPeriod = false,
      onHeaderClose,
      trialHeader = null
  } = props

  let headerVal = localStorageService.getShowHeaderTrail()
  return (
      <div>
          {' '}
          {headerVal && trialPeriod ? (
              <div className='trail-period-header-ban'>
                  <div className='trail-period'>
                      <div className='d-flex headerJustifyDoc'>
                          <div className='d-flex'>
                              <svg
                                  width='18'
                                  height='18'
                                  viewBox='0 0 20 20'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                  style={{ marginTop: '3px' }}>
                                  <path
                                      d='M9.99944 6.99999V8.99999M9.99944 13H10.0094M3.07144 17H16.9274C18.4674 17 19.4294 15.333 18.6594 14L11.7314 1.99999C10.9614 0.666994 9.03744 0.666994 8.26744 1.99999L1.33944 14C0.569436 15.333 1.53144 17 3.07144 17Z'
                                      stroke='#C2410C'
                                      stroke-width='2'
                                      stroke-linecap='round'
                                      stroke-linejoin='round'
                                  />
                              </svg>

                              <div className='pl-2'>
                                  <div>{trialHeader}</div>
                                  <small>
                                      {trialPeriod} Go to{' '}
                                      <Link to='/billingsubscriptions'>
                                          {' '}
                                          Billing & Subscription
                                      </Link>{' '}
                                      to subscribe to ITILITE Expense.
                                  </small>
                              </div>
                          </div>
                          <div>
                              <svg
                                  width='14'
                                  height='14'
                                  viewBox='0 0 14 14'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                      onHeaderClose()
                                      localStorageService.closeTrailHeader()
                                  }}>
                                  <path
                                      d='M1 1L13 13M1 13L13 1L1 13Z'
                                      stroke='#C2410C'
                                      stroke-width='2'
                                      stroke-linecap='round'
                                      stroke-linejoin='round'
                                  />
                              </svg>
                          </div>
                      </div>
                  </div>
              </div>
          ) : null}
          <div
              className={
                  props.isHomeComponent
                      ? 'submitterUiPageHeader-container submitterUiPageHeader-container-msite home-pageHeader'
                      : 'submitterUiPageHeader-container submitterUiPageHeader-container-msite'
              }
              style={{
                  borderBottom: borderBottom ? '1px solid #E4E4E7' : 'none'
              }}>
              {
                  <div className='btn-toggle' onClick={handleToggleSidebar}>
                      <FaBars />
                  </div>
              }
              {headerComponent()}
          </div>
      </div>
  )
}

function trailHeaderBar(props) {
  const { trialPeriod = false, onHeaderClose, trialHeader = null } = props
  let headerVal = localStorageService.getShowHeaderTrail()

  return (
      <div>
          {headerVal && trialPeriod ? (
              <div className='trail-period-header'>
                  <div className='trail-period'>
                      <div className='d-flex headerJustifyDoc'>
                          <div className='d-flex'>
                              <svg
                                  width='18'
                                  height='18'
                                  viewBox='0 0 20 20'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                  style={{ marginTop: '3px' }}>
                                  <path
                                      d='M9.99944 6.99999V8.99999M9.99944 13H10.0094M3.07144 17H16.9274C18.4674 17 19.4294 15.333 18.6594 14L11.7314 1.99999C10.9614 0.666994 9.03744 0.666994 8.26744 1.99999L1.33944 14C0.569436 15.333 1.53144 17 3.07144 17Z'
                                      stroke='#C2410C'
                                      stroke-width='2'
                                      stroke-linecap='round'
                                      stroke-linejoin='round'
                                  />
                              </svg>

                              <div className='pl-2'>
                                  <div>{trialHeader}</div>
                                  <small>
                                      {trialPeriod} Go to{' '}
                                      <Link to='/billingsubscriptions'>
                                          {' '}
                                          Billing & Subscription
                                      </Link>{' '}
                                      to subscribe to ITILITE Expense.
                                  </small>
                              </div>
                          </div>
                          <div>
                              <svg
                                  width='14'
                                  height='14'
                                  viewBox='0 0 14 14'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                      onHeaderClose()
                                      localStorageService.closeTrailHeader()
                                  }}>
                                  <path
                                      d='M1 1L13 13M1 13L13 1L1 13Z'
                                      stroke='#C2410C'
                                      stroke-width='2'
                                      stroke-linecap='round'
                                      stroke-linejoin='round'
                                  />
                              </svg>
                          </div>
                      </div>
                  </div>
              </div>
          ) : null}
      </div>
  )
}

export default function PayoutTable(props) {
  let header = null;

  if (props.type === "pageHeader") {
    header = pageHeader(props);
  } else if (props.type === "pageHeaderWithComponents") {
    header = pageHeaderWithComponents(props);
  } else if (props.type === "submitterUiPageHeader") {
    header = SubmitterUiPageHeader(props);
  }else if (props.type === 'trailHeaderBar') {  
    header = trailHeaderBar(props)
  }

  return header;
}

