export const SET_CODAT_DATA = "setCodatData"
export const GET_CODAT_DATA = "getCodatData"
export const SET_CODAT_CONFIG_FORM_DATA= "setCodatConfigFormData"
export const SET_IS_FORM_OTHER_OPTION_SELECTED ="setIsOtherFormOptionSelected"
export const SET_CODAT_INTEGRATION_RESPONSE="setCodatIntegrationResponse"
export const CREATE_CODAT_INTEGRATION_REQUEST="createCodatIntegrationRequest"
export const Get_CODAT_AUTHENTICATE_REQUEST="getCodatAuthenticateRequest"
export const SET_CODAT_AUTHENTICATE_RESPONSE="setCodatAuthenticateResponse"
export const SET_CODAT_REQUEST_MODAL="setCodatRequestModal"
export const CREATE_CODAT_CONFIGURATION_REQUEST= "createCodatConfigurationRequest"
export const SET_CODAT_CONFIGURATION_RESPONSE = "setCodatConfigurationResponse"
export const GET_CODAT_Authorize_Request = "getCodatAuthorizeRequest"
export const SET_CODAT_Authorize_Response = "setCodatAuthorizeRequest"