import createReducer from '../../../../components/common/Utility';

import {
    SCHEDULE_REPORT,
    SET_SCHEDULE_REPORT,
    CANCEL_SCHEDULED_REPORT,
    SET_CANCEL_SCHEDULED_REPORT,
    CREATE_CUSTOM_REPORT,
    SET_CREATE_CUSTOM_REPORT,
    LIST_WIDGETS,
    SET_LIST_WIDGETS,
    LIST_REPORTS,
    SET_LIST_REPORTS,
    DOWNLOAD_REPORTS,
    SET_DOWNLOAD_REPORTS,
    SEARCH_LIST_REPORTS,
    SET_SEARCH_LIST_REPORTS
} from '../../../actions/Admin/Analytics/Analytics.action.type';

// import { SEARCH_ACTION_LABEL } from '../../../actions/Common/ReportSearch/ReportSearch.action.type'

import { API_START, API_END, RESET_STATE } from '../../../actions/Common/Api.types';

export const initialState = {
    isLoadingData: false,
    isReportScheduled: false,
    isScheduledReportCanceled: false,
    isCostumReportCreated: false,
    respData: null,
    widgetsList: {},
    analyticsTableColumnsData: null,
    tableColumns: [],
    tableColumnsCount: 0,
    downloadReportData: null
}

export const reducerObj = {

    [SET_SCHEDULE_REPORT]: (prevState, payload) => (
        {
            isReportScheduled: true,
            respData: payload
        }
    ),

    [SET_CANCEL_SCHEDULED_REPORT]: (prevState, payload) => (
        {
            isScheduledReportCanceled: true,
            respData: payload
        }
    ),

    [SET_CREATE_CUSTOM_REPORT]: (prevState, payload) => (
        {
            isCostumReportCreated: true,
            respData: payload
        }
    ),

    [SET_LIST_WIDGETS]: (prevState, payload) => (
        {
            respData: null,
            widgetsList: payload
        }
    ),

    [SET_LIST_REPORTS]: (prevState, payload) => (
        {
            analyticsTableColumnsData: payload,
            tableColumns: payload.data || [],
            tableColumnsCount: payload?.total_records || 0,
        }
    ),

    [SET_SEARCH_LIST_REPORTS]: (prevState, payload) => (
        {
            isLoadingData: false,
            analyticsTableColumnsData: payload,
            tableColumns: payload?.message ? [] : payload.data,
            tableColumnsCount: payload?.message ? 0 : payload.data.length,
        }
    ),

    [SET_DOWNLOAD_REPORTS]: (prevState, payload) => (
        {
            respData: !payload["message"] ? {
                    ...payload,
                    message: payload.status ? "Downloaded Successfully" : "Failed to download"
                } : payload,
            downloadReportData: payload,
        }
    ),

    [RESET_STATE]: () => (
        {
            respData: null,
            // isLoadingData: false,
            isReportScheduled: false,
            isScheduledReportCanceled: false,
            isCostumReportCreated: false,
            downloadReportData: null
        }
    ),

    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === SCHEDULE_REPORT || payload === CANCEL_SCHEDULED_REPORT || payload === CREATE_CUSTOM_REPORT || payload === LIST_WIDGETS) {
            return (
                { isLoadingData: true }
            )
        }
        if (payload === LIST_REPORTS || payload === SEARCH_LIST_REPORTS) {
            return (
                {
                    tableColumns: [],
                    isLoadingData: true,
                    tableColumnsCount: 0,
                    analyticsTableColumnsData: null,
                }
            )
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === SET_SCHEDULE_REPORT || payload === SET_CANCEL_SCHEDULED_REPORT || payload === SET_CREATE_CUSTOM_REPORT || payload === SET_LIST_WIDGETS || payload === SET_LIST_REPORTS || payload === SET_DOWNLOAD_REPORTS || payload === SET_SEARCH_LIST_REPORTS) {
            return (
                { isLoadingData: false }
            )
        }
    },

}

let AnalyticsReducer = createReducer(initialState, reducerObj);

export default AnalyticsReducer;