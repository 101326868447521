import * as React from 'react'

export const CorporateBag = React.memo(
    ({ size = 12, color = '#1B7E49', className, ...rest }) => {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width={size}
                height={size}
                fill='none'
                viewBox={`0 0 ${size} ${size}`}
                className={className}
                {...rest}>
                <path
                    fill={color}
                    d='M4.5 0A1.5 1.5 0 003 1.5V3h-.75A2.25 2.25 0 000 5.25v4.5A2.25 2.25 0 002.25 12h7.5A2.25 2.25 0 0012 9.75v-4.5A2.25 2.25 0 009.75 3H9V1.5A1.5 1.5 0 007.5 0h-3zm3 3h-3V1.5h3V3z'></path>
            </svg>
        )
    }
)

CorporateBag.displayName = 'CorporateBag'
