export const FETCH_ALL_REPORTS = "fetchAllReports";
export const SET_ALL_MY_REPORTS = "setAllReports";

export const FETCH_DRAFT_REPORTS = "fetchDraftReports";
export const SET_DRAFT_REPORTS = "setDraftReports";

export const FETCH_DRAFT_EXPENSE = "fetchDraftExpense";
export const SET_DRAFT_EXPENSE = "setDraftExpense";

export const FETCH_PENDING_APPROVAL = "fetchPendingApproval";
export const SET_PENDING_APPROVAL = "setPendingApproval";

export const DELETE_DRAFT = "deleteDraft";
export const SET_DELETE_DRAFT = "setDeleteDraft";

export const FETCH_TRIP_IDS = "fetchTripIds";
export const SET_TRIP_IDS = "setTripIds";

export const CREATE_NEW_EXPENSE = "createNewExpense";
export const SET_NEW_EXPENSE = "setNewExpense";

export const EDIT_EXPENSE = "editExpense";
export const SET_EDIT_EXPENSE = "setEditExpense";

export const DELETE_EXPENSE = "deleteExpense";
export const SET_DELETE_EXPENSE = "setDeleteExpense";

export const CREATE_NEW_DRAFT_EXPENSE = "createNewDraftExpense";
export const SET_NEW_DRAFT_EXPENSE = "setNewDraftExpense";

export const CREATE_NEW_REPORT = "createNewReport"; // for creating a new report
export const SET_CREATE_NEW_REPORT = "setCreateNewReport";

export const FETCH_CALC_ATTR = "fetchCalcAttr"; // for calculated attribute
export const SET_CALC_ATTR = "setCalcAttr";

export const FETCH_TIME_BASED_POLICIES = "fetch_time_based_policies";
export const SET_TIME_BASED_POLICIES_DATA = "set_time_based_policies_data";

export const RESET_TRIPID_STATE="resetTripIdData";

export const DELETE_LOCAL_DRAFT_EXPENSE = "deleteLocalDraftExpense";
export const SET_DELETE_LOCAL_DRAFT_EXPENSE = "setDeleteLocalDraftExpense";

export const RESET_DRAFT_EXPENSE_STATE = "resetDraftExpenseState";
export const SET_RESET_DRAFT_EXPENSE_STATE = "setResetDraftExpenseState";

export const DELETE_LOCAL_DRAFT_REPORT = "deleteLocalDraftReport";
export const SET_DELETE_LOCAL_DRAFT_REPORT = "setDeleteLocalDraftReport";

export const RESET_ALL_REPORT_STATE = "resetAllReportState";
export const SET_RESET_ALL_REPORT_STATE = "setResetAllReportState";

// export const RESET_DRAFT_REPORT = "resetDraftReport";
// export const SET_RESET_DRAFT_REPORT = "setResetDraftReport";

export const RESET_DRAFT_DELETE_RESPONSE="resetDraftDeleteResponse";

//Travel Nudge
export const SET_USER_NAVIGATED_COUNT="setUserNavigatedCount"
export const SET_TRAVEL_NUDGE_USER_NAVIGATED_COUNT="setTravelNudgeUserNavigatedCount";

export const TRAVEL_NUDGE_ELIGIBILITY="travelNudgeEligibility"
export const SET_TRAVEL_NUDGE_ELIGIBILITY="setTravelNudgeEligility"

export const RESET_TRAVEL_NUDGE_ELIGIBILITY_RESPONSE="resetTravelNudgeEligibilityResponse"

