export const GET_TANDE_FEATURE_STATUS = "getTandEFeatureStatus";
export const SET_TANDE_FEATURE_STATUS = "setTandEFeatureStatus";
export const RESET_TANDE_FEATURE_STATUS = "resetTandEFeatureStatus";

export const TOGGLE_TANDE_FEATURE_STATUS = "toggleTandEFeatureStatus";
export const SET_TOGGLE_TANDE_FEATURE_STATUS = "setToggleTandEFeatureStatus";

export const GET_REIMBURSEMENT_STATUS = "getReimbursementStatus";
export const SET_REIMBURSEMENT_STATUS = "setReimbursementStatus";

export const TOGGLE_REIMBURSEMENT_OPTION = "toggleReimbursementOption";
export const SET_TOGGLE_REIMBURSEMENT_OPTION = "setToggleReimbursementOption";

export const GET_CATEGORIES_MAPPING = "getCategoriesMapping";
export const SET_CATEGORIES_MAPPING = "setCategoriesMapping";

export const GET_ATTRIBUTE_MAPPING = "getAttributeMapping";
export const SET_ATTRIBUTE_MAPPING = "setAttributeMapping";

export const EDIT_CATEGORY_MAPPING = "editCategoryMapping";
export const SET_EDIT_CATEGORY_MAPPING = "setEditCategoryMapping";
export const RESET_EDIT_CATEGORY_MAPPING = "resetEditCategoryMapping";

export const RESET_ATTRIBUTE_MAPPING = "resetAttributeMapping";

export const RESET_TANDE_STATE = "resetTandEState";

export const RESET_COMMON_ERROR_RESPONSE = "resetCommonResponse"