export const FETCH_ALL_DATA = "fetchAllCreateCategory";
export const SET_ALL_DATA = "setAllCreateCategory";

// attributes 
export const FETCH_ALL_ATTRIBUTES = "fetchAllAttributes";
export const SET_ALL_ATTRIBUTES = "setAllAttributes";

// attributes types
export const FETCH_ALL_ATTRIBUTES_TYPES = "fetchAllAttributesTypes";
export const SET_ALL_ATTRIBUTES_TYPES = "setAllAttributesTypes";

// create
export const CREATE_CATEGORY = "createCategory";
export const SET_CREATED_CATEGORY = "setCreatedCategory";

// edit
export const EDIT_CATEGORY = "editCategory";
export const SET_EDITED_CATEGORY = "setEditedCategory";

// misc/calc
export const FETCH_CALC = "fetchCalc";
export const SET_CALC = "setCalc";



