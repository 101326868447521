import * as React from 'react'

export const ItiliteLogo = React.memo(
    ({ size = 16, color = '#EC5D25', className, ...rest }) => {
        return (
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width={size}
                height={size}
                fill='none'
                viewBox={`0 0 ${size} ${size}`}
                className={className}
                {...rest}>
                <path
                    fill={color}
                    d='M16 8c0 4.417-3.583 8-8 8s-8-3.583-8-8 3.583-8 8-8 8 3.583 8 8zm-2.012-3.57l-.16-.111-.11-.012h-.54l-.22.11-.332.209-.442.331-.442.331-.527.43-1.89 1.558-.503.012-2.086-.184-1.362-.086-.147.037-1.521.847L3.632 8l-.012.086.086.049 3.042 1.497-1.521 1.534-1.89-.234-.38.013-.982.65-.049.184 1.988.908h.368l.957-.43 2.59-1.398 3.079-2.626 2.172-1.939.675-.674.184-.32.11-.208.11-.331v-.11l-.049-.111-.122-.11z'></path>
            </svg>
        )
    }
)

ItiliteLogo.displayName = 'ItiliteLogo'
