const GTMService = (function () {
    function _init(__res) {

        // you have to provide user information from your store
        const userinfo = {
            email: __res.email,
            username: __res.username,
            first_name: __res.first_name,
            last_name: __res.last_name,
            role: __res.role,
            client_id: __res.client_id,
            user_id: __res.user_id,
            company_name: __res.company_name,
            usm_company_id: __res.usm_company_id
        };
        window.dataLayer.push(userinfo);
        // console.log(gtm__obj,"dev")
        window.dataLayer.push({ event: "user-data-set-3" });
    }
    return {
        init: _init,
    };
})();

export default GTMService;