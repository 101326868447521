export const GET_APPLY_ADVANCES = "getApplyAdvances";
export const SET_APPLY_ADVANCES = "setApplyAdvances";

export const GET_PAYMENTS_QUEUE = "getPaymentsQueue";
export const SET_PAYMENTS_QUEUE = "setPaymentsQueue";

export const GET_PAYMENT_PROCESSING = "getProcessing";
export const SET_PAYMENT_PROCESSING = "setProcessing";

export const GET_PAID = "getPaid";
export const SET_PAID = "setPaid";

export const MOVE_TO_PAID_MODAL = "moveToPaidModal";
export const SET_MOVE_TO_PAID_MODAL = "setMoveToPaidModal";

export const SUBMIT_REJECTION_REASON = "submitRejectReason";
export const SET_SUBMIT_REJECTION_REASON = "setSubmitRejectReason";

export const MOVE_TO_PAID = "moveToPaid";
export const SET_MOVE_TO_PAID = "setMoveToPaid";

export const SEND_TO_PROCESSING = "sendToProcessing";
export const SET_SEND_TO_PROCESSING = "setSendToProcessing";

export const SEND_TO_QUEUE = "sendToQueue";
export const SET_SEND_TO_QUEUE = "setSendToQueue";

export const GET_PAYOUT_COUNT = "getPayoutCount";
export const SET_PAYOUT_COUNT = "setPayoutCount";

export const MAKE_PAYMENT_USING_ACH = "makePaymentUsingAch"
export const SET_ACH_PAYMENT_RESPONSE = "setAchPaymentResponse"

export const CANCEL_ACH_PAYMENT = "cancelAchPayment"
export const SET_CANCEL_ACH_PAYMENT_RESPONSE = "setCancelAchPaymentResponse"

export const GET_ACH_ERROR_PAYMENT = "getAchErrorPayment"
export const SET_ACH_PAYMENT_ERROR_RESPONSE = "setAchPaymentErrorResponse"

export const SET_ACTION_ROW_ID = "setActionRowId" // use to identify, on which row action is being taken
// export const  RESET_COMMON_RESPONSE_PAYOUT = "resetPayoutCommonErrorResponse"

export const BULK_PROCESSING_TO_PAID = "bulkProcessingToPaid";
export const SET_BULK_PROCESSING_TO_PAID = "setBulkProcessingToPaid";
export const RESET_BULK_PROCESSING_TO_PAID = "resetBulkProcessingToPaid";

export const  RESET_COMMON_RESPONSE_PAYOUT = "resetCommonResponse"

export const START_MASS_ACH_PAYMENTS = "initiateMassPayments"
export const SET_MASS_ACH_PAYMENTS_RESPONSE = "setMassPaymentResponse"

export const SET_TYPE_OF_ACTION = "setTypeOfAction"

export const SET_DOWNLOADED_REPORT_URL = "setDownloadedReportUrl"

export const START_MANUAL_AND_ACH_PAYMENT = "processManualAndAchPayment"
export const SET_MANUAL_AND_ACH_PAYMENT_RESPONSE = "setProcessManualAndAchPaymentResponse"

export const START_REPORT_COUNT_API = "startReportCountApi"
export const SET_REPORT_COUNT_API = "setReportCountApi"

export const PAYOUT_FILTER_OPTIONS = "payoutFilterOptions";
export const SET_PAYOUT_FILTER_OPTIONS = "setPayoutFilterOptions";

export const SELECTED_FILTER_DATA = "selectedFilterData";
export const SET_SELECTED_FILTER_DATA = "setSelectedFilterData";
export const RESET_SELECTED_FILTER_DATA = "resetSelectedFilterData";
