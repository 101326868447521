import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import localStorageService from "../Storage_services/LocalStorageService";
import axios from "axios";
import { redirectUrls } from "./RedirectUrls";
import { generateJWTAuthKey } from "../../../../utils/utils";
import { subscriptionUrls } from "../../../Onboarding/Subscription/ApiUrls";
import { services } from "@itilite/universal-ui";


const AuthRoute = (props) => {
  let history = useHistory();
  const { UUILocalStorageService } = services;

  UUILocalStorageService.removeUUITokens();
  localStorageService.clearToken();
  localStorageService.closeTrailHeader();

  if (props.computedMatch.params.token) {
    const params = new URLSearchParams(props.computedMatch.url);
    const pgNo = params.get("pg");

    let urlToReturn = "/";
    const accessCode = props.computedMatch.params.token.split("&");
    localStorageService.setToken(JSON.stringify(accessCode[0]));
    localStorageService.setFlowToken("/");
    localStorageService.showTrailHeader(true);
    axios({
      method: "get",
      url: subscriptionUrls.GET_CLIENT_INFO,
      headers: {
        "access-token": JSON.parse(localStorageService.getAccessToken()),
        "request-from": "web",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 1 || res.data.status === true) {
            const authKey = generateJWTAuthKey();
            props.setClientDetails(res.data.result);
            props.setUrlToReturnForSubscription("/getting-started");

            if (res.data.result.subscription_required === 0) {
              if (pgNo && redirectUrls[pgNo] !== undefined)
                urlToReturn = redirectUrls[pgNo];

              localStorageService.clearSubscriptionToken();
              localStorageService.clearFlowToken();
              props.setDisplaySidebar(true);
              props.setUrlToReturnForSubscription("");
              return history.push(urlToReturn);
            }

            axios({
              method: "POST",
              url: subscriptionUrls.IL_ADMIN_VERIFY,
              data: {
                expense_client_id: res.data.result.client_id,
                username: res.data.result.user_name,
              },
              headers: {
                Authorization: authKey,
                "access-token": JSON.parse(
                  localStorageService.getAccessToken()
                ),
                "service-type": "client-onboarding",
              },
            }).then((resp) => {
              if (
                resp.status === 200 &&
                resp.data &&
                resp.data.iladmin_action
              ) {
                props.setIlAdminMenuVisible(true);
              }
            });

            return axios({
              method: "POST",
              url: subscriptionUrls.FETCH_CLIENT_SUBSCRIPTION,
              data: {
                expense_client_id: res.data.result.client_id,
              },
              headers: {
                Authorization: authKey,
                "access-token": JSON.parse(
                  localStorageService.getAccessToken()
                ),
                "service-type": "client-onboarding",
              },
            });
          } else {
            localStorageService.clearToken();
            localStorageService.clearFlowToken();
            localStorageService.clearSubscriptionToken();
            localStorageService.closeTrailHeader();
            return history.push(`/`);
          }
        }
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data?.status) {
            if (pgNo && redirectUrls[pgNo] !== undefined) {
              urlToReturn = redirectUrls[pgNo];
            }
            localStorageService.clearFlowToken();
            localStorageService.setSubscriptionToken(btoa("enabled"));
            props.setDisplaySidebar(true);
          } else {
            urlToReturn = "/subscriptions";
            localStorageService.setFlowToken("/subscriptions");
            props.setDisplaySidebar(false);
            localStorageService.clearSubscriptionToken();
          }

          props.setUrlToReturnForSubscription("");
          return history.push(`${urlToReturn}`);
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorageService.clearToken();
          localStorageService.clearFlowToken();
          localStorageService.clearSubscriptionToken();
          localStorageService.closeTrailHeader();
          props.setInitCheck(true);
          props.setDisplaySidebar(true);
        }
      });
    return <Redirect to={urlToReturn} />;
  } else {
    localStorageService.clearToken();
    localStorageService.clearFlowToken();
    localStorageService.clearSubscriptionToken();
    localStorageService.closeTrailHeader();
    return <Redirect to="/" />;
  }
};

export default AuthRoute;
