export const FETCH_RULES = "fetchRules";
export const SET_RULES = "setRules";
export const SAVE_RULES = "savePolicy";
export const SET_SAVE_RULES = "setSavePolicy";
export const SET_RULES_BY_ID = "setPolicyById";
export const FETCH_RULES_BY_ID = "fetchPolicyById";

export const GET_SAVE_APPROVAL_RULE_PRIORITY_ORDER = "getSaveApprovalRulePriorityOrder";
export const SET_SAVE_APPROVAL_RULE_PRIORITY_ORDER = "setSaveApprovalRulePriorityOrder";

export const SET_SAVE_POLICY_FALSE="setSavePolicyFalse";

export const SET_APPROVAL_LIST_FOR_PAGE_DATA="setApprovalListForPageData";

export const CHANGE_APPROVAL_FLOW_STATUS = "changeApprovalFlowStatus"
export const SET_APPROVAL_FLOW_STATUS = "setApprovalFlowStatus"
export const RESET_APPROVAL_FLOW_STATUS_RESPONSE = "resetApprovalFlowStatusResponse"



// Email based approval
export const GET_USERS = "getUsers";
export const SET_USERS = "setUsers";

export const GENERATE_USM_TOKEN = "generateUSMToken";
export const SET_USM_TOKEN = "setUSMToken";