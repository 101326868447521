import React from 'react'

export const PerDiemIcon = () => <svg width="20" height="21" viewBox="0 0 20 21" fill="orange" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_868_19169)">
                                    <path d="M15.8327 3.83341H14.166V3.00008C14.166 2.77907 14.0782 2.56711 13.9219 2.41083C13.7657 2.25455 13.5537 2.16675 13.3327 2.16675C13.1117 2.16675 12.8997 2.25455 12.7434 2.41083C12.5871 2.56711 12.4993 2.77907 12.4993 3.00008V3.83341H7.49935V3.00008C7.49935 2.77907 7.41155 2.56711 7.25527 2.41083C7.09899 2.25455 6.88703 2.16675 6.66602 2.16675C6.445 2.16675 6.23304 2.25455 6.07676 2.41083C5.92048 2.56711 5.83268 2.77907 5.83268 3.00008V3.83341H4.16602C3.50297 3.83341 2.86709 4.09681 2.39825 4.56565C1.92941 5.03449 1.66602 5.67037 1.66602 6.33341V16.3334C1.66602 16.9965 1.92941 17.6323 2.39825 18.1012C2.86709 18.57 3.50297 18.8334 4.16602 18.8334H15.8327C16.4957 18.8334 17.1316 18.57 17.6004 18.1012C18.0693 17.6323 18.3327 16.9965 18.3327 16.3334V6.33341C18.3327 5.67037 18.0693 5.03449 17.6004 4.56565C17.1316 4.09681 16.4957 3.83341 15.8327 3.83341ZM16.666 16.3334C16.666 16.5544 16.5782 16.7664 16.4219 16.9227C16.2657 17.079 16.0537 17.1667 15.8327 17.1667H4.16602C3.945 17.1667 3.73304 17.079 3.57676 16.9227C3.42048 16.7664 3.33268 16.5544 3.33268 16.3334V10.5001H16.666V16.3334ZM16.666 8.83341H3.33268V6.33341C3.33268 6.1124 3.42048 5.90044 3.57676 5.74416C3.73304 5.58788 3.945 5.50008 4.16602 5.50008H5.83268V6.33341C5.83268 6.55443 5.92048 6.76639 6.07676 6.92267C6.23304 7.07895 6.445 7.16675 6.66602 7.16675C6.88703 7.16675 7.09899 7.07895 7.25527 6.92267C7.41155 6.76639 7.49935 6.55443 7.49935 6.33341V5.50008H12.4993V6.33341C12.4993 6.55443 12.5871 6.76639 12.7434 6.92267C12.8997 7.07895 13.1117 7.16675 13.3327 7.16675C13.5537 7.16675 13.7657 7.07895 13.9219 6.92267C14.0782 6.76639 14.166 6.55443 14.166 6.33341V5.50008H15.8327C16.0537 5.50008 16.2657 5.58788 16.4219 5.74416C16.5782 5.90044 16.666 6.1124 16.666 6.33341V8.83341Z" fill="#4A5568"/>
                                    <circle cx="6.75" cy="13.75" r="1.75" fill="#4A5568"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_868_19169">
                                    <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                                    </clipPath>
                                    </defs>
                                  </svg>
export const PerDiemIconOrange = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M19 4H17V3C17 2.73478 16.8946 2.48043 16.7071 2.29289C16.5196 2.10536 16.2652 2 16 2C15.7348 2 15.4804 2.10536 15.2929 2.29289C15.1054 2.48043 15 2.73478 15 3V4H9V3C9 2.73478 8.89464 2.48043 8.70711 2.29289C8.51957 2.10536 8.26522 2 8 2C7.73478 2 7.48043 2.10536 7.29289 2.29289C7.10536 2.48043 7 2.73478 7 3V4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H19C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V12H20V19ZM20 10H4V7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H7V7C7 7.26522 7.10536 7.51957 7.29289 7.70711C7.48043 7.89464 7.73478 8 8 8C8.26522 8 8.51957 7.89464 8.70711 7.70711C8.89464 7.51957 9 7.26522 9 7V6H15V7C15 7.26522 15.1054 7.51957 15.2929 7.70711C15.4804 7.89464 15.7348 8 16 8C16.2652 8 16.5196 7.89464 16.7071 7.70711C16.8946 7.51957 17 7.26522 17 7V6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V10Z" fill="#EB5C24"/>
                                      <circle cx="7.75" cy="15.75" r="1.75" fill="#EB5C24"/>
                                      </svg>

export const RightPointingArrow = () => <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683417 -0.097631 1.31658 -0.097631 1.70711 0.292893L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683417 10.0976 0.292893 9.70711Z" fill="#6B7280"/>
                                        </svg>
export const CountryFlagIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1226_8716" fill="white">
                                        <path d="M2 2H13.4519C13.8725 2 14.1052 2.48769 13.8406 2.8146L11.6667 5.5L13.8406 8.1854C14.1052 8.51231 13.8725 9 13.4519 9H2V2Z"/>
                                        </mask>
                                        <path d="M2 2V0C0.89543 0 0 0.89543 0 2L2 2ZM2 9H0C0 10.1046 0.89543 11 2 11L2 9ZM11.6667 5.5L10.1122 4.2416C9.51816 4.97539 9.51816 6.02461 10.1122 6.7584L11.6667 5.5ZM2 4H13.4519V0H2V4ZM13.4519 7H2V11H13.4519V7ZM4 9V2H0V9H4ZM12.2861 1.5562L10.1122 4.2416L13.2212 6.7584L15.3951 4.073L12.2861 1.5562ZM10.1122 6.7584L12.2861 9.4438L15.3951 6.927L13.2212 4.2416L10.1122 6.7584ZM13.4519 11C15.5549 11 16.7183 8.56154 15.3951 6.927L12.2861 9.4438C11.4922 8.46307 12.1901 7 13.4519 7V11ZM13.4519 4C12.1901 4 11.4922 2.53693 12.2861 1.5562L15.3951 4.073C16.7183 2.43845 15.5549 0 13.4519 0V4Z" fill="#6B7280" mask="url(#path-1-inside-1_1226_8716)"/>
                                        <path d="M3 13L3 7" stroke="#6B7280" strokeWidth="2" strokeLinecap="round"/>
                                      </svg>

export const CityIcon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3 12C2.44772 12 2 12.4477 2 13C2 13.5523 2.44772 14 3 14L3 12ZM13 14C13.5523 14 14 13.5523 14 13C14 12.4477 13.5523 12 13 12L13 14ZM3 14L13 14L13 12L3 12L3 14Z" fill="#6B7280"/>
                              <rect x="4" y="3" width="8" height="10" rx="1" stroke="#6B7280" strokeWidth="2"/>
                              <path d="M8.23828 6H9.73828" stroke="#6B7280" strokeWidth="1.5"/>
                              <path d="M5.56641 6H7.06641" stroke="#6B7280" strokeWidth="1.5"/>
                              <path d="M5.56641 9.03125H7.06641" stroke="#6B7280" strokeWidth="1.5"/>
                              </svg>

export const activeRightNavigationSVG = () =>  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M0.292893 11.7071C-0.0976311 11.3166 -0.097631 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.097631 1.31658 -0.0976311 0.683418 0.292893 0.292894C0.683418 -0.0976312 1.31658 -0.0976312 1.70711 0.292894L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071Z" fill="#EC5D25"/>
                                        </svg>

export const exitNavigationSVG = () => <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0.732955 11V0.818182H6.87784V1.91193H1.96591V5.35227H6.55966V6.44602H1.96591V9.90625H6.95739V11H0.732955ZM9.81605 3.36364L11.6456 6.4858L13.4751 3.36364H14.8274L12.3615 7.18182L14.8274 11H13.4751L11.6456 8.03693L9.81605 11H8.46378L10.8899 7.18182L8.46378 3.36364H9.81605ZM16.5016 11V3.36364H17.6749V11H16.5016ZM17.0982 2.09091C16.8695 2.09091 16.6723 2.01302 16.5066 1.85724C16.3442 1.70147 16.263 1.5142 16.263 1.29545C16.263 1.0767 16.3442 0.889441 16.5066 0.733664C16.6723 0.577888 16.8695 0.5 17.0982 0.5C17.3269 0.5 17.5224 0.577888 17.6848 0.733664C17.8506 0.889441 17.9334 1.0767 17.9334 1.29545C17.9334 1.5142 17.8506 1.70147 17.6848 1.85724C17.5224 2.01302 17.3269 2.09091 17.0982 2.09091ZM23.1449 3.36364V4.35795H19.1875V3.36364H23.1449ZM20.3409 1.53409H21.5142V8.8125C21.5142 9.14394 21.5623 9.39252 21.6584 9.55824C21.7578 9.72064 21.8838 9.83002 22.0362 9.88636C22.192 9.93939 22.3561 9.96591 22.5284 9.96591C22.6577 9.96591 22.7637 9.95928 22.8466 9.94602C22.9295 9.92945 22.9957 9.91619 23.0455 9.90625L23.2841 10.9602C23.2045 10.9901 23.0935 11.0199 22.951 11.0497C22.8085 11.0829 22.6278 11.0994 22.4091 11.0994C22.0777 11.0994 21.7528 11.0282 21.4347 10.8857C21.1198 10.7431 20.858 10.526 20.6491 10.2344C20.4437 9.94271 20.3409 9.57481 20.3409 9.13068V1.53409Z" fill="#4B5563"/>
                                </svg>

export const inactiveLeftNavigationSVG = () => <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M6.70711 11.7071C7.09763 11.3166 7.09763 10.6834 6.70711 10.2929L2.41421 6L6.70711 1.70711C7.09763 1.31658 7.09763 0.683418 6.70711 0.292894C6.31658 -0.0976312 5.68342 -0.0976312 5.29289 0.292894L0.292893 5.29289C-0.097631 5.68342 -0.097631 6.31658 0.292893 6.70711L5.29289 11.7071C5.68342 12.0976 6.31658 12.0976 6.70711 11.7071Z" fill="#D1D5DB"/>
                                        </svg>


export const activeLeftNavigationSVG = () => <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.70711 11.7071C7.09763 11.3166 7.09763 10.6834 6.70711 10.2929L2.41421 6L6.70711 1.70711C7.09763 1.31658 7.09763 0.683418 6.70711 0.292894C6.31658 -0.0976312 5.68342 -0.0976312 5.29289 0.292894L0.292893 5.29289C-0.097631 5.68342 -0.097631 6.31658 0.292893 6.70711L5.29289 11.7071C5.68342 12.0976 6.31658 12.0976 6.70711 11.7071Z" fill="#EC5D25"/>
                                      </svg>


//T&E Integration SVG

export const goToLinkArrow = () => <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683417 -0.097631 1.31658 -0.097631 1.70711 0.292893L5.70711 4.29289C6.09763 4.68342 6.09763 5.31658 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683417 10.0976 0.292893 9.70711Z" fill="#6B7280"/>
                                  </svg>

export const disbaledHeaderExclamation = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 5C11 5.55228 10.5523 6 10 6C9.44771 6 9 5.55228 9 5C9 4.44772 9.44771 4 10 4C10.5523 4 11 4.44772 11 5Z" fill="#6B7280"/>
                                                <path d="M8 8C7.44772 8 7 8.44771 7 9C7 9.55229 7.44772 10 8 10H9V13H8C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H12C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13H11V9C11 8.44771 10.5523 8 10 8H8Z" fill="#6B7280"/>
                                                <path d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2Z" fill="#6B7280"/>
                                              </svg>
export const CloseButtonCrossIcon = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#6B7280"/>
                                          </svg>

export const LockIcon = () => <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M5.16399 9.75222C5.98555 9.75222 6.65156 9.08622 6.65156 8.26466C6.65156 7.4431 5.98555 6.7771 5.16399 6.7771C4.34244 6.7771 3.67643 7.4431 3.67643 8.26466C3.67643 9.08622 4.34244 9.75222 5.16399 9.75222ZM5.16399 8.41889C5.24917 8.41889 5.31822 8.34984 5.31822 8.26466C5.31822 8.17948 5.24917 8.11043 5.16399 8.11043C5.07882 8.11043 5.00977 8.17948 5.00977 8.26466C5.00977 8.34984 5.07882 8.41889 5.16399 8.41889Z" fill="#6B7280"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M2.45312 3.47966V2.78511C2.45312 1.43105 3.55081 0.333374 4.90487 0.333374C6.25892 0.333374 7.35661 1.43106 7.35661 2.78511V3.47966H8C9.10457 3.47966 10 4.37509 10 5.47966V11C10 12.1046 9.10457 13 8 13H2C0.89543 13 0 12.1046 0 11V5.47966C0 4.37509 0.895431 3.47966 2 3.47966H2.45312ZM3.78646 2.78511C3.78646 2.16744 4.28719 1.66671 4.90487 1.66671C5.52254 1.66671 6.02327 2.16744 6.02327 2.78511V3.47966H3.78646V2.78511ZM8 4.81299H2C1.63181 4.81299 1.33333 5.11147 1.33333 5.47966V11C1.33333 11.3682 1.63181 11.6667 2 11.6667H8C8.36819 11.6667 8.66667 11.3682 8.66667 11V5.47966C8.66667 5.11147 8.36819 4.81299 8 4.81299Z" fill="#6B7280"/>
                              </svg>
export const Info_I_Icon = () => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" fill="#6B7280"/>
                                  <path d="M8.5 10.5V6H6.5V7H7.5V10.5H6V11.5H10V10.5H8.5Z" fill="#6B7280"/>
                                  <path d="M8 3.5C7.58579 3.5 7.25 3.83579 7.25 4.25C7.25 4.66421 7.58579 5 8 5C8.41421 5 8.75 4.66421 8.75 4.25C8.75 3.83579 8.41421 3.5 8 3.5Z" fill="#6B7280"/>
                                  </svg>

export const UpArrow = () => <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.7072 5.70692C10.5197 5.89439 10.2654 5.99971 10.0002 5.99971C9.73505 5.99971 9.48074 5.89439 9.29321 5.70692L6.00021 2.41392L2.70721 5.70692C2.61497 5.80243 2.50462 5.87861 2.38262 5.93102C2.26061 5.98343 2.12939 6.01102 1.99661 6.01217C1.86384 6.01332 1.73216 5.98802 1.60926 5.93774C1.48636 5.88746 1.37471 5.81321 1.28082 5.71931C1.18693 5.62542 1.11267 5.51377 1.06239 5.39087C1.01211 5.26798 0.986809 5.1363 0.987963 5.00352C0.989117 4.87074 1.0167 4.73952 1.06911 4.61751C1.12152 4.49551 1.1977 4.38517 1.29321 4.29292L5.29321 0.292919C5.48074 0.105448 5.73505 0.000132561 6.00021 0.000132561C6.26538 0.000132561 6.51969 0.105448 6.70721 0.292919L10.7072 4.29292C10.8947 4.48045 11 4.73475 11 4.99992C11 5.26508 10.8947 5.51939 10.7072 5.70692V5.70692Z" fill="#EC5D25"/>
                              </svg>


//TandE SVGs
export const travelBagSVG = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" fill="white"/>
                                    <rect x="2.5" y="14.5" width="19" height="2.5" rx="1" fill="#FAA666"/>
                                    <rect x="2.5" y="7" width="19" height="13" rx="4" stroke="#111827" stroke-width="2"/>
                                    <path d="M16 9V6C16 4.34315 14.6569 3 13 3H11C9.34315 3 8 4.34315 8 6V9" stroke="#111827" stroke-width="2" stroke-linecap="round"/>
                                  </svg>

export const dottedNavigationSVG = () => <svg width="24" height="51" viewBox="0 0 24 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="12" cy="2" r="2" fill="#B6BAC3"/>
                                            <circle cx="12" cy="14" r="2" fill="#B6BAC3"/>
                                            <path d="M12 27L15 24H9L12 27Z" fill="#B6BAC3" stroke="#B6BAC3" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                            <circle cx="12" cy="37" r="2" fill="#B6BAC3"/>
                                            <circle cx="12" cy="49" r="2" fill="#B6BAC3"/>
                                          </svg>

export const expenseSVG = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect width="24" height="24" fill="white"/>
                                  <path d="M6 21V2.75L8 4L9.75 2.75L11.5 4L13.25 2.75L15 4L16.75 2.75L18.25 4L20 2.75V15C20 18.3137 17.3137 21 14 21H6Z" stroke="#111827" stroke-width="2" stroke-linejoin="round"/>
                                  <path d="M3 16.5H15V18.5C15 19.8807 16.1193 21 17.5 21H7.5C5.01472 21 3 18.9853 3 16.5Z" fill="white" stroke="#111827" stroke-width="2" stroke-linejoin="round"/>
                                  <path d="M8.5 7L15.5 7" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M8.5 9.5L14.5 9.5" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M8.5 12L11.5 12" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M14.5 14L17.5 14" stroke="#FF8183" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

export const dashSVG = () => <svg width="20" height="4" viewBox="0 0 20 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.53317 3.33341C2.00428 3.33341 1.56064 3.20541 1.20224 2.94941C0.845082 2.6943 0.666504 2.37786 0.666504 2.00008C0.666504 1.6223 0.845082 1.30541 1.20224 1.04941C1.56064 0.794303 2.00428 0.666748 2.53317 0.666748H17.4665C17.9954 0.666748 18.4384 0.794303 18.7956 1.04941C19.154 1.30541 19.3332 1.6223 19.3332 2.00008C19.3332 2.37786 19.154 2.6943 18.7956 2.94941C18.4384 3.20541 17.9954 3.33341 17.4665 3.33341H2.53317Z" fill="#D1D5DB"/>
                            </svg>

export const PerdiemNodatasvg = () => <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <g clipPath="url(#clip0_678_52274)">
                                          <path d="M25.3346 5.33433H22.668V4.001C22.668 3.64737 22.5275 3.30824 22.2774 3.05819C22.0274 2.80814 21.6883 2.66766 21.3346 2.66766C20.981 2.66766 20.6419 2.80814 20.3918 3.05819C20.1418 3.30824 20.0013 3.64737 20.0013 4.001V5.33433H12.0013V4.001C12.0013 3.64737 11.8608 3.30824 11.6108 3.05819C11.3607 2.80814 11.0216 2.66766 10.668 2.66766C10.3143 2.66766 9.97521 2.80814 9.72516 3.05819C9.47511 3.30824 9.33463 3.64737 9.33463 4.001V5.33433H6.66797C5.6071 5.33433 4.58969 5.75576 3.83954 6.5059C3.0894 7.25605 2.66797 8.27346 2.66797 9.33433V25.3343C2.66797 26.3952 3.0894 27.4126 3.83954 28.1628C4.58969 28.9129 5.6071 29.3343 6.66797 29.3343H25.3346C26.3955 29.3343 27.4129 28.9129 28.1631 28.1628C28.9132 27.4126 29.3346 26.3952 29.3346 25.3343V9.33433C29.3346 8.27346 28.9132 7.25605 28.1631 6.5059C27.4129 5.75576 26.3955 5.33433 25.3346 5.33433ZM26.668 25.3343C26.668 25.688 26.5275 26.0271 26.2774 26.2771C26.0274 26.5272 25.6883 26.6677 25.3346 26.6677H6.66797C6.31435 26.6677 5.97521 26.5272 5.72516 26.2771C5.47511 26.0271 5.33464 25.688 5.33464 25.3343V16.001H26.668V25.3343ZM26.668 13.3343H5.33464V9.33433C5.33464 8.98071 5.47511 8.64157 5.72516 8.39152C5.97521 8.14147 6.31435 8.001 6.66797 8.001H9.33463V9.33433C9.33463 9.68795 9.47511 10.0271 9.72516 10.2771C9.97521 10.5272 10.3143 10.6677 10.668 10.6677C11.0216 10.6677 11.3607 10.5272 11.6108 10.2771C11.8608 10.0271 12.0013 9.68795 12.0013 9.33433V8.001H20.0013V9.33433C20.0013 9.68795 20.1418 10.0271 20.3918 10.2771C20.6419 10.5272 20.981 10.6677 21.3346 10.6677C21.6883 10.6677 22.0274 10.5272 22.2774 10.2771C22.5275 10.0271 22.668 9.68795 22.668 9.33433V8.001H25.3346C25.6883 8.001 26.0274 8.14147 26.2774 8.39152C26.5275 8.64157 26.668 8.98071 26.668 9.33433V13.3343Z" fill="#4A5568"/>
                                          <circle cx="9.5" cy="20.501" r="2.5" fill="#4A5568"/>
                                          </g>
                                          <defs>
                                          <clipPath id="clip0_678_52274">
                                          <rect width="32" height="32" fill="white" transform="translate(0 0.000976562)"/>
                                          </clipPath>
                                          </defs>
                                      </svg>



// Export Templates SVG
export const ExportTemplate = () => <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.16667 11.0625C6 10.8958 5.92 10.6944 5.92667 10.4583C5.93389 10.2222 6.02083 10.0208 6.1875 9.85417L7.70833 8.33333H1.33333C1.09722 8.33333 0.899445 8.25333 0.74 8.09333C0.58 7.93389 0.5 7.73611 0.5 7.5C0.5 7.26389 0.58 7.06583 0.74 6.90583C0.899445 6.74639 1.09722 6.66667 1.33333 6.66667H7.70833L6.16667 5.125C6 4.95833 5.91667 4.76056 5.91667 4.53167C5.91667 4.30222 6 4.10417 6.16667 3.9375C6.33333 3.77083 6.53139 3.6875 6.76083 3.6875C6.98972 3.6875 7.1875 3.77083 7.35417 3.9375L10.3333 6.91667C10.4167 7 10.4758 7.09028 10.5108 7.1875C10.5453 7.28472 10.5625 7.38889 10.5625 7.5C10.5625 7.61111 10.5453 7.71528 10.5108 7.8125C10.4758 7.90972 10.4167 8 10.3333 8.08333L7.33333 11.0833C7.18056 11.2361 6.98972 11.3125 6.76083 11.3125C6.53139 11.3125 6.33333 11.2292 6.16667 11.0625ZM2.16667 15C1.70833 15 1.31583 14.8369 0.989167 14.5108C0.663055 14.1842 0.5 13.7917 0.5 13.3333V10.8333C0.5 10.5972 0.58 10.3992 0.74 10.2392C0.899445 10.0797 1.09722 10 1.33333 10C1.56944 10 1.7675 10.0797 1.9275 10.2392C2.08694 10.3992 2.16667 10.5972 2.16667 10.8333V13.3333H13.8333V1.66667H2.16667V4.16667C2.16667 4.40278 2.08694 4.60056 1.9275 4.76C1.7675 4.92 1.56944 5 1.33333 5C1.09722 5 0.899445 4.92 0.74 4.76C0.58 4.60056 0.5 4.40278 0.5 4.16667V1.66667C0.5 1.20833 0.663055 0.815833 0.989167 0.489167C1.31583 0.163055 1.70833 0 2.16667 0H13.8333C14.2917 0 14.6842 0.163055 15.0108 0.489167C15.3369 0.815833 15.5 1.20833 15.5 1.66667V13.3333C15.5 13.7917 15.3369 14.1842 15.0108 14.5108C14.6842 14.8369 14.2917 15 13.8333 15H2.16667Z" fill="#4A5568"/>
</svg>

export const PlusIcon = () => <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 0.00100708C5.55228 0.00100708 6 0.448722 6 1.00101V4.00101H9C9.55229 4.00101 10 4.44872 10 5.00101C10 5.55329 9.55229 6.00101 9 6.00101H6V9.00101C6 9.55329 5.55228 10.001 5 10.001C4.44772 10.001 4 9.55329 4 9.00101V6.00101H1C0.447715 6.00101 0 5.55329 0 5.00101C0 4.44872 0.447715 4.00101 1 4.00101H4V1.00101C4 0.448722 4.44772 0.00100708 5 0.00100708Z" fill="white"/>
</svg>

export const TemplateSVG = () => <svg width="95" height="105" viewBox="0 0 95 105" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M87.5 90C87.5 91.375 86.38 92.5 85 92.5H56.339C54.903 95.2551 53.0838 97.7787 50.9521 100H85C90.52 100 95 95.52 95 90V39.14C95 36.49 93.945 33.945 92.07 32.07L62.925 2.93C62.8502 2.85524 62.7656 2.7904 62.6814 2.72598C62.6192 2.67837 62.5574 2.63099 62.5 2.58C62.145 2.26 61.795 1.945 61.405 1.68C61.2786 1.59471 61.1403 1.52738 61.0026 1.46036C60.9224 1.4213 60.8423 1.38234 60.765 1.34C60.6815 1.29297 60.5983 1.24483 60.515 1.19663C60.244 1.03985 59.9718 0.882375 59.685 0.76C58.7 0.35 57.64 0.145 56.565 0.0699997C56.4665 0.0637114 56.3689 0.0503945 56.271 0.0370467C56.1354 0.0185525 55.9994 0 55.86 0H25C19.48 0 15 4.48 15 10V47.4908C17.3709 46.5021 19.8839 45.7862 22.5 45.3822V10C22.5 8.625 23.62 7.5 25 7.5H55V30C55 35.52 59.48 40 65 40H87.5V90ZM62.5 13.105L81.89 32.5H65C63.62 32.5 62.5 31.375 62.5 30V13.105Z" fill="#111827"/>
<path d="M55 77.5C55 92.6878 42.6878 105 27.5 105C12.3122 105 0 92.6878 0 77.5C0 62.3122 12.3122 50 27.5 50C42.6878 50 55 62.3122 55 77.5ZM12.5 75C11.1193 75 10 76.1193 10 77.5C10 78.8807 11.1193 80 12.5 80H36.4645L28.2322 88.2322C27.2559 89.2085 27.2559 90.7915 28.2322 91.7678C29.2085 92.7441 30.7915 92.7441 31.7678 91.7678L44.2678 79.2678C45.2441 78.2915 45.2441 76.7085 44.2678 75.7322L31.7678 63.2322C30.7915 62.2559 29.2085 62.2559 28.2322 63.2322C27.2559 64.2085 27.2559 65.7915 28.2322 66.7678L36.4645 75H12.5Z" fill="#FAA666"/>
</svg>

export const ExclamationSVG = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 5C11 5.55228 10.5523 6 10 6C9.44771 6 9 5.55228 9 5C9 4.44772 9.44771 4 10 4C10.5523 4 11 4.44772 11 5Z" fill="#6366F1"/>
<path d="M8 8C7.44772 8 7 8.44771 7 9C7 9.55229 7.44772 10 8 10H9V13H8C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H12C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13H11V9C11 8.44771 10.5523 8 10 8H8Z" fill="#6366F1"/>
<path d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2Z" fill="#6366F1"/>
</svg>

export const EditIcon = () => <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 16C1.45 16 0.979334 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14C0 13.45 0.196 12.979 0.588 12.587C0.979334 12.1957 1.45 12 2 12C2.55 12 3.021 12.1957 3.413 12.587C3.80433 12.979 4 13.45 4 14C4 14.55 3.80433 15.021 3.413 15.413C3.021 15.8043 2.55 16 2 16ZM2 10C1.45 10 0.979334 9.804 0.588 9.412C0.196 9.02067 0 8.55 0 8C0 7.45 0.196 6.979 0.588 6.587C0.979334 6.19567 1.45 6 2 6C2.55 6 3.021 6.19567 3.413 6.587C3.80433 6.979 4 7.45 4 8C4 8.55 3.80433 9.02067 3.413 9.412C3.021 9.804 2.55 10 2 10ZM2 4C1.45 4 0.979334 3.804 0.588 3.412C0.196 3.02067 0 2.55 0 2C0 1.45 0.196 0.979333 0.588 0.588C0.979334 0.196 1.45 0 2 0C2.55 0 3.021 0.196 3.413 0.588C3.80433 0.979333 4 1.45 4 2C4 2.55 3.80433 3.02067 3.413 3.412C3.021 3.804 2.55 4 2 4Z" fill="#6B7280"/>
</svg>

export const LighterLockSVG = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 5C11 5.55228 10.5523 6 10 6C9.44771 6 9 5.55228 9 5C9 4.44772 9.44771 4 10 4C10.5523 4 11 4.44772 11 5Z" fill="#6B7280"/>
<path d="M8 8C7.44772 8 7 8.44771 7 9C7 9.55229 7.44772 10 8 10H9V13H8C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H12C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13H11V9C11 8.44771 10.5523 8 10 8H8Z" fill="#6B7280"/>
<path d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2Z" fill="#6B7280"/>
</svg>

export const OrangePlusIcon = () => <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 0C5.55228 0 6 0.447715 6 1V4H9C9.55229 4 10 4.44772 10 5C10 5.55228 9.55229 6 9 6H6V9C6 9.55229 5.55228 10 5 10C4.44772 10 4 9.55229 4 9V6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H4V1C4 0.447715 4.44772 0 5 0Z" fill="#EC5D25"/>
</svg>

export const GreyExclamationSVG = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#6B7280"/>
<path d="M7.5 9.5V5H5.5V6H6.5V9.5H5V10.5H9V9.5H7.5Z" fill="#6B7280"/>
<path d="M7 2.5C6.58579 2.5 6.25 2.83579 6.25 3.25C6.25 3.66421 6.58579 4 7 4C7.41421 4 7.75 3.66421 7.75 3.25C7.75 2.83579 7.41421 2.5 7 2.5Z" fill="#6B7280"/>
</svg>

export const DragDropIcon = () => <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14C0 13.45 0.196 12.979 0.588 12.587C0.979333 12.1957 1.45 12 2 12C2.55 12 3.021 12.1957 3.413 12.587C3.80433 12.979 4 13.45 4 14C4 14.55 3.80433 15.021 3.413 15.413C3.021 15.8043 2.55 16 2 16ZM8 16C7.45 16 6.97933 15.8043 6.588 15.413C6.196 15.021 6 14.55 6 14C6 13.45 6.196 12.979 6.588 12.587C6.97933 12.1957 7.45 12 8 12C8.55 12 9.021 12.1957 9.413 12.587C9.80433 12.979 10 13.45 10 14C10 14.55 9.80433 15.021 9.413 15.413C9.021 15.8043 8.55 16 8 16ZM2 10C1.45 10 0.979333 9.804 0.588 9.412C0.196 9.02067 0 8.55 0 8C0 7.45 0.196 6.979 0.588 6.587C0.979333 6.19567 1.45 6 2 6C2.55 6 3.021 6.19567 3.413 6.587C3.80433 6.979 4 7.45 4 8C4 8.55 3.80433 9.02067 3.413 9.412C3.021 9.804 2.55 10 2 10ZM8 10C7.45 10 6.97933 9.804 6.588 9.412C6.196 9.02067 6 8.55 6 8C6 7.45 6.196 6.979 6.588 6.587C6.97933 6.19567 7.45 6 8 6C8.55 6 9.021 6.19567 9.413 6.587C9.80433 6.979 10 7.45 10 8C10 8.55 9.80433 9.02067 9.413 9.412C9.021 9.804 8.55 10 8 10ZM2 4C1.45 4 0.979333 3.804 0.588 3.412C0.196 3.02067 0 2.55 0 2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0C2.55 0 3.021 0.196 3.413 0.588C3.80433 0.979333 4 1.45 4 2C4 2.55 3.80433 3.02067 3.413 3.412C3.021 3.804 2.55 4 2 4ZM8 4C7.45 4 6.97933 3.804 6.588 3.412C6.196 3.02067 6 2.55 6 2C6 1.45 6.196 0.979333 6.588 0.588C6.97933 0.196 7.45 0 8 0C8.55 0 9.021 0.196 9.413 0.588C9.80433 0.979333 10 1.45 10 2C10 2.55 9.80433 3.02067 9.413 3.412C9.021 3.804 8.55 4 8 4Z" fill="#9CA3AF"/>
</svg>

export const LightGreyCrossIcon = () =>  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.00005 8.39999L2.10005 13.3C1.91672 13.4833 1.68338 13.575 1.40005 13.575C1.11672 13.575 0.883382 13.4833 0.700048 13.3C0.516715 13.1167 0.425049 12.8833 0.425049 12.6C0.425049 12.3167 0.516715 12.0833 0.700048 11.9L5.60005 6.99999L0.700048 2.09999C0.516715 1.91665 0.425049 1.68332 0.425049 1.39999C0.425049 1.11665 0.516715 0.883321 0.700048 0.699987C0.883382 0.516654 1.11672 0.424988 1.40005 0.424988C1.68338 0.424988 1.91672 0.516654 2.10005 0.699987L7.00005 5.59999L11.9 0.699987C12.0834 0.516654 12.3167 0.424988 12.6 0.424988C12.8834 0.424988 13.1167 0.516654 13.3 0.699987C13.4834 0.883321 13.575 1.11665 13.575 1.39999C13.575 1.68332 13.4834 1.91665 13.3 2.09999L8.40005 6.99999L13.3 11.9C13.4834 12.0833 13.575 12.3167 13.575 12.6C13.575 12.8833 13.4834 13.1167 13.3 13.3C13.1167 13.4833 12.8834 13.575 12.6 13.575C12.3167 13.575 12.0834 13.4833 11.9 13.3L7.00005 8.39999Z" fill="#9CA3AF"/>
</svg>

export const RedExclamationSVG = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 5C11 5.55228 10.5523 6 10 6C9.44771 6 9 5.55228 9 5C9 4.44772 9.44771 4 10 4C10.5523 4 11 4.44772 11 5Z" fill="#F43F5E"/>
<path d="M8 8C7.44772 8 7 8.44771 7 9C7 9.55229 7.44772 10 8 10H9V13H8C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15H12C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13H11V9C11 8.44771 10.5523 8 10 8H8Z" fill="#F43F5E"/>
<path d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2Z" fill="#F43F5E"/>
</svg>


export const ErrorMessageSVG = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM6 4C6 3.44772 6.44772 3 7 3C7.55229 3 8 3.44772 8 4V6C8 6.55229 7.55229 7 7 7C6.44772 7 6 6.55229 6 6V4ZM6 10C6 9.44771 6.44772 9 7 9C7.55229 9 8 9.44771 8 10C8 10.5523 7.55229 11 7 11C6.44772 11 6 10.5523 6 10Z" fill="#E11D48"/>
</svg>

export const EyeSVG = () => <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 12.001C12.25 12.001 13.3127 11.5636 14.188 10.689C15.0627 9.81364 15.5 8.75098 15.5 7.50098C15.5 6.25098 15.0627 5.18831 14.188 4.31298C13.3127 3.43831 12.25 3.00098 11 3.00098C9.75005 3.00098 8.68738 3.43831 7.81205 4.31298C6.93738 5.18831 6.50005 6.25098 6.50005 7.50098C6.50005 8.75098 6.93738 9.81364 7.81205 10.689C8.68738 11.5636 9.75005 12.001 11 12.001ZM11 10.201C10.25 10.201 9.61272 9.93831 9.08805 9.41298C8.56272 8.88831 8.30005 8.25098 8.30005 7.50098C8.30005 6.75098 8.56272 6.11331 9.08805 5.58798C9.61272 5.06331 10.25 4.80098 11 4.80098C11.75 4.80098 12.3877 5.06331 12.913 5.58798C13.4377 6.11331 13.7 6.75098 13.7 7.50098C13.7 8.25098 13.4377 8.88831 12.913 9.41298C12.3877 9.93831 11.75 10.201 11 10.201ZM11 15.001C8.68338 15.001 6.56672 14.3886 4.65005 13.164C2.73338 11.9386 1.28338 10.2843 0.300049 8.20098C0.250049 8.11764 0.216716 8.01331 0.200049 7.88798C0.183382 7.76331 0.175049 7.63431 0.175049 7.50098C0.175049 7.36764 0.183382 7.23831 0.200049 7.11298C0.216716 6.98831 0.250049 6.88431 0.300049 6.80098C1.28338 4.71764 2.73338 3.06364 4.65005 1.83898C6.56672 0.613643 8.68338 0.000976562 11 0.000976562C13.3167 0.000976562 15.4334 0.613643 17.35 1.83898C19.2667 3.06364 20.7167 4.71764 21.7001 6.80098C21.75 6.88431 21.7834 6.98831 21.8 7.11298C21.8167 7.23831 21.8251 7.36764 21.8251 7.50098C21.8251 7.63431 21.8167 7.76331 21.8 7.88798C21.7834 8.01331 21.75 8.11764 21.7001 8.20098C20.7167 10.2843 19.2667 11.9386 17.35 13.164C15.4334 14.3886 13.3167 15.001 11 15.001ZM11 13.001C12.8834 13.001 14.6127 12.505 16.188 11.513C17.7627 10.5216 18.9667 9.18431 19.8 7.50098C18.9667 5.81764 17.7627 4.47998 16.188 3.48798C14.6127 2.49664 12.8834 2.00098 11 2.00098C9.11672 2.00098 7.38738 2.49664 5.81205 3.48798C4.23738 4.47998 3.03338 5.81764 2.20005 7.50098C3.03338 9.18431 4.23738 10.5216 5.81205 11.513C7.38738 12.505 9.11672 13.001 11 13.001Z" fill="#EC5D25"/>
</svg>

export const DownArrow = () => <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.195262 0.196239C0.455612 -0.0641108 0.877722 -0.0641108 1.13807 0.196239L4 3.05817L6.86193 0.196239C7.12228 -0.0641108 7.54439 -0.0641108 7.80474 0.196239C8.06509 0.456588 8.06509 0.878698 7.80474 1.13905L4.47141 4.47238C4.21106 4.73273 3.78895 4.73273 3.5286 4.47238L0.195262 1.13905C-0.0650874 0.878698 -0.0650874 0.456588 0.195262 0.196239Z" fill="#6B7280"/>
</svg>

export const SearchIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.84871 15.3729 11.551 14.3199 12.9056L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12.9056 14.3199C11.551 15.3729 9.84871 16 8 16C3.58172 16 0 12.4183 0 8Z" fill="#6B7280"/>
</svg>

export const GreenTickSVG = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.0001 13.4006C8.69748 13.4006 10.3253 12.7263 11.5256 11.5261C12.7258 10.3258 13.4001 8.69797 13.4001 7.00059C13.4001 5.3032 12.7258 3.67534 11.5256 2.4751C10.3253 1.27487 8.69748 0.600586 7.0001 0.600586C5.30271 0.600586 3.67485 1.27487 2.47461 2.4751C1.27438 3.67534 0.600098 5.3032 0.600098 7.00059C0.600098 8.69797 1.27438 10.3258 2.47461 11.5261C3.67485 12.7263 5.30271 13.4006 7.0001 13.4006ZM9.9657 5.96619C10.1114 5.8153 10.1921 5.61322 10.1902 5.40346C10.1884 5.19371 10.1043 4.99306 9.95595 4.84473C9.80763 4.6964 9.60698 4.61227 9.39722 4.61045C9.18746 4.60862 8.98538 4.68926 8.8345 4.83499L6.2001 7.46939L5.1657 6.43499C5.01482 6.28926 4.81273 6.20862 4.60298 6.21045C4.39322 6.21227 4.19257 6.2964 4.04424 6.44473C3.89592 6.59306 3.81178 6.79371 3.80996 7.00346C3.80814 7.21322 3.88877 7.4153 4.0345 7.56619L5.6345 9.16619C5.78452 9.31616 5.98797 9.40041 6.2001 9.40041C6.41223 9.40041 6.61567 9.31616 6.7657 9.16619L9.9657 5.96619Z" fill="#008F5F"/>
</svg>


export const GreenTicksvg = () => <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M1 6.99951L5 10.9995L15 0.999512" stroke="#49A261" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                          

export const TravelBagsvg = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M21 7V14H27V7H21ZM30 14V6C30 4.89543 29.1046 4 28 4H20C18.8954 4 18 4.89543 18 6V14H16C12.6863 14 10 16.6863 10 20V36C10 38.7958 11.9122 41.1449 14.5 41.811V42.5C14.5 43.3284 15.1716 44 16 44C16.8284 44 17.5 43.3284 17.5 42.5V42H30.5V42.5C30.5 43.3284 31.1716 44 32 44C32.8284 44 33.5 43.3284 33.5 42.5V41.811C36.0878 41.1449 38 38.7958 38 36V20C38 16.6863 35.3137 14 32 14H30ZM16 17C14.3431 17 13 18.3431 13 20V36C13 37.6569 14.3431 39 16 39H32C33.6569 39 35 37.6569 35 36V20C35 18.3431 33.6569 17 32 17H16Z" fill="#4B5563"/>
                                  <path d="M19.5 20C20.3284 20 21 20.6716 21 21.5V34.5C21 35.3284 20.3284 36 19.5 36C18.6716 36 18 35.3284 18 34.5V21.5C18 20.6716 18.6716 20 19.5 20Z" fill="#FB923C"/>
                                  <path d="M28.5 20C29.3284 20 30 20.6716 30 21.5V34.5C30 35.3284 29.3284 36 28.5 36C27.6716 36 27 35.3284 27 34.5V21.5C27 20.6716 27.6716 20 28.5 20Z" fill="#FB923C"/>
                                  </svg>
                                
export const ExpenseFilesvg = () => <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.80534 8.17055C9.30036 7.91189 9.8981 7.94969 10.3566 8.26865L12.375 9.67275L14.3934 8.26865C14.9083 7.91046 15.5917 7.91046 16.1066 8.26865L18.125 9.67275L20.1434 8.26865C20.6583 7.91046 21.3417 7.91046 21.8566 8.26865L23.875 9.67275L25.8934 8.26865C26.4083 7.91046 27.0917 7.91046 27.6066 8.26865L29.625 9.67275L31.6434 8.26865C32.1019 7.94969 32.6996 7.91189 33.1947 8.17055C33.6897 8.42921 34 8.94148 34 9.5V28H38.5C39.3284 28 40 28.6716 40 29.5V37.5C40 39.9853 37.9853 42 35.5 42H14C10.6863 42 8 39.3137 8 36V9.5C8 8.94148 8.31032 8.42921 8.80534 8.17055ZM31.2561 39H14C12.3431 39 11 37.6569 11 36V12.3707L11.5184 12.7314C12.0333 13.0896 12.7167 13.0896 13.2316 12.7314L15.25 11.3273L17.2684 12.7314C17.7833 13.0896 18.4667 13.0896 18.9816 12.7314L21 11.3273L23.0184 12.7314C23.5333 13.0896 24.2167 13.0896 24.7316 12.7314L26.75 11.3273L28.7684 12.7314C29.2833 13.0896 29.9667 13.0896 30.4816 12.7314L31 12.3707V37.5C31 38.026 31.0902 38.5308 31.2561 39ZM35.5 39C36.3284 39 37 38.3284 37 37.5V31H34V37.5C34 38.3284 34.6716 39 35.5 39Z" fill="#4B5563"/>
                                    <path d="M14 17.5C14 16.6716 14.6716 16 15.5 16H26.5C27.3284 16 28 16.6716 28 17.5C28 18.3284 27.3284 19 26.5 19H15.5C14.6716 19 14 18.3284 14 17.5Z" fill="#FB923C"/>
                                    <path d="M14 24.5C14 23.6716 14.6716 23 15.5 23H21.5C22.3284 23 23 23.6716 23 24.5C23 25.3284 22.3284 26 21.5 26H15.5C14.6716 26 14 25.3284 14 24.5Z" fill="#FB923C"/>
                                    </svg>

export const BlueTick = () => <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.6835 0.342654C18.1156 0.789138 18.1039 1.50135 17.6574 1.93343L6.03233 13.1834C5.80345 13.4049 5.4914 13.519 5.17363 13.4974C4.85586 13.4758 4.56214 13.3205 4.36537 13.07L0.240434 7.82004C-0.143427 7.33148 -0.0585529 6.62425 0.430006 6.24039C0.918564 5.85653 1.6258 5.9414 2.00966 6.42996L5.36397 10.6992L16.0927 0.316571C16.5392 -0.115507 17.2514 -0.103829 17.6835 0.342654Z" fill="#0A65E7"/>
</svg>