// import { users } from '../../../../Urls';

import {
    // GET_MY_DETAILS,
    SET_MY_DETAILS
} from './MyDetails.action.type';

// importing action creator
// import actionCreator from '../../Common/Utils';

// export const getMyDetails = (token, method) => {

//     return actionCreator({
//         method,
//         url: users.details_by_token,
//         onSuccess: setUserDetails,
//         accessToken: token,
//         onFailure: () => console.log("Failed to fetch Reports."),
//         label: GET_MY_DETAILS
//     });

//     function setUserDetails(resData) {
//         return {
//             type: SET_MY_DETAILS,
//             payload: resData
//         };
//     }

// }

export const setMyDetails = (data) => {
    return {
        type: SET_MY_DETAILS,
        payload: data
    };
}