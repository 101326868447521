export const FETCH_ALL_CARDS = "fetchAllCards";
export const SET_ALL_CARDS = "setAllCards";

export const ACTIVATE_CARD = "activateCard";
export const SET_ACTIVATE_CARD = "setActivateCard";

export const DEACTIVATE_CARD = "deactivateCard";
export const SET_DEACTIVATE_CARD = "setDeactivateCard";

export const SET_CURRENT_CARD_ACTION = 'addCurrentCardAction';
