const paymentBaseUrl = process.env.REACT_APP_PAYMENT_BASE_URL;
const SUBS_BASE_URL = process.env.REACT_APP_SUBSCRIPTIONS_BILLING_URL;
const EXPENSE_BASE_URL = process.env.REACT_APP_BASEURL;

export const subscriptionUrls = {
	GET_SUBSCRIPTION_PLAN_DETAILS: `${SUBS_BASE_URL}/subscription/getSubscriptionPlanDetails`,
	UPDATE_SUBSCRIPTION_DETAILS_BY_APP_ID: `${SUBS_BASE_URL}/subscription/updateSubscriptionDetailsByApplicationClientId`,
	MONTHLY_PAYMENT_REDIRECT: `${paymentBaseUrl}/api/v1/auth`,
	YEARLY_PAYMENT_VERIFY: `${SUBS_BASE_URL}/subscription/generatePaymentOrderId`,
	YEARLY_PAYMENT_REDIRECT: `${paymentBaseUrl}/api/v1/payment/order`,
	VERIFY_PAYMENT: `${SUBS_BASE_URL}/subscription/paymentverify`,
	VERIFY_CARD: `${SUBS_BASE_URL}/subscription/cardverify`,
	FETCH_CLIENT_SUBSCRIPTION: `${SUBS_BASE_URL}/subscription/getActiveSubscriptionDetailsByApplicationClientId`,
	GET_CLIENT_INFO : `${EXPENSE_BASE_URL}/auth/verifypayment`,
	GET_VENDOR_DATA: `${SUBS_BASE_URL}/subscription/getclientconfiguredvendors`,
	GET_ALL_COUNTRY: `${SUBS_BASE_URL}/clientaddress/getClientCountryDetails`,
	SEND_ADDRESS_DETAILS : `${SUBS_BASE_URL}/clientaddress/addClientBillingAddress`,
	CLIENT_CARD_ADDED: `${SUBS_BASE_URL}/subscription/addcard`,
	GET_ALL_UNSUBSCRIBED_CLIENT:`${SUBS_BASE_URL}/subscription/getInactiveSubscriptionClient`,
    GET_CLIENT_ADDRESS : `${SUBS_BASE_URL}/clientaddress/showClientBillingAddress`,
	UPDATE_SUBSCRIPTION_DETAILS_IL_ADMIN : `${SUBS_BASE_URL}/subscription/createIlAdminSubscription`,
	IL_ADMIN_VERIFY : `${EXPENSE_BASE_URL}/subscription/verfiy-iladmin`,
}