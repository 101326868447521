export const FETCH_ALL_PENDING_APPROVALS = "fetchAllPendingApprovals";
export const SET_ALL_PENDING_APPROVALS = "setAllPendingApprovals";

export const REJECT_REPORT = "rejectReport";
export const SET_REJECT_REPORT = "setRejectReason";

export const APPROVE_REPORT = "approveReport";
export const SET_APPROVE_REPORT = "setApproveReason";

export const FETCH_APPROVALS_HISTORY = "fetchApprovalsHistory";
export const SET_APPROVALS_HISTORY= "setApprovalsHistory";