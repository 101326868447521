import createReducer from "../../../../../components/common/Utility";
import { SEND_MAIL, SET_BENEFICIAL_FORM_DATA, SET_DELETE_FUNDING_SOURCE_STATUS, SET_BUSINESS_INFORMATION_FORM_DATA, SET_BUSINESS_TYPE, SET_CONTROLLER_FORM_DATA, SET_CURRENT_FORM_STEP, SET_MAIL_RESPONSE, SET_PREVIOUS_FORM_STEP , DELETE_FUNDING_SOURCE_COMPANY, DELETE_FUNDING_SOURCE_EMPLOYEE, SET_PREVIOUS_BTN_CLICK, SET_BUSINESS_CLASSIFICATION_DATA, SET_BUSINESS_VERIFIED_CUSTOMER_RESPONSE, SET_BENEFICIAL_OWNER_RESPONSE, SET_SELECTED_BUSINESS_CLASSIFICATION_DATA, SET_FUNDING_SOURCE_INFO, SET_ACH_DATA, GET_CONTROLLER_DATA_FROM_API, GET_BUSINESS_CLASSIFICATION_DATA, CREATE_BENEFICIAL_OWNER, CREATE_BUSINESS_VERIFIED_CUSTOMER, SET_CONTROLLER_DATA_FROM_API, SET_IAV_TOKEN, GET_IAV_TOKEN, SET_FUNDING_SOURCE_STATUS, SET_UPLOAD_DOCUMENT_STATUS, SET_ACCOUNT_ALIAS_STATUS, RESET_COMMON_RESPONSE, SET_DOCUMENT_ARRAY_STATUS, SET_MICRO_DEPOSIT_VERIFICATION_STATUS, SET_MICRO_DEPOSIT_VERIFICATION_STATUS_COMPANY, SET_MICRO_DEPOSIT_VERIFICATION_STATUS_EMPLOYEE, SET_MICRO_DEPOSIT_ATTEMPTS, SET_MICRO_DEPOSIT_ATTEMPTS_COMPANY, SET_MICRO_DEPOSIT_ATTEMPTS_EMPLOYEE,SET_DWOLLA_API_TOKEN, SET_CREATE_CUSTOMER_FOR_BACKEND} from "../../../../actions/Admin/Settings/ACH/Ach.action.type";
import { API_END, API_ERROR, API_START, RESET_STATE } from "../../../../actions/Common/Api.types"

export const initialState = {
    isAchEnabled: false,
    isAchApplicable: false,
    isAchRequested: false,
    achData: null,
    sendMailResponse: null,
    currentFormStep: "",
    previousFormStep: "",
    isLoadingData: false,
    businessInfoFormData: null,
    controllerFormData: null,
    controllerFormDataFromApi: null,
    beneficialOwnerFormData: null,
    businessType: null,
    isPreviousContBtnClik : false,
    businessClassificationData: null,
    selectedBusinessClassificationData: null,
    createBusinessVerifiedCustomerResponse: null,
    createBeneficialOwnerResponse: null,
    fundingSourceInfo: null,
    fundingSourceStatusFromApi: null,
    iavTokenData: null,
    deleteFundingSourceStatus: null,
    documentUploadStatusData: null,
    commonResponse:null,
    accountAliasNameStatus: null,
    documentStatusArray: null,
    microDepositVerificationStatusEmployee: null,
    microDepositVerificationStatusCompany: null,
    microdepositAttemptEmployee: null,
    microdepositAttemptCompany: null,
    dwollaApiTokenData:null,
    customerCreationBackendStatus: null
}


export const reducerObj = {
    
    [SET_ACH_DATA]: (prevState, payload) => (
       {
        achData: payload
       }
    ),
    [SET_CURRENT_FORM_STEP]: (prevState, payload) => (
        {
            currentFormStep: payload
        }
    ),
    [SET_PREVIOUS_FORM_STEP]: (prevState, payload) => (
        {
            previousFormStep: payload
        }
    ),
    [SET_BUSINESS_CLASSIFICATION_DATA]: (prevState, payload) => (
        {
            businessClassificationData: payload
        }
    ),
    [SET_PREVIOUS_BTN_CLICK]: (prevState, payload) => (
        {
            isPreviousContBtnClik: payload
        }
    ),
    [SET_BUSINESS_TYPE]: (prevState, payload) => (
        {
            businessType: payload
        }
    ),
    [SET_BUSINESS_INFORMATION_FORM_DATA]: (prevState, payload) => (
        {
            businessInfoFormData: payload
        }
    ),
    
    [SET_CONTROLLER_FORM_DATA]: (prevState, payload) => ( 
        {
            controllerFormData: payload
        }
    ),
    [SET_BENEFICIAL_FORM_DATA]: (prevState, payload) => (
        {
            beneficialOwnerFormData: payload
        }
    ),
    [SET_SELECTED_BUSINESS_CLASSIFICATION_DATA]: (prevState, payload) => (
        {
            selectedBusinessClassificationData: payload
        }
    ),
    [SET_FUNDING_SOURCE_INFO]: (prevState, payload) => (
        {
            fundingSourceInfo: payload
        }
    ),
    [SET_FUNDING_SOURCE_STATUS]: (prevState, payload) => (
        {
            fundingSourceStatusFromApi: payload
        }
    ),
    [SET_MAIL_RESPONSE]: (prevState, payload) => (
        {
            sendMailResponse: payload
        }
    ),
    [SET_BUSINESS_VERIFIED_CUSTOMER_RESPONSE]: (prevState, payload) => (
        {
            createBusinessVerifiedCustomerResponse: payload
        }
    ),
    [SET_BENEFICIAL_OWNER_RESPONSE]: (prevState, payload) => (
        {
            createBeneficialOwnerResponse: payload
        }
    ),
    
    [SET_CONTROLLER_DATA_FROM_API]: (prevState, payload) => (
        {
            controllerFormDataFromApi: payload
        }
    ),
    [SET_IAV_TOKEN]: (prevState, payload) => (
        {
            iavTokenData: payload
        }
    ),
    [SET_DELETE_FUNDING_SOURCE_STATUS]: (prevState, payload) => (
        {
            deleteFundingSourceStatus: payload
        }
    ),
    [SET_UPLOAD_DOCUMENT_STATUS]: (prevState, payload) => (
        {
            documentUploadStatusData: payload,
            // documentStatusArray: [...prevState.documentStatusArray, payload.status]
        }
    ),
    [SET_ACCOUNT_ALIAS_STATUS]: (prevState, payload) => (
        {
            accountAliasNameStatus : payload
        }
    ),
    [RESET_COMMON_RESPONSE]: (prevState, payload) => (
        {
            commonResponse: null
        }
    ),
    [SET_DOCUMENT_ARRAY_STATUS]: (prevState, payload) => (
        {
            documentStatusArray: payload
        }
    ),
    [SET_MICRO_DEPOSIT_VERIFICATION_STATUS_EMPLOYEE]: (prevState, payload) => (
        {
            microDepositVerificationStatusEmployee: payload
        }
    ),
    [SET_MICRO_DEPOSIT_VERIFICATION_STATUS_COMPANY]: (prevState, payload) => (
        {
            microDepositVerificationStatusCompany: payload
        }
    ),
    [SET_MICRO_DEPOSIT_ATTEMPTS_COMPANY]: (prevState, payload) => (
        {
            microdepositAttemptCompany: payload
        }
    ),
    [SET_MICRO_DEPOSIT_ATTEMPTS_EMPLOYEE]: (prevState, payload) => (
        {
            microdepositAttemptEmployee: payload
        }
    ),
    [SET_DWOLLA_API_TOKEN]:(prevState,payload)=>(
        {
            dwollaApiTokenData:payload
        }
    ),
    [SET_CREATE_CUSTOMER_FOR_BACKEND]:(prevState,payload)=>(
        {
            customerCreationBackendStatus:payload
        }
    ),
    [RESET_STATE]: (prevState , payload) => (
        {
            // isAchEnabled: false,
            // isAchApplicable: false,
            // isAchRequested: false,
            sendMailResponse: null,
            achData: null,
            currentFormStep: "",
            previousFormStep: "",
            isLoadingData: false,
            businessInfoFormData: null,
            controllerFormData: null,
            controllerFormDataFromApi: null,
            beneficialOwnerFormData: null,
            businessType: null,
            isPreviousContBtnClik : false,
            businessClassificationData: null,
            selectedBusinessClassificationData: null,
            createBusinessVerifiedCustomerResponse: null,
            createBeneficialOwnerResponse: null,
            fundingSourceInfo: null, // data that dwolla white label gives us
            
            iavTokenData: null,
            fundingSourceStatus: null, // data from our api that we get from dwolla about the status of the funding source status,
            documentUploadStatusData: null,
            deleteFundingSourceStatus: null,
            fundingSourceStatusFromApi: null,
            accountAliasNameStatus: null,
            commonResponse: null,
            microDepositVerificationStatusEmployee: null,
            microDepositVerificationStatusCompany: null,
            microdepositAttemptEmployee: null,
            microdepositAttemptCompany: null,
            dwollaApiTokenData:null,
            customerCreationBackendStatus: null
        
            
        } 
    ),
    [API_START]: (prevState, payload) => {
		if ( payload === SEND_MAIL || payload === GET_CONTROLLER_DATA_FROM_API || payload === GET_BUSINESS_CLASSIFICATION_DATA || payload === CREATE_BENEFICIAL_OWNER || payload === CREATE_BUSINESS_VERIFIED_CUSTOMER || GET_IAV_TOKEN, DELETE_FUNDING_SOURCE_COMPANY, DELETE_FUNDING_SOURCE_EMPLOYEE )
        return { isLoadingData: true }
	},
    
    [API_END]: (prevState, payload) => (
	   ( payload === SET_MAIL_RESPONSE || payload === SET_BUSINESS_CLASSIFICATION_DATA || payload === SET_BUSINESS_VERIFIED_CUSTOMER_RESPONSE || payload === SET_BENEFICIAL_OWNER_RESPONSE || payload === SET_CONTROLLER_DATA_FROM_API )
			? { isLoadingData: false }
			: { isLoadingData: false }
	),
    [API_ERROR]: (prevState, payload) => {
		
			return {
				commonResponse: payload
			}
		
	}
}




let AchReducer = createReducer(initialState , reducerObj);

export default AchReducer;