/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import localStorageService from "./components/common/services/Storage_services/LocalStorageService";
import * as actions_rp from "./store/actions/Admin/RolesAndPermissions/RolesAndPermissions.action";
import * as myDetailsActions from "./store/actions/Common/MyDetails/MyDetails.action";
import loadable from '@loadable/component'

import { BrowserRouter as Router, Switch, Redirect, Link, useHistory, NavLink } from "react-router-dom";
import PrivateRoute from "./components/common/services/Auth/PrivateRoute";
import PublicRoute from "./components/common/services/Auth/PublicRoute";
import AuthRoute from "./components/common/services/Auth/AuthRoute";
import Home from "./components/Admin/Home/Home";

import ProfileSetting from "./components/common/components/ProfileSetting/ProfileSetting";
import axios from "axios";
import { users, subscriptionsUrls, NAVBAR } from "./Urls";

// import DraftExpense from "./components/Personal/DraftExpense/DraftExpense";

// import Approvals from "./components/Admin/Approvals/Approvals";
import { generateJWTAuthKey } from './utils/utils'
import Header from "./components/common/components/Header/Header";
import LandingPage from "./components/Personal/Home/LandingPage";
import GTMService from "./utils/GTMService";

import { services } from '@itilite/utils'

// const DraftExpense = React.lazy(() => import('./components/Personal/DraftExpense/DraftExpense'));
// const Approvals = React.lazy(() => import('./components/Admin/Approvals/Approvals'));

// import ExpenseDetails from "./components/Admin/Report/ExpenseDetails";
// import CreateNewReport from "./components/Personal/DraftExpense/Create/CreateNewReport";
// import AttchmentViewer from "./components/common/components/UI/components/AttachmentViewer";
// import PersonalAdvances from "./components/Personal/Advances/Advances";
// import PendingApproval from "./components/Personal/PendingApproval/PendingApproval";

// import ChangePassword from "./components/Admin/ChangePassword/ChangePassword";
// import Payout from "./components/Admin/Payout/Payout";
// import Report from "./components/Admin/Report/Report";
// import Advances from "./components/Admin/Advances/Advances";
// import BulkUpload from "./components/Admin/BulkUpload/BulkUpload";
// import AdvancesDetails from "./components/Admin/Report/AdvancesDetails";
// import Dashboard from "./components/Admin/OrgDashboard/Dashboard";
// import Settings from "./components/Admin/Settings/Settings";

// import CreateNewAdvance from "./components/Personal/Advances/create/CreateNewAdvance";
// import Cards from "./components/Personal/Cards/Cards";
// import Transactions from "./components/Personal/Cards/Transactions/Transactions";
// import Analytics from "./components/Admin/Analytics/Analytics";
// import AnalyticsDownload from "./components/Admin/Analytics/AnalyticsDownload";
// import GettingStarted from "./components/Onboarding/GettingStarted";
// import OnboardingSubscriptions from "./components/Onboarding/Subscription";
// import PaymentConfirmation from "./components/Onboarding/PaymentConfirmation";
// import PaymentError from "./components/Onboarding/PaymentError";
// import BillingSubscriptions from "./components/Admin/BillingSubscriptions/BillingSubscriptions";
// import HelpSupport from "./components/common/components/HelpSupport/HelpSupport";
// import EditMileage from "./components/Personal/DraftExpense/Create/EditMileage";
// import NewUserAdd from "./components/Admin/Settings/Users/NewUserAdd";
// import UserBanks from "./components/Personal/Banks/UserBanks";
import UniversalNavigation from "./components/common/components/UniversalNavigation/UniversalNavigation";
import { checkRoleAndPermissionForPage, checkRoleAndPermissionForTab } from "./utils/checkRoleAndPermission";
import fetchAndVerifyUSMToken from "./utils/uumFetchAndVerifyToken";
import { getClientDetails, getNavbarDetails } from "./store/actions/Common/Navbar/Navbar.action";
import { SET_NAVBAR_DETAILS } from "./store/actions/Common/Navbar/Navbar.action.type";
// import EditPerDiem from "./components/Personal/DraftExpense/Per Diem/EditPerDiem";
// import CreateNewExpense from "./components/Personal/DraftExpense/Create/CreateNewExpense";
// import ChangePassword from "./components/Admin/ChangePassword/ChangePassword";

const { IUILocalStorageService } = services;

const DraftExpense = loadable(() => import('./components/Personal/DraftExpense/DraftExpense'))
const Settings = loadable(() => import('./components/Admin/Settings/Settings'),)
const Payout = loadable(() => import('./components/Admin/Payout/Payout'),)
const Report = loadable(() => import('./components/Admin/Report/Report'),)
// const Header = loadable(() => import('./components/common/components/Header/Header'),)
const Advances = loadable(() => import( './components/Admin/Advances/Advances'),)
const BulkUpload = loadable(() => import('./components/Admin/BulkUpload/BulkUpload'),)
const AdvancesDetails = loadable(() => import('./components/Admin/Report/AdvancesDetails'),)
const Dashboard = loadable(() => import('./components/Admin/OrgDashboard/Dashboard'),)
const ExpenseDetails = loadable(() => import('./components/Admin/Report/ExpenseDetails'),)

const AttchmentViewer = loadable(() => import('./components/common/components/UI/components/AttachmentViewer'),)
const PersonalAdvances = loadable(() => import('./components/Personal/Advances/Advances'),)
const PendingApproval = loadable(() => import('./components/Personal/PendingApproval/PendingApproval'),)

const ChangePassword = loadable(() => import('./components/Admin/ChangePassword/ChangePassword'),)
// const UniversalNavigation = loadable(() => import('./components/common/components/UniversalNavigation/UniversalNavigation'),)
const EditPerDiem = loadable(() => import('./components/Personal/DraftExpense/Per Diem/EditPerDiem'),)
const CreateNewAdvance = loadable(() => import('./components/Personal/Advances/create/CreateNewAdvance'),)
const Cards = loadable(() => import('./components/Personal/Cards/Cards'),)
const Transactions = loadable(() => import('./components/Personal/Cards/Transactions/Transactions'),)
const Analytics = loadable(() => import('./components/Admin/Analytics/Analytics'),)
const CorporateCards = loadable(() => import('./components/Admin/CorporateCards/CorporateCards'))
const AnalyticsDownload = loadable(() => import('./components/Admin/Analytics/AnalyticsDownload'),)
const GettingStarted = loadable(() => import('./components/Onboarding/GettingStarted'),)
const OnboardingSubscriptions = loadable(() => import('./components/Onboarding/Subscription'),)
const PaymentConfirmation = loadable(() => import('./components/Onboarding/PaymentConfirmation'),)
const PaymentError = loadable(() => import('./components/Onboarding/PaymentError'),)
const BillingSubscriptions = loadable(() => import('./components/Admin/BillingSubscriptions/BillingSubscriptions'),)
const HelpSupport = loadable(() => import('./components/common/components/HelpSupport/HelpSupport'),)
const EditMileage = loadable(() => import('./components/Personal/DraftExpense/Create/EditMileage'),)
const NewUserAdd = loadable(() => import('./components/Admin/Settings/Users/NewUserAdd'),)
const UserBanks = loadable(() => import('./components/Personal/Banks/UserBanks'),)
const CreateNewExpense = loadable(() => import('./components/Personal/DraftExpense/Create/CreateNewExpense'),)
const ExportHistory = loadable(() => import('./components/Admin/ExportHistory/ExportHistory'),)

// import ExportHistory from "./components/Admin/ExportHistory/ExportHistory";

const CreateNewReport = loadable(() =>
  import('./components/Personal/DraftExpense/Create/CreateNewReport'),
)
const Approvals = loadable(() => import('./components/Admin/Approvals/Approvals'), {
  webpackPrefetch: true 
})

const App = () => {
  // test comment line
  const [rolesAndPermData, setRolesAndPermData] = useState({
    showReportsTab: false,
    showPeronalExpenseReportTab: false,
    showPayoutTab: false,
    showAdvancesTab: false,
    showSettingsTab: false,
    showDashboardTab: false,
    showReportDownloadTab: false,
    showPendingApprovalTab: false,
    showOrgTab: false,
    showILTab: false,
    showAchUser: false,
    showAchAdmin: false,
    showPerDiemUser: false
  });



//   useEffect(() => {
    
    
//     // UniversalNavigation.preload()
//     DraftExpense.preload()

//       Settings.preload()
    // ExpenseDetails.preload()

//     ChangePassword.preload()
//     Payout.preload()
//     Report.preload()
//     Advances.preload()
//     BulkUpload.preload()
//     AdvancesDetails.preload()
//     Dashboard.preload()



//     AttchmentViewer.preload()
//     PersonalAdvances.preload()
//     PendingApproval.preload()
//     CreateNewReport.preload()
//     Approvals.preload()
//     EditPerDiem.preload()
//     EditMileage.preload()
//     CreateNewAdvance.preload()
//     Cards.preload()
//     Transactions.preload()
//     Analytics.preload()
//     AnalyticsDownload.preload()
//     GettingStarted.preload()
//     OnboardingSubscriptions.preload()
//     PaymentConfirmation.preload()
//     PaymentError.preload()
//     BillingSubscriptions.preload()
//     HelpSupport.preload()
//     NewUserAdd.preload()
//     UserBanks.preload()
//     ChangePassword.preload()

// }, [] )


  const initialState = {
    role: "employee",
    showUtrModal: false,
    rolesAndPermissions: {},
  };

  const token = localStorageService.getAccessToken();

  const reducer = (state, action) => {
    switch (action.type) {
      case "showUtrModal":
        return {
          ...state,
          showUtrModal: action.payload.showUtrModal,
        };

      default:
        return;
    }
  };

  const [stateData, dispatcher] = useReducer(reducer, initialState);
  const [isInitCheck, setInitCheck] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [usmDetails, setUsmDetails] = useState({});
  const [displaySidebar, setDisplaySidebar] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [urlToReturnForSubscription, setUrlToReturnForSubscription] =
    useState("");
  const [billingMenuVisible, setBillingMenuVisible] = useState(false);
  const [ilAdminMenuVisible, setIlAdminMenuVisible] = useState(false);
  const [showTrailHeaderUI, setTrailHeaderUI] = useState(false);
  const [trailHeader, setTrailHeader] = useState(null);
  const [isSessionActive, setIsSessionActive] = useState(true);

  // initiating dispatch hook.
  const dispatch = useDispatch();

  const rolesAndPermissionsState = useSelector((state) => state.RolesAndPermissionsReducer)
  const { userRolesAndPermissions, card_recon_enabled, expense_only, ach_flags, show_per_diem } = rolesAndPermissionsState;
 
  const navbarDetails = useSelector(state => state.NavbarReducer);
    // destructuring state variables.
    const { uumDetails } = navbarDetails;

  const checkAchPermissionUser = () => {

    if(ach_flags)
    {
      if(ach_flags.is_ach_applicable && ach_flags.details?.customer?.status === "verified")
      return true
      else return false
    }

  }

  const checkAchPermissionAdmin = () => {

    if(ach_flags)
    {
      if(ach_flags.is_ach_applicable)
      return true
      else return false
    }

  }

  const checkPerDiemPermission = () => {
    if(show_per_diem)
    {
      return true
    }
    else{
      return false
    }
  }
  
  useEffect(() => {
    if(Object.keys(userRolesAndPermissions).length && uumDetails?.company)
    {
      // axios.post(
      //   NAVBAR.auth + `/${uumDetails?.company}`,

      // )

      fetch(`${process.env.REACT_APP_USM_URL}${NAVBAR.auth}/${uumDetails?.company}`, {
        method: 'POST',
        headers: {
          'access-token': JSON.parse(localStorageService.getAccessToken()),
          'Authorization': `Bearer ${IUILocalStorageService.getAccessToken()}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refresh_token: IUILocalStorageService.getRefreshToken()
        })
      }).then(res => res.json()).then(res => {
        dispatch({
          type: SET_NAVBAR_DETAILS,
          payload: res
        })
      })

      // axios({
      //   method: 'post',
      //   url: NAVBAR.auth + `/${uumDetails?.company}`,
      //   data: {
      //     refresh_token: IUILocalStorageService.getRefreshToken()
      //   },
      //   headers: {
      //     'access-token': JSON.parse(localStorageService.getAccessToken()),
      //     'Authorization': `Bearer ${IUILocalStorageService.getAccessToken()}`
      //   }
      // }).then(res => {
      //   console.log(res)
      // })


      // dispatch(getNavbarDetails(
      //   JSON.parse(localStorageService.getAccessToken()),
      //   "POST",
      //   {
      //     refresh_token: IUILocalStorageService.getRefreshToken()
      //   },
      //   uumDetails?.company,
      //   [{
      //     key: "Authorization",
      //     value: `Bearer ${IUILocalStorageService.getAccessToken()}`
      //   }]
      // ))
    }
  },[userRolesAndPermissions, uumDetails])

useEffect(() => {
  if (Object.keys(userRolesAndPermissions).length) {
    let showReportsTab = checkRoleAndPermissionForPage(
      userRolesAndPermissions,
      "/approvals"
    );
    let showPeronalExpenseReportTab = checkRoleAndPermissionForPage(
      userRolesAndPermissions,
      "/individual/reports"
    );
    let showPersonalAdvanceTab = checkRoleAndPermissionForPage(
      userRolesAndPermissions,
      "/individual/advances"
    );
    let showPayoutTab = checkRoleAndPermissionForPage(
      userRolesAndPermissions,
      "/payout"
    );
    let showAdvancesTab = checkRoleAndPermissionForPage(
      userRolesAndPermissions,
      "/advances"
    );
    let showSettingsTab = checkRoleAndPermissionForPage(
      userRolesAndPermissions,
      "/settings"
    );
    let showDashboardTab = checkRoleAndPermissionForPage(
      userRolesAndPermissions,
      "/insights"
    );
    let showReportDownloadTab = checkRoleAndPermissionForPage(
      userRolesAndPermissions,
      "/analytics"
    );
    let showPendingApprovalTab = checkRoleAndPermissionForTab(
      userRolesAndPermissions,
      "/individual/approvals",
      "#pending-approvals",
      "#approvals-histroy"
    );
    let showAchUser = checkAchPermissionUser()
    let showAchAdmin = checkAchPermissionAdmin()
    let showPerDiemUser = checkPerDiemPermission()

    setRolesAndPermData({
      ...rolesAndPermData,
      showReportsTab: showReportsTab,
      showPeronalExpenseReportTab: showPeronalExpenseReportTab,
      showPersonalAdvanceTab: showPersonalAdvanceTab,
      showPayoutTab: showPayoutTab,
      showAdvancesTab: showAdvancesTab,
      showSettingsTab: showSettingsTab,
      showDashboardTab: showDashboardTab,
      showReportDownloadTab: showReportDownloadTab,
      showPendingApprovalTab: showPendingApprovalTab,
      showOrgTab:
        showReportsTab ||
        showPayoutTab ||
        showAdvancesTab ||
        showSettingsTab ||
        showDashboardTab ||
        showReportDownloadTab,
      showILTab: ilAdminMenuVisible,
      showAchUser: showAchUser,
      showAchAdmin: showAchAdmin,
      showPerDiemUser: showPerDiemUser
    });

  }
}, [userRolesAndPermissions, ilAdminMenuVisible, show_per_diem]);

useEffect(() => {
  
  GettingStarted.preload()
  CreateNewExpense.preload()
  DraftExpense.preload()
  ExpenseDetails.preload()
  EditPerDiem.preload()
  EditMileage.preload()
  ChangePassword.preload()
  NewUserAdd.preload()

  if(rolesAndPermData.showPendingApprovalTab)
  {
    Approvals.preload()
  }
  if(rolesAndPermData.showReportsTab)
  {
    // DraftExpense.preload()
  }
  if(rolesAndPermData.showPayoutTab)
  {
    Payout.preload()
  }
  if(rolesAndPermData.showPeronalExpenseReportTab)
  {
    // DraftExpense.preload()
  }
  if(rolesAndPermData.showPersonalAdvanceTab)
  {
    PersonalAdvances.preload()
    CreateNewAdvance.preload()
  }
  if(rolesAndPermData.showPendingApprovalTab)
  {
    PendingApproval.preload()
  }

  if(rolesAndPermData.showOrgTab && rolesAndPermData.showSettingsTab)
  {
    Settings.preload()

  }

  // else if( !rolesAndPermData.showSettingsTab && rolesAndPermData.showOrgTab)
  // {
  //   Settings.preload()
  // }

  if(rolesAndPermData.showOrgTab)
  {
    Analytics.preload()
    AnalyticsDownload.preload()
    OnboardingSubscriptions.preload()
    PaymentConfirmation.preload()
    PaymentError.preload()
    BillingSubscriptions.preload()
    HelpSupport.preload()

  }


  // Payout.preload()
  // Approvals.preload()
  // Report.preload()
  // Advances.preload()
  // BulkUpload.preload()
  // AdvancesDetails.preload()
  // Dashboard.preload()
  // AttchmentViewer.preload()
  // CreateNewReport.preload()
  // EditPerDiem.preload()
  // CreateNewAdvance.preload()
  // Cards.preload()
  // Transactions.preload()

  // EditMileage.preload()
  // NewUserAdd.preload()
  // UserBanks.preload()

}, [rolesAndPermData] )



  useEffect(() => {


      // dispatching the "fetchRolesAndPermissions" action.
      if (isInitCheck && localStorageService.getAccessToken()) {
        dispatch(actions_rp.fetchRolesAndPermissions(JSON.parse(token), "GET"));
      } else if (!isInitCheck && urlToReturnForSubscription === "") {
        if (
          localStorageService.getFlowToken() &&
          localStorageService.getFlowToken() === "/"
        )
          return;
        axios({
          method: "get",
          url: users.details_by_token,
          data: {},
          headers: {
            "access-token": JSON.parse(localStorageService.getAccessToken()),
            "request-from": "web",
          },
        })
          .then((res) => {
            let urlToReturn = "/home";
            if (res.status === 200) {
              if(!res.data.data.is_active){
                setIsSessionActive(false);
                window.localStorage.clear();
              }
              dispatch(myDetailsActions.setMyDetails(res.data));

              (async () => {
                const usmResp = await fetchAndVerifyUSMToken(dispatch);
                 setUsmDetails(usmResp);

              })()
              if (localStorageService.getFlowToken() === "/subscriptions") {
                setDisplaySidebar(false);
              } else {
                setDisplaySidebar(true);
              }
              setUserDetails(res.data.data);
              setInitCheck(true);
              verifyIlAdmin(res.data.data.email);
              if (localStorageService.getShowHeaderTrail()) {
                getActiveSubscriptionDetailsByApplicationClientId(
                    res.data.data.client_id
                )
              }
              if (res.data.data.is_active && res.data.data.chat_id) {
                var Tawk_API = Tawk_API || {},
                  Tawk_LoadStart = new Date();
                
                window.Tawk_API = window.Tawk_API || {};
  
                window.Tawk_API.onChatMinimized = function(){
                    window.productFruits.api.button.showButton();
                };
                window.Tawk_API.onChatMaximized = function() {
                    window.productFruits.api.button.hideButton();
                }
                
                window.addEventListener('productfruits_button_ext_widget_init', () => {
                  window.Tawk_API.maximize();
                });
  
                (function () {
                  var s1 = document.createElement("script"),
                    s0 = document.getElementsByTagName("script")[0];
                  s1.async = true;
                  s1.src = `https://embed.tawk.to/${res.data.data.chat_id}/default`;
                  s1.charset = "UTF-8";
                  s1.setAttribute("crossorigin", "*");
                  s0.parentNode.insertBefore(s1, s0);
                })();
              }
              return <Redirect to={urlToReturn} />;
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              localStorageService.clearToken();
              localStorageService.closeTrailHeader();
              setIsSessionActive(false);
              setInitCheck(true);
              return <Redirect to="/" />;
            }
          });
      }

  }, [dispatch, isInitCheck, urlToReturnForSubscription]);
  
  useEffect(() => {
    if (userDetails?.id && usmDetails?.id) {
      
        GTMService.init({

            first_name: userDetails.first_name,

            last_name: userDetails.last_name,

            username: userDetails.email,
			
            email: userDetails.email,

            client_id: userDetails.client_id,

            role: userDetails.role,

            user_id: usmDetails?.id,

            company_name:userDetails.company,

            usm_company_id: usmDetails.company
        })

    }

}, [userDetails, usmDetails]);
  
  const verifyIlAdmin = async (email) => {
    try {
      const resp = await axios({
        method: "POST",
        url: subscriptionsUrls.IL_ADMIN_VERIFY,
        data: {
          // expense_client_id: res.data.result.client_id,
          username: email,
        },
        headers: {
          "access-token": JSON.parse(token),
          "service-type": "client-onboarding",
        },
      });
      if (resp.status === 200 && resp.data && resp.data.iladmin_action) {
        setIlAdminMenuVisible(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getActiveSubscriptionDetailsByApplicationClientId = (client_id) => {
    const authKey = generateJWTAuthKey()
    axios({
        method: 'POST',
        url: subscriptionsUrls.get_active_subscription_details_by_app_id,
        data: {
            expense_client_id: client_id
        },
        headers: {
            Authorization: authKey,
            'access-token': JSON.parse(
                localStorageService.getAccessToken()
            ),
            'service-type': 'client-onboarding'
        }
    }).then((resp) => {
        if (
            resp.status === 200 &&
            resp.data &&
            !resp.data.status &&
            resp.data.trial_period_msg
        ) {
            localStorageService.showTrailHeader(true)
            setTrailHeaderUI(
                resp.data.trial_period_msg.trial_period_status
                    ? resp.data.trial_period_msg.message +
                          (resp.data.trial_period_msg.days || null)
                    : resp.data.trial_period_msg.message
            )
            setTrailHeader(
                resp.data.trial_period_msg.trial_period_status
                    ? 'Trial ending soon!'
                    : 'Trial period is over!'
            )
        }
    })
  }

  const showHideUtrModal = () => {
    dispatcher({
      type: "showUtrModal",
      payload: {
        showUtrModal: true,
      },
    });
  };

  const submitUtr = () => {
    dispatcher({
      type: "showUtrModal",
      payload: {
        showUtrModal: false,
      },
    });
  };
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };
  const handleCloseToggleSidebar = () => {
    setToggled(!toggled);
  };

  return (
    <Fragment>
      {
        <Router>
          {displaySidebar && (
            <Header
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
              handleCloseToggleSidebar={handleCloseToggleSidebar}
              billingMenuVisible={billingMenuVisible}
              ilAdminMenuVisible={ilAdminMenuVisible}
            />
          )}

          <div className={`App ${displaySidebar ? "" : "no-sidebar"}`}>
          {displaySidebar && isInitCheck && isSessionActive && <UniversalNavigation/>}
            
            <Switch>
              <AuthRoute
                path="/auth/:token"
                isInitCheck={isInitCheck}
                setInitCheck={setInitCheck}
                setClientDetails={setClientDetails}
                setUrlToReturnForSubscription={setUrlToReturnForSubscription}
                setDisplaySidebar={setDisplaySidebar}
                setBillingMenuVisible={setBillingMenuVisible}
                setIlAdminMenuVisible={setIlAdminMenuVisible}
                exact
                setTrailHeaderUI={setTrailHeaderUI}
              />
              {isInitCheck && (
                <PublicRoute
                  path="/"
                  exact
                  render={(props) => <Home {...props} />}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/approvals"
                  exact
                  render={(props) => (
                    <Approvals
                      role={stateData.role}
                      data={stateData.data}
                      showUtrModal={showHideUtrModal}
                      displayUtrModal={stateData.showUtrModal}
                      submitUtr={submitUtr}
                      handleToggleSidebar={handleToggleSidebar}
                      userDetails={userDetails}
                      showTrailHeaderUI={showTrailHeaderUI}
                      trailHeader={trailHeader}
                      setTrailHeaderUI={setTrailHeaderUI}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                ></PrivateRoute>
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/advances"
                  exact
                  render={(props) => (
                    <Advances
                      role={stateData.role}
                      data={stateData.data}
                      showUtrModal={showHideUtrModal}
                      displayUtrModal={stateData.showUtrModal}
                      submitUtr={submitUtr}
                      handleToggleSidebar={handleToggleSidebar}
                      showTrailHeaderUI={showTrailHeaderUI}
                      trailHeader={trailHeader}
                      setTrailHeaderUI={setTrailHeaderUI}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                ></PrivateRoute>
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/report/:reportId"
                  exact
                  render={(props) => (
                    <Report
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/payout"
                  exact
                  render={(props) => (
                    <Payout
                      role={stateData.role}
                      data={stateData.data}
                      showUtrModal={showHideUtrModal}
                      displayUtrModal={stateData.showUtrModal}
                      submitUtr={submitUtr}
                      handleToggleSidebar={handleToggleSidebar}
                      showTrailHeaderUI={showTrailHeaderUI}
                      trailHeader={trailHeader}
                      setTrailHeaderUI={setTrailHeaderUI}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    />
                  )}
                ></PrivateRoute>
              )}
              {isInitCheck && (
                <PrivateRoute path="/upload" exact>
                  <BulkUpload
                    data={stateData.uploadData}
                    showUtrModal={showHideUtrModal}
                    submitUtr={submitUtr}
                  />
                </PrivateRoute>
              )}
              {isInitCheck && (
                <PrivateRoute path="/home" exact component={LandingPage} />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/expense/:expenseId"
                  exact
                  render={(props) => (
                    <ExpenseDetails 
                    {...props}
                    fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>} />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/advance/:advanceId"
                  exact
                  component={AdvancesDetails}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/insights"
                  exact
                  render={(props) => (
                    <Dashboard
                      handleToggleSidebar={handleToggleSidebar}
                      showTrailHeaderUI={showTrailHeaderUI}
                      trailHeader={trailHeader}
                      setTrailHeaderUI={setTrailHeaderUI}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/advances"
                  exact
                  render={(props) => (
                    <PersonalAdvances
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/reports"
                  exact
                  render={(props) => (
                    <DraftExpense
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/approvals"
                  exact
                  render={(props) => (
                    <PendingApproval
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/reports/expense/edit/:e_id"
                  exact
                  render={(props) => (
                    <CreateNewExpense
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/reports/expense/mileage/edit/:e_id"
                  exact
                  render={(props) => (
                    <EditMileage
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/reports/expense/perDiem/edit/:e_id"
                  exact
                  render={(props) => (
                    <EditPerDiem
                      handleToggleSidebar={handleToggleSidebar}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                      {...props}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/reports/expense/create"
                  exact
                  render={(props) => (
                    <CreateNewExpense
                      handleToggleSidebar={handleToggleSidebar}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                      {...props}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/reports/report/create/:reportId"
                  exact
                  render={(props) => (
                    <CreateNewReport
                      handleToggleSidebar={handleToggleSidebar}
                      userDetails={userDetails}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/advances/advance/:action/:a_id"
                  exact
                  render={(props) => (
                    <CreateNewAdvance
                      handleToggleSidebar={handleToggleSidebar}
                      userDetails={userDetails}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/advances/advance/:action"
                  exact
                  render={(props) => (
                    <CreateNewAdvance
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/cards"
                  exact
                  render={(props) => (
                    <Cards
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/cards/transactions/:id"
                  exact
                  render={(props) => (
                    <Transactions
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/individual/banks"
                  exact
                  render={(props) => (
                    <UserBanks
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/attachment"
                  exact
                  component={AttchmentViewer}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/profile"
                  exact
                  render={(props) => (
                    // <ProfileSetting
                    //   handleToggleSidebar={handleToggleSidebar}
                    //   {...props}
                    // />
					<NewUserAdd 
						handleToggleSidebar={handleToggleSidebar} 
						isProfile={true} 
            fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
						{...props}
					/>
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/changepassword"
                  exact
                  component={ChangePassword}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/settings"
                  render={(props) => (
                    <Settings
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      userDetails={clientDetails}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/analytics"
                  exact
                  render={(props) => (
                    <Analytics
                      handleToggleSidebar={handleToggleSidebar}
                      showTrailHeaderUI={showTrailHeaderUI}
                      trailHeader={trailHeader}
                      setTrailHeaderUI={setTrailHeaderUI}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/corporate-cards"
                  exact
                  render={(props) => (
                    <CorporateCards
                      handleToggleSidebar={handleToggleSidebar}
                      showTrailHeaderUI={showTrailHeaderUI}
                      trailHeader={trailHeader}
                      setTrailHeaderUI={setTrailHeaderUI}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/analytics/:widget_name"
                  exact
                  render={(props) => (
                    <AnalyticsDownload
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                    
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/getting-started"
                  exact
                  render={(props) => (
                    <GettingStarted
                      handleToggleSidebar={handleToggleSidebar}
                      userDetails={userDetails}
                      setDisplaySidebar={setDisplaySidebar}
                      // result={result} isLoading={isLoading}
                      {...props}
                      
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/subscriptions"
                  exact
                  render={(props) => (
                    <OnboardingSubscriptions
                      handleToggleSidebar={handleToggleSidebar}
                      userDetails={userDetails}
                      clientDetails={clientDetails}
                      setDisplaySidebar={setDisplaySidebar}
                      setIlAdminMenuVisible={setIlAdminMenuVisible}
                      {...props}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/adminsubscriptions"
                  exact
                  render={(props) => (
                    <OnboardingSubscriptions
                      handleToggleSidebar={handleToggleSidebar}
                      userDetails={userDetails}
                      clientDetails={clientDetails}
                      setDisplaySidebar={setDisplaySidebar}
                      {...props}
                      isIlAdmin={ilAdminMenuVisible}
                      setIlAdminMenuVisible={setIlAdminMenuVisible}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/payment-loading"
                  exact
                  render={(props) => (
                    <PaymentConfirmation
                      handleToggleSidebar={handleToggleSidebar}
                      setDisplaySidebar={setDisplaySidebar}
                      setTrailHeaderUI={setTrailHeaderUI}
                      {...props}
                      
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/payment-error"
                  exact
                  render={(props) => (
                    <PaymentError
                      handleToggleSidebar={handleToggleSidebar}
                      setDisplaySidebar={setDisplaySidebar}
                      {...props}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/billingsubscriptions"
                  exact
                  render={(props) => (
                    <BillingSubscriptions
                      handleToggleSidebar={handleToggleSidebar}
                      setDisplaySidebar={setDisplaySidebar}
                      {...props}
                    />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/export-history"
                  exact
                  render={(props) => (
                    <ExportHistory
                      handleToggleSidebar={handleToggleSidebar}
                      setDisplaySidebar={setDisplaySidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                     />
                  )}
                />
              )}
              {isInitCheck && (
                <PrivateRoute
                  path="/help"
                  exact
                  render={(props) => (
                    <HelpSupport
                      handleToggleSidebar={handleToggleSidebar}
                      {...props}
                      fallback={<div><img src="../../../../assets/images/loader.gif" alt="Loader" className="loader-img" /> </div>}
                     />
                  )}
                />
              )}
            </Switch>
          </div>
        </Router>
      }
    </Fragment>
  );
};

export default App;
